import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { DialogActions, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function DeleteConfirmDialog(props) {
    const open = props.open;
    const itemToDelete = props.itemToDelete;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const close = () => {
        props.cancel();
    };
    const deleteConfirm = () => {
        props.confirmDelete();
    };

    return (
        <Dialog
            onClose={close}
            aria-labelledby="simple-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogTitle>{t('general.confirmRemoveTitle')}</DialogTitle>
            <DialogContent dividers>
                <br />
                <span>
                    {t('general.confirmRemove')} <strong>{itemToDelete}</strong>?
                </span>
                <br />
            </DialogContent>
            <DialogActions>
                <Button
                    name="dialog-btn-locationedit-remove"
                    variant="contained"
                    color="primary"
                    onClick={deleteConfirm}>
                    {t('buttons.remove')}
                </Button>
                <Button name="dialog-btn-locationedit-cancel-remove" variant="contained" onClick={close}>
                    {t('buttons.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
