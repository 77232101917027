const LocationDeliveryAddressResolver = {
    getDeliveryAddress(location) {
        let deliveryInfo = '';
        if (location != null) {
            if (location.deliveryCustomer != null && location.deliveryCustomer.deliveryAddress != null) {
                const deliveryRecipient =
                    location.deliveryCustomer.deliveryAddress.name != null &&
                    location.deliveryCustomer.deliveryAddress.name.length > 0
                        ? location.deliveryCustomer.deliveryAddress.name
                        : location.deliveryCustomer.name;
                deliveryInfo = `${deliveryRecipient}, ${location.deliveryCustomer.deliveryAddress.streetAddress}, ${location.deliveryCustomer.deliveryAddress.zipCode} ${location.deliveryCustomer.deliveryAddress.city}`;
            } else if (location.deliveryAddress != null) {
                const deliveryRecipient =
                    location.deliveryAddress.name != null && location.deliveryAddress.name.length > 0
                        ? location.deliveryAddress.name
                        : location.name;
                deliveryInfo = `${deliveryRecipient}, ${location.deliveryAddress.streetAddress}, ${location.deliveryAddress.zipCode} ${location.deliveryAddress.city}`;
            }
        }
        return deliveryInfo;
    },
};

export default LocationDeliveryAddressResolver;
