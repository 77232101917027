import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLocationFull, getCustomerTree } from '../../modules/customerlocationdata';
import { newOrderFromLocations, addMachineToOrder, addFilterToOrder } from '../../modules/customerorderdata';
import CustomerHomeLocations from './customerhomelocations';
import { useTranslation } from 'react-i18next';

export default function CustomerHomeContainer() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const locations = useSelector((state) => state.customerlocationdata.locationDescriptions);
    const customers = useSelector((state) => state.customerlocationdata.customers);
    const pendingCustomerOrders = useSelector((state) => state.customerorderdata.pendingOrders);
    const locationsById = useSelector((state) => state.customerlocationdata.locationsById);

    useEffect(() => {
        dispatch(getCustomerTree());
    }, [dispatch]);

    const _newOrderFromLocations = (locationIds) => {
        dispatch(newOrderFromLocations(locationIds));
    };

    const _getLocationFull = (id) => {
        dispatch(getLocationFull(id));
    };

    const _addMachineToOrder = (locId, machineId) => {
        dispatch(addMachineToOrder(locId, machineId));
    };

    const _addFilterToOrder = (locationId, machineId, filterId) => {
        dispatch(addFilterToOrder(locationId, machineId, filterId));
    };

    if (customers == null) {
        return <div>{t('general.loading')}</div>;
    }

    return (
        <div>
            <CustomerHomeLocations
                locations={locations}
                customers={customers}
                addToCart={_newOrderFromLocations}
                pendingCustomerOrders={pendingCustomerOrders}
                locationsById={locationsById}
                getLocationFull={_getLocationFull}
                addMachineToCart={_addMachineToOrder}
                addFilterToCart={_addFilterToOrder}
            />
        </div>
    );
}
