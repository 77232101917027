import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function GlobalNotifications(props) {
    return (
        <div>
            <ToastContainer position="bottom-right" draggable={false} pauseOnHover />
        </div>
    );
}

export default GlobalNotifications;
