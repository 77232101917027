import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import UserStatusBoolean from './userstatusboolean';
import RepresentationsList from './representationslist';

const useStyles = makeStyles((theme) => ({
    openButton: {
        marginTop: '10px',
    },
    labelText: {
        fontWeight: 'bold',
    },
}));

export default function UserView(props) {
    const classes = useStyles();
    const user = props.user;

    const onEdit = () => {
        props.history.push('/admin/user/edit/' + user.id);
    };

    return (
        <Grid container>
            {user && (
                <Grid item container>
                    <Grid item xs={12} md={6} className={classes.labelText}>
                        Tila
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <UserStatusBoolean status={{ enabled: user.enabled, amazonStatus: user.amazonStatus }} />
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.labelText}>
                        Käyttäjänimi
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {user.username}
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.labelText}>
                        Nimi
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {user.name}
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.labelText}>
                        Puhelin
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {user.phoneNumber}
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.labelText}>
                        Roolit
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {user.roles.map((r) => (
                            <span key={r}>{r}&nbsp;</span>
                        ))}
                    </Grid>

                    {user.isCustomerUser && (
                        <Grid item container>
                            <Grid item xs={12} md={6} className={classes.labelText}>
                                Edustaa
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RepresentationsList representations={user.representations} allowEdit={false} />
                            </Grid>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Button variant="outlined" name="btn-open-edit" className={classes.openButton} onClick={onEdit}>
                            <i className="fas fa-pencil-alt" />
                            &nbsp;Muokkaa
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
