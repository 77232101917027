import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

export default function Spinner(props) {
    const padding = props.padding ? props.padding : '0px';
    const size = props.size ? props.size : '1rem';

    return (
        <div>
            <div style={{ padding: padding }}>
                {props.title && <React.Fragment>{props.title}... &nbsp;</React.Fragment>}
                <CircularProgress color={'secondary'} size={size} />
            </div>
        </div>
    );
}

Spinner.propTypes = {
    padding: PropTypes.string,
    size: PropTypes.string,
    title: PropTypes.string,
};
