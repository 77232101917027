import i18next from 'i18next';

function monthNbrToText(nbr) {
    switch (nbr) {
        case 0:
            return '';
        case 1:
            return i18next.t('month.january') ? i18next.t('month.january') : 'month.january';
        case 2:
            return i18next.t('month.february') ? i18next.t('month.february') : 'month.february';
        case 3:
            return i18next.t('month.march') ? i18next.t('month.march') : 'month.march';
        case 4:
            return i18next.t('month.april') ? i18next.t('month.april') : 'month.april';
        case 5:
            return i18next.t('month.may') ? i18next.t('month.may') : 'month.may';
        case 6:
            return i18next.t('month.june') ? i18next.t('month.june') : 'month.june';
        case 7:
            return i18next.t('month.july') ? i18next.t('month.july') : 'month.july';
        case 8:
            return i18next.t('month.august') ? i18next.t('month.august') : 'month.august';
        case 9:
            return i18next.t('month.september') ? i18next.t('month.september') : 'month.september';
        case 10:
            return i18next.t('month.october') ? i18next.t('month.october') : 'month.october';
        case 11:
            return i18next.t('month.november') ? i18next.t('month.november') : 'month.november';
        case 12:
            return i18next.t('month.december') ? i18next.t('month.december') : 'month.december';
        default:
            throw new Error('invalid month number');
    }
}

export const monthNumberToText = (nbr) => {
    return monthNbrToText(nbr);
};

export const monthNumberListToTextList = (monthArr) => {
    if (monthArr && monthArr.length > 0) {
        let monthTxtArr = [];
        for (let mNbr of monthArr) {
            monthTxtArr.push(monthNumberToText(mNbr));
        }
        return monthTxtArr;
    }
    return [];
};

export const verifyEmailAddress = (email) => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
};

export const verifyPasswordStrength = (password) => {
    var passwordStrength = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    return passwordStrength.test(password);
};

export const pseudoRandomPasswordGenerate = (upperCase, lowerCase, numbers) => {
    var chars = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz', '0123456789'];
    return [upperCase, lowerCase, numbers]
        .map(function (len, i) {
            return Array(len)
                .fill(chars[i])
                .map(function (x) {
                    return x[Math.floor(Math.random() * x.length)];
                })
                .join('');
        })
        .concat()
        .join('')
        .split('')
        .sort(function () {
            return 0.5 - Math.random();
        })
        .join('');
};

export const verifyPhonenumber = (phonenumber) => {
    const regex = /^[+]{1}[0-9]{5,}$/;
    return regex.test(phonenumber);
};

export const parseInteger = (value) => {
    let output = parseInt(value);
    if (typeof output !== 'number') {
        return 0;
    }
    return output;
};

//note: if string starts with number, parser return decimals before first letter
export const parseDecimal = (value) => {
    let output = parseFloat(value);
    if (isNaN(output) || typeof output !== 'number') {
        return 0;
    }
    return output;
};

const prepareForCompare = (o) => {
    if (Array.isArray(o)) {
        let arr = [];
        for (let i in o) {
            arr[i] = prepareForCompare(o[i]);
        }
        return arr;
    } else if (typeof o == 'number') {
        return o.toFixed(2);
    } else if (typeof o == 'string') {
        if (o === '0') {
            return '0.00';
        }
        return o;
    } else if (typeof o != 'object') {
        return o;
    }
    let obj = { ...o };
    let removeKeys = [];
    let keys = Object.keys(obj);
    for (let k in keys) {
        let key = keys[k];
        let item = obj[key];
        if (item === null) {
            removeKeys.push(key);
            continue;
        } else {
            item = prepareForCompare(item);
        }
        obj[key] = item;
    }
    for (let i in removeKeys) {
        delete obj[removeKeys[i]];
    }
    return obj;
};

export const objectsDiffer = (objectA, objectB) => {
    return JSON.stringify(prepareForCompare(objectA)) !== JSON.stringify(prepareForCompare(objectB));
};

export const formatCurrency = (value, fractionDigits = 2) => {
    const formatter = new Intl.NumberFormat('fi-FI', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: fractionDigits,
    });
    return formatter.format(value);
};

export const verifyAndReturnDecimal = (value, decimals) => {
    let valueStr = value.toString().replace(',', '.');
    if (!isNaN(parseFloat(valueStr))) {
        return parseFloat(valueStr);
    }
    return null;
};
