import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../common/spinner';
import Grid from '@material-ui/core/Grid';
import SearchResultsLocationDetails from './searchresultslocationdetails';
import SearchResultsCustomerDetails from './searchresultscustomerdetails';
import SearchResultsOrderDetails from './searchresultsorderdetails';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px',
        margin: '5px',
    },
    margin: {
        marginTop: '30px',
    },
    bold: {
        fontWeight: 'bold',
    },
}));

export default function SearchResults(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const resultsData = useSelector((state) => state.searchdata.data);
    const locationSearch = _.find(resultsData, (item) => item.name === 'locations');
    const customerSearch = _.find(resultsData, (item) => item.name === 'customers');
    const orderSearch = _.find(resultsData, (item) => item.name === 'orders');

    useEffect(() => {});

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <h5>{t('general.locations')}</h5>
                {locationSearch.loading && <Spinner />}
                {!locationSearch.loading && locationSearch.results.length >= 250 && (
                    <div className={classes.bold}>{t('general.tooManySearchResultsRefineQuery')}</div>
                )}
                {!locationSearch.loading && locationSearch.results.length > 0 && (
                    <div>
                        {locationSearch.results.map((loc) => (
                            <Grid item xs={12} key={loc.id}>
                                <SearchResultsLocationDetails location={loc} history={props.history} />
                            </Grid>
                        ))}
                    </div>
                )}
                {!locationSearch.loading && locationSearch.results.length === 0 && (
                    <em>{t('general.noSearchResults')}</em>
                )}
            </Grid>

            <Grid item xs={12}>
                <h5 className={classes.margin}>{t('general.customers')}</h5>
                {customerSearch.loading && <Spinner />}
                {!customerSearch.loading && customerSearch.results.length >= 250 && (
                    <div className={classes.bold}>{t('general.tooManySearchResultsRefineQuery')}</div>
                )}
                {!customerSearch.loading && customerSearch.results.length > 0 && (
                    <div>
                        {customerSearch.results.map((cust) => (
                            <Grid item xs={12} key={cust.id}>
                                <SearchResultsCustomerDetails customer={cust} history={props.history} />
                            </Grid>
                        ))}
                    </div>
                )}
                {!customerSearch.loading && customerSearch.results.length === 0 && (
                    <em>{t('general.noSearchResults')}</em>
                )}
            </Grid>

            <Grid item xs={12}>
                <h5 className={classes.margin}>{t('menu.orders')}</h5>
                {orderSearch.loading && <Spinner />}
                {!orderSearch.loading && orderSearch.results.length >= 250 && (
                    <div className={classes.bold}>{t('general.tooManySearchResultsRefineQuery')}</div>
                )}
                {!orderSearch.loading && orderSearch.results.length > 0 && (
                    <div>
                        {orderBy(orderSearch.results, 'orderSent', 'desc').map((order) => (
                            <Grid item xs={12} key={order.id}>
                                <SearchResultsOrderDetails order={order} history={props.history} />
                            </Grid>
                        ))}
                    </div>
                )}
                {!orderSearch.loading && orderSearch.results.length === 0 && <em>{t('general.noSearchResults')}</em>}
            </Grid>
        </Grid>
    );
}
