import Ajax from './ajax';
import { PortfolioDescription, Portfolio } from '../domain/portfolio';
import { LOGGED_OFF } from './authentication';
import { cloneDeep, findIndex } from 'lodash';

export const FETCH_PORTFOLIO_DATA_REQUESTED = 'portfoliodata/FETCH_PORTFOLIO_DATA_REQUESTED';
export const FETCHED_PORTFOLIO_DATA = 'portfoliodata/FETCHED_PORTFOLIO_DATA';
export const FETCH_PORTFOLIO_DATA_FAILED = 'portfoliodata/FETCH_PORTFOLIO_DATA_FAILED';

export const SAVE_PORTFOLIO_DATA_REQUESTED = 'portfoliodata/SAVE_PORTFOLIO_DATA_REQUESTED';
export const SAVED_PORTFOLIO_DATA = 'portfoliodata/SAVED_PORTFOLIO_DATE';
export const SAVE_PORTFOLIO_DATA_FAILED = 'portfoliodata/SAVE_PORTFOLIO_DATA_FAILED';

export const DELETE_PORTFOLIO_DATA_REQUESTED = 'portfoliodata/DELETE_PORTFOLIO_DATA_REQUESTED';
export const DELETED_PORTFOLIO_DATA = 'portfoliodata/DELETED_PORTFOLIO_DATE';
export const DELETE_PORTFOLIO_DATA_FAILED = 'portfoliodata/DELETE_PORTFOLIO_DATA_FAILED';

export const SELECT_PORTFOLIO_REQUESTED = 'portfoliodata/SELECT_PORTFOLIO_REQUESTED';
export const SELECT_PORTFOLIO_SUCCESS = 'portfoliodata/SELECT_PORTFOLIO_SUCCESS';
export const SELECT_PORTFOLIO_FAILED = 'portfoliodata/SELECT_PORTFOLIO_FAILED';

export const GET_PORTFOLIO_DESCRIPTIONS_IN_PROGRESS = 'portfoliodata/getportfoliodescriptionsinprogress';
export const GET_PORTFOLIO_DESCRIPTIONS_DONE = 'portfoliodata/getportfoliodescriptionsdone';
export const GET_PORTFOLIO_DESCRIPTIONS_FAILED = 'portfoliodata/getportfoliodescriptionsfailed';

const initialState = {
    portfolios: [],
    isFetching: false,
    isSaving: false,
    isDeleting: false,
    deletedStorageId: null,
    savedStorageId: null,
    savedLogicalId: null,
    isSelecting: false,
    portfolioDescriptions: null,
    fetchingPortfolioDescriptions: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PORTFOLIO_DATA_REQUESTED:
            return {
                ...state,
                isFetching: true,
            };
        case SAVE_PORTFOLIO_DATA_REQUESTED:
            return {
                ...state,
                isSaving: true,
            };
        case DELETE_PORTFOLIO_DATA_REQUESTED:
            return {
                ...state,
                isDeleting: true,
            };
        case FETCHED_PORTFOLIO_DATA:
            return {
                ...state,
                isFetching: false,
                portfolios: action.data.map((p) => new Portfolio(p)),
            };
        case SAVED_PORTFOLIO_DATA:
            let newPortfolios = cloneDeep(state.portfolios);
            let toUpdateIndex = findIndex(newPortfolios, ['id', action.portfolio.id]);
            if (toUpdateIndex > -1) {
                newPortfolios[toUpdateIndex] = new Portfolio(action.portfolio);
            } else {
                newPortfolios.push(new Portfolio(action.portfolio));
            }
            return {
                ...state,
                isSaving: false,
                savedStorageId: action.savedStorageId,
                savedLogicalId: action.savedLogicalId,
                portfolios: newPortfolios,
            };
        case DELETED_PORTFOLIO_DATA:
            return {
                ...state,
                isDeleting: false,
                deletedStorageId: action.deletedStorageId,
                portfolios: [],
            };
        case SAVE_PORTFOLIO_DATA_FAILED:
        case DELETE_PORTFOLIO_DATA_FAILED:
        case FETCH_PORTFOLIO_DATA_FAILED:
            return {
                ...state,
                isSaving: false,
                isFetching: false,
                isDeleting: false,
            };
        case LOGGED_OFF:
            return initialState;
        case SELECT_PORTFOLIO_REQUESTED:
            return {
                ...state,
                isSelecting: true,
            };
        case SELECT_PORTFOLIO_SUCCESS:
            return state;
        case SELECT_PORTFOLIO_FAILED:
            return {
                ...state,
                isSelecting: false,
            };
        case GET_PORTFOLIO_DESCRIPTIONS_DONE:
            const portfolios = [];
            for (const p of action.portfolios) {
                portfolios.push(new PortfolioDescription(p));
            }
            return {
                ...state,
                fetchingPortfolioDescriptions: false,
                portfolioDescriptions: portfolios,
            };
        case GET_PORTFOLIO_DESCRIPTIONS_IN_PROGRESS:
            return {
                ...state,
                fetchingPortfolioDescriptions: true,
            };
        case GET_PORTFOLIO_DESCRIPTIONS_FAILED:
            return {
                ...state,
                fetchingPortfolioDescriptions: false,
                portfolioDescriptions: null,
            };
        default:
            return state;
    }
};

export const listPortfolios = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_PORTFOLIO_DATA_REQUESTED,
        });
        Ajax.get('api/portfolio/list')
            .then(function (data) {
                dispatch({
                    type: FETCHED_PORTFOLIO_DATA,
                    data: data.data,
                });
            })
            .catch(function (err) {
                dispatch({
                    type: FETCH_PORTFOLIO_DATA_FAILED,
                });
            });
    };
};

export const deletePortfolio = (portfolio_cluster_id) => {
    return (dispatch) => {
        dispatch({
            type: DELETE_PORTFOLIO_DATA_REQUESTED,
        });
        let promise = new Promise((resolve, reject) => {
            Ajax.delete('api/portfolio/' + portfolio_cluster_id)
                .then(function (data) {
                    dispatch({
                        type: DELETED_PORTFOLIO_DATA,
                        deletedStorageId: portfolio_cluster_id,
                    });
                    resolve();
                })
                .catch(function (err) {
                    dispatch({
                        type: DELETE_PORTFOLIO_DATA_FAILED,
                    });
                    reject();
                });
        });
        return promise;
    };
};

export const savePortfolio = (portfolio) => {
    return (dispatch) => {
        dispatch({
            type: SAVE_PORTFOLIO_DATA_REQUESTED,
        });

        let promise = new Promise((resolve, reject) => {
            Ajax.post('api/portfolio/' + portfolio.id, portfolio)
                .then(function (data) {
                    dispatch({
                        type: SAVED_PORTFOLIO_DATA,
                        portfolio: data.data,
                        savedStorageId: data.data.storageId,
                        savedLogicalId: data.data.id,
                    });
                    resolve();
                })
                .catch(function (err) {
                    dispatch({
                        type: SAVE_PORTFOLIO_DATA_FAILED,
                    });
                    reject();
                });
        });
        return promise;
    };
};

export const selectPortfolio = (portfolioId) => {
    return (dispatch) => {
        dispatch({
            type: SELECT_PORTFOLIO_REQUESTED,
        });
        Ajax.put('api/portfolioselection/' + portfolioId)
            .then(function (data) {
                dispatch({
                    type: SELECT_PORTFOLIO_SUCCESS,
                });
                window.location.reload(true);
            })
            .catch(function (err) {
                dispatch({
                    type: SELECT_PORTFOLIO_FAILED,
                });
            });
    };
};

export const getPortfolioDescriptions = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_PORTFOLIO_DESCRIPTIONS_IN_PROGRESS,
        });
        try {
            const resp = await Ajax.get('api/portfolio/');
            dispatch({
                type: GET_PORTFOLIO_DESCRIPTIONS_DONE,
                portfolios: resp.data,
            });
        } catch (err) {
            console.log(err);
            dispatch({});
        }
    };
};
