import moment from 'moment';

class CapacityReservation {
    constructor(json) {
        if (json) {
            this.portfolioId = json.portfolioId;
            this.orderId = json.orderId;
            this.dateOfReservation = moment(json.dateOfReservation);
            this.reservedCapacity = json.reservedCapacity;
            this.orderNumber = json.orderNumber;
            if (json.validUntil) {
                this.validUntil = moment(json.validUntil);
            } else {
                this.validUntil = null;
            }
        } else {
            this.portfolioId = 0;
            this.orderId = 0;
            this.dateOfReservation = moment();
            this.reservedCapacity = 0;
            this.orderNumber = null;
            this.validUntil = null;
        }
    }
}

export default CapacityReservation;
