import React from 'react';
import { withStyles } from '@material-ui/core';
import LocationIcon from '@material-ui/icons/StoreMallDirectory';
import IconButton from '@material-ui/core/IconButton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Button from '@material-ui/core/Button';
import Spinner from '../../common/spinner';
import i18next from 'i18next';

const styles = (theme) => ({
    root: {},
    details: {
        paddingLeft: '20px',
    },
    indented: {
        marginLeft: '20px',
    },
});

function t(key, params) {
    return i18next.t(key, params) ? i18next.t(key, params) : key;
}

class LocationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: false,
        };
    }
    addMachineToCart = (locId, mId) => {
        this.props.addMachineToCart(locId, mId);
    };
    addFilterToCart = (locId, mId, fId) => {
        this.props.addFilterToCart(locId, mId, fId);
    };
    addToCart = (id) => {
        this.props.addToCart(id);
    };
    onClickLocation = () => {
        if (this.state.expand === false && this.props.locationFull == null)
            this.props.getLocationFull(this.props.location.id);

        this.setState({ ...this.state, expand: !this.state.expand });
    };
    render() {
        const loc = this.props.location;
        const locFull = this.props.locationFull;
        return (
            <div style={{ display: 'block', width: '100%' }}>
                <Button onClick={this.onClickLocation}>
                    <LocationIcon />
                    {loc.name}&nbsp;
                </Button>
                <IconButton
                    disabled={false}
                    id={`add-to-order-${loc.id}`}
                    onClick={() => this.addToCart(loc.id)}
                    color="secondary"
                    aria-label="Lisää tilaukseen">
                    <AddShoppingCartIcon />
                </IconButton>

                {this.state.expand && (
                    <div className={this.props.classes.details}>
                        {locFull != null && (
                            <div>
                                {locFull != null &&
                                    locFull.machines != null &&
                                    locFull.machines.length > 0 &&
                                    locFull.machines.map((m) => (
                                        <div key={m.id}>
                                            <strong>{m.name}</strong>, sijainti: {m.machineLocation}
                                            {m.machineArea != null && m.machineArea.length > 0 && (
                                                <span>, palvelualue: {m.machineArea}</span>
                                            )}
                                            <IconButton
                                                disabled={false}
                                                onClick={() => this.addMachineToCart(locFull.id, m.id)}
                                                color="secondary"
                                                aria-label="Lisää tilaukseen">
                                                <AddShoppingCartIcon />
                                            </IconButton>
                                            {m.filters != null &&
                                                m.filters.length > 0 &&
                                                m.filters.map((f) => (
                                                    <div className={this.props.classes.indented} key={f.id}>
                                                        {f.count} x {f.product.productName}
                                                        <IconButton
                                                            disabled={false}
                                                            onClick={() => this.addFilterToCart(locFull.id, m.id, f.id)}
                                                            color="secondary"
                                                            aria-label="Lisää tilaukseen">
                                                            <AddShoppingCartIcon />
                                                        </IconButton>
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                            </div>
                        )}
                        {locFull == null && <Spinner padding="20px" title={t('general.retrievingInformation')} />}
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(LocationDetails);
