import React from 'react';
import { formatCurrency } from '../common/common';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    warning: {
        color: 'darkred',
    },
    ok: {
        color: 'green',
    },
}));

const FilterMargin = ({ filter }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const getClass = (marginPercentage) => {
        return marginPercentage > 0 ? classes.ok : classes.warning;
    };

    return (
        <React.Fragment>
            {filter.marginEur() !== -1 && (
                <span className={getClass(filter.marginPercentage())}>
                    Kate {formatCurrency(filter.marginEur())} ({filter.marginPercentage()}%)
                </span>
            )}
            {filter.marginEur() === -1 && <span className={classes.warning}>{t('general.marging')} *</span>}
        </React.Fragment>
    );
};

export default FilterMargin;
