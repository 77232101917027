import React from 'react';
import moment from 'moment';
import TimeSortedLocationGroup from './timesortedlocationgroup';
import { Alert } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

const TimeSortedLocationList = (props) => {
    const { t } = useTranslation();

    const getTimeSortedAndFiltered = () => {
        let sortedLocations = {};
        let result = [];
        if (props.locationDescriptions == null) return result;

        // Filter
        const locationsFiltered = _.filter(props.locationDescriptions, (l) => {
            return _.deburr(l.name).toLowerCase().indexOf(_.deburr(props.filter).toLowerCase()) !== -1;
        });

        sortedLocations = _.sortBy(locationsFiltered, 'updateDate').reverse();

        //gategorize data by time
        const timelimitToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        const timelimit5days = moment(timelimitToday).add(-5, 'days');
        const timelimit30days = moment(timelimitToday).add(-30, 'days');

        const todayLoc = _.filter(sortedLocations, (l) => {
            return l.updateDate.isAfter(timelimitToday);
        });
        const in5dLoc = _.filter(sortedLocations, (l) => {
            return l.updateDate.isBetween(timelimit5days, timelimitToday);
        });
        const in30dLoc = _.filter(sortedLocations, (l) => {
            return l.updateDate.isBetween(timelimit30days, timelimit5days);
        });
        const oldestLoc = _.filter(sortedLocations, (l) => {
            return l.updateDate.isBefore(timelimit30days);
        });

        result.push(
            { title: t('location.modifiedToday'), locations: todayLoc },
            { title: t('location.modifiedWithinTheLast5days'), locations: in5dLoc },
            { title: t('location.modifiedWithinTheLast30days'), locations: in30dLoc },
            { title: t('location.modifiedOver30daysAgo'), locations: oldestLoc }
        );

        return result;
    };

    if (props.locationDescriptions != null && props.locationDescriptions.length === 0) {
        return (
            <div style={{ padding: '20px' }}>
                <Alert severity="info">{props.emptyListText}</Alert>
            </div>
        );
    }

    const sortedByTime = getTimeSortedAndFiltered();

    return (
        <Grid item xs={12}>
            {sortedByTime.map((group, i) => (
                <TimeSortedLocationGroup group={group} openByDefault={i === 0} history={props.history} key={i} />
            ))}
        </Grid>
    );
};

export default TimeSortedLocationList;
