import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getOrdersByOrderNumber } from '../modules/orderdata';
import OrderItem from './orderitem';
import OrderConfirmationMailInputDialog from './orderConfirmationMailInfoInputDialog';
import OrderSendStatusDialog from './ordersendstatusdialog';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { cloneDeep, find, sumBy, maxBy } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import CancelIcon from '@material-ui/icons/Cancel';
import { sendEditOrders } from '../modules/orderdata';
import {
    earliestDeliveryDateForCombinedOrder,
    makeCapacityReservationsCombinedOrder,
    removeCapacityReservations,
} from './capacityplanner';
import OrderRow from '../domain/orderrow';
import FilterProduct from '../domain/filterproduct';
import { ProductBundle } from '../domain/productbundle';
//import Machine from '../domain/machine';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export default function OrderEditViewContainer(props) {
    const dispatch = useDispatch();
    const ordersByOrderNumber = useSelector((state) => state.orderdata.ordersByOrderNumber);
    const customersById = useSelector((state) => state.customerdata.customersById);
    const customerDescriptions = useSelector((state) => state.customerdata.customerDescriptions);
    const sendingOrdersInProgress = useSelector((state) => state.orderdata.sendingOrdersInProgress);
    const savingCombinedOrder = useSelector((state) => state.orderdata.savingCombinedOrder);
    const orderSendFailed = useSelector((state) => state.orderdata.orderSendFailed);
    const capacityAllocations = useSelector((state) => state.capacityAllocations);
    const capacityReservations = useSelector((state) => state.capacityReservations);
    const parameters = useSelector((state) => state.parameterdata.parameters);
    const products = useSelector((state) => state.productdata.filterProducts);
    const bundles = useSelector((state) => state.productdata.productBundles);
    const orderNumber = parseInt(props.match.params.orderNumber, 10);
    const [ordersToEdit, setOrdersToEdit] = useState(null);
    const [orderFormOk, setOrderFormOk] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [sendingOrdersModalOpen, setSendingOrdersModalOpen] = useState(false);
    const [earliestDeliveryDate, setEarliestDeliveryDate] = useState(null);
    const portfolios = useSelector((state) => state.portfoliodata.portfolioDescriptions);
    const [portfolio, setPortfolio] = useState({});
    const allowPricing = true;
    const store = useStore();
    const ordersToEditRef = useRef(null);
    ordersToEditRef.current = ordersToEdit;
    const { t, i18n } = useTranslation();
    const userLanguage = i18n.language;

    useEffect(() => {
        if (!ordersByOrderNumber[orderNumber]) {
            dispatch(getOrdersByOrderNumber(orderNumber));
        } else {
            setOrdersToEdit(cloneDeep(ordersByOrderNumber[orderNumber]));
        }
    }, [ordersByOrderNumber, dispatch, orderNumber]);

    useEffect(() => {
        async function getEarliestDeliveryDate() {
            console.log('%%%%');
            const earliestTime = await earliestDeliveryDateForCombinedOrder(store, ordersToEdit, {
                orderType: ordersToEdit[0].orderType,
            });
            if (earliestTime) {
                setEarliestDeliveryDate(earliestTime.format());
            } else {
                setEarliestDeliveryDate(null);
            }
        }
        if (ordersToEdit && ordersToEdit.length > 0) {
            getEarliestDeliveryDate();
        }
    }, [capacityAllocations, capacityReservations, ordersToEdit, store]);

    useEffect(() => {
        if (ordersByOrderNumber[orderNumber] && portfolios && portfolios.length > 0) {
            setPortfolio(portfolios.find((p) => p.id === ordersByOrderNumber[orderNumber][0].portfolioId));
        }
    }, [ordersByOrderNumber, portfolios, orderNumber]);

    const removeOrder = (orderToRemoveId) => {
        const newOrdersToEdit = ordersToEditRef.current.filter((o) => o.id !== orderToRemoveId);
        setOrdersToEdit(newOrdersToEdit);
    };

    const saveOrder = async (newOrder) => {
        let promise = new Promise((resolve, reject) => {
            const newOrdersToEdit = ordersToEditRef.current.map((o) => {
                if (o.id === newOrder.id) {
                    //case where filter is removed
                    return calculateOrderPrice(newOrder);
                }
                //case for address change - update for all (only in case it is not private customer order)
                if (!o.orderToPrivateCustomer) {
                    o.deliveryAddress = newOrder.deliveryAddress;
                    o.deliveryRecipient = newOrder.deliveryRecipient;
                }
                return o;
            });
            setOrdersToEdit(newOrdersToEdit);
            resolve();
        });
        return promise;
    };

    const saveOrderShallow = async (newOrder) => {
        let promise = new Promise((resolve, reject) => {
            const newOrdersToEdit = ordersToEditRef.current.map((o) => {
                o.deliveryTerms = newOrder.deliveryTerms;
                o.deliveryTime = newOrder.deliveryTime;
                o.ordererName = newOrder.ordererName;
                o.ordererTel = newOrder.ordererTel;
                o.reference = newOrder.reference;
                o.confirmationEmail = newOrder.confirmationEmail;
                o.packingInstructions = newOrder.packingInstructions;
                o.orderType = newOrder.orderType;
                o.generalInfo = newOrder.generalInfo;
                o.deliveryMethod = newOrder.deliveryMethod;
                o.earlyDeliveryTime = newOrder.earlyDeliveryTime;
                return o;
            });
            setOrdersToEdit(newOrdersToEdit);
            resolve();
        });
        return promise;
    };

    const updateOrderFormState = (isFormOk, orderId) => {
        setOrderFormOk(isFormOk);
    };

    const saveFilterOrder = (newOrder, fOrderToUpdate) => {
        const newOrdersToEdit = ordersToEditRef.current.map((o) => {
            if (o.id === newOrder.id) {
                const filterOrder = find(o.orderedFilters, { id: fOrderToUpdate.id });
                filterOrder.totalPriceWithoutVat = fOrderToUpdate.count * fOrderToUpdate.unitPriceWithoutVat;
                filterOrder.unitPriceWithoutVat = fOrderToUpdate.unitPriceWithoutVat;
                filterOrder.machineName = fOrderToUpdate.machineName;
                filterOrder.machineLocation = fOrderToUpdate.machineLocation;
                filterOrder.machineArea = fOrderToUpdate.machineArea;
                filterOrder.count = fOrderToUpdate.count;
                filterOrder.palletInformation = fOrderToUpdate.palletInformation;
                o = calculateOrderPrice(o);
            }
            return o;
        });
        setOrdersToEdit(newOrdersToEdit);
    };

    const onReserveCapacity = async (selectedDate, order) => {
        return true;
    };

    const onOrderConfirmationMailInfo = () => {
        setConfirmationDialogOpen(true);
    };

    const onConfirmationCancel = () => {
        setConfirmationDialogOpen(false);
    };

    const onConfirmationAccept = async (language, confirmationOptions) => {
        await onSendOrders(confirmationOptions, language);
        props.history.goBack();
    };

    const onSendOrders = async (confirmationOptions, language) => {
        setSendingOrdersModalOpen(true);
        setConfirmationDialogOpen(false);

        const reservations = await makeCapacityReservationsCombinedOrder(
            store,
            ordersToEdit,
            ordersToEdit[0].deliveryTime,
            {
                orderType: ordersToEdit[0].orderType,
            }
        );
        if (reservations) {
            await sendEditOrders(ordersToEdit, confirmationOptions, language, reservations)(dispatch);
        }
    };

    const isOrderSendDone = () => {
        return !sendingOrdersInProgress && !savingCombinedOrder;
    };

    const okBtn = () => {
        setSendingOrdersModalOpen(false);
    };

    const onCancel = () => {
        props.history.goBack();
    };

    const calculateOrderPrice = (order) => {
        order.orderTotalPriceWithoutVat = sumBy(order.orderedFilters, 'totalPriceWithoutVat');
        order.vatAmount = (order.vatPercent / 100) * order.orderTotalPriceWithoutVat;
        order.orderTotalPrice = order.orderTotalPriceWithoutVat + order.vatAmount;
        return order;
    };

    const addProductWithId = (orderId, productId) => {
        let product = find(products, { id: productId });
        if (!product) product = find(bundles, { id: productId });

        if (!product) {
            toast.error(t('order.errorProductNotFound', { prod: productId }), { autoClose: 5000 });
            return;
        }
        addProductInternal(orderId, product);
    };

    const addProductWithCode = (orderId, productCode) => {
        const product = find(products, { productCode: productCode });
        if (!product) {
            toast.error(t('order.errorProductNotFound', { prod: productCode }), { autoClose: 5000 });
            return;
        }
        addProductInternal(orderId, product);
    };

    const addProductInternal = (orderId, product) => {
        let order = find(ordersToEditRef.current, function (o) {
            return o.id === orderId;
        });

        OrderRow.NewObject().then((ford) => {
            ford.storageId = null;
            ford.product = product.productType() === 'fp' ? new FilterProduct(product) : null;
            ford.productBundle = product.productType() === 'bundle' ? new ProductBundle(product) : null;
            ford.machineName = '';
            ford.machineLocation = '';
            ford.machineArea = '';
            ford.machineAdditionalInfo = '';
            ford.count = 1;
            ford.discount = 0;
            ford.totalPriceWithoutVat =
                product.productType() === 'fp' ? product.priceWithoutVat : product.priceWithoutVat();
            ford.unitPriceWithoutVat =
                product.productType() === 'fp' ? product.priceWithoutVat : product.priceWithoutVat();
            ford.viewOrder = order.orderedFilters.length;
            ford.machineViewOrder = maxBy(order.orderedFilters, (f) => f.machineViewOrder).machineViewOrder + 1;
            ford.palletInformation = '';
            ford.filterAdditionalInfo = '';

            const newOrdersToEdit = ordersToEditRef.current.map((o) => {
                if (o.id === order.id) {
                    o.orderedFilters.push(ford);
                }
                return o;
            });
            setOrdersToEdit(newOrdersToEdit);
        });
    };

    const onRemoveCapacityReservations = async (orderId) => {
        await removeCapacityReservations(store, orderId);
    };

    if (!ordersToEdit) {
        return (
            <div>
                {t('general.loading')} <CircularProgress color={'secondary'} size={'1rem'} />
            </div>
        );
    }

    return (
        <div>
            <Alert severity="warning">{t('order.youAreEditingSentOrder')}</Alert>
            <OrderItem
                customerView={false}
                key={ordersToEdit[0].id}
                orders={ordersToEdit}
                removeOrder={removeOrder}
                saveOrder={saveOrder}
                saveOrderShallow={saveOrderShallow}
                updateOrderFormState={updateOrderFormState}
                saveFilterOrder={saveFilterOrder}
                allowPricing={allowPricing}
                customers={customerDescriptions ? customerDescriptions : []}
                customersById={customersById}
                makeCapacityReservations={onReserveCapacity}
                earliestDeliveryDate={earliestDeliveryDate}
                editOrderedItem={true}
                parameters={parameters}
                editFoPalletInfo={() => {}}
                portfolio={portfolio}
                addProductWithCode={addProductWithCode}
                addProductWithId={addProductWithId}
                removeCapacityReservations={onRemoveCapacityReservations}
            />
            <OrderConfirmationMailInputDialog
                open={confirmationDialogOpen}
                cancel={onConfirmationCancel}
                save={onConfirmationAccept}
                orders={ordersToEdit}
                combineOrders={ordersToEdit.length > 1}
                userLanguage={userLanguage}
            />
            <OrderSendStatusDialog
                open={sendingOrdersModalOpen}
                orderSendDone={isOrderSendDone()}
                orderSendFailed={orderSendFailed}
                okBtn={okBtn}
            />
            <Footer>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        id="send-order-edit-btn"
                        name="send-order-edit-btn"
                        disabled={!orderFormOk}
                        onClick={onOrderConfirmationMailInfo}
                        startIcon={<PublishIcon />}>
                        {t('buttons.send')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        name="mass-edit-btn"
                        onClick={onCancel}
                        startIcon={<CancelIcon />}>
                        {t('buttons.cancel')}
                    </Button>
                </Grid>
            </Footer>
            <FooterPadding />
        </div>
    );
}
