import React from 'react';
import Grid from '@material-ui/core/Grid';
import { formatCurrency } from '../common/common';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const AddressWarning = ({ address }) => {
    const { t } = useTranslation();

    if (!address.isValid())
        return (
            <Tooltip title={t('general.invalidAddress')}>
                <WarningIcon
                    style={{
                        color: 'orange',
                        verticalAlign: 'middle',
                        display: 'inline-flex',
                        fontSize: '1.25em',
                    }}
                />
            </Tooltip>
        );

    return null;
};

export default function CustomerViewBasicInfo(props) {
    const customerFull = props.customer;
    const { t } = useTranslation();

    return (
        <Grid container>
            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('general.customerNumber')}
            </Grid>
            <Grid item xs={12} md={6}>
                {customerFull.customerNumber}
            </Grid>

            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('general.businessId')}
            </Grid>
            <Grid item xs={12} md={6}>
                {customerFull.businessId}
            </Grid>

            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('general.vatId')}
            </Grid>
            <Grid item xs={12} md={6}>
                {customerFull.vatId}
            </Grid>

            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('general.contactPerson')}
            </Grid>
            <Grid item xs={12} md={6}>
                {customerFull.contactName}
            </Grid>

            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('general.contactPersonTel')}
            </Grid>
            <Grid item xs={12} md={6}>
                <a href={'tel:' + customerFull.contactTel}>{customerFull.contactTel}</a>
            </Grid>

            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('parameterCategories.ContractNumber')}
            </Grid>
            <Grid item xs={12} md={6}>
                {!!customerFull.contractNumber && <span>{customerFull.contractNumber.value}</span>}
            </Grid>

            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('parameterCategories.InvoicingTerms')}
            </Grid>
            <Grid item xs={12} md={6}>
                {!!customerFull.invoicingTerms && <span>{customerFull.invoicingTerms.value}</span>}
            </Grid>

            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('parameterCategories.DeliveryTerms')}
            </Grid>
            <Grid item xs={12} md={6}>
                {!!customerFull.deliveryTerms && <span>{customerFull.deliveryTerms.value}</span>}
            </Grid>
            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('parameterCategories.DeliveryMethod')}
            </Grid>
            <Grid item xs={12} md={6}>
                {!!customerFull.deliveryMethod && <span>{customerFull.deliveryMethod.value}</span>}
            </Grid>

            {customerFull.deliveryAddress != null && (
                <Grid container>
                    <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                        {t('general.deliveryAddress')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AddressWarning address={customerFull.deliveryAddress} />
                        {customerFull.deliveryAddress.name != null && customerFull.deliveryAddress.name.length > 0
                            ? customerFull.deliveryAddress.name
                            : customerFull.name}
                        ,&nbsp;
                        {customerFull.deliveryAddress.streetAddress},&nbsp;
                        {customerFull.deliveryAddress.zipCode}&nbsp;
                        {customerFull.deliveryAddress.city}&nbsp;
                    </Grid>
                </Grid>
            )}

            {customerFull.billingAddress != null && (
                <Grid container>
                    <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                        {t('general.invoicingAddress')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AddressWarning address={customerFull.billingAddress} />
                        {customerFull.billingAddress.name != null && customerFull.billingAddress.name.length > 0
                            ? customerFull.billingAddress.name
                            : customerFull.name}
                        ,&nbsp;
                        {customerFull.billingAddress.streetAddress},&nbsp;
                        {customerFull.billingAddress.zipCode}&nbsp;
                        {customerFull.billingAddress.city}&nbsp;
                    </Grid>
                </Grid>
            )}

            {customerFull.electronicInvoiceAddress != null && (
                <Grid container>
                    <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                        {t('general.eInvoiceAddress')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {customerFull.electronicInvoiceAddress.ovt},&nbsp;
                        {customerFull.electronicInvoiceAddress.operator},&nbsp;
                        {customerFull.electronicInvoiceAddress.operatorBrokerId}
                    </Grid>
                </Grid>
            )}
            {customerFull.emailInvoiceAddress != null && customerFull.emailInvoiceAddress.length > 0 && (
                <Grid container>
                    <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                        {t('invoice.emailInvoiceAddress')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {customerFull.emailInvoiceAddress}
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('general.packingInstructions')}
            </Grid>
            <Grid item xs={12} md={6}>
                {customerFull.packingInstructions}
            </Grid>
            {!props.showcaseMode && (
                <React.Fragment>
                    <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                        {t('general.value')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {formatCurrency(customerFull.customershipValue)}
                    </Grid>
                </React.Fragment>
            )}
            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('general.yourReference')}
            </Grid>
            <Grid item xs={12} md={6}>
                {customerFull.reference}
            </Grid>
            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                {t('parameterCategories.OurReference')}
            </Grid>
            <Grid item xs={12} md={6}>
                {!!customerFull.ourReference && <span>{customerFull.ourReference.value}</span>}
            </Grid>
        </Grid>
    );
}
