import React from 'react';
import ActionRow from './actionrow';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    summaryText: {
        fontSize: '1.4em',
        fontWeight: '500',
    },
    accordionDetails: {
        display: 'block',
    },
    iconLink: {
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
}));

export default function ActionCustomerItem(props) {
    const classes = useStyles();

    const isOrderPending = (locationId) => {
        return false;
    };

    const openCustomer = () => {
        props.history.push('/customers/' + props.customer.id);
    };

    let customer = props.customer;

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <OpenInNewIcon onClick={openCustomer} className={classes.iconLink} />
                    </Grid>
                    <Grid item>
                        <span className={classes.summaryText}>
                            {customer.name}({customer.locationCount})
                        </span>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {customer.actionList.map((item) => (
                    <ActionRow
                        key={item.id}
                        type="info"
                        action={item}
                        hasPendingOrder={isOrderPending(item.location.id)}
                        newOrderFromLocations={props.newOrderFromLocations}
                        history={props.history}
                    />
                ))}
            </AccordionDetails>
        </Accordion>
    );
}
