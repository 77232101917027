import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PartyAutocomplete from './partyautocomplete';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import PartyRelation from '../domain/partyrelation';
import IconButton from '@material-ui/core/IconButton';
import ConfirmationDialog from './confirmationdialog';
import PartyEditDialog from './partyeditdialog';
import { saveParty } from '../modules/partydata';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    table: {
        width: '100%',
    },
    emptytable: {
        width: '100%',
        padding: '10px',
        fontSize: '0.75em',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    paper: {
        width: '100%',
        marginBottom: '5px',
    },
    cancelBtn: {
        marginTop: '15px',
        marginLeft: '5px',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

export default function PartyView(props) {
    const classes = useStyles();
    const parties = props.parties;
    const dispatch = useDispatch();
    const showControls = props.showControls;
    const [showAc, setShowAc] = React.useState(false);
    const [showRoleSelect, setShowRoleSelect] = React.useState(false);
    const [selectedParty, setSelectedParty] = React.useState(null);
    const [selectedPartyRole, setSelectedPartyRole] = React.useState(0);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [partyToRemove, setPartyToRemove] = React.useState({});
    const [newPartyDialogOpen, setNewPartyDialogOpen] = React.useState(false);
    const { t } = useTranslation();

    const onDismiss = () => {
        setShowAc(false);
        setShowRoleSelect(false);
        setSelectedPartyRole(0);
        setSelectedParty(null);
    };

    const onSelect = (item) => {
        setSelectedParty(item);
        setShowAc(false);
        setShowRoleSelect(true);
    };

    const onSaveNewParty = () => {
        props.saveNewPartyRelation(selectedParty, selectedPartyRole);
        onDismiss();
    };

    const onRemoveParty = () => {
        props.removeParty(partyToRemove.id, partyToRemove.relation);
        setPartyToRemove({});
        setConfirmOpen(false);
    };

    const saveNewParty = async (party) => {
        const resp = await saveParty(party)(dispatch);
        if (resp) {
            setNewPartyDialogOpen(false);
        } else {
        }
    };
    const cancelNewParty = () => {
        setNewPartyDialogOpen(false);
    };

    return (
        <React.Fragment>
            {parties && parties.length > 0 && (
                <Paper className={classes.paper}>
                    <div className={classes.sectionDesktop}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('general.name')}</TableCell>
                                    <TableCell>{t('general.phone')}</TableCell>
                                    <TableCell>{t('general.email')}</TableCell>
                                    <TableCell>{t('general.role')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {parties.map((party) => (
                                    <TableRow key={party.relatedParty.id}>
                                        <TableCell>{party.relatedParty.name}</TableCell>
                                        <TableCell>{party.relatedParty.phonenumber}</TableCell>
                                        <TableCell>{party.relatedParty.email}</TableCell>
                                        <TableCell>{PartyRelation.PartyRelationText(party.relation)}</TableCell>
                                        <TableCell>
                                            {showControls && (
                                                <IconButton
                                                    size="small"
                                                    name="party-remove"
                                                    onClick={() => {
                                                        setPartyToRemove({
                                                            id: party.relatedParty.id,
                                                            relation: party.relation,
                                                            name: party.relatedParty.name,
                                                        });
                                                        setConfirmOpen(true);
                                                    }}>
                                                    <i
                                                        className="fas fa-trash-alt fa-lg"
                                                        style={{ color: 'red' }}
                                                        title={t('buttons.remove')}
                                                    />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    <div className={classes.sectionMobile}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('general.name')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {parties.map((party) => (
                                    <TableRow key={party.relatedParty.id}>
                                        <TableCell>
                                            {party.relatedParty.name}
                                            <br />
                                            {party.relatedParty.phonenumber}
                                            <br />
                                            {party.relatedParty.email}
                                            <br />
                                            {PartyRelation.PartyRelationText(party.relation)}
                                        </TableCell>
                                        <TableCell>
                                            {showControls && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        onRemoveParty(party.relatedParty.id, party.relation)
                                                    }>
                                                    <i
                                                        className="fas fa-trash-alt fa-lg"
                                                        style={{ color: 'red' }}
                                                        title={t('buttons.remove')}
                                                    />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            )}
            {!showAc && showControls && (
                <Grid item xs={12}>
                    <Button
                        name="add-new-relation"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setShowAc(true);
                        }}>
                        {t('buttons.add')}
                    </Button>
                </Grid>
            )}
            {(showAc || showRoleSelect) && showControls && (
                <Grid item container>
                    {showRoleSelect && (
                        <Grid item xs={8}>
                            <b>
                                <br />
                                {selectedParty.name}
                            </b>
                            <FormControl className={classes.select}>
                                <InputLabel id="role-selector">{t('general.role')}</InputLabel>
                                <Select
                                    labelId="role-selector"
                                    id="role-selector"
                                    name="role-selector"
                                    value={selectedPartyRole}
                                    onChange={(evt) => {
                                        setSelectedPartyRole(evt.target.value);
                                    }}>
                                    <MenuItem key={0} value={0}>
                                        {t('general.optionNotSelected')}
                                    </MenuItem>
                                    {PartyRelation.AllRelations().map((relation) => (
                                        <MenuItem key={relation.value} value={relation.value}>
                                            {relation.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {showAc && (
                        <Grid item xs={8}>
                            <PartyAutocomplete
                                selected={onSelect}
                                dismiss={onDismiss}
                                newParty={() => setNewPartyDialogOpen(true)}
                            />
                            <Tooltip title={t('general.addContactPersonHint')}>
                                <IconButton>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        {showRoleSelect && (
                            <Button
                                className={classes.cancelBtn}
                                variant="contained"
                                color="primary"
                                disabled={!selectedParty || selectedPartyRole === 0}
                                onClick={onSaveNewParty}>
                                {t('buttons.save')}
                            </Button>
                        )}
                        <Button
                            className={classes.cancelBtn}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setShowAc(false);
                                setShowRoleSelect(false);
                            }}>
                            {t('buttons.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            )}
            <ConfirmationDialog
                open={confirmOpen}
                confirm={() => onRemoveParty()}
                cancel={() => setConfirmOpen(false)}
                confirmText={t('buttons.remove')}>
                {t('general.confirmRemoveContactPerson', { contactName: partyToRemove.name })}
            </ConfirmationDialog>
            <PartyEditDialog open={newPartyDialogOpen} cancel={cancelNewParty} save={saveNewParty} />
        </React.Fragment>
    );
}
