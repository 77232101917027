import React from 'react';
import { formatCurrency } from '../common/common';

const LocationMargin = ({ location }) => {
    return (
        <React.Fragment>
            {formatCurrency(location.marginEur())} ({location.marginPercentage()}%)
            {!location.allProductsHaveProdPrice() && (
                <em>
                    <br />
                    Huom! Kohteessa käytössä tuotteita, joille ei löydy ostohintaa, katelaskelma on vajavainen!
                </em>
            )}
        </React.Fragment>
    );
};

export default LocationMargin;
