import Ajax from './ajax';
import Order from '../domain/order';
import { LOGGED_OFF } from './authentication';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import i18next from 'i18next';

export const FETCH_ORDER_DATA_REQ = 'customerorderdata/FETCHORDERDATAREQ';
export const FETCH_ORDER_DATA_COMPLETED = 'customerorderdata/FETCHORDERDATACOMPLETED';
export const FETCH_ORDER_DATA_FAILED = 'customerorderdata/FETCHORDERDATAFAILED';

export const SAVING_ORDER = 'customerorderdata/SAVINGORDER';
export const ORDER_SAVED = 'customerorderdata/ORDERSAVED';
export const ORDER_SAVE_FAILED = 'customerorderdata/ORDERSAVEFAILED';

export const SAVING_COMBINED_ORDER = 'customerorderdata/SAVINGCOMBINEDORDER';
export const SAVING_COMBINED_ORDER_SUCCESS = 'customerorderdata/SAVINGCOMBINEDORDERSUCCESS';
export const SAVING_COMBINED_ORDER_FAIL = 'customerorderdata/SAVINGCOMBINEDORDERFAIL';

export const REMOVE_ORDER = 'customerorderdata/REMOVEORDER';

export const SENDING_ORDERS = 'customerorderdata/SENDINGORDERS';
export const SEND_ORDERS_SUCCESS = 'customerorderdata/SENDORDERSSUCCESS';
export const SEND_ORDERS_FAILED = 'customerorderdata/SENDORDERSFAILED';

export const GET_ORDERDATA_FULL_REQ = 'customerorderdata/GETFULLREQ';
export const GET_ORDERDATA_FULL_SUCCESS = 'customerorderdata/GETFULLSUCCESS';
export const GET_ORDERDATA_FULL_FAIL = 'customerorderdata/GETFULLFAIL';

export const FETCH_ORDER_BY_ORDER_NUMBER = 'customerorderdata/ORDER_BY_ORDER_NUMBER';
export const FETCH_ORDER_BY_ORDER_NUMBER_SUCCESS = 'customerorderdata/ORDER_BY_ORDER_NUMBER_SUCCESS';
export const FETCH_ORDER_BY_ORDER_NUMBER_FAIL = 'customerorderdata/ORDER_BY_ORDER_NUMBER_FAIL';

export const SAVE_ORDER_SHALLOW_BATCH_STARTED = 'orderdata/SAVE_ORDER_SHALLOW_BATCH_STARTED';
export const SAVE_ORDER_SHALLOW_BATCH_SUCCESS = 'orderdata/SAVE_ORDER_SHALLOW_BATCH_SUCCESS';
export const SAVE_ORDER_SHALLOW_BATCH_FAIL = 'orderdata/SAVE_ORDER_SHALLOW_BATCH_FAIL';

export const DataSaveStatus = {
    Saving: 1,
    SaveSuccess: 2,
    SaveFailed: 3,
};

export const OrderSendStatus = {
    Sending: 1,
    SendSuccessful: 2,
    SendFailed: 3,
};

const initialState = {
    pendingOrders: null,
    isFetchingOrderData: false,
    fetchOrderDataFailed: false,
    orderSaveStatus: {},
    orderSavingInProgress: false,
    sendingOrdersInProgress: false,
    orderSendFailed: false,
    orderDataById: {},
    getOrderByIdInProgress: false,
    getOrderByIdFailed: false,
    savingCombinedOrder: false,
    ordersByOrderNumber: {}, // would contain e.g. 12345: [order1, order2,..]
    saveOrderShallowBatchInProgress: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDER_DATA_REQ:
            return {
                ...state,
                isFetchingOrderData: true,
                fetchOrderDataFailed: false,
            };
        case FETCH_ORDER_DATA_COMPLETED:
            const orderList = [];
            for (const o of action.orders) {
                orderList.push(new Order(o));
            }
            return {
                ...state,
                pendingOrders: orderList,
                isFetchingOrderData: false,
                fetchOrderDataFailed: false,
            };
        case FETCH_ORDER_DATA_FAILED:
            return {
                ...state,
                actions: [],
                isFetchingOrderData: false,
                fetchOrderDataFailed: true,
            };
        case SAVING_ORDER: {
            let newStatus = Object.assign({}, state.orderSaveStatus);
            if (action.id != null) newStatus[action.id] = DataSaveStatus.Saving;
            return {
                ...state,
                orderSaveStatus: newStatus,
                orderSavingInProgress: true,
            };
        }
        case ORDER_SAVED: {
            let newStatus = Object.assign({}, state.orderSaveStatus);
            let newPendingOrderList = [];
            if (action.multiple === true) {
                for (let o of state.pendingOrders) {
                    let newInd = 0;
                    let addOld = true;
                    for (let newOrder of action.orders) {
                        if (newOrder.id === o.id) {
                            newPendingOrderList.push(new Order(newOrder));
                            addOld = false;
                            action.orders.splice(newInd, 1);
                            break;
                        }
                        newInd++;
                    }
                    if (addOld) newPendingOrderList.push(o);
                }
                if (action.orders.length > 0) {
                    for (let o of action.orders) {
                        newPendingOrderList.push(new Order(o));
                    }
                }
            } else {
                newStatus[action.order.id] = DataSaveStatus.SaveSuccess;
                let addOrderAsNew = true;
                for (let o of state.pendingOrders) {
                    if (o.id === action.order.id) {
                        newPendingOrderList.push(new Order(action.order));
                        addOrderAsNew = false;
                    } else {
                        newPendingOrderList.push(o);
                    }
                }
                if (addOrderAsNew) newPendingOrderList.push(new Order(action.order));
            }

            return {
                ...state,
                pendingOrders: newPendingOrderList,
                orderSaveStatus: newStatus,
                orderSavingInProgress: false,
            };
        }
        case ORDER_SAVE_FAILED: {
            let newStatus = Object.assign({}, state.orderSaveStatus);
            if (action.id != null) newStatus[action.id] = DataSaveStatus.SaveFailed;
            return {
                ...state,
                orderSaveStatus: newStatus,
                orderSavingInProgress: false,
            };
        }
        case REMOVE_ORDER: {
            let newPendingOrderList = [];
            for (let o of state.pendingOrders) {
                if (o.id !== action.id) {
                    newPendingOrderList.push(o);
                }
            }
            return {
                ...state,
                pendingOrders: newPendingOrderList,
            };
        }
        case SENDING_ORDERS: {
            return {
                ...state,
                orderSendFailed: false,
                sendingOrdersInProgress: true,
            };
        }
        case SEND_ORDERS_SUCCESS: {
            const sentIds = action.orders.map((o) => o.id);
            const orderList = state.pendingOrders.filter((o) => !sentIds.includes(o.id));
            return {
                ...state,
                orderSendFailed: false,
                pendingOrders: orderList,
                sendingOrdersInProgress: false,
            };
        }
        case SEND_ORDERS_FAILED: {
            return {
                ...state,
                orderSendFailed: true,
                sendingOrdersInProgress: false,
            };
        }
        case GET_ORDERDATA_FULL_REQ:
            return {
                ...state,
                getOrderByIdInProgress: true,
                getOrderByIdFailed: false,
            };
        case GET_ORDERDATA_FULL_SUCCESS:
            let newArr = Object.assign({}, state.orderDataById);
            newArr[action.data.id] = new Order(action.data);
            return {
                ...state,
                orderDataById: newArr,
                getOrderByIdFailed: false,
                getOrderByIdInProgress: false,
            };
        case GET_ORDERDATA_FULL_FAIL:
            return {
                ...state,
                getOrderByIdFailed: true,
                getOrderByIdInProgress: false,
            };

        case SAVING_COMBINED_ORDER: {
            return {
                ...state,
                savingCombinedOrder: true,
            };
        }
        case SAVING_COMBINED_ORDER_SUCCESS: {
            return {
                ...state,
                savingCombinedOrder: false,
            };
        }
        case SAVING_COMBINED_ORDER_FAIL: {
            return {
                ...state,
                savingCombinedOrder: false,
            };
        }
        case FETCH_ORDER_BY_ORDER_NUMBER:
            return state;
        case FETCH_ORDER_BY_ORDER_NUMBER_SUCCESS: {
            let newOrderList = cloneDeep(state.ordersByOrderNumber);
            newOrderList[action.orderNumber] = action.orders.map((item) => {
                return new Order(item);
            });
            return {
                ...state,
                ordersByOrderNumber: newOrderList,
            };
        }
        case FETCH_ORDER_BY_ORDER_NUMBER_FAIL:
            return state;
        case SAVE_ORDER_SHALLOW_BATCH_STARTED:
            return {
                ...state,
                saveOrderShallowBatchInProgress: true,
            };
        case SAVE_ORDER_SHALLOW_BATCH_SUCCESS:
        case SAVE_ORDER_SHALLOW_BATCH_FAIL:
            return {
                ...state,
                saveOrderShallowBatchInProgress: false,
            };
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const saveFilterOrder = (order, fOrder) => {
    return async (dispatch) => {
        dispatch({
            type: SAVING_ORDER,
            id: order.id,
        });
        try {
            const resp = await Ajax.put('api/customerorder/' + order.id + '/filterorder/' + fOrder.id, fOrder);
            dispatch({
                type: ORDER_SAVED,
                order: resp.data,
            });
        } catch (err) {
            console.log(err);
            dispatch({
                type: ORDER_SAVE_FAILED,
                id: order.id,
            });
        }
    };
};

export const saveOrderShallow = (order) => {
    return (dispatch) => {
        Ajax.put('api/customerorder/shallow/' + order.id, order)
            .then(function (data) {
                dispatch({
                    type: ORDER_SAVED,
                    order: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
                dispatch({
                    type: ORDER_SAVE_FAILED,
                    id: order.id,
                });
            });
    };
};

export const saveOrderShallowBatch = (orders) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_ORDER_SHALLOW_BATCH_STARTED,
        });
        try {
            let data = await Ajax.put('api/customerorder/shallow/batch/', orders);
            dispatch({
                type: FETCH_ORDER_DATA_COMPLETED,
                orders: data.data,
            });
            dispatch({
                type: SAVE_ORDER_SHALLOW_BATCH_SUCCESS,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: SAVE_ORDER_SHALLOW_BATCH_FAIL,
            });
            return false;
        }
    };
};

// - This method is in practise limited to update order when filter order(s) are being deleted from the order
// - Any other updates are not performed!
export const saveOrder = (newOrder) => {
    return (dispatch) => {
        dispatch({
            type: SAVING_ORDER,
            id: newOrder.id,
        });
        Ajax.put('api/customerorder/' + newOrder.id, newOrder)
            .then(function (data) {
                dispatch({
                    type: ORDER_SAVED,
                    order: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
                dispatch({
                    type: ORDER_SAVE_FAILED,
                    id: newOrder.id,
                });
            });
    };
};

export const getPendingOrders = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_ORDER_DATA_REQ,
        });
        Ajax.get('api/customerorder/draft/')
            .then(function (data) {
                dispatch({
                    type: FETCH_ORDER_DATA_COMPLETED,
                    orders: data.data.draftOrders,
                });
            })
            .catch(function (err) {
                dispatch({
                    type: FETCH_ORDER_DATA_FAILED,
                });
            });
    };
};

export const removeOrder = (id) => {
    return (dispatch) => {
        Ajax.delete('api/customerorder/' + id)
            .then(function (res) {
                dispatch({
                    type: REMOVE_ORDER,
                    id: id,
                });
            })
            .catch(function (err) {
                console.log(err);
            });
    };
};

const sendOrdersInternal = (orders, combine, dispatch, language, reservations) => {
    dispatch({
        type: SENDING_ORDERS,
        orders: orders,
    });
    const url = combine ? 'api/customerorder/sendcombined/' : 'api/customerorder/send/';
    Ajax.post(url, { orders, language, reservations })
        .then(function (res) {
            dispatch({
                type: SEND_ORDERS_SUCCESS,
                orders: orders,
            });
            dispatch(getPendingOrders());
        })
        .catch(function (err) {
            console.log(err);
            dispatch({
                type: SEND_ORDERS_FAILED,
                orders: orders,
            });
        });
};

export const sendOrdersCombined = (orders, language, reservations) => {
    return (dispatch) => {
        let promisesToWait = [];
        for (let o of orders) {
            dispatch({
                type: SAVING_COMBINED_ORDER,
            });
            promisesToWait.push(Ajax.put('api/customerorder/shallow/' + o.id, o));
        }
        Promise.all(promisesToWait)
            .then((results) => {
                //console.log(results);
                dispatch({
                    type: SAVING_COMBINED_ORDER_SUCCESS,
                });
                return sendOrdersInternal(orders, true, dispatch, language, reservations);
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: SAVING_COMBINED_ORDER_FAIL,
                });
            });
    };
};

export const sendOrders = (orders, language) => {
    return (dispatch) => {
        sendOrdersInternal(orders, false, dispatch, language);
    };
};

export const newOrderFromLocation = (locationId, actionId) => {
    return (dispatch, getState) => {
        toastInfo('toast.addingLocationToOrder');
        dispatch({
            type: SAVING_ORDER,
            id: null,
        });
        Ajax.post('api/customerorder/fromlocation/' + locationId + '/actionid/' + actionId, {})
            .then(function (res) {
                toastInfo('toast.addedLocationToOrder');
                dispatch({
                    type: ORDER_SAVED,
                    order: res.data,
                });
            })
            .catch(function (err) {
                toastError('toast.addingLocationToOrderFailed');
                dispatch({
                    type: ORDER_SAVE_FAILED,
                    id: null,
                });
            });
    };
};

export const addMachineToOrder = (locId, machineId) => {
    return (dispatch) => {
        toastInfo('toast.addingMachineToOrder');
        dispatch({
            type: SAVING_ORDER,
            id: null,
        });
        Ajax.post('api/customerorder/' + locId + '/' + machineId)
            .then(function (res) {
                toastInfo('toast.addedMachineToOrder');
                dispatch({
                    type: ORDER_SAVED,
                    order: res.data,
                });
            })
            .catch(function (err) {
                toastError('toast.addingMachineToOrderFailed');
                dispatch({
                    type: ORDER_SAVE_FAILED,
                    id: null,
                });
            });
    };
};

export const addFilterToOrder = (locationId, machineId, filterId) => {
    return (dispatch) => {
        toastInfo('toast.addingFilterToOrder');
        dispatch({
            type: SAVING_ORDER,
            id: null,
        });
        Ajax.post('api/customerorder/' + locationId + '/' + machineId + '/' + filterId)
            .then(function (res) {
                toastInfo('toast.addedFilterToOrder');
                dispatch({
                    type: ORDER_SAVED,
                    order: res.data,
                });
            })
            .catch(function (err) {
                toastError('toast.addingFilterToOrderFailed');
                dispatch({
                    type: ORDER_SAVE_FAILED,
                    id: null,
                });
            });
    };
};

export const newOrderFromLocations = (locationIds) => {
    return (dispatch, getState) => {
        toastInfo('toast.addingLocationToOrder');
        dispatch({
            type: SAVING_ORDER,
            id: null,
        });
        Ajax.post('api/customerorder/fromlocations/', locationIds)
            .then(function (res) {
                toastInfo('toast.addedLocationToOrder');
                dispatch({
                    type: ORDER_SAVED,
                    multiple: true,
                    orders: res.data,
                });
            })
            .catch(function (err) {
                toastError('toast.addingLocationToOrderFailed');
                dispatch({
                    type: ORDER_SAVE_FAILED,
                    id: null,
                });
            });
    };
};

export const getOrderDataFull = (id) => {
    return (dispatch) => {
        dispatch({
            type: GET_ORDERDATA_FULL_REQ,
        });
        Ajax.get('api/customerorder/' + id)
            .then(function (data) {
                dispatch({
                    type: GET_ORDERDATA_FULL_SUCCESS,
                    data: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
                dispatch({
                    type: GET_ORDERDATA_FULL_FAIL,
                });
            });
    };
};

export const getOrdersByOrderNumber = (orderNumber) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_ORDER_BY_ORDER_NUMBER });
        try {
            const resp = await Ajax.get('api/customerorder/ordernumber/' + orderNumber);
            dispatch({
                type: FETCH_ORDER_BY_ORDER_NUMBER_SUCCESS,
                orders: resp.data,
                orderNumber: orderNumber,
            });
        } catch (err) {
            console.log(err.toJSON());
            dispatch({ type: FETCH_ORDER_BY_ORDER_NUMBER_FAIL });
        }
    };
};

const toastInfo = (message) => {
    toast.info(i18next.t(message), { timeout: 1000, hideProgressBar: true });
};

const toastError = (message) => {
    toast.error(i18next.t(message), { timeout: 5000 });
};
