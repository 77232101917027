import Customer from '../domain/customer';
import CustomerDescription from '../domain/customerdescription';
import TreeDescription from '../domain/treedescription';
import Ajax from './ajax';
import { REMOVE_LOCATION_SUCCESS, SAVE_LOCATION_SUCCESS, LOCATION_PORTFOLIO_UPDATED } from './locationdata';
import { LOGGED_OFF } from './authentication';
import CustomershipValuation from '../domain/customershipvaluation';
import { toast } from 'react-toastify';
import i18next from 'i18next';

const _ = require('lodash');

export const SAVE_NEW_CUSTOMER_IN_PROGRESS = 'customerdata/SAVE_NEW_CLIENT_IN_PROGRESS';
export const SAVE_NEW_CUSTOMER_SUCCESS = 'customerdata/SAVE_NEW_CLIENT_SUCCESS';
export const SAVE_NEW_CUSTOMER_FAILED = 'customerdata/SAVE_NEW_CLIENT_FAILED';

export const REMOVE_CUSTOMER_IN_PROGRESS = 'customerdata/REMOVE_CLIENT_IN_PROGRESS';
export const REMOVE_CUSTOMER_SUCCESS = 'customerdata/REMOVE_CLIENT_SUCCESS';
export const REMOVE_CUSTOMER_FAILED = 'customerdata/REMOVE_CLIENT_FAILED';

export const GET_DESCRIPTIVE_INFO_IN_PROGRESS = 'customerdata/getdescinfoinprogress';
export const GET_DESCRIPTIVE_INFO_FAIL = 'customerdata/getdescinfofail';
export const GET_DESCRIPTIVE_INFO_SUCCESS = 'customerdata/getdescinfosuccess';

export const GET_CUSTOMER_FULL_IN_PROGRESS = 'customerdata/getcustfullinproc';
export const GET_CUSTOMER_FULL_SUCCESS = 'customerdata/getcustfullsuccess';
export const GET_CUSTOMER_FULL_FAILED = 'customerdata/getcustfullfailed';

export const TREE_FOLDER_OPENED = 'customerdata/treefolderopened';
export const TREE_FOLDER_CLOSED = 'customerdata/treefolderclosed';
export const TREE_SCROLL_Y_POS_UPDATE = 'customerdata/treescrollypositionupdate';

export const CUSTOMER_TREEDATA_UPDATED = 'customerdata/treedataupdated';
export const CUSTOMER_VIEW_FILTER_UPDATE = 'customerdata/customerfilterchanged';

export const GET_ALL_CUSTOMERS_IN_PROGRESS = 'customerdata/getallcustomersinproc';
export const GET_ALL_CUSTOMERS_SUCCESS = 'customerdata/getallcustomersssucces';
export const GET_ALL_CUSTOMERS_FAILED = 'customerdata/getallcustomersfailed';

export const GET_VALUATIONS_IN_PROGRESS = 'customerdata/getvaluationsinproc';
export const GET_VALUATIONS_DONE = 'customerdata/getvaluationsdone';
export const GET_VALUATIONS_FAILED = 'customerdata/getvaluationsfailed';

export const NOTE_SAVED = 'customerdata/notesaved';
export const NOTE_REMOVED = 'customerdata/notesremoved';

export const CLEAR_CUSTOMER_FULL_DATA = 'customerdata/CLEAR_CUSTOMER_FULL_DATA';
export const CLEAR_CUSTOMER_FULL_DATA_BY_ID = 'customerdata/CLEAR_CUSTOMER_FULL_DATA_BY_ID';

export const DataRemoveStatus = {
    Removing: 1,
    RemoveSuccess: 2,
    RemoveFailed: 3,
};

const initialState = {
    isSavingData: false,
    saveDataFailed: false,
    saveDataDone: false,
    customerDescriptions: null,
    getCustomerDescriptionsInProgress: false,
    getCustomerDescriptionsFailed: false,
    customerDescriptionsOwn: null,
    getCustomerDescriptionsOwnInProgress: false,
    getCustomerDescriptionsOwnFailed: false,
    customersById: {},
    getCustomerByIdInProgress: false,
    getCustomerByIdFailed: false,
    removeCustomerStatus: {},
    treeviewOpenedFolderIds: [],
    treeScrollYValue: 0,
    customerFilter: '',
    allCustomers: null,
    allCustomersFetching: false,
    customershipValuations: null,
    customershipValuationsFetching: false,
    customershipValuationsFailed: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SAVE_NEW_CUSTOMER_IN_PROGRESS:
            return {
                ...state,
                isSavingData: true,
                saveDataFailed: false,
                saveDataDone: false,
            };
        case SAVE_NEW_CUSTOMER_FAILED:
            return {
                ...state,
                isSavingData: false,
                saveDataFailed: true,
            };
        case SAVE_NEW_CUSTOMER_SUCCESS:
            let customersNew = _.cloneDeep(state.customersById);
            customersNew[action.customer.id] = new Customer(action.customer);
            return {
                ...state,
                customersById: customersNew,
                isSavingData: false,
                saveDataFailed: false,
                saveDataDone: true,
                customerDescriptions: null,
                customerDescriptionsOwn: null,
                allCustomers: null,
            };
        case REMOVE_CUSTOMER_IN_PROGRESS: {
            let newStatus = _.cloneDeep(state.removeCustomerStatus);
            newStatus[action.id] = DataRemoveStatus.Removing;
            return {
                ...state,
                removeCustomerStatus: newStatus,
            };
        }
        case REMOVE_CUSTOMER_FAILED: {
            let newStatus = _.cloneDeep(state.removeCustomerStatus);
            newStatus[action.id] = DataRemoveStatus.RemoveFailed;
            return {
                ...state,
                removeCustomerStatus: newStatus,
            };
        }
        case REMOVE_CUSTOMER_SUCCESS: {
            let newStatus = _.cloneDeep(state.removeCustomerStatus);
            newStatus[action.data.customerId] = DataRemoveStatus.RemoveSuccess;
            return {
                ...state,
                removeCustomerStatus: newStatus,
            };
        }
        case GET_DESCRIPTIVE_INFO_IN_PROGRESS:
            return {
                ...state,
                customerDescriptions: null,
                getCustomerDescriptionsInProgress: true,
                getCustomerDescriptionsFailed: false,
            };
        case GET_DESCRIPTIVE_INFO_FAIL:
            return {
                ...state,
                getCustomerDescriptionsFailed: true,
                getCustomerDescriptionsInProgress: false,
                customerDescriptions: null,
            };
        case GET_DESCRIPTIVE_INFO_SUCCESS: {
            const all = [];
            const own = [];
            if (action.all != null && action.all.length > 0) {
                for (let c of action.all) {
                    all.push(new CustomerDescription(c));
                }
            }
            if (action.ownPortfolio != null && action.ownPortfolio.length > 0) {
                for (let c of action.ownPortfolio) {
                    own.push(new CustomerDescription(c));
                }
            }
            if (action.ownEdited != null && action.ownEdited.length > 0) {
                for (let c of action.ownEdited) {
                    if (!own.find((o) => o.id === c.id)) own.push(new CustomerDescription(c));
                }
            }
            return {
                ...state,
                customerDescriptions: all,
                customerDescriptionsOwn: own,
                getCustomerDescriptionsInProgress: false,
            };
        }
        case GET_CUSTOMER_FULL_IN_PROGRESS:
            return {
                ...state,
                getCustomerByIdInProgress: true,
                getCustomerByIdFailed: false,
            };
        case GET_CUSTOMER_FULL_SUCCESS:
            let newArr = _.cloneDeep(state.customersById);
            newArr[action.data.id] = markOpenFoldersInCustomerTree(
                new Customer(action.data),
                state.treeviewOpenedFolderIds
            );
            return {
                ...state,
                customersById: newArr,
                getCustomerByIdFailed: false,
                getCustomerByIdInProgress: false,
            };
        case GET_CUSTOMER_FULL_FAILED:
            return {
                ...state,
                getCustomerByIdFailed: true,
                getCustomerByIdInProgress: false,
            };
        case TREE_FOLDER_OPENED: {
            let opened = state.treeviewOpenedFolderIds.slice(0);
            let newCustomers = _.cloneDeep(state.customersById);
            opened.push(action.folderId);
            newCustomers[action.customerId] = markOpenFoldersInCustomerTree(
                state.customersById[action.customerId],
                opened
            );
            return {
                ...state,
                treeviewOpenedFolderIds: opened,
                customersById: newCustomers,
            };
        }
        case TREE_FOLDER_CLOSED: {
            let opened = [];
            let newCustomers = _.cloneDeep(state.customersById);
            for (let item of state.treeviewOpenedFolderIds) {
                if (item !== action.folderId) {
                    opened.push(item);
                }
            }
            newCustomers[action.customerId] = markOpenFoldersInCustomerTree(
                state.customersById[action.customerId],
                opened
            );

            return {
                ...state,
                treeviewOpenedFolderIds: opened,
                customersById: newCustomers,
            };
        }
        case TREE_SCROLL_Y_POS_UPDATE: {
            return {
                ...state,
                treeScrollYValue: action.scrollY,
            };
        }
        case CUSTOMER_TREEDATA_UPDATED: {
            let customersNew = _.cloneDeep(state.customersById);
            let newCust = _.cloneDeep(state.customersById[action.data.custid]);
            newCust.treeData = action.data.data;
            customersNew[action.data.custid] = newCust;
            return {
                ...state,
                customersById: customersNew,
            };
        }
        case SAVE_LOCATION_SUCCESS:
            if (action.clearTreeData) {
                let newCustomers = _.cloneDeep(state.customersById);
                newCustomers[action.location.customer.id] = undefined;
                return {
                    ...state,
                    customersById: newCustomers,
                };
            }
            return state;
        case REMOVE_LOCATION_SUCCESS: {
            let customersNew = _.cloneDeep(state.customersById);
            customersNew[action.customerId] = undefined;
            return {
                ...state,
                customersById: customersNew,
            };
        }
        case CUSTOMER_VIEW_FILTER_UPDATE: {
            return {
                ...state,
                customerFilter: action.filter,
            };
        }
        case NOTE_SAVED: {
            let customer = new Customer(state.customersById[action.customerId]);
            customer.notes = action.notes;
            let newArr = _.cloneDeep(state.customersById);
            newArr[action.customerId] = new Customer(customer);
            return {
                ...state,
                customersById: newArr,
            };
        }
        case NOTE_REMOVED: {
            let customer = new Customer(state.customersById[action.customerId]);
            customer.notes = _.filter(customer.notes, function (n) {
                return n.storageId !== action.note.storageId;
            });
            let newArr = _.cloneDeep(state.customersById);
            newArr[action.customerId] = new Customer(customer);
            return {
                ...state,
                customersById: newArr,
            };
        }
        case GET_ALL_CUSTOMERS_IN_PROGRESS:
            return {
                ...state,
                allCustomersFetching: true,
            };
        case GET_ALL_CUSTOMERS_SUCCESS:
            let customers = [];
            for (let c of action.customers) {
                customers.push(new Customer(c));
            }
            return {
                ...state,
                allCustomers: customers,
                allCustomersFetching: false,
            };
        case GET_ALL_CUSTOMERS_FAILED:
            return {
                ...state,
                allCustomersFetching: false,
            };
        case GET_VALUATIONS_IN_PROGRESS:
            return {
                ...state,
                customershipValuationsFetching: true,
                customershipValuations: null,
                customershipValuationsFailed: false,
            };
        case GET_VALUATIONS_DONE:
            let valuations = [];
            for (const v of action.valuations) {
                valuations.push(new CustomershipValuation(v));
            }
            return {
                ...state,
                customershipValuationsFetching: false,
                customershipValuations: valuations,
                customershipValuationsFailed: false,
            };
        case GET_VALUATIONS_FAILED:
            return {
                ...state,
                customershipValuationsFetching: false,
                customershipValuations: null,
                customershipValuationsFailed: true,
            };
        case CLEAR_CUSTOMER_FULL_DATA: {
            const newCustomers = {};
            if (!!action.doNotRemoveThis) {
                const preserve = state.customersById[action.doNotRemoveThis];
                newCustomers[action.doNotRemoveThis] = preserve;
            }
            return {
                ...state,
                customersById: newCustomers,
            };
        }
        case CLEAR_CUSTOMER_FULL_DATA_BY_ID: {
            const newCustomers = _.cloneDeep(state.customersById);
            newCustomers[action.id] = undefined;
            return {
                ...state,
                customersById: newCustomers,
            };
        }
        case LOCATION_PORTFOLIO_UPDATED: {
            const newArr = _.cloneDeep(state.customersById);
            newArr[action.customerId] = undefined;
            return {
                ...state,
                customersById: newArr,
            };
        }
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

const markOpenFoldersInCustomerTree = (customer, openFolders) => {
    let tree = _.cloneDeep(customer.treeData);
    let cust = _.cloneDeep(customer);
    markOpenFolders(tree, openFolders);
    if (openFolders.indexOf(customer.id) !== -1) tree.open = true;
    cust.treeData = tree;
    return cust;
};

const markOpenFolders = (tree, opened) => {
    if (opened.indexOf(tree.id) !== -1) tree.open = true;
    else tree.open = false;
    if (tree.childFolders != null && tree.childFolders.length > 0) {
        for (let child of tree.childFolders) {
            markOpenFolders(child, opened);
        }
    }
};

const sanitizeTreeDataLocations = (treedata) => {
    if (treedata.locations != null && treedata.locations.length > 0) {
        let ind = 0;
        for (let l of treedata.locations) {
            let id = l.id;
            treedata.locations[ind] = { id: id };
            ind++;
        }
    }
    if (treedata.childFolders != null && treedata.childFolders.length > 0) {
        for (let c of treedata.childFolders) {
            sanitizeTreeDataLocations(c);
        }
    }
};

export const updateCustomerTreedata = (custId, treedata) => {
    return (dispatch) => {
        let toBeSent = new TreeDescription(treedata);
        sanitizeTreeDataLocations(toBeSent);
        Ajax.put('api/customertreedata/' + custId, toBeSent)
            .then(function (data) {
                dispatch({
                    type: CUSTOMER_TREEDATA_UPDATED,
                    data: {
                        custid: custId,
                        data: treedata,
                    },
                });
            })
            .catch(function (err) {
                console.log(err);
            });
    };
};

export const saveCustomer = (customer) => {
    return (dispatch) => {
        dispatch({
            type: SAVE_NEW_CUSTOMER_IN_PROGRESS,
        });
        let url = 'api/customer/' + customer.id;

        // do not send treedata object with the customer, it's being updated separately for now
        const toSend = _.cloneDeep(customer);
        toSend.treeData = undefined;

        let promise = new Promise((resolve, reject) => {
            toastInfo('toast.saving');
            Ajax.put(url, toSend)
                .then(function (data) {
                    dispatch({
                        type: SAVE_NEW_CUSTOMER_SUCCESS,
                        customer: data.data,
                        update: true,
                    });
                    toastInfo('toast.savedSuccess');
                    resolve();
                })
                .catch(function (err) {
                    if (err.response && err.response.status === 404) {
                        toastError('toast.customerInfoCouldBeNotSavedPotentiallyRemoved');
                    }
                    if (err.response && err.response.status === 409) {
                        toastError('toast.saveFailedConflict');
                    }
                    dispatch({
                        type: SAVE_NEW_CUSTOMER_FAILED,
                    });
                    reject();
                });
        });
        return promise;
    };
};

export const removeCustomer = (customer) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_CUSTOMER_IN_PROGRESS,
            id: customer.id,
        });
        let promise = new Promise((resolve, reject) => {
            Ajax.delete('api/customer/' + customer.id)
                .then(function (data) {
                    dispatch({
                        type: REMOVE_CUSTOMER_SUCCESS,
                        data: data.data,
                    });
                    resolve();
                })
                .catch(function (err) {
                    console.log(err);
                    if (err.response && err.response.status === 404) {
                        toastError('toast.customerInfoCouldNotBeRemoved');
                    }
                    dispatch({
                        type: REMOVE_CUSTOMER_FAILED,
                        id: customer.id,
                    });
                    reject();
                });
        });
        return promise;
    };
};

export const getCustomerDescriptiveInfo = () => {
    return (dispatch) => {
        dispatch({
            type: GET_DESCRIPTIVE_INFO_IN_PROGRESS,
        });
        const reqs = [
            Ajax.get('api/customer/descriptiveinfo/'),
            Ajax.get('api/customer/descriptiveinfo-ownportfolio/'),
            Ajax.get('api/customer/descriptiveinfo-ownedited/'),
        ];
        Promise.all(reqs)
            .then((values) => {
                dispatch({
                    type: GET_DESCRIPTIVE_INFO_SUCCESS,
                    all: values[0].data,
                    ownPortfolio: values[1].data,
                    ownEdited: values[2].data,
                });
            })
            .catch(function (err) {
                console.log(err);
                dispatch({
                    type: GET_DESCRIPTIVE_INFO_FAIL,
                });
            });
    };
};

export const getCustomerFull = (id) => async (dispatch) => {
    dispatch({
        type: GET_CUSTOMER_FULL_IN_PROGRESS,
    });
    try {
        const resp = await Ajax.get('api/customer/' + id);
        dispatch({
            type: GET_CUSTOMER_FULL_SUCCESS,
            data: resp.data,
        });
        return resp.data;
    } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 404) {
            toastError('general.fetchFailedRetry');
        }
        dispatch({
            type: GET_CUSTOMER_FULL_FAILED,
        });
        return null;
    }
};

export const saveNote = (note) => {
    return async (dispatch) => {
        try {
            let resp = await Ajax.post('api/note/', note);
            dispatch({
                type: NOTE_SAVED,
                notes: resp.data,
                customerId: note.customerId,
            });
            return true;
        } catch (err) {
            console.log(err);
            if (err.response && err.response.status === 404) {
                toastError('toast.customerInfoCouldBeNotSavedPotentiallyRemoved');
            }
            return false;
        }
    };
};

export const editNote = (note) => {
    return async (dispatch) => {
        try {
            let resp = await Ajax.put('api/note/', note);
            dispatch({
                type: NOTE_SAVED,
                notes: resp.data,
                customerId: note.customerId,
            });
            return true;
        } catch (err) {
            console.log(err);
            if (err.response && err.response.status === 404) {
                toastError('toast.customerInfoCouldBeNotSavedPotentiallyRemoved');
            }
            return false;
        }
    };
};

export const removeNote = (note) => {
    return async (dispatch) => {
        try {
            await Ajax.delete('api/note/' + note.storageId);
            dispatch({
                type: NOTE_REMOVED,
                note: note,
                customerId: note.customerId,
            });
            return true;
        } catch (err) {
            console.log(err);
            toastError('toast.customerInfoCouldBeNotSavedPotentiallyRemoved');
            return false;
        }
    };
};

export const getAllCustomers = () => {
    return (dispatch) => {
        dispatch({ type: GET_ALL_CUSTOMERS_IN_PROGRESS });
        Ajax.get('api/customer/')
            .then((data) => {
                dispatch({
                    type: GET_ALL_CUSTOMERS_SUCCESS,
                    customers: data.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: GET_ALL_CUSTOMERS_FAILED });
            });
    };
};

export const getValuations = (portfolioId) => {
    return (dispatch) => {
        dispatch({ type: GET_VALUATIONS_IN_PROGRESS });
        Ajax.get(`api/customer/valuations/${portfolioId}`)
            .then((data) => {
                dispatch({
                    type: GET_VALUATIONS_DONE,
                    valuations: data.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: GET_VALUATIONS_FAILED });
            });
    };
};

export const refreshCustomerFullDataIfNeeded = (id, cid) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get(`api/customer/${id}/currentcid/${cid}`);

            if (resp.status === 204) {
                // 204 = HTTP no content. Data is okay in the frontend
                return true;
            } else if (resp.status === 200) {
                // 200 = HTTP OK, new data provided
                dispatch({
                    type: GET_CUSTOMER_FULL_SUCCESS,
                    data: resp.data,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };
};

export const clearCustomerFullDatas = (doNotRemoveThis) => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_CUSTOMER_FULL_DATA,
            doNotRemoveThis: doNotRemoveThis,
        });
    };
};

export const clearCustomerFullDataById = (id) => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_CUSTOMER_FULL_DATA_BY_ID,
            id,
        });
    };
};

export const getCustomerByCustomerNumber = (customerNumber) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get(`api/customer/customerNumber/${customerNumber}`);
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

const toastInfo = (message) => {
    toast.info(i18next.t(message), { timeout: 1000 });
};

const toastError = (message) => {
    toast.error(i18next.t(message), { timeout: 5000, hideProgressBar: true });
};
