export const ParseProductDimension = (productcode) => {
    const regex = /^[A-Za-z]+ [A-Za-z0-1]+ [a-zA-z0-1]+ \d+% (\d+) (\d+) (\d+\/?\d+)/;
    let m;
    if ((m = regex.exec(productcode)) !== null && !m[3].includes('/')) {
        return {
            width: Number(m[1]),
            height: Number(m[2]),
            depth: Number(m[3]),
        };
    }
    return null;
};

export const ProductDataParser = (productcode) => {
    const productItems = productcode.split(' ');
    //sanity check
    if (productItems.length < 12) return null;
    if (!productItems[0] || productItems[0].toUpperCase() !== 'VPF') return null;
    let product = {
        type: '',
        material: '',
        class: '',
        classP: '',
        width: 0,
        height: 0,
        depth: 0,
        depthS: null, //diagonal product has value
        pockets: 0,
        frame: '',
        gasket: '',
        materialType: '',
    };

    product.type = productItems[0];
    product.material = productItems[1];
    product.class = productItems[2];
    product.classP = productItems[3];
    product.width = Number(productItems[4]);
    product.height = Number(productItems[5]);
    if (productItems[6].includes('/')) {
        product.depth = Number(productItems[6].split('/')[0]);
        product.depthS = Number(productItems[6].split('/')[1]);
    } else {
        product.depth = Number(productItems[6]);
    }
    product.pockets = Number(productItems[7]);
    product.frame = productItems[9];
    product.gasket = productItems[10];
    product.materialType = productItems[11];

    return product;
};

export const IsProductStandard = (productcode) => {
    var product = ProductDataParser(productcode);
    //if not recognized product or diagonal type, return true
    if (!product) return true;
    if (product.depthS) return true;

    if (
        product.material === 'NW' &&
        product.class === 'ePM10' &&
        product.classP === '65%' &&
        product.materialType === 'R'
    ) {
        return [525, 650].includes(product.depth);
    }
    if (
        product.material === 'NW' &&
        product.class === 'ePM1' &&
        product.classP === '65%' &&
        product.materialType === 'E'
    ) {
        return [360, 525, 600, 650].includes(product.depth);
    }

    if (
        product.material === 'NW' &&
        product.class === 'ePM1' &&
        product.classP === '65%' &&
        product.materialType === 'R'
    ) {
        return [360, 525, 600, 650].includes(product.depth);
    }

    if (product.material === 'NW' && product.class === 'ePM1' && product.classP === '80%') {
        return [360, 525, 650].includes(product.depth);
    }

    if (product.material === 'NL' && product.class === 'ePM1' && product.classP === '65%') {
        return [360, 550, 650].includes(product.depth);
    }

    if (product.material === 'NL' && product.class === 'ePM1' && product.classP === '90%') {
        return [360, 550, 650].includes(product.depth);
    }

    if (product.material === 'SE' && product.class === 'ePM10' && product.classP === '65%') {
        return [360, 500, 600, 640].includes(product.depth);
    }

    if (
        product.material === 'SE' &&
        ((product.class === 'ePM10' && product.classP === '70%') ||
            (product.class === 'EN779' && product.classP === 'F7'))
    ) {
        return [360, 500, 600, 640].includes(product.depth);
    }

    if (product.class === 'Coarse' && product.classP === '80%') {
        return [140, 190, 240, 300, 360, 500, 620].includes(product.depth);
    }

    return true;
};
