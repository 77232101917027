class CustomershipStatus {
    static Potential() {
        return 1;
    }
    static Active() {
        return 2;
    }
}

export default CustomershipStatus;
