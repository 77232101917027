import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { getOpenSurveysForUserPortfolio } from '../modules/surveydata';
import { useHistory } from 'react-router-dom';

export default function SurveyNtf(props) {
    const dispatch = useDispatch();
    const features = useSelector((state) => state.settingsdata.features);
    const surveysInUse = features != null ? features.surveysInUse : false;
    const [surveys, setSurveys] = React.useState([]);
    const history = useHistory();

    useEffect(() => {
        async function getSurveys() {
            var resp = await getOpenSurveysForUserPortfolio()(dispatch);
            if (resp.length > 0) {
                setSurveys(resp);
            }
        }
        if (surveysInUse) {
            getSurveys();
        }
    }, [surveysInUse, dispatch]);

    const onClick = () => {
        history.push('/surveys');
    };

    if (!surveysInUse || surveys.length === 0) return null;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Alert severity="info" onClick={onClick}>
                    Kartoitusraportteja saatavilla {surveys.length} kpl
                </Alert>
            </Grid>
        </Grid>
    );
}
