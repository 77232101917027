import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../common/spinner';
import { listPortfolios } from '../modules/portfoliodata';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';

const _ = require('lodash');

export default function PortfolioView(props) {
    const dispatch = useDispatch();
    const portfolioId = parseInt(props.match.params.portfolioId, 10);
    const portfolios = useSelector((state) => state.portfoliodata.portfolios);
    //const portfolioFull = portfolios != null ? _.find(portfolios, ['id', portfolioId]) : null;
    const [portfolioFull, setPortfolioFull] = React.useState(null);

    useEffect(() => {
        if (portfolios == null || portfolios.length === 0) {
            dispatch(listPortfolios());
        } else {
            setPortfolioFull(_.find(portfolios, ['id', portfolioId]));
        }
    }, [dispatch, portfolios, portfolioId]);

    const close = () => {
        props.history.goBack();
    };

    const onEditItem = () => {
        props.history.push('/admin/portfolio/edit/' + portfolioId);
    };

    const disableButtons = !portfolioFull;

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {portfolioFull != null && (
                        <h2>
                            <Button onClick={close}>
                                <i className="fas fa-chevron-left fa-2x"></i>&nbsp;&nbsp;
                            </Button>
                            {portfolioFull.name}
                        </h2>
                    )}
                    {!portfolioFull && <Spinner padding="20px" title="Haetaan salkun tietoja" />}
                </Grid>

                {portfolioFull && (
                    <React.Fragment>
                        {(portfolioFull.users == null || portfolioFull.users.length === 0) && (
                            <Grid item xs={12}>
                                <Alert severity="warning">Salkulle ei ole määritetty käyttäjiä.</Alert>
                            </Grid>
                        )}
                        <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                            Käyttäjät
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {portfolioFull.users.map((puser) => puser.user.name).join(', ')}
                        </Grid>
                    </React.Fragment>
                )}
                <Footer>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            name="btn-edit"
                            onClick={onEditItem}
                            disabled={disableButtons}>
                            <i className="fas fa-pencil-alt" />
                            &nbsp;Muokkaa
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" name="btn-close" onClick={close}>
                            Sulje
                        </Button>
                    </Grid>
                </Footer>
                <FooterPadding />
            </Grid>
        </div>
    );
}
