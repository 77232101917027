import React, { Component } from 'react';
import Login from '../common/login';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../modules/authentication';
import * as orderActions from '../modules/orderdata';
import Main from './main';
import { withRouter } from 'react-router';
import GlobalNotifications from '../common/globalnotifications';
import ChangePassword from '../userprofile/changepassword';
import moment from 'moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './VadoTheme';
import CacheBuster from './cachebuster';
import { Container } from '@material-ui/core';

class App extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.checkTokenValidity = this.checkTokenValidity.bind(this);
    }

    componentDidMount() {
        if (!this.props.authenticated && !this.props.loginInProgress) this.props.CheckAuthentication();

        this.checkTokenValidity();
    }

    checkTokenValidity() {
        var _this = this;
        setTimeout(() => {
            if (
                sessionStorage.getItem('accessTokenValidUntil') != null &&
                sessionStorage.getItem('refreshToken') != null
            ) {
                var expires = moment.unix(sessionStorage.getItem('accessTokenValidUntil'));
                var diff = expires.diff(moment(), 'minutes');
                if (diff < 5) {
                    _this.props.RefreshToken();
                }
            }
            _this.checkTokenValidity();
        }, 1000 * 30);
    }

    onSubmit(user, pass) {
        this.props.doLogin(user, pass);
    }

    componentDidUpdate() {
        if (!this.props.authenticated && !this.props.logoffInProgress) {
            if (this.props.history.location != null) {
                if (this.props.history.location.pathname === '/logout') {
                    this.props.history.replace('/');
                }
            }
        }
    }

    render() {
        const jwtPresent = sessionStorage.getItem('refreshToken') != null;
        const showLoginDialog = this.props.loginInProgress || (!jwtPresent && !this.props.authenticated);
        const showLoading =
            this.props.logoffInProgress || (jwtPresent && !this.props.loginInProgress && !this.props.authenticated);

        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                    }
                    if (this.props.newPasswordRequired)
                        return (
                            <MuiThemeProvider theme={theme}>
                                <Container disableGutters={true} maxWidth={false}>
                                    <ChangePassword
                                        passwordChangeInProgress={this.props.passwordChangeInProgress}
                                        forcedChangePassword={this.props.doForcedPasswordChange}
                                        passwordChangeFailed={this.props.passwordChangeFailed}
                                        username={this.props.newPasswordRequiredFor}
                                    />
                                </Container>
                            </MuiThemeProvider>
                        );
                    if (showLoginDialog)
                        return (
                            <MuiThemeProvider theme={theme}>
                                <Container disableGutters={true} maxWidth={false}>
                                    <Login
                                        show={true}
                                        onSubmit={this.onSubmit}
                                        loginFailed={this.props.loginFailed}
                                        loginInProgress={this.props.loginInProgress}
                                    />
                                </Container>
                            </MuiThemeProvider>
                        );
                    if (showLoading) {
                        return <div></div>;
                    }
                    return (
                        <Container disableGutters={true} maxWidth={false}>
                            <MuiThemeProvider theme={theme}>
                                <Main user={this.props.userProfile} history={this.props.history} />
                                <GlobalNotifications />
                            </MuiThemeProvider>
                        </Container>
                    );
                }}
            </CacheBuster>
        );
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.authentication.authenticated,
    userProfile: state.authentication.userProfile,
    loginFailed: state.authentication.loginFailed,
    loginInProgress: state.authentication.loginInProgress,
    logoffInProgress: state.authentication.logoffInProgress,
    newPasswordRequired: state.authentication.newPasswordRequired,
    passwordChangeFailed: state.authentication.passwordChangeFailed,
    passwordChangeInProgress: state.authentication.passwordChangeInProgress,
    newPasswordRequiredFor: state.authentication.newPasswordRequiredFor,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...authActions, ...orderActions }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
