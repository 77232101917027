import React from 'react';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const UserData = () => {
    const { t } = useTranslation();
    const userprofile = useSelector((state) => state.authentication.userProfile);

    return (
        <div>
            <Container maxWidth="sm">
                <Grid
                    container
                    spacing={3}
                    alignItems={'flex-end'}
                    alignContent={'flex-end'}
                    justifyContent={'flex-end'}>
                    <Grid item xs={12}>
                        <h1>{t('general.userInfo')}</h1>
                    </Grid>
                    <Grid item xs={6}>
                        {t('general.name')}
                    </Grid>
                    <Grid item xs={6}>
                        {userprofile.name}
                    </Grid>
                    <Grid item xs={6}>
                        {t('general.email')}
                    </Grid>
                    <Grid item xs={6}>
                        {userprofile.username}
                    </Grid>
                    <Grid item xs={6}>
                        {t('general.phone')}
                    </Grid>
                    <Grid item xs={6}>
                        {userprofile.phoneNumber}
                    </Grid>
                    {!userprofile.isCustomerUser && (
                        <>
                            <Grid item xs={6}>
                                {t('admin.portfolios')}
                            </Grid>
                            <Grid item xs={6}>
                                {userprofile.portfolioDescriptions.map((p, index) => (
                                    <span key={index}>{(index ? ', ' : '') + p.name}</span>
                                ))}
                            </Grid>
                        </>
                    )}
                </Grid>
            </Container>
        </div>
    );
};

export default UserData;
