import Ajax from '../modules/ajax';

class Logger {
    static LogError(msg, stackTrace) {
        Ajax.post('api/debug/error/', { message: msg, stack: stackTrace });
    }

    static LogDebug(msg) {}

    static LogInformation(msg) {}
}

export default Logger;
