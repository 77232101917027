import { LOGGED_OFF } from './authentication';
import { POSTAL_CODE_API_KEY } from './settingsdata';
import CONFIG from '../config';

export const POSTAL_CODE_FETCHING = 'postaldata/fetching';
export const POSTAL_CODES_RETRIEVED = 'postaldata/retrieved';

const initialState = {
    fetchingData: false,
    postalCodes: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POSTAL_CODE_FETCHING:
            return {
                ...state,
                fetchingData: true,
            };
        case POSTAL_CODES_RETRIEVED:
            return {
                postalCodes: action.codes.sort((a, b) => parseInt(a.postal_code) - parseInt(b.postal_code)),
                fetchingData: false,
            };
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const getPostalCodes = () => {
    return async (dispatch) => {
        dispatch({
            type: POSTAL_CODE_FETCHING,
        });
        try {
            const resp = await fetch(CONFIG.postalCodeApiUrl, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'x-api-key': sessionStorage.getItem(POSTAL_CODE_API_KEY),
                },
            });
            const codes = await resp.json();
            dispatch({ type: POSTAL_CODES_RETRIEVED, codes });
        } catch (err) {
            console.log(err);
        }
    };
};
