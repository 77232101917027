import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { IconButton, InputAdornment } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    inputField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: '130px',
        maxWidth: '150px',
    },
}));

export const TypeOnChange = 1;
export const TypeOnBlur = 2;

//props
//id:       field id, used on call back
//value:    input field value
//onChange: call back when value changed
//min:      not allow numbers below this (optional)
//type:     TypeOnChange, TypeOnBlur (optional, default TypeOnChange)
export default function NumericInputElement(props) {
    let classes = useStyles();

    const [inputValue, setInputValue] = useState(props.value);
    const type = !!props.type ? props.type : TypeOnChange;
    const min = props.min !== undefined ? parseInt(props.min) : null;
    const disable = props.saving;

    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    const updateCount = async (count) => {
        await props.onChange(props.id, count);
    };

    const onBlur = (e) => {
        if (!!!e.target.value) {
            setInputValue(1);
            updateCount(1);
        } else if (type === TypeOnBlur) {
            updateCount(parseInt(e.target.value));
        }
    };

    const countChange = async (e) => {
        let typedInput = e.target.value;
        if (!/^\d*$/.test(typedInput)) {
            return;
        }
        if (min !== null && parseInt(e.target.value) < min) {
            return;
        }
        setInputValue(typedInput);
        if (!!typedInput && type === TypeOnChange) {
            let count = parseInt(e.target.value);
            await updateCount(count);
        }
    };

    const countIncrease = async () => {
        let newValue = props.value + 1;
        await updateCount(newValue);
        setInputValue(newValue);
    };

    const countDecrease = async () => {
        let newValue = props.value - 1;
        if (min !== null && parseInt(newValue) < min) {
            return;
        }
        await updateCount(newValue);
        setInputValue(newValue);
    };

    return (
        <Grid container>
            <Grid item>
                <TextField
                    className={classes.inputField}
                    name="count"
                    onChange={(e) => countChange(e)}
                    value={inputValue}
                    onBlur={(e) => onBlur(e)}
                    disabled={disable}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="decrease"
                                    name="decrease"
                                    onClick={countDecrease}
                                    disabled={(min !== null && parseInt(inputValue) <= min) || disable}>
                                    <RemoveIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="increase"
                                    name="increase"
                                    onClick={countIncrease}
                                    disabled={disable}>
                                    <AddIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}></TextField>
            </Grid>
        </Grid>
    );
}
