import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DialogActions, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { uniq } from 'lodash';
import { getPalletInfosUsedInOrder, determineAllowedPalletCodesInSelection } from './palletcodeutil';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

export default function PalletInfoSelectorDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [selected, setSelected] = useState('');
    const availablePalletCodes = useSelector((state) => state.codedata.palletInfoCodes);
    const allUsedCodes = props.allUsedCodes;
    const orderInScope = props.orderInScope;
    const fosInScope = props.fosInScope;
    const foUpdate = fosInScope && fosInScope.length > 0;
    const { t } = useTranslation();

    useEffect(() => {
        if (props.open) {
            setSelected('');
        }
    }, [props.open]);

    const orderPalletInfosInUse = getPalletInfosUsedInOrder(props.orderInScope);

    const determineAllowedPalletInfos = (props) => {
        if (!props.orderInScope || !props.allowedPalletCodes || !props.allUsedCodes) return null;

        return determineAllowedPalletCodesInSelection(
            props.orderInScope,
            props.fosInScope,
            props.allowedPalletCodes,
            allUsedCodes,
            availablePalletCodes
        );
    };

    const allowedPalletCodesAndLocations = determineAllowedPalletInfos(props);

    const onSave = () => {
        if (selected && selected !== '') {
            if (!foUpdate) {
                props.save(selected, orderInScope.id, undefined);
            } else {
                props.save(selected, orderInScope.id, fosInScope);
            }
        } else {
            props.cancel();
        }
    };

    const onCancel = () => {
        props.cancel();
    };

    const handleChange = (e) => {
        setSelected(e.target.value);
    };

    const formatLocations = (locations) => {
        if (locations.length <= 3) {
            const len = 50 / locations.length;
            return locations
                .map((l) => {
                    return l.substr(0, len);
                })
                .join(', ');
        } else {
            const len = 50 / 3;
            return (
                locations
                    .map((l, ind) => {
                        if (ind <= 2) {
                            return l.substr(0, len);
                        } else {
                            return '';
                        }
                    })
                    .join(', ') + '...'
            );
        }
    };

    if (!props.open) return null;

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <strong>
                            {orderInScope.location ? orderInScope.location.name : orderInScope.deliveryRecipient}
                        </strong>
                        <br />
                        {!foUpdate ? (
                            <span>
                                {t('order.filtersFromLocationInPallets')}{' '}
                                <strong>{orderPalletInfosInUse.join(', ')}</strong>
                            </span>
                        ) : (
                            <span>
                                {t('order.selectedFiltersInPallets', { filterCount: fosInScope.length })}{' '}
                                <strong>{uniq(fosInScope.map((fo) => fo.palletInformation)).join(', ')}</strong>
                            </span>
                        )}
                        <br />
                        <br />
                        {allowedPalletCodesAndLocations && allowedPalletCodesAndLocations.length === 0 && (
                            <span>{t('order.filtersInThisOrderCannotBeMovedToDifferentPallet')}</span>
                        )}
                        {allowedPalletCodesAndLocations && allowedPalletCodesAndLocations.length > 0 && (
                            <span>
                                {!foUpdate ? (
                                    <React.Fragment>
                                        {t('order.choosePalletWhereToPlaceAllFiltersInOrder')}&nbsp;
                                        {t('order.multipleOrdersInSamePalletOnlyIfDeliveryAddressSame')}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {t('order.choosePalletWhereToPlaceSelectedFilters')}&nbsp;
                                        {t('order.multipleOrdersInSamePalletOnlyIfDeliveryAddressSame')}
                                    </React.Fragment>
                                )}
                            </span>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {allowedPalletCodesAndLocations && allowedPalletCodesAndLocations.length > 0 && (
                            <FormControl className={classes.select}>
                                <InputLabel id="lavaryhma-label">{t('order.palletGroup')}</InputLabel>
                                <Select
                                    labelId="lavaryhma-label"
                                    id="lavaryhma-select"
                                    name="lavaryhma-select"
                                    value={selected}
                                    onChange={handleChange}>
                                    {allowedPalletCodesAndLocations.map((v) => (
                                        <MenuItem key={v.code} value={v.code}>
                                            {v.code} ({formatLocations(v.locations)})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="default" name="cancel" onClick={onCancel}>
                    {t('buttons.cancel')}
                </Button>
                <Button variant="contained" color="primary" name="save" onClick={onSave} disabled={selected === ''}>
                    {t('buttons.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
