import React, { useState } from 'react';
import moment from 'moment';
import TimeSortedLocationGroup from './timesortedlocationgroup';
import { sortBy, filter, deburr } from 'lodash';
import { Checkbox, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

export default function OrderSortedLocationList(props) {
    const [withFiltersChecked, setWithFiltersChecked] = useState(false);
    // const [sortedAndFiltered, setSortedAndFiltered] = useState([]);
    const { t } = useTranslation();

    const getFilteredAndSortedLocations = () => {
        let sortedLocations = {};
        let result = [];
        if (props.locationDescriptions == null) return result;

        // Filter
        let locationsFiltered = filter(props.locationDescriptions, (l) => {
            return deburr(l.name).toLowerCase().indexOf(deburr(props.filter).toLowerCase()) !== -1;
        });
        if (!withFiltersChecked) {
            locationsFiltered = filter(locationsFiltered, (l) => {
                return l.reminderMonthsArr.length === 0;
            });
        }

        sortedLocations = sortBy(locationsFiltered, 'latestOrder').reverse();

        //gategorize data by time
        const timelimit6m = moment().add(-6, 'months');
        const timelimit1y = moment(timelimit6m).add(-6, 'months');
        const timelimit2y = moment(timelimit6m).add(-18, 'months');

        const in6mLoc = filter(sortedLocations, (l) => {
            return l.latestOrder.isAfter(timelimit6m);
        });
        const in12mLoc = filter(sortedLocations, (l) => {
            return l.latestOrder.isBetween(timelimit1y, timelimit6m);
        });
        const in24mLoc = filter(sortedLocations, (l) => {
            return l.latestOrder.isBetween(timelimit2y, timelimit1y);
        });
        const noOrdersLoc = filter(sortedLocations, (l) => {
            return l.latestOrder.isValid() === false;
        });

        result.push(
            { title: t('location.orderedWithinTheLast6Months'), locations: in6mLoc },
            { title: t('location.orderedWithinLastYear'), locations: in12mLoc },
            { title: t('location.orderedWithinLast2Years'), locations: in24mLoc },
            { title: t('location.neverOrdered'), locations: sortBy(noOrdersLoc, 'name') }
        );
        return result;
    };

    const handleCheckbox = () => {
        setWithFiltersChecked(!withFiltersChecked);
    };

    if (props.locationDescriptions != null && props.locationDescriptions.length === 0) {
        return (
            <div style={{ padding: '20px' }}>
                <Alert severity="info">{props.emptyListText}</Alert>
            </div>
        );
    }

    const sortedAndFiltered = getFilteredAndSortedLocations();

    return (
        <Grid item xs={12}>
            <Checkbox checked={withFiltersChecked} onChange={handleCheckbox} name="with-reminder" color="primary" />
            {t('location.showLocationsWithRemindersSet')}
            {sortedAndFiltered.map((group, i) => (
                <TimeSortedLocationGroup
                    group={group}
                    openByDefault={false}
                    history={props.history}
                    key={i}
                    useOrderTime={true}
                />
            ))}
        </Grid>
    );
}
