import Ajax from './ajax';
import Logger from '../common/logger';

class EmailSender {
    static async send(template, values, recipient, subject) {
        try {
            const req = {
                templateName: template,
                keyValues: values,
                recipient: recipient,
                subject: subject,
            };
            await Ajax.post('api/emailsender/', req);
        } catch (err) {
            Logger.LogError(`failed to send email: ${err.message}`, err.stack);
            console.log(err);
        }
    }
}

export default EmailSender;
