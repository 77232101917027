import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadCatalogExcel, updateCatalog, getUpdateStatus } from '../modules/productdata';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import ProductUpdateProgress from './productupdateprogress';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        width: '100%',
        marginBottom: '20px',
    },
    paper: {
        padding: '5px',
    },
}));

const ProductMaintenance = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [uploadResults, setUploadResults] = useState(null);
    const [uid, setUid] = useState('');
    const [uploading, setUploading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [updateDone, setUpdateDone] = useState(false);
    const [showDuplicates, setShowDuplicates] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(null);
    const [fileOk, setFileOk] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const { t } = useTranslation();

    const doStatusUpdates = useCallback(() => {
        setTimeout(async () => {
            const status = await getUpdateStatus(uid)(dispatch);
            setUpdateStatus(status);
            if (status.updateFinished) {
                setUpdating(false);
                if (status.updateSuccess) {
                    setUpdateDone(true);
                    toast.info(t('product.productsUpdated'), { autoClose: 1500, hideProgressBar: true });
                } else {
                    toast.error(t('product.productsUpdateFailed'), { autoClose: 5000 });
                }
            } else {
                doStatusUpdates();
            }
        }, 1000);
    }, [dispatch, uid, t]);

    useEffect(() => {
        const checkUpdateStatus = async () => {
            const status = await getUpdateStatus('')(dispatch);
            if (status.updateOngoing) {
                setUpdating(true);
                doStatusUpdates();
            }
        };

        if (props.active !== isActive) {
            setUploadResults(null);
            setUpdating(false);
            setUploading(false);
            setUid('');
            setUpdateDone(false);
            setIsActive(true);
            checkUpdateStatus();
        }
    }, [props.active, dispatch, doStatusUpdates, isActive]);

    const doUpdate = () => {
        setUpdating(true);
        doStatusUpdates();
        toast.info(t('product.updatingProducts'), { autoClose: 1500, hideProgressBar: true });
        dispatch(updateCatalog(uid));
    };

    const cancel = () => {
        setUploadResults(null);
    };

    const fileUpload = async (evt) => {
        const fileReader = new FileReader();
        toast.info(t('product.loadingFile'), { autoClose: 1500, hideProgressBar: true });
        setUploading(true);
        fileReader.readAsDataURL(evt.target.files[0]);
        fileReader.onload = async (e) => {
            try {
                const resp = await uploadCatalogExcel(fileReader.result)(dispatch);
                setUploadResults(resp.result);
                setUid(resp.uid);
                setFileOk(true);
                setUploading(false);
                toast.info(t('product.fileLoaded'), { autoClose: 1500, hideProgressBar: true });
            } catch (err) {
                setUploading(false);
                setUploadResults(null);
                setFileOk(false);
                toast.error(t('product.fileLoadFailed'), { autoClose: 5000 });
            }
        };
    };

    return (
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <br />
                <h4>Tuotevalikoiman päivitys</h4>
                <br />
                {updateDone && <Grid item>Päivitys suoritettu.</Grid>}

                {updating && updateStatus && (
                    <div>
                        {t('product.updatingProducts')}
                        <ProductUpdateProgress
                            updated={updateStatus.updatedAmount}
                            total={updateStatus.totalToUpdate}
                        />
                    </div>
                )}

                {fileOk && !updateDone && !updating && uploadResults && (
                    <Grid item container>
                        <Grid item xs={12}>
                            <strong>{t('product.fileLoaded')}</strong>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            Tuoterivejä yhteensä
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {uploadResults.totalProductCount}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {t('product.changes')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {uploadResults.changedProducts.length}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {t('product.multipleProductsWithSameName')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12}>
                                {uploadResults.duplicates.length}
                                {uploadResults.duplicates.length && !showDuplicates && (
                                    <Button onClick={() => setShowDuplicates(true)}>{t('buttons.show')}</Button>
                                )}
                                {uploadResults.duplicates.length && showDuplicates && (
                                    <Button onClick={() => setShowDuplicates(false)}>{t('buttons.hide')}</Button>
                                )}
                            </Grid>
                            {uploadResults.duplicates.length && (
                                <Grid item xs={12}>
                                    {showDuplicates && (
                                        <Grid item xs={12}>
                                            {uploadResults.duplicates.map((item) => (
                                                <Grid item>{item}</Grid>
                                            ))}
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Paper>

            <Footer>
                {fileOk && !updateDone && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            name="tallenna"
                            onClick={doUpdate}
                            disabled={updating || !uploadResults || uploadResults.changedProducts.length === 0}>
                            {updating && <i className="fas fa-spinner fa-spin"></i>}
                            {!updating && <i className="fas fa-save"></i>}
                            &nbsp;{t('buttons.update')}
                        </Button>
                    </Grid>
                )}

                {fileOk && !updateDone && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            name="peruuta"
                            onClick={cancel}
                            disabled={updating}>
                            {t('buttons.cancel')}
                        </Button>
                    </Grid>
                )}

                {!uploadResults && (
                    <div>
                        <label htmlFor="upload-excel">
                            <input
                                style={{ display: 'none' }}
                                id="upload-excel"
                                name="upload-excel"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={fileUpload}
                                type="file"
                            />

                            <Button
                                color="primary"
                                disabled={uploading || updating}
                                component="span"
                                aria-label="add"
                                variant="contained"
                                startIcon={<AddIcon />}
                                endIcon={uploading ? <CircularProgress color={'secondary'} size={'1rem'} /> : ''}>
                                {t('product.loadXlsx')}
                            </Button>
                        </label>
                    </div>
                )}
            </Footer>
            <FooterPadding />
        </Grid>
    );
};

export default ProductMaintenance;
