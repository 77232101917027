import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';
import Party from '../domain/party';
export const PARTYDATA_LOADED = 'parties/loaded';
export const PARTYDATA_LOADING = 'parties/loading';
export const SAVING_NEW_PARTY = 'parties/saving';
export const NEW_PARTY_SAVED = 'parties/saved';
const _ = require('lodash');

const initialState = {
    parties: null,
    loading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PARTYDATA_LOADED:
            let parties = [];
            for (const p of action.parties) {
                parties.push(new Party(p));
            }
            return {
                ...state,
                parties: parties,
                loading: false,
            };
        case PARTYDATA_LOADING:
            return {
                ...state,
                loading: true,
            };
        case SAVING_NEW_PARTY:
            return state;
        case NEW_PARTY_SAVED:
            let newParties = _.cloneDeep(state.parties);
            newParties.push(new Party(action.party));
            return {
                ...state,
                parties: newParties,
            };
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const getParties = () => {
    return async (dispatch) => {
        dispatch({ type: PARTYDATA_LOADING });
        try {
            const resp = await Ajax.get('api/party/');
            dispatch({
                type: PARTYDATA_LOADED,
                parties: resp.data,
            });
        } catch (err) {
            console.log(err);
        }
    };
};

export const saveParty = (party) => {
    return async (dispatch) => {
        dispatch({ type: SAVING_NEW_PARTY });
        try {
            const resp = await Ajax.post('api/party', party);
            dispatch({
                type: NEW_PARTY_SAVED,
                party: resp.data,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};
