import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { ParameterGroupView } from './parametergroupview';
import { useTranslation } from 'react-i18next';

export const ParameterManagement = (props) => {
    const parameters = useSelector((state) => state.parameterdata.parameters);
    const { t } = useTranslation();

    const keys = !!parameters ? Object.keys(parameters) : [];

    return (
        <Grid item xs={12}>
            <br />
            <h4>{t('admin.parameters')}</h4>
            <br />

            {keys.map((k) => (
                <ParameterGroupView key={k} category={k} parameters={parameters[k]} />
            ))}
        </Grid>
    );
};
