import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    textfield: {
        width: '100%',
    },
}));

export default function TextInputDialog(props) {
    const open = props.open;
    const confirm = props.confirm;
    const cancel = props.cancel;
    const textFieldLabel = props.textFieldLabel;
    const [text, setText] = React.useState('');
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    useEffect(() => {
        setText(open && props.text ? props.text : '');
    }, [open, props.text]);

    const onChange = (evt) => {
        setText(evt.target.value);
    };

    if (open)
        return (
            <Dialog
                onClose={cancel}
                aria-labelledby="simple-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                fullScreen={fullScreen}>
                <DialogContent dividers>
                    <TextField
                        label={textFieldLabel}
                        className={classes.textfield}
                        name="textinputdialoginput"
                        value={text}
                        onChange={onChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={text.length < 3}
                        color="primary"
                        name="btn-modal-confirm"
                        onClick={() => confirm(text)}>
                        {t('buttons.save')}
                    </Button>
                    &nbsp;&nbsp;
                    <Button variant="contained" color="default" name="btn-modal-cancel" onClick={cancel}>
                        {t('buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    return null;
}
