import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../modules/authentication';

class Logout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (this.props.authenticated) {
            this.props.doLogoff();
        }
    }

    render() {
        return <div>Logging out...</div>;
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.authentication.authenticated,
    logoffInProgress: state.authentication.logoffInProgress,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...appActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
