import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    ok: {
        color: 'green',
    },
    warning: {
        color: 'red',
    },
}));

export default function UserStatusBoolean(props) {
    const classes = useStyles();
    const status = props.status;

    return (
        <React.Fragment>
            {status.enabled && <span className={classes.ok}>Aktiivinen</span>}
            {!status.enabled && <span className={classes.warning}>Disabloitu</span>}
            &nbsp;
            {status.amazonStatus && <span>({status.amazonStatus})</span>}
        </React.Fragment>
    );
}
