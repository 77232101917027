import React from 'react';

const languages = [
    {
        name: 'languages.finnish',
        lang: 'fi',
        flagImg: (
            <img
                src="https://flagcdn.com/w20/fi.png"
                srcSet="https://flagcdn.com/w40/fi.png 2x"
                width="20"
                alt="Finland"></img>
        ),
    },
    {
        name: 'languages.english',
        lang: 'en',
        flagImg: (
            <img
                src="https://flagcdn.com/w20/gb.png"
                srcSet="https://flagcdn.com/w40/gb.png 2x"
                width="20"
                alt="Gb"></img>
        ),
    },
    {
        name: 'languages.estonian',
        lang: 'et',
        flagImg: (
            <img
                src="https://flagcdn.com/w20/ee.png"
                srcSet="https://flagcdn.com/w40/ee.png 2x"
                width="20"
                alt="Estonia"></img>
        ),
    },
];

export default languages;
