import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { getOpenSurveysForUserPortfolio } from '../modules/surveydata';
//import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SurveyView from './surveyView';

export default function Surveys(props) {
    const dispatch = useDispatch();
    const features = useSelector((state) => state.settingsdata.features);
    const surveysInUse = features != null ? features.surveysInUse : false;
    const [surveys, setSurveys] = useState([]);
    //const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        async function getSurveys() {
            var resp = await getOpenSurveysForUserPortfolio()(dispatch);
            if (resp.length > 0) {
                setSurveys(resp);
            }
        }
        if (surveysInUse) {
            getSurveys();
        }
    }, [surveysInUse, dispatch]);

    if (surveys.length === 0) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    {t('survey.noSurveys')}
                </Grid>
            </Grid>
        );
    }
    return (
        <Grid container>
            {surveys.map((survey) => (
                <Grid item xs={12} key={survey.id}>
                    <SurveyView survey={survey} />
                </Grid>
            ))}
        </Grid>
    );
}
