import React, { useState, useEffect, useCallback } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Button,
    TableBody,
    TableContainer,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { getSurveyPdf, getImageUrl } from '../modules/surveydata';
import { useDispatch } from 'react-redux';
import { flatMapDeep, isObject, uniqBy, map, clone } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    gridMain: {
        marginLeft: '1em',
    },
    table: {
        width: '100%',
        marginBottom: '20px',
    },
    paper: {
        padding: '5px',
    },
}));

export default function SurveyView(props) {
    const [expanded, setExpanded] = useState(false);
    const [imageUrls, setImageUrls] = useState({});
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const onExpand = () => {
        setExpanded(!expanded);
    };

    const onDownloadSurvey = async () => {
        await getSurveyPdf(props.survey.id, `${props.survey.name}.pdf`)(dispatch);
    };

    const fetchAllImageUrls = useCallback(
        async (imageIds) => {
            try {
                let imageUrlsClone = clone(imageUrls);
                for (const imageId of imageIds) {
                    //let imageUrlsClone = clone(imageUrls);
                    if (!imageUrlsClone[imageId]) {
                        const response = await getImageUrl(imageId)(dispatch);
                        if (response) {
                            imageUrlsClone[imageId] = response.printUrl;
                        }
                    }
                }
                setImageUrls(imageUrlsClone);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching image urls:', error);
            }
        },
        [dispatch, imageUrls]
    );

    const getAllSurveyPictures = useCallback((survey) => {
        return flatMapDeep(survey, (value, key) => {
            if (key === 'pictures' && Array.isArray(value)) {
                return value;
            }
            if (isObject(value)) {
                // Recursively for nested objects
                return getAllSurveyPictures(value);
            }
            return [];
        });
    }, []);

    useEffect(() => {
        const getAllSurveyPicturesX = (survey) => {
            return flatMapDeep(survey, (value, key) => {
                if (key === 'pictures' && Array.isArray(value)) {
                    return value;
                }
                if (isObject(value)) {
                    return getAllSurveyPicturesX(value);
                }
                return [];
            });
        };

        if (expanded && loading) {
            const pictures = getAllSurveyPicturesX(props.survey);
            const uniqPicures = uniqBy(map(pictures, 'imageId'));
            fetchAllImageUrls(uniqPicures);
        }
    }, [props.survey, expanded, fetchAllImageUrls, loading]);

    const mapSurveyUsage = (usage) => {
        switch (usage) {
            case 1:
                return t('survey.usageOffice');
            case 2:
                return t('survey.usageProductionIndustrial');
            case 3:
                return t('survey.usageHousing');
            case 4:
                return t('survey.usageWarehouseOrLogistics');
            case 5:
                return t('survey.usageShopping');
            case 6:
                return t('survey.usageSports');
            default:
                return t('survey.undefined');
        }
    };

    const mapClassification = (classification) => {
        switch (classification) {
            case 1:
                return t('survey.classificationOda1');
            case 2:
                return t('survey.classificationOda1');
            case 3:
                return t('survey.classificationOda1');
            default:
                return t('survey.undefined');
        }
    };

    const mapFilterClass = (filterClass) => {
        switch (filterClass) {
            case 1:
                return 'ePM1';
            case 2:
                return 'ePM2,5';
            case 3:
                return 'ePM10';
            case 4:
                return 'Coarse';
            default:
                return t('survey.undefined');
        }
    };

    const mapFilterType = (filterType) => {
        switch (filterType) {
            case 1:
                return t('survey.filterTypeBag');
            case 2:
                return t('survey.filterTypeCartridge');
            case 3:
                return t('survey.filterTypeOther');
            default:
                return t('survey.undefined');
        }
    };

    const mapHeatRecoveryModel = (heatRecoveryModel) => {
        switch (heatRecoveryModel) {
            case 1:
                return t('survey.heatRecoveryRotating');
            default:
                return t('survey.undefined');
        }
    };

    return (
        <Accordion expanded={expanded} onChange={onExpand}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid item>
                    <h3>{props.survey.name}</h3>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container className={classes.gridMain} spacing={2}>
                    <Grid item xs={12}>
                        {props.survey.pictures && props.survey.pictures.length > 0 && (
                            <ImageList>
                                {props.survey.pictures.map((item) => (
                                    <ImageListItem key={item.imageId}>
                                        {loading ? (
                                            <Skeleton variant="rectangular" width={210} height={118} animation="wave" />
                                        ) : (
                                            <img src={`${imageUrls[item.imageId]}`} alt={'img'} />
                                        )}
                                        <ImageListItemBar title={item.comments} />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <h4>{t('survey.propertyDetails')}</h4>
                    </Grid>
                    <Grid item xs={12}>
                        {props.survey.comment}
                    </Grid>
                    <Grid item xs={12}>
                        <h5>{t('general.address')}</h5>
                        {props.survey.address ? (
                            <React.Fragment>
                                {props.survey.address.name}
                                <br />
                                {props.survey.address.streetAddress},&nbsp;
                                {props.survey.address.zipCode} {props.survey.address.city}
                            </React.Fragment>
                        ) : (
                            <span>{t('survey.noAddressInformation')}</span>
                        )}
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        {t('survey.usage')}: {mapSurveyUsage(props.survey.usage)}
                        <br />
                        {t('survey.classification')}: {mapClassification(props.survey.classification)}
                    </Grid>
                    {props.survey.machineRooms && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <h4>{t('survey.machineRooms', { pcs: props.survey.machineRooms.length })}</h4>
                            </Grid>

                            {props.survey.machineRooms.map((machineRoom, index) => (
                                <React.Fragment key={index + machineRoom.name}>
                                    <Grid item xs={12}>
                                        <h5>{machineRoom.name}</h5>
                                    </Grid>
                                    {machineRoom.pictures && machineRoom.pictures.length > 0 && (
                                        <ImageList>
                                            {machineRoom.pictures.map((item) => (
                                                <ImageListItem key={item.imageId}>
                                                    {loading ? (
                                                        <Skeleton
                                                            variant="rectangular"
                                                            width={210}
                                                            height={118}
                                                            animation="wave"
                                                        />
                                                    ) : (
                                                        <img src={`${imageUrls[item.imageId]}`} alt={'img'} />
                                                    )}
                                                    <ImageListItemBar title={item.comments} />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    )}
                                    {machineRoom.machines.length < 1 && (
                                        <Grid item xs={12}>
                                            {t('survey.noMachines')}
                                        </Grid>
                                    )}
                                    {machineRoom.machines.map((machine, index) => (
                                        <Grid item xs={12} key={index + machine.name}>
                                            <h5>{machine.name}</h5>
                                            {t('location.airVolume')}: {machine.airVolume} m2
                                            <br />
                                            {t('survey.filterClass')}: {mapFilterClass(machine.filterClass)} <br />
                                            {t('survey.filterEfficiency')}: {machine.filterEfficiency} % <br />
                                            {t('survey.filterType')}: {mapFilterType(machine.filterType)} <br />
                                            {t('survey.heatRecoveryModel')}:{' '}
                                            {mapHeatRecoveryModel(machine.heatRecoveryModel)} <br />
                                            {t('location.serviceArea')}: {machine.serviceArea} <br />
                                            {machine.filters.length === 0 ? (
                                                <em>{t('survey.containsNoFilters')}</em>
                                            ) : (
                                                <React.Fragment>
                                                    <h5>{t('survey.usedFilterDimensions')}</h5>

                                                    <TableContainer component={Paper}>
                                                        <Table
                                                            className={classes.table}
                                                            size="small"
                                                            aria-label="Filters"
                                                            sx={{ maxWidth: 650 }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>{t('survey.height')}</TableCell>
                                                                    <TableCell>{t('survey.width')}</TableCell>
                                                                    <TableCell>{t('survey.maxDepth')}</TableCell>
                                                                    <TableCell>{t('survey.quantity')}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {machine.filters.map((filter) => (
                                                                    <TableRow>
                                                                        <TableCell>{filter.dimensionHeight}</TableCell>
                                                                        <TableCell>{filter.dimensionWidth}</TableCell>
                                                                        <TableCell>{filter.maxDepth}</TableCell>
                                                                        <TableCell>{filter.count}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </React.Fragment>
                                            )}
                                            {machine.numberOfSeals > 0 && (
                                                <React.Fragment>
                                                    <h5>{t('survey.usedSeals')}</h5>

                                                    <TableContainer component={Paper}>
                                                        <Table
                                                            className={classes.table}
                                                            size="small"
                                                            aria-label="Seals"
                                                            sx={{ maxWidth: 650 }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>{t('survey.sealType')}</TableCell>
                                                                    <TableCell>{t('survey.quantity')}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>{machine.selectedSeal}</TableCell>
                                                                    <TableCell>{machine.numberOfSeals}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </React.Fragment>
                                            )}
                                            {machine.pictures && machine.pictures.length > 0 && (
                                                <ImageList>
                                                    {machine.pictures.map((item) => (
                                                        <ImageListItem key={item.imageId}>
                                                            {loading ? (
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    width={210}
                                                                    height={118}
                                                                    animation="wave"
                                                                />
                                                            ) : (
                                                                <img src={`${imageUrls[item.imageId]}`} alt={'img'} />
                                                            )}
                                                            <ImageListItemBar title={item.comments} />
                                                        </ImageListItem>
                                                    ))}
                                                </ImageList>
                                            )}
                                        </Grid>
                                    ))}
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        name="btn-survey-pdf"
                        onClick={onDownloadSurvey}
                        startIcon={<DownloadIcon />}>
                        PDF
                    </Button>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
