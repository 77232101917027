import User from '../domain/user';
import { LOGGED_OFF } from './authentication';
const _ = require('lodash');

export const GET_USER_DATA_IN_PROGRESS = 'userdata/getuserdatainproc';
export const GET_USER_DATA_SUCCESS = 'userdata/getuserdatainprocsuccess';
export const GET_USER_DATA_FAILED = 'userdata/getuserdatafailed';
export const GET_USER_BY_ID_IN_PROGRESS = 'userdata/getuserbyidinprogress';
export const GET_USER_BY_ID_DONE = 'userdata/getuserbyiddone';
export const GET_USER_BY_ID_FAILED = 'userdata/getuserbyidfailed';
export const SAVING_USER = 'userdata/savinguser';
export const SAVE_USER_DONE = 'userdata/saveuserdone';
export const SAVE_USER_FAILED = 'userdata/saveuserfailed';
export const RESET_PASSWORD_IN_PROGRESS = 'userdata/resetpasswordinprogress';
export const RESET_PASSWORD_DONE = 'userdata/resetpassworddone';
export const RESET_PASSWORD_FAILED = 'userdata/resetpasswordfailed';
export const REMOVE_USER_IN_PROGRESS = 'userdata/removeuserinprogress';
export const REMOVE_USER_DONE = 'userdata/removeuserdone';
export const REMOVE_USER_FAILED = 'userdata/removeuserfailed';

const initialState = {
    getUserDataInProgress: false,
    orgUsers: null,
    contractCustomerUsers: null,
    usersById: {},
    getUserByIdInProgress: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_DATA_FAILED:
            return {
                ...state,
                getUserDataInProgress: false,
            };
        case GET_USER_DATA_IN_PROGRESS:
            return {
                ...state,
                getUserDataInProgress: true,
            };
        case GET_USER_DATA_SUCCESS:
            let newOrgUsers = [];
            let newCcUsers = [];
            let newUsersById = {};
            for (let u of action.orgUsers) {
                newOrgUsers.push(new User(u));
                newUsersById[u.id] = new User(u);
            }
            for (let u of action.ccUsers) {
                newCcUsers.push(new User(u));
                newUsersById[u.id] = new User(u);
            }
            return {
                ...state,
                orgUsers: newOrgUsers,
                usersById: newUsersById,
                contractCustomerUsers: newCcUsers,
                getUserDataInProgress: false,
            };
        case GET_USER_BY_ID_IN_PROGRESS:
            return {
                ...state,
                getUserByIdInProgress: true,
            };
        case GET_USER_BY_ID_DONE:
            let usersById = _.clone(state.usersById);
            usersById[action.user.id] = new User(action.user);
            return {
                ...state,
                usersById: usersById,
                getUserByIdInProgress: false,
            };
        case GET_USER_BY_ID_FAILED:
            return {
                ...state,
                getUserByIdInProgress: false,
            };
        case SAVE_USER_DONE: {
            let newUsersById = _.clone(state.usersById);
            newUsersById[action.user.id] = new User(action.user);
            return {
                ...state,
                usersById: newUsersById,
            };
        }
        case RESET_PASSWORD_DONE: {
            let newUsersById = _.clone(state.usersById);
            newUsersById[action.userId] = null;
            return {
                ...state,
                usersById: newUsersById,
                orgUsers: null,
                contractCustomerUsers: null,
            };
        }
        case REMOVE_USER_DONE: {
            let newUsersById = _.clone(state.usersById);
            newUsersById[action.userId] = null;
            return {
                ...state,
                userById: newUsersById,
                orgUsers: null,
                contractCustomerUsers: null,
            };
        }
        case LOGGED_OFF:
            return initialState;
        case REMOVE_USER_IN_PROGRESS:
        case REMOVE_USER_FAILED:
        case SAVE_USER_FAILED:
        case SAVING_USER:
        case RESET_PASSWORD_IN_PROGRESS:
        case RESET_PASSWORD_FAILED:
        default:
            return state;
    }
};
