import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../common/spinner';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import CustomerViewBasicInfo from '../customer/customerviewbasicinfo';
import { getCustomerFull } from '../modules/customerdata';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export default function SearchResultsCustomerDetails(props) {
    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customerdata.customersById);
    const customerFull = customers[props.customer.id];
    const [expanded, setExpanded] = useState(false);
    const customer = props.customer;

    const onExpand = () => {
        setExpanded(!expanded);
        if (!customerFull) dispatch(getCustomerFull(customer.id));
    };

    const openCustomer = (event) => {
        props.history.push('/customers/' + customer.id);
        event.stopPropagation();
    };

    return (
        <React.Fragment>
            <Accordion expanded={expanded} onChange={onExpand}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                            <OpenInNewIcon onClick={(event) => openCustomer(event)} />
                        </Grid>
                        <Grid item>{customer.name}</Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item>
                        {customerFull && <CustomerViewBasicInfo customer={customerFull} />}
                        {!customerFull && <Spinner />}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
}
