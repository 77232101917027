import React, { useState, useEffect, useRef, useCallback } from 'react';
import Customer from '../domain/customer';
import ElectronicInvoiceInfo from '../domain/electronicinvoiceinfo';
import Address from '../domain/address';
import Spinner from '../common/spinner';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, IconButton, InputAdornment } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import FooterPadding from '../main/footerpadding';
import Footer from '../main/footer';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CustomershipStatus from '../domain/customershipstatus';
import AddressInput from '../common/addressinput';
import EInvoiceInput from '../common/einvoiceinput';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ParameterSelector from '../common/parameterselector';
import PartyView from '../common/partyview';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PartyRelation from '../domain/partyrelation';
import BisLookupContainer from '../common/bislookupcontainer';
import Alert from '@material-ui/lab/Alert';
import DuplicateItemsDialog, { TypeCustomer } from '../common/duplicateitemsview';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import EmailInvoiceAddressInput from '../common/emailinvoiceaddressinput';
import AddIcon from '@material-ui/icons/Add';

let timerHandle = null;
let editActionId = null;

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    btnContainer: {
        marginTop: theme.spacing(1),
    },
    indent: {
        marginLeft: theme.spacing(1),
    },
    accordion: {
        marginBottom: '0.5em',
    },
    search: {
        cursor: 'pointer',
    },
    duplicateAlert: {
        cursor: 'pointer',
    },
    errorInAddressHeader: {
        color: 'red',
    },
}));

export default function CustomerEditView(props) {
    const classes = useStyles();
    const [customerId, setCustomerId] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [saving, setSaving] = useState(false);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [itemToBeDeleted, setItemToBeDeleted] = useState('');
    const [customerLocationCount, setCustomerLocationCount] = useState(0);
    const [formOk, setFormOk] = useState(false);
    const [bisLookupOpen, setBisLookupOpen] = useState(false);
    const [bisLookupSearchTerm, setBisLookupSearchTerm] = useState(null);
    const [bisLookupBusinessIdTerm, setBisLookupBusinessIdTerm] = useState(null);
    const [duplicateViewOpen, setDuplicateViewOpen] = useState(false);
    const parameters = props.parameters;
    const customerRef = useRef(customer);
    customerRef.current = customer;
    const checkDuplicates = props.checkDuplicates;
    const { t } = useTranslation();

    const onCancel = () => {
        props.history.goBack();
    };

    const onSubmit = () => {
        if (!formOk) return; // should not come here, button disabled

        let toBeSavedCustomer = new Customer(customer);
        toBeSavedCustomer.customerNumber = parseInt(toBeSavedCustomer.customerNumber);
        setSaving(true);

        const _props = props;
        props
            .saveCustomer(toBeSavedCustomer)
            .then((resp) => {
                if (customerId === 'new') {
                    _props.history.replace('/customers/edit/' + customer.id);
                }
                setSaving(false);
                setCustomerId(customer.id);
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const onRemove = () => {
        removeCustomer();
    };

    const removeCustomerData = () => {
        let toBeRemovedCustomer = new Customer(customer);
        toast.info(t('toast.removing'), { autoClose: 1500, hideProgressBar: true });

        props
            .removeCustomer(toBeRemovedCustomer)
            .then(() => {
                toast.info(t('toast.customerRemoved'), { autoClose: 2000, hideProgressBar: true });
                props.history.go(-2);
            })
            .catch(() => {
                toast.error(t('toast.removingCustomerFailed'), { autoClose: 5000 });
            });
    };

    const handleChange = (event) => {
        var newCustomer = new Customer(customer);
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let checkForDuplicate = false;
        switch (name) {
            case 'customerName':
                newCustomer.name = value;
                checkForDuplicate = true;
                break;
            case 'customershipStatus':
                newCustomer.status = value;
                break;
            case 'customerBusinessId':
                newCustomer.businessId = value;
                checkForDuplicate = true;
                break;
            case 'contactName':
                newCustomer.contactName = value;
                break;
            case 'contactTel':
                newCustomer.contactTel = value;
                break;
            case 'visible-to-all':
                newCustomer.visibleToAll = event.target.checked;
                break;
            case 'packingInstructions':
                newCustomer.packingInstructions = value;
                break;
            case 'reference':
                newCustomer.reference = value;
                break;
            case 'vatId':
                newCustomer.vatId = value;
                break;
            case 'emailInvoiceAddress':
                newCustomer.emailInvoiceAddress = value;
                break;
            default:
                break;
        }
        setCustomer(newCustomer);
        if (checkForDuplicate) {
            triggerDuplicateCheck();
        }
        checkIfFormOk(newCustomer);
    };

    const checkIfFormOk = useCallback(
        (newCustomer) => {
            let ok = true;
            if (newCustomer.name == null || newCustomer.name.length === 0) ok = false;
            if (newCustomer.deliveryAddress && !newCustomer.deliveryAddress.isValid()) ok = false;
            if (newCustomer.billingAddress && !newCustomer.billingAddress.isValid()) ok = false;
            if (
                newCustomer.status !== CustomershipStatus.Active() &&
                newCustomer.status !== CustomershipStatus.Potential()
            )
                ok = false;

            if (ok !== formOk) {
                setFormOk(ok);
            }
        },
        [formOk]
    );

    const triggerDuplicateCheck = useCallback(() => {
        clearTimeout(timerHandle);
        timerHandle = setTimeout(() => {
            checkDuplicates(
                editActionId,
                customerRef.current.id,
                customerRef.current.name,
                customerRef.current.businessId
            );
        }, 500);
    }, [checkDuplicates]);

    useEffect(() => {
        if (props.newCustomer && customer === null) {
            Customer.NewObject(
                props.userProfile.portfolioDescriptions.filter((p) => p.id === props.userProfile.selectedPortfolioId)[0]
            )
                .then(function (customer) {
                    setCustomer(customer);
                    setCustomerId('new');
                })
                .catch(function (err) {
                    console.log(err);
                });
        } else if (props.customer && (customer === null || props.customer.storageId !== customer.storageId)) {
            let c = new Customer(props.customer);
            setCustomer(c);
            setCustomerId(props.customer.id);
            setCustomerLocationCount(props.customer.customerLocations.length);
            checkIfFormOk(c);
            customerRef.current = c;
            triggerDuplicateCheck();
        }
    }, [
        props.customer,
        checkIfFormOk,
        customer,
        props.newCustomer,
        props.userProfile.selectedPortfolioId,
        props.userProfile.portfolioDescriptions,
        triggerDuplicateCheck,
    ]);

    useEffect(() => {
        editActionId = Math.floor(Math.random() * 1000000);
    }, []);

    const addElectronicInvoiceAddress = () => {
        ElectronicInvoiceInfo.NewObject()
            .then(function (info) {
                let c = new Customer(customer);
                c.electronicInvoiceAddress = info;
                setCustomer(c);
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    const addDeliveryAddress = () => {
        Address.NewObject()
            .then(function (address) {
                let c = new Customer(customer);
                c.deliveryAddress = address;
                setCustomer(c);
                checkIfFormOk(c);
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    const addBillingAddress = () => {
        Address.NewObject()
            .then(function (address) {
                let c = new Customer(customer);
                c.billingAddress = address;
                setCustomer(c);
                checkIfFormOk(c);
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    const addEmailInvoiceAddress = () => {
        let c = new Customer(customer);
        c.emailInvoiceAddress = '';
        setCustomer(c);
    };

    const confirmDelete = () => {
        let c = new Customer(customer);
        switch (itemToBeDeleted) {
            case t('customer.removeEinvAddress'):
                c.electronicInvoiceAddress = null;
                break;
            case t('customer.removeDeliveryAddress'):
                c.deliveryAddress = null;
                break;
            case t('customer.removeInvoicingAddress'):
                c.billingAddress = null;
                break;
            case t('customer.removeCustomerData'):
                removeCustomerData();
                break;
            case t('invoice.emailInvoiceAddress'):
                c.emailInvoiceAddress = null;
                break;
            default:
                break;
        }
        setCustomer(c);
        checkIfFormOk(c);
        setConfirmDeleteModalOpen(false);
        setItemToBeDeleted('');
    };

    const cancelDelete = () => {
        setConfirmDeleteModalOpen(false);
        setItemToBeDeleted('');
    };

    const removeElectronicInvoiceAddress = () => {
        setItemToBeDeleted(t('customer.removeEinvAddress'));
        setConfirmDeleteModalOpen(true);
    };

    const removeEmailInvoiceAddress = () => {
        setItemToBeDeleted(t('invoice.emailInvoiceAddress'));
        setConfirmDeleteModalOpen(true);
    };

    const removeDeliveryAddress = () => {
        setItemToBeDeleted(t('customer.removeDeliveryAddress'));
        setConfirmDeleteModalOpen(true);
    };

    const removeBillingAddress = () => {
        setItemToBeDeleted(t('customer.removeInvoicingAddress'));
        setConfirmDeleteModalOpen(true);
    };

    const removeCustomer = () => {
        setItemToBeDeleted(t('customer.removeCustomerData'));
        setConfirmDeleteModalOpen(true);
    };

    const ConfirmationDialog = () => {
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
        if (confirmDeleteModalOpen)
            return (
                <Dialog
                    onClose={cancelDelete}
                    aria-labelledby="simple-dialog-title"
                    open={confirmDeleteModalOpen}
                    fullWidth={true}
                    maxWidth={'md'}
                    fullScreen={fullScreen}>
                    <DialogContent dividers>
                        {t('general.confirmRemove')} <strong>{itemToBeDeleted}</strong>?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            name="btn-modal-poista"
                            id="modal-poista"
                            onClick={confirmDelete}>
                            {t('buttons.remove')}
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            variant="contained"
                            color="default"
                            name="btn-modal-peruuta"
                            id="modal-peruuta"
                            onClick={cancelDelete}>
                            {t('buttons.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        return null;
    };

    const useCareOfInBillingAddressChange = (event) => {
        let c = new Customer(customer);
        c.billingNameNotationSetting = parseInt(event.target.value);
        setCustomer(c);
    };

    const getBillingName = () => {
        if (customer.billingNameNotationSetting === Customer.BillingNameNotationDefault()) {
            return 'As Oy Esimerkki c/o ' + customer.name;
        } else if (customer.billingNameNotationSetting === Customer.BillingNameNotationUseCustomerName()) {
            return customer.name;
        } else if (customer.billingNameNotationSetting === Customer.BillingNameNotationUseCustomerAndLocationName()) {
            return customer.name + ' As Oy Esimerkki';
        } else {
            throw new Error('error');
        }
    };

    const deliveryAddressChanged = (address) => {
        let c = new Customer(customer);
        c.deliveryAddress = address;
        setCustomer(c);
        checkIfFormOk(c);
    };

    const billingAddressChanged = (address) => {
        console.log('chan');
        let c = new Customer(customer);
        c.billingAddress = address;
        setCustomer(c);
        checkIfFormOk(c);
    };

    const einvoiceinfoChanged = (einvoiceinfo) => {
        let c = new Customer(customer);
        c.electronicInvoiceAddress = einvoiceinfo;
        setCustomer(c);
    };

    const GetHeader = () => {
        if (customer == null) return null;
        return (
            <h2>
                <Button onClick={onCancel}>
                    <i className="fas fa-chevron-left fa-2x"></i>&nbsp;&nbsp;
                </Button>
                {customer.name}
            </h2>
        );
    };

    const isCustomerShipStatusOk = () => {
        return customer.status === CustomershipStatus.Potential() || customer.status === CustomershipStatus.Active();
    };

    const parameterSelectorChange = (name, value) => {
        const nameOfProp = name[0].toLowerCase() + name.slice(1);
        const c = new Customer(customer);
        c[nameOfProp] = parameters[name].find((item) => item.id === value);
        setCustomer(c);
    };

    const newPartyRelation = (party, relation) => {
        const c = new Customer(customer);
        c.partyRelations.push(
            new PartyRelation({
                relatedParty: party,
                relation: relation,
            })
        );
        setCustomer(c);
    };

    const removeParty = (id, relation) => {
        const c = new Customer(customer);
        const newRelations = [];
        for (let p of c.partyRelations) {
            if (p.relatedParty.id !== id || p.relation !== relation) {
                newRelations.push(p);
            }
        }
        c.partyRelations = newRelations;
        setCustomer(c);
    };

    const bisLookupResultCancel = () => {
        setBisLookupOpen(false);
    };

    const bisLookupResultSave = (name, businessId) => {
        setBisLookupOpen(false);
        var newCustomer = new Customer(customer);
        if (name !== null) newCustomer.name = name;
        if (businessId !== null) newCustomer.businessId = businessId;
        setCustomer(newCustomer);
        checkIfFormOk(newCustomer);
        triggerDuplicateCheck();
    };

    const bisLookupByNameHandle = (term) => {
        setBisLookupOpen(true);
        setBisLookupSearchTerm(term);
        setBisLookupBusinessIdTerm(null);
    };

    const bisLookupByBusinessIdHandle = (businessId) => {
        setBisLookupOpen(true);
        setBisLookupSearchTerm(null);
        setBisLookupBusinessIdTerm(businessId);
    };

    const handleDuplicateViewClick = () => {
        setDuplicateViewOpen(!duplicateViewOpen);
    };

    const anyPotentialDuplicates = (data) => {
        return (
            (data && data.duplicatesByName && data.duplicatesByName.length) ||
            (data && data.duplicatesByBusinessId && data.duplicatesByBusinessId.length)
        );
    };

    if (customer === null) {
        return <Spinner padding="20px" title={t('general.loading')} />;
    }
    const customershipPotential = CustomershipStatus.Potential();
    const customershipActive = CustomershipStatus.Active();
    const hasDuplicates =
        props.duplicates[editActionId] &&
        props.duplicates[editActionId].customerDuplicates &&
        props.duplicates[editActionId].customerDuplicates.duplicatesByName &&
        props.duplicates[editActionId].customerDuplicates.duplicatesByName.length > 0;
    const hasDuplicateWarnings =
        props.duplicates[editActionId] &&
        (anyPotentialDuplicates(props.duplicates[editActionId].customerDuplicates) ||
            anyPotentialDuplicates(props.duplicates[editActionId].locationDuplicates));

    return (
        <fieldset disabled={saving}>
            <Grid container>
                <Grid item xs={12}>
                    <GetHeader />
                </Grid>
                {hasDuplicates ? (
                    <Grid item xs={12}>
                        <Alert severity="error" onClick={handleDuplicateViewClick} className={classes.duplicateAlert}>
                            {t('customer.cannotBeSavedDueToDuplicate')}
                        </Alert>
                    </Grid>
                ) : hasDuplicateWarnings ? (
                    <Grid item xs={12}>
                        <Alert severity="warning" onClick={handleDuplicateViewClick} className={classes.duplicateAlert}>
                            {t('customer.warningDuplicates')}
                        </Alert>
                    </Grid>
                ) : null}

                <Grid item xs={12} md={6}>
                    <span className={classes.indent}>
                        {t('general.portfolio')}: {customer.portfolio.name}
                    </span>
                    <br />
                    <span className={classes.indent}>
                        {t('general.customerNumber')}: {customer.customerNumber}
                    </span>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Tooltip title={t('customer.visibleToAllHint')}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={customer.visibleToAll}
                                    onChange={handleChange}
                                    name="visible-to-all"
                                    color="primary"
                                />
                            }
                            label={t('customer.visibleToAll')}
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        error={customer.name == null || customer.name.length === 0}
                        id="customerName"
                        name="customerName"
                        label={t('customer.customerName')}
                        value={customer.name}
                        className={classes.textField}
                        margin="normal"
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => bisLookupByNameHandle(customer.name ? customer.name : '')}
                                        className={classes.search}
                                        name="bis-search-by-name">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl className={classes.select}>
                        <InputLabel id="customership-status-label">{t('customer.customerShipStatus')}</InputLabel>
                        <Select
                            required
                            error={!isCustomerShipStatusOk()}
                            labelId="customership-status-label"
                            id="customershipStatus"
                            name="customershipStatus"
                            value={customer.status}
                            onChange={handleChange}>
                            <MenuItem value={customershipPotential}>{t('customer.statusPotential')}</MenuItem>
                            <MenuItem value={customershipActive}>{t('customer.statusActive')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="customerBusinessId"
                        name="customerBusinessId"
                        label={t('general.businessId')}
                        value={customer.businessId ? customer.businessId : ''}
                        className={classes.textField}
                        margin="normal"
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            bisLookupByBusinessIdHandle(customer.businessId ? customer.businessId : '')
                                        }
                                        className={classes.search}
                                        name="bis-search-by-business-id">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="vatId"
                        name="vatId"
                        label={t('general.vatId')}
                        value={customer.vatId ? customer.vatId : ''}
                        className={classes.textField}
                        margin="normal"
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        id="packingInstructions"
                        name="packingInstructions"
                        label={t('general.packingInstructions')}
                        value={customer.packingInstructions ? customer.packingInstructions : ''}
                        className={classes.textField}
                        margin="normal"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="reference"
                        name="reference"
                        label={t('general.yourReference')}
                        value={customer.reference ? customer.reference : ''}
                        className={classes.textField}
                        margin="normal"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ParameterSelector
                        values={parameters}
                        category={'OurReference'}
                        selected={customer.ourReference}
                        handleChange={parameterSelectorChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ParameterSelector
                        values={parameters}
                        category={'ContractNumber'}
                        selected={customer.contractNumber}
                        handleChange={parameterSelectorChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ParameterSelector
                        values={parameters}
                        category={'InvoicingTerms'}
                        selected={customer.invoicingTerms}
                        handleChange={parameterSelectorChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ParameterSelector
                        values={parameters}
                        category={'DeliveryTerms'}
                        selected={customer.deliveryTerms}
                        handleChange={parameterSelectorChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ParameterSelector
                        values={parameters}
                        category={'DeliveryMethod'}
                        selected={customer.deliveryMethod}
                        handleChange={parameterSelectorChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <h4>{t('general.contactInformation')}</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item container>
                                <Grid item xs={12}>
                                    <PartyView
                                        showControls={true}
                                        parties={customer.partyRelations}
                                        saveNewPartyRelation={newPartyRelation}
                                        removeParty={removeParty}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="contactName"
                                        name="contactName"
                                        label={t('general.contactPerson')}
                                        value={customer.contactName ? customer.contactName : ''}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="contactTel"
                                        name="contactTel"
                                        label={t('general.contactPersonTel')}
                                        value={customer.contactTel ? customer.contactTel : ''}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {customer.emailInvoiceAddress != null && (
                    <Grid item xs={12}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4>
                                    {t('invoice.emailInvoiceAddress')}{' '}
                                    <Button onClick={removeEmailInvoiceAddress}>
                                        <i className="fas fa-trash-alt fa-lg" style={{ color: 'red' }}></i>
                                    </Button>
                                </h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <EmailInvoiceAddressInput
                                    email={customer.emailInvoiceAddress}
                                    onChange={handleChange}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {customer.electronicInvoiceAddress && (
                    <Grid item xs={12}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4>
                                    {t('general.eInvoiceAddress')}{' '}
                                    <Button onClick={removeElectronicInvoiceAddress}>
                                        <i className="fas fa-trash-alt fa-lg" style={{ color: 'red' }}></i>
                                    </Button>
                                </h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <EInvoiceInput
                                    einvoiceinfo={customer.electronicInvoiceAddress}
                                    einvoiceinfoChanged={einvoiceinfoChanged}
                                    idBase={'einvoice-'}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {customer.deliveryAddress && (
                    <Grid item xs={12}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4>
                                    {!customer.deliveryAddress.isValid() ? (
                                        <span className={classes.errorInAddressHeader}>
                                            {t('general.deliveryAddress')}
                                        </span>
                                    ) : (
                                        t('general.deliveryAddress')
                                    )}

                                    <Button onClick={removeDeliveryAddress}>
                                        <i className="fas fa-trash-alt fa-lg" style={{ color: 'red' }}></i>
                                    </Button>
                                </h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item xs={12}>
                                    <AddressInput
                                        address={customer.deliveryAddress}
                                        addressChanged={deliveryAddressChanged}
                                        idBase={'deliveryAddress-'}
                                    />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {customer.billingAddress && (
                    <Grid item xs={12}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4>
                                    {!customer.billingAddress.isValid() ? (
                                        <span className={classes.errorInAddressHeader}>
                                            {t('general.invoicingAddress')}
                                        </span>
                                    ) : (
                                        t('general.invoicingAddress')
                                    )}

                                    <Button onClick={removeBillingAddress}>
                                        <i className="fas fa-trash-alt fa-lg" style={{ color: 'red' }}></i>
                                    </Button>
                                </h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item xs={12}>
                                    <AddressInput
                                        address={customer.billingAddress}
                                        addressChanged={billingAddressChanged}
                                        idBase={'billingAddress-'}
                                    />
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                            {t('customer.invoicingNameFormulation.title')}
                                        </FormLabel>
                                        <RadioGroup
                                            aria-label="use-care-of"
                                            name="use-care-of"
                                            value={customer.billingNameNotationSetting}
                                            onChange={useCareOfInBillingAddressChange}>
                                            <FormControlLabel
                                                value={Customer.BillingNameNotationDefault()}
                                                control={<Radio />}
                                                label={t('customer.invoicingNameFormulation.defaultRuleCO')}
                                            />
                                            <FormControlLabel
                                                value={Customer.BillingNameNotationUseCustomerName()}
                                                control={<Radio />}
                                                label={t('customer.invoicingNameFormulation.ruleCustomerName')}
                                            />
                                            <FormControlLabel
                                                value={Customer.BillingNameNotationUseCustomerAndLocationName()}
                                                control={<Radio />}
                                                label={t(
                                                    'customer.invoicingNameFormulation.ruleCustomerAndLocationName'
                                                )}
                                            />
                                        </RadioGroup>
                                        {t('customer.invoicingName')}: <em>{getBillingName()}</em>
                                    </FormControl>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                <Grid container spacing={2} className={classes.btnContainer}>
                    {!customer.electronicInvoiceAddress && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                id="add-einv-address"
                                name="add-einv-address"
                                startIcon={<AddIcon />}
                                onClick={addElectronicInvoiceAddress}>
                                {t('general.eInvoiceAddress')}
                            </Button>
                        </Grid>
                    )}
                    {!customer.deliveryAddress && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                id="add-delivery-address"
                                name="add-delivery-address"
                                startIcon={<AddIcon />}
                                onClick={addDeliveryAddress}>
                                {t('general.deliveryAddress')}
                            </Button>
                        </Grid>
                    )}
                    {!customer.billingAddress && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                id="add-billing-address"
                                name="add-billing-address"
                                startIcon={<AddIcon />}
                                onClick={addBillingAddress}>
                                {t('general.invoicingAddress')}
                            </Button>
                        </Grid>
                    )}
                    {(!customer.emailInvoiceAddress || customer.emailInvoiceAddress.length === 0) && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                id="add-email-invoice-address"
                                name="add-email-invoice-address"
                                startIcon={<AddIcon />}
                                onClick={addEmailInvoiceAddress}>
                                {t('invoice.emailInvoiceAddress')}
                            </Button>
                        </Grid>
                    )}
                </Grid>

                <Footer>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            name="delete-customer"
                            onClick={onRemove}
                            startIcon={<DeleteIcon />}
                            disabled={customerId === 'new' || customerLocationCount > 0 || customer.useCount > 0}>
                            {t('buttons.remove')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            name="tallenna"
                            onClick={onSubmit}
                            disabled={saving || !formOk || hasDuplicates}
                            startIcon={saving ? <CircularProgress color={'secondary'} size={'1rem'} /> : <SaveIcon />}>
                            {t('buttons.save')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            name="close"
                            onClick={onCancel}
                            disabled={saving}
                            startIcon={<CloseIcon />}>
                            {t('buttons.close')}
                        </Button>
                    </Grid>
                </Footer>
                <FooterPadding />

                <ConfirmationDialog />
                <BisLookupContainer
                    open={bisLookupOpen}
                    searchTerm={bisLookupSearchTerm}
                    searchBusinessId={bisLookupBusinessIdTerm}
                    cancel={bisLookupResultCancel}
                    save={bisLookupResultSave}
                />
                <DuplicateItemsDialog
                    open={duplicateViewOpen}
                    cancel={handleDuplicateViewClick}
                    items={props.duplicates[editActionId]}
                    type={TypeCustomer}
                    history={props.history}
                />
            </Grid>
        </fieldset>
    );
}
