import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextEditBlock from '../invoice/texteditblock';
import { useTranslation } from 'react-i18next';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
const _ = require('lodash');

export default function EInvoiceInput(props) {
    const einvoiceinfo = props.einvoiceinfo;
    const idBase = props.idBase;
    const fieldConfig = props.fieldConfig ? props.fieldConfig : {};
    const allowedEInvOperators = props.allowedEInvOperators;
    const { t } = useTranslation();

    const fixFieldConfigIfNeeded = (config) => {
        if (!config) {
            return {
                showCounter: false,
                maxLength: undefined,
            };
        }
        return config;
    };

    const handleChangeBrokerId = (_tmp, elem) => {
        let newEinv = _.clone(einvoiceinfo);
        newEinv.operatorBrokerId = elem.brokerId;
        newEinv.operator = elem.operator;
        props.einvoiceinfoChanged(newEinv);
    };

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let newEinv = _.clone(einvoiceinfo);

        switch (name) {
            case 'ovt':
                newEinv.ovt = value;
                break;
            case 'operator':
                newEinv.operator = value;
                break;
            case 'operatorBrokerId':
                newEinv.operatorBrokerId = value;
                break;
            default:
                break;
        }
        props.einvoiceinfoChanged(newEinv);
    };

    fieldConfig['ovt'] = fixFieldConfigIfNeeded(fieldConfig['ovt']);
    fieldConfig['operator'] = fixFieldConfigIfNeeded(fieldConfig['operator']);
    fieldConfig['operatorBrokerId'] = fixFieldConfigIfNeeded(fieldConfig['operatorBrokerId']);

    return (
        <Grid container>
            <TextEditBlock
                id={idBase + 'ovt'}
                name="ovt"
                label={t('einvoice.ovt')}
                value={einvoiceinfo.ovt ? einvoiceinfo.ovt : ''}
                onChange={handleChange}
                required={true}
                number={false}
                showCounter={fieldConfig['ovt'].showCounter}
                maxLength={fieldConfig['ovt'].maxLength}
            />

            {!fieldConfig['operator'].hide && (
                <TextEditBlock
                    id={idBase + 'operator'}
                    name="operator"
                    label={t('einvoice.operator')}
                    value={einvoiceinfo.operator ? einvoiceinfo.operator : ''}
                    required={true}
                    number={false}
                    onChange={handleChange}
                    showCounter={fieldConfig['operator'].showCounter}
                    maxLength={fieldConfig['operator'].maxLength}
                />
            )}

            {allowedEInvOperators && allowedEInvOperators.length > 0 && (
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        options={allowedEInvOperators ? allowedEInvOperators : []}
                        getOptionLabel={(option) => (option ? `${option.brokerId}` : '')}
                        renderOption={(option) => (
                            <span>
                                {option.operator} {option.brokerId}
                            </span>
                        )}
                        filterOptions={createFilterOptions({
                            stringify: (option) => option.operator + option.brokerId,
                        })}
                        id={idBase + 'operatorBrokerId'}
                        name="operatorBrokerId"
                        loading={!allowedEInvOperators}
                        loadingText={t('general.loading')}
                        onChange={handleChangeBrokerId}
                        value={
                            einvoiceinfo.operatorBrokerId
                                ? { brokerId: einvoiceinfo.operatorBrokerId, operator: einvoiceinfo.operator }
                                : { brokerId: '' }
                        }
                        getOptionSelected={(option, value) => {
                            return option.brokerId === value.brokerId;
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                label={t('einvoice.operatorBrokerId')}
                                margin={'normal'}
                                name="operatorBrokerId"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                }}
                                error={!einvoiceinfo.operatorBrokerId || einvoiceinfo.operatorBrokerId.length < 1}
                                className={''}
                                onBlur={(e) => {}}
                            />
                        )}
                    />
                </Grid>
            )}
            {!allowedEInvOperators && (
                <TextEditBlock
                    id={idBase + 'operatorBrokerId'}
                    name="operatorBrokerId"
                    label={t('einvoice.operatorBrokerId')}
                    value={einvoiceinfo.operatorBrokerId ? einvoiceinfo.operatorBrokerId : ''}
                    required={true}
                    number={false}
                    onChange={handleChange}
                    showCounter={fieldConfig['operatorBrokerId'].showCounter}
                    maxLength={fieldConfig['operatorBrokerId'].maxLength}
                />
            )}
        </Grid>
    );
}
