import ObjectIdGenerator from '../modules/objectidgenerator';

class Address {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.name = json.name;
            this.streetAddress = json.streetAddress;
            this.zipCode = json.zipCode;
            this.city = json.city;
            this.country = json.country;
        } else {
            this.id = 0;
            this.name = '';
            this.streetAddress = '';
            this.zipCode = null;
            this.city = '';
            this.country = { countryId: 246 };
        }
    }

    isValid() {
        if (!this.streetAddress || this.streetAddress.length < 0) return false;
        if (!this.city || this.city.length < 0) return false;
        if (!this.zipCode || this.zipCode.length !== 5) return false;

        return true;
    }

    static areEqual(one, other) {
        if (one.name !== other.name) return false;
        if (one.streetAddress !== other.streetAddress) return false;
        if (one.zipCode !== other.zipCode) return false;
        if (one.city !== other.city) return false;
        return true;
    }

    static NewObject() {
        const promise = new Promise(function (resolve, reject) {
            const address = new Address();
            ObjectIdGenerator.newId()
                .then(function (id) {
                    address.id = id;
                    resolve(address);
                })
                .catch(function (err) {
                    console.log(err);
                    reject();
                });
        });
        return promise;
    }
}

export default Address;
