import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { rbacrules } from './rbacrules';

const RbacRoute = ({ user, path, component, render }) => {
    let userAllowedPaths = [];
    if (user != null && user.roles != null) {
        for (let r of user.roles) {
            userAllowedPaths = rbacrules[r].paths.concat(userAllowedPaths);
        }
    } else {
        return null;
    }

    if (userAllowedPaths.indexOf(path) !== -1) {
        if (component != null) return <Route exact path={path} component={component} />;
        else if (render != null) return <Route exact path={path} render={render} />;
        else return <Route exact path={path} render={() => <Redirect to="/home" />} />;
    } else {
        return <Route exact path={path} render={() => <Redirect to="/home" />} />;
    }
};

export default RbacRoute;
