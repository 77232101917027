import React, { useState, useEffect } from 'react';
import { DialogActions, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import AddressInput from './addressinput';
import { useTranslation } from 'react-i18next';

export default function AddressEditDialog(props) {
    const [address, setAddress] = useState(props.address);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    useEffect(() => {
        setAddress(props.address);
    }, [props.address]);

    const onSave = () => {
        props.save(address);
    };

    const onCancel = () => {
        props.cancel();
    };

    const addressChanged = (addr) => {
        setAddress(addr);
    };

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <AddressInput idBase={'addresseditdialog'} address={address} addressChanged={addressChanged} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="default" name="address-edit-cancel" onClick={onCancel}>
                    {t('buttons.cancel')}
                </Button>
                <Button variant="contained" color="primary" name="address-edit-save" onClick={onSave}>
                    {t('buttons.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
