import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import TextInputDialog from '../common/textinputdialog';
import { newParameter, updateParameter } from '../modules/parameterdata';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { filter } from 'lodash';

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: '0.5em',
    },
}));

export const ParameterGroupView = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [edit, setEdit] = React.useState(false);
    const [inputOpen, setInputOpen] = React.useState(false);
    const [paramToEdit, setParamToEdit] = React.useState(null);
    //filteröidään custom parametrit id = 1
    const parameters = filter(props.parameters, (p) => p.id !== 1);
    const category = props.category;
    const { t } = useTranslation();

    const toggleEdit = () => {
        setEdit(!edit);
    };

    const addParameter = () => {
        setParamToEdit(null);
        setInputOpen(true);
    };

    const saveParam = async (text) => {
        setInputOpen(false);
        if (paramToEdit) {
            const toSave = paramToEdit;
            toSave.value = text;
            await updateParameter(toSave)(dispatch);
            setParamToEdit(null);
        } else {
            await newParameter(category, text)(dispatch);
        }
    };

    const editText = (id) => {
        setParamToEdit(parameters.find((p) => p.id === id));
        setInputOpen(true);
    };

    return (
        <Grid item xs={12}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12}>
                            {parameters.length > 0 && <span>{parameters[0].categoryText}</span>}
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item container>
                            {parameters.map((v) => (
                                <Grid item key={v.id}>
                                    {edit && (
                                        <Chip
                                            className={classes.chip}
                                            icon={<EditIcon />}
                                            clickable={true}
                                            label={v.value}
                                            onClick={() => editText(v.id)}
                                        />
                                    )}
                                    {!edit && <Chip className={classes.chip} label={v.value} />}
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                            {!edit && (
                                <Button
                                    startIcon={<EditIcon />}
                                    variant="contained"
                                    color="primary"
                                    name="btn-open-edit"
                                    onClick={toggleEdit}>
                                    {t('buttons.edit')}
                                </Button>
                            )}
                            {edit && (
                                <Grid item xs={12}>
                                    <Button
                                        startIcon={<AddIcon />}
                                        variant="contained"
                                        color="primary"
                                        name="btn-add"
                                        onClick={addParameter}>
                                        {t('buttons.add')}
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button
                                        startIcon={<CheckIcon />}
                                        variant="contained"
                                        color="primary"
                                        name="btn-close-edit"
                                        onClick={toggleEdit}>
                                        {t('buttons.close')}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <TextInputDialog
                textFieldLabel={t('general.name')}
                open={inputOpen}
                confirm={saveParam}
                cancel={() => setInputOpen(false)}
                text={paramToEdit ? paramToEdit.value : ''}
            />
        </Grid>
    );
};
