import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import InvoiceAttribute from './invoiceattribute';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmationDialog from '../common/confirmationdialog';
import {
    sendInvoice,
    createCreditInvoice,
    deleteInvoice,
    checkIfCustomerInformationShouldBeUpdate,
    updateCustomerInformationFromInvoice,
} from '../modules/invoicedata';
import InvoiceRow from './invoicerow';
import { toast } from 'react-toastify';
import { Tooltip } from '@material-ui/core';
import UpdateCustomerInfoDialog from './updatecustomerinfodialog';
import { useTranslation } from 'react-i18next';
import Invoice from '../domain/invoice';
import { sumBy } from 'lodash';
import { round } from 'mathjs';

const useStyles = makeStyles((theme) => ({
    accordion: {
        marginTop: '10px',
    },
    table: {
        marginTop: '10px',
    },
    buttons: {
        marginBottom: '10px',
    },
}));

export default function InvoiceView(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const invoice = props.invoice;
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [confirmText, setConfirmText] = React.useState('');
    const [confirmBtnText, setConfirmBtnText] = React.useState('');
    const [sending, setSending] = React.useState(false);
    const [creatingCreditInv, setCreatingCreditInv] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [updateCustomerInfoDialogOpen, setUpdateCustomerInfoDialogOpen] = React.useState(false);
    const [checkingCustomerInfo, setCheckingCustomerInfo] = React.useState(false);
    const [updateCustomerInfoData, setUpdateCustomerInfoData] = React.useState({});
    const [updateCustomerInfoInProgress, setUpdateCustomerInfoInProgress] = React.useState(false);
    const salesInvoiceIntegrationInUse = props.salesInvoiceIntegrationInUse;
    const allowedEInvOperatorsNetvisor = props.allowedEInvOperatorsNetvisor;
    const vatCodes = props.vatCodes;
    const salesInvoiceIntegration = invoice.salesInvoiceIntegration;
    const { t } = useTranslation();

    const editInvoice = () => {
        props.history.push('/invoices/edit/' + invoice.id);
    };

    const startSendInvoice = () => {
        setConfirmBtnText(t('buttons.send'));
        setConfirmText(t('invoice.confirmSendInvoice'));
        setConfirmOpen(true);
    };

    const makeCreditInvoice = () => {
        setConfirmBtnText(t('buttons.create'));
        setConfirmText(t('invoice.confirmCreditInvoice'));
        setConfirmOpen(true);
    };

    const confirm = async () => {
        if (confirmBtnText === t('buttons.send')) await confirmSend();
        else if (confirmBtnText === t('buttons.create')) await confirmMakeCreditInvoice();
        else if (confirmBtnText === t('buttons.remove')) await confirmDeleteInvoice();
        else {
            setConfirmOpen(false);
            setConfirmText('');
            setConfirmBtnText('');
        }
    };

    const confirmMakeCreditInvoice = async () => {
        setConfirmOpen(false);
        setCreatingCreditInv(true);
        toast.info(t('invoice.toast.creatingCreditInvoice'), { autoClose: 1500, hideProgressBar: true });
        const resp = await createCreditInvoice(invoice.invoiceNumber)(dispatch);
        if (resp) {
            toast.info(t('invoice.toast.creditInvoiceCreated'), { autoClose: 1500, hideProgressBar: true });
        } else {
            toast.error(t('invoice.toast.failedToCreateCreditInvoice'), { autoClose: 4000 });
        }
        setCreatingCreditInv(false);
    };

    const confirmSend = async () => {
        setConfirmOpen(false);
        setSending(true);
        toast.info(t('invoice.toast.sendingInvoice'), { autoClose: 1500, hideProgressBar: true });

        const resp = await sendInvoice(invoice)(dispatch);
        setSending(false);
        if (resp) {
            toast.info(t('invoice.toast.invoiceSent'), { autoClose: 1500, hideProgressBar: true });
            props.onStatusChange();
        } else {
            toast.error(t('invoice.toast.sendInvoiceFailedRetry'), { autoClose: 5000 });
        }
    };

    const confirmDeleteInvoice = async () => {
        setDeleting(true);
        setConfirmOpen(false);
        toast.info(t('invoice.toast.removingInvoice'), { autoClose: 1500, hideProgressBar: true });
        const resp = await deleteInvoice(invoice.invoiceNumber)(dispatch);
        if (resp) {
            toast.info(t('invoice.toast.invoiceRemoved'), { autoClose: 1500, hideProgressBar: true });
        } else {
            toast.error(t('invoice.toast.failedToRemoveInvoiceRetry'), { autoClose: 5000 });
        }
        setDeleting(false);
    };

    const cancelConfirm = () => {
        setConfirmOpen(false);
    };

    const removeInvoice = () => {
        setConfirmBtnText(t('buttons.remove'));
        setConfirmText(t('buttons.confirmRemoveInvoice'));
        setConfirmOpen(true);
    };

    const isBusy = () => {
        return deleting || sending || creatingCreditInv || checkingCustomerInfo;
    };

    const actionAllowed = (action) => {
        switch (action) {
            case 'edit':
                return invoice.status === 1;
            case 'send':
                return invoice.status === 1;
            case 'credit-invoice':
                return invoice.status === 2;
            case 'remove':
                return invoice.status === 1;
            case 'correct':
                return invoice.status === 2;
            default:
                throw new Error('error');
        }
    };

    const updateCustomerInfoConfirm = async () => {
        setUpdateCustomerInfoInProgress(true);
        const toastId = toast.info(t('invoice.toast.savingChangesToCustomerData'), {
            autoClose: 10000,
            hideProgressBar: false,
        });
        if (!(await updateCustomerInformationFromInvoice(invoice.invoiceNumber)(dispatch))) {
            toast.error(t('invoice.toast.failedToSaveCustomerDataChanges'), { autoClose: 5000, hideProgressBar: true });
        } else {
            setUpdateCustomerInfoDialogOpen(false);
        }
        toast.dismiss(toastId);
        setUpdateCustomerInfoInProgress(false);
    };

    const isEinvDataValid = (einvaddress) => {
        if (!einvaddress || einvaddress.isEmpty()) return true;
        if (invoice.salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationTalenom()) return true;
        if (!allowedEInvOperatorsNetvisor) return true;
        if (!allowedEInvOperatorsNetvisor.find((op) => op.brokerId === einvaddress.operatorBrokerId)) return false;
        return true;
    };

    const getSendBtnTip = () => {
        if (salesInvoiceIntegrationInUse !== invoice.salesInvoiceIntegration)
            return t('invoice.cannotSendInvoiceToConfiguredIntegration');
        if (!einvDataValid) return t('invoice.eInvoiceBrokerIdInvalid');
        return '';
    };

    const gatherVatInfos = (rows) => {
        const vat10 = sumBy(
            rows.filter((r) => r.vatRate === 10.0),
            (r) => r.priceVatIncluded - r.priceVatExcluded
        );
        const vat14 = sumBy(
            rows.filter((r) => r.vatRate === 14.0),
            (r) => r.priceVatIncluded - r.priceVatExcluded
        );
        const vat24 = sumBy(
            rows.filter((r) => r.vatRate === 24.0),
            (r) => r.priceVatIncluded - r.priceVatExcluded
        );
        const vat255 = sumBy(
            rows.filter((r) => r.vatRate === 25.5),
            (r) => r.priceVatIncluded - r.priceVatExcluded
        );

        let results = [
            { vatRate: 10.0, vatAmount: round(vat10, 2) },
            { vatRate: 14.0, vatAmount: round(vat14, 2) },
            { vatRate: 24.0, vatAmount: round(vat24, 2) },
            { vatRate: 25.5, vatAmount: round(vat255, 2) },
        ];
        return results.filter((r) => r.vatAmount > 0);
    };

    const einvDataValid = isEinvDataValid(invoice.electronicInvoiceAddress);

    return (
        <div>
            <Grid container>
                <Grid item container className={classes.buttons}>
                    {actionAllowed('remove') && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                name="btn-remove-invoice"
                                disabled={isBusy()}
                                startIcon={
                                    deleting ? <CircularProgress color={'secondary'} size={'1rem'} /> : <DeleteIcon />
                                }
                                onClick={removeInvoice}>
                                {' '}
                                {t('buttons.remove')}
                            </Button>
                            &nbsp;
                        </Grid>
                    )}
                    {actionAllowed('edit') && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                name="btn-edit-invoice"
                                disabled={isBusy()}
                                startIcon={<EditIcon />}
                                onClick={editInvoice}>
                                {' '}
                                {t('buttons.edit')}
                            </Button>
                            &nbsp;
                        </Grid>
                    )}
                    {actionAllowed('credit-invoice') && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                name="btn-make-credit-invoice"
                                disabled={isBusy()}
                                startIcon={
                                    creatingCreditInv ? <CircularProgress color={'secondary'} size={'1rem'} /> : null
                                }
                                onClick={makeCreditInvoice}>
                                {t('invoice.creditInvoice')}
                            </Button>
                            &nbsp;
                        </Grid>
                    )}
                    {actionAllowed('send') && (
                        <Tooltip title={getSendBtnTip()}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    name="btn-send-invoice"
                                    disabled={
                                        isBusy() ||
                                        salesInvoiceIntegrationInUse !== invoice.salesInvoiceIntegration ||
                                        !einvDataValid
                                    }
                                    startIcon={
                                        sending ? <CircularProgress color={'secondary'} size={'1rem'} /> : <SendIcon />
                                    }
                                    onClick={startSendInvoice}>
                                    {t('buttons.send')}
                                </Button>
                                &nbsp;
                            </Grid>
                        </Tooltip>
                    )}

                    {actionAllowed('correct') && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                name="btn-correct-data"
                                disabled={isBusy()}
                                startIcon={<EditIcon />}
                                onClick={editInvoice}>
                                {t('buttons.fix')}
                            </Button>
                            &nbsp;
                        </Grid>
                    )}

                    <Grid item>
                        <Tooltip title={t('invoice.updateCustomerDataBasedOnInvoice')}>
                            <Button
                                variant="contained"
                                color="primary"
                                name="btn-correct-data"
                                disabled={isBusy()}
                                startIcon={
                                    checkingCustomerInfo ? (
                                        <CircularProgress color={'secondary'} size={'1rem'} />
                                    ) : (
                                        <SyncAltIcon />
                                    )
                                }
                                onClick={async () => {
                                    setCheckingCustomerInfo(true);
                                    const data = await checkIfCustomerInformationShouldBeUpdate(invoice.invoiceNumber)(
                                        dispatch
                                    );
                                    if (
                                        !data.invoiceAddrUpdateNeeded &&
                                        !data.eInvAddrUpdateNeeded &&
                                        !data.emailInvAddrUpdateNeeded
                                    ) {
                                        setConfirmText(t('invoice.customerDataUpToDateNoNeedForChanges'));
                                        setConfirmBtnText(t('buttons.close'));
                                        setConfirmOpen(true);
                                        setCheckingCustomerInfo(false);
                                        return;
                                    }
                                    setUpdateCustomerInfoData(data);
                                    setUpdateCustomerInfoDialogOpen(true);
                                    setCheckingCustomerInfo(false);
                                }}>
                                {t('buttons.update')}
                            </Button>
                        </Tooltip>
                        &nbsp;
                    </Grid>
                </Grid>
                <InvoiceAttribute name={t('general.portfolio')} value={invoice.portfolio.name} />
                <InvoiceAttribute name={t('invoice.invoiceNumber')} value={invoice.invoiceNumber} />
                {invoice.originalInvoiceNumber && (
                    <InvoiceAttribute name={t('invoice.creditsInvoice')} value={invoice.originalInvoiceNumber} />
                )}
                <InvoiceAttribute name={t('general.orderDate')} value={invoice.orderDate.format('DD.MM.YYYY')} />
                <InvoiceAttribute name={t('general.deliveryDate')} value={invoice.deliveryDate.format('DD.MM.YYYY')} />
                <InvoiceAttribute name={t('invoice.invoiceDate')} value={invoice.invoiceDate.format('DD.MM.YYYY')} />
                <InvoiceAttribute name={t('invoice.dueDate')} value={invoice.dueDate.format('DD.MM.YYYY')} />
                <InvoiceAttribute name={t('invoice.referenceNumber')} value={invoice.referenceNumberFormatted()} />
                <InvoiceAttribute name={t('invoice.payer')} value={`${invoice.payerName}${invoice.payerNameExt}`} />
                <InvoiceAttribute
                    name={t('invoice.payer') + ' ' + t('general.customerNumber')}
                    value={invoice.payerNumber}
                />
                <InvoiceAttribute
                    name={t('invoice.payer') + ' ' + t('general.businessId')}
                    value={invoice.payerBusinessId}
                />
                <InvoiceAttribute name={t('invoice.payer') + ' ' + t('general.vatId')} value={invoice.payerVatId} />
                <InvoiceAttribute name={t('general.orderNumber')} value={invoice.orderNumber} />
                <InvoiceAttribute name={t('parameterCategories.OurReference')} value={invoice.ourReference} />
                <InvoiceAttribute name={t('invoice.customerReference')} value={invoice.customerReference} />
                <InvoiceAttribute name={t('invoice.termsDnet')} value={invoice.invoiceDnet} />
                <InvoiceAttribute name={t('invoice.overdueInterest')} value={invoice.overdueInterest} />
                <InvoiceAttribute name={t('invoice.remarkTimeDays')} value={invoice.remarkTime} />

                {invoice.salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationTalenom() && (
                    <InvoiceAttribute name={t('invoice.shipMode')} value={invoice.shipModeText()} />
                )}

                {invoice.salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationTalenom() && (
                    <InvoiceAttribute name={t('invoice.toFinanceCompany')} value={invoice.factoring ? 'Kyllä' : '-'} />
                )}

                {!!invoice.emailInvoiceAddress && (
                    <InvoiceAttribute name={t('invoice.emailInvoiceAddress')} value={invoice.emailInvoiceAddress} />
                )}
                {!!invoice.electronicInvoiceAddress && (
                    <InvoiceAttribute
                        name={t('general.eInvoiceAddress')}
                        value={`${invoice.electronicInvoiceAddress.ovt}, ${invoice.electronicInvoiceAddress.operatorBrokerId}`}
                    />
                )}
                {!!invoice.deliveryAddress && (
                    <InvoiceAttribute
                        name={t('general.deliveryAddress')}
                        value={`${invoice.deliveryAddress.name}, ${invoice.deliveryAddress.streetAddress}, ${invoice.deliveryAddress.zipCode} ${invoice.deliveryAddress.city}`}
                    />
                )}
                {!!invoice.billingAddress && (
                    <InvoiceAttribute
                        name={t('general.invoicingAddress')}
                        value={`${invoice.billingAddress.streetAddress}, ${invoice.billingAddress.zipCode} ${invoice.billingAddress.city}`}
                    />
                )}
                <InvoiceAttribute name={t('general.language')} value={invoice.invoiceLanguage} />

                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('product.code')}</TableCell>
                            <TableCell>{t('product.name')}</TableCell>
                            <TableCell>{t('pcs')}</TableCell>
                            <TableCell>{t('invoice.pricePerUnit')}</TableCell>
                            <TableCell>{t('invoice.vatPercentage')}</TableCell>
                            <TableCell>{t('invoice.priceVatZero')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoice.rows.map((row) => (
                            <InvoiceRow
                                key={row.storageId}
                                row={row}
                                vatCodes={vatCodes}
                                salesInvoiceIntegration={salesInvoiceIntegration}
                            />
                        ))}
                        <TableRow>
                            <TableCell colSpan={5}>{t('invoice.totalPriceVatZero')} </TableCell>
                            <TableCell>
                                <strong>{round(invoice.vatExcluded, 2)}</strong>
                            </TableCell>
                        </TableRow>
                        {gatherVatInfos(invoice.rows).map((info) => (
                            <TableRow key={info.vatRate}>
                                <TableCell colSpan={4}>{t('general.vatShort')}</TableCell>
                                <TableCell>{info.vatRate} %</TableCell>
                                <TableCell>
                                    <strong>{info.vatAmount}</strong>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={4}>{t('general.vatShort')}</TableCell>
                            <TableCell>{t('reports.altogether')}</TableCell>
                            <TableCell>
                                <strong>{round(invoice.vat, 2)}</strong>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={5}>{t('invoice.totalPriceVatIncl')}</TableCell>
                            <TableCell>
                                <strong>{round(invoice.vatIncluded, 2)}</strong>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <ConfirmationDialog
                    open={confirmOpen}
                    confirm={confirm}
                    cancel={cancelConfirm}
                    confirmText={confirmBtnText}>
                    <span>{confirmText}</span>
                </ConfirmationDialog>
                <UpdateCustomerInfoDialog
                    open={updateCustomerInfoDialogOpen}
                    confirm={updateCustomerInfoConfirm}
                    cancel={() => {
                        setUpdateCustomerInfoDialogOpen(false);
                        setUpdateCustomerInfoData({});
                    }}
                    updateInfo={updateCustomerInfoData}
                    busy={updateCustomerInfoInProgress}
                />
            </Grid>
        </div>
    );
}
