class Party {
    constructor(json) {
        if (json) {
            this.storageId = json.storageId;
            this.id = json.id;
            this.name = json.name;
            this.phonenumber = json.phonenumber;
            this.email = json.email;
            this.organization = json.organization;
            this.externalDataSourceId = json.externalDataSourceId;
        }
    }
}
export default Party;
