import { Portfolio } from './portfolio';

class CustomershipValuation {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.name = json.name;
            this.status = json.status;
            this.valuation = json.valuation;
            this.portfolio = new Portfolio(json.portfolio);
        }
    }
}

export default CustomershipValuation;
