import React from 'react';

/* CacheBuster component */
import packageJson from '../../package.json';
global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

export default class CacheBuster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {
                console.log('Clearing cache and hard reloading...');
                if (caches) {
                    let promises = [];
                    caches.keys().then((names) => {
                        for (let n of names) {
                            promises.push(caches.delete(n));
                        }
                    });
                    Promise.all(promises).then(() => {
                        window.location.reload(true);
                    });
                } else {
                }
                // delete browser cache and hard reload
                window.location.reload(true);
            },
        };
    }

    componentDidMount() {
        fetch('/meta.json', { cache: 'no-store' })
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    this.setState({ loading: false, isLatestVersion: false });
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    this.setState({ loading: false, isLatestVersion: true });
                }
            })
            .catch((err) => {
                console.log('Failed to retrieve valid meta.json -> local dev most likely, ignore');
                this.setState({ loading: false, isLatestVersion: true });
            });
    }

    render() {
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
        return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
    }
}
