import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { orderBy } from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

export default function DebugInfo(props) {
    const wsEvents = useSelector((state) => state.appstate.debugWsEvents);
    const lifecycleEvents = useSelector((state) => state.appstate.debugLifecycleEvents);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3} alignItems={'flex-end'} alignContent={'flex-end'} justifyContent={'flex-end'}>
                <Grid item xs={12}>
                    <h4>ws events</h4>
                    <table>
                        <thead>
                            <tr>
                                <td>ts</td>
                                <td>event</td>
                            </tr>
                        </thead>
                        <tbody>
                            {orderBy(wsEvents, ['timestamp'], ['desc']).map((e) => (
                                <tr key={e.timestamp}>
                                    <td>{e.timestamp}</td>
                                    <td>{e.event}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Grid>
                <Grid item xs={12}>
                    <h4>Lifecycle events</h4>
                    <table>
                        <thead>
                            <tr>
                                <td>ts</td>
                                <td>event</td>
                            </tr>
                        </thead>
                        <tbody>
                            {orderBy(lifecycleEvents, ['timestamp'], ['desc']).map((e) => (
                                <tr key={e.timestamp}>
                                    <td>{e.timestamp}</td>
                                    <td>
                                        {e.old} ... {e.new}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Grid>
            </Grid>
        </div>
    );
}
