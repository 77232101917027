import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';
import { SEND_ORDERS_SUCCESS } from './customerorderdata';
export const GET_CUST_YEARLY_REPORT_IN_PROGRESS = 'customerreportdata/getyearlydatainproc';
export const GET_CUST_YEARLY_REPORT_SUCCESS = 'customerreportdata/getyearlydatasuccess';
export const GET_CUST_YEARLY_REPORT_FAILED = 'customerreportdata/getyearlydatafailed';
export const GET_CUST_ORDER_REPORT_IN_PROGRESS = 'customerreportdata/getorderreportinproc';
export const GET_CUST_ORDER_REPORT_SUCCESS = 'customerreportdata/getorderreportsuccess';
export const GET_CUST_ORDER_REPORT_FAILED = 'customerreportdata/getorderreportfailed';

const initialState = {
    getYearlyReportInProgress: false,
    yearlyReports: null,
    getOrderReportInProgress: false,
    orderReports: null,
    orderReportRange: [null, null],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CUST_YEARLY_REPORT_FAILED:
            return {
                ...state,
                getYearlyReportInProgress: false,
            };
        case GET_CUST_YEARLY_REPORT_IN_PROGRESS:
            return {
                ...state,
                getYearlyReportInProgress: true,
            };
        case GET_CUST_YEARLY_REPORT_SUCCESS:
            return {
                ...state,
                getYearlyReportInProgress: false,
                yearlyReports: action.reports,
            };
        case GET_CUST_ORDER_REPORT_IN_PROGRESS:
            return {
                ...state,
                getOrderReportInProgress: true,
            };
        case GET_CUST_ORDER_REPORT_SUCCESS:
            return {
                ...state,
                getOrderReportInProgress: false,
                orderReports: action.reports,
                orderReportRange: action.range,
            };
        case GET_CUST_ORDER_REPORT_FAILED:
            return {
                ...state,
                getOrderReportInProgress: false,
            };
        case SEND_ORDERS_SUCCESS:
            return {
                ...state,
                orderReports: null,
                orderReportRange: [null, null],
            };
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const getCustYearlyReports = () => {
    return (dispatch) => {
        dispatch({
            type: GET_CUST_YEARLY_REPORT_IN_PROGRESS,
        });
        return Ajax.get('api/customerreport/v1/year/')
            .then((data) => {
                dispatch({
                    type: GET_CUST_YEARLY_REPORT_SUCCESS,
                    reports: data.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_CUST_YEARLY_REPORT_FAILED,
                });
            });
    };
};

export const getCustOrderReports = (start, end) => {
    return (dispatch) => {
        dispatch({
            type: GET_CUST_ORDER_REPORT_IN_PROGRESS,
        });
        return Ajax.get(
            'api/customerreport/v1/orderhistory/start/' +
                start.format('YYYY-MM-DD') +
                '/end/' +
                end.format('YYYY-MM-DD')
        )
            .then((data) => {
                dispatch({
                    type: GET_CUST_ORDER_REPORT_SUCCESS,
                    reports: data.data,
                    range: [start, end],
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_CUST_ORDER_REPORT_FAILED,
                });
            });
    };
};
