import React, { useEffect } from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import * as invActions from '../modules/invoicedata';
import { getOrdersByOrderNumber } from '../modules/orderdata';
import Spinner from '../common/spinner';
import InvoiceView from './invoiceview';
import { formatCurrency } from '../common/common';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getMoreInvoicesSent, getInvoicesOpen, RESET_INVOICE_DATA } from '../modules/invoicedata';
import Button from '@material-ui/core/Button';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        marginTop: '5px',
        marginBottom: '5px',
    },
    invoiceHeader: {
        padding: '5px',
        fontSize: '1.1em',
        fontWeight: 'bold',
    },
    fieldLabel: {
        fontWeight: 'bold',
    },
    addMarginTop: {
        marginTop: '10px',
    },
}));

export default function InvoiceListView(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const allowedEInvOperatorsNetvisor = props.allowedEInvOperatorsNetvisor;
    const vatCodes = props.vatCodes;
    const invoicesOpen = useSelector((state) => state.invoicedata.invoicesOpen);
    const invoicesSent = useSelector((state) => state.invoicedata.invoicesSent);
    const loadingInvoicesOpen = useSelector((state) => state.invoicedata.loadingInvoicesOpen);
    const loadingInvoicesSent = useSelector((state) => state.invoicedata.loadingInvoicesSent);
    const invoicesById = useSelector((state) => state.invoicedata.invoicesById);
    const ordersByOrderNumber = useSelector((state) => state.orderdata.ordersByOrderNumber);
    const searchInProgress = useSelector((state) => state.invoicedata.searchInProgress);
    const [viewData, setViewData] = React.useState([]);
    const dispatch = useDispatch();
    const [invoicesToShow, setInvoicesToShow] = React.useState(50);

    useEffect(() => {
        if (!invoicesSent && !loadingInvoicesSent) {
            dispatch(getMoreInvoicesSent(0, 2000)); // TODO FIX hard-coded value for fetching..
        }
        if (!invoicesOpen && !loadingInvoicesOpen) {
            dispatch(getInvoicesOpen());
        }
    }, [invoicesSent, invoicesOpen, loadingInvoicesOpen, loadingInvoicesSent, dispatch]);

    useEffect(() => {
        if (!loadingInvoicesOpen && !loadingInvoicesSent && !searchInProgress) {
            let invoiceList = [];

            if (props.filter && props.filter.length >= 3) {
                // näytetään hakutulokset
                if (props.viewOnlyDraft) invoiceList = props.searchResults.filter((inv) => inv.status === 1);
                else invoiceList = props.searchResults;
            } else if (props.viewOnlyDraft) {
                // ei hakutuloksia, oletuksena avoimet laskut näkyville
                let sentInvoicesToAdd = [];
                if (invoicesOpen && invoicesOpen.length > 0) {
                    // jos löytyy avoimia laskuja, tarkistetaan löytyykö samalla tilausnumerolle myös lähetettyjä. Lisätään
                    // nämä listaan, jotta näkymä osaa näyttää myös nämä samassa listassa riippumatta siitä katsellaanko vain
                    // avoimia vai kaikkia laskuja
                    sentInvoicesToAdd = _.flatten(
                        invoicesOpen.map((inv) => {
                            return invoicesSent.filter(
                                (iv) => iv.orderNumber === inv.orderNumber && iv.invoiceNumber !== inv.invoiceNumber
                            );
                        })
                    );
                }
                invoiceList = sentInvoicesToAdd.length > 0 ? sentInvoicesToAdd.concat(invoicesOpen) : invoicesOpen;
            } else {
                // kaikki laskut näkyville
                invoiceList = invoicesSent;
            }
            if (invoiceList) {
                invoiceList = _.orderBy(invoiceList, 'invoiceNumber', 'desc');
                const groupedByOrderNumber = _(invoiceList).groupBy('orderNumber').value();
                let allData = Object.keys(groupedByOrderNumber).map((k) => {
                    return { orderNumber: k, invoices: groupedByOrderNumber[k] };
                });
                allData = _.orderBy(
                    allData,
                    [
                        function (item) {
                            return item.invoices[0].invoiceNumber;
                        },
                    ],
                    'desc'
                );
                allData.splice(invoicesToShow, allData.length - invoicesToShow);
                setViewData(allData);
            }
        }
    }, [
        props.viewOnlyDraft,
        props.searchResults,
        props.filter,
        invoicesOpen,
        invoicesSent,
        loadingInvoicesOpen,
        loadingInvoicesSent,
        searchInProgress,
        invoicesToShow,
    ]);

    const onExpand = (invoiceId) => {
        if (!invoicesById[invoiceId]) {
            dispatch(invActions.getInvoiceById(invoiceId));
        }
    };

    // Tänne tullaan kun lasku on lähetetty
    const onInvoiceStatusChange = () => {
        dispatch({ type: RESET_INVOICE_DATA });
    };

    const expandOrder = (orderNumber) => {
        if (!ordersByOrderNumber[orderNumber]) {
            dispatch(getOrdersByOrderNumber(orderNumber));
        }
    };

    const loadMoreSentInvoices = () => {
        if (invoicesSent && invoicesSent.length > 0) {
            //dispatch(getMoreInvoicesSent(invoicesSent.length, 50));
            setInvoicesToShow(invoicesToShow + 50);
        }
    };

    if (searchInProgress)
        return (
            <div className={classes.root}>
                <Grid container spacing={0} direction="column" alignItems="center" style={{ minHeight: '10vh' }}>
                    <Spinner size={'2rem'} />
                </Grid>
            </div>
        );

    if (props.filter && props.filter.length >= 3 && props.searchResults.length === 0) {
        return (
            <div className={classes.root}>
                <Grid container spacing={0} direction="column" alignItems="center" style={{ minHeight: '10vh' }}>
                    <Grid item>{t('general.noSearchResults')}</Grid>
                </Grid>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Grid container>
                {viewData &&
                    viewData.length > 0 &&
                    viewData.map((orderItem) => (
                        <Grid item xs={12} key={orderItem.orderNumber}>
                            <Paper variant="outlined" className={classes.paper}>
                                <Accordion onChange={() => expandOrder(orderItem.orderNumber)}>
                                    <AccordionSummary>
                                        <Grid container className={classes.invoiceHeader}>
                                            <Grid item xs={4}>
                                                {orderItem.invoices[0].payerName}
                                                {orderItem.invoices[0].payerNameExt &&
                                                    orderItem.invoices[0].payerNameExt.length > 0 && (
                                                        <span>
                                                            <br />
                                                            {orderItem.invoices[0].payerNameExt}
                                                        </span>
                                                    )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {t('general.ordered')}{' '}
                                                {orderItem.invoices[0].orderDate.format('DD.MM.YYYY')}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {t('general.orderNumber')} {orderItem.orderNumber}
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {!ordersByOrderNumber[orderItem.orderNumber] && <Spinner />}
                                        {!!ordersByOrderNumber[orderItem.orderNumber] &&
                                            !!ordersByOrderNumber[orderItem.orderNumber][0] &&
                                            !!ordersByOrderNumber[orderItem.orderNumber][0].deliveryInfo && (
                                                <Grid container>
                                                    <Grid item xs={12} sm={6} className={classes.fieldLabel}>
                                                        {t('general.deliveryDate')}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        {moment(
                                                            ordersByOrderNumber[orderItem.orderNumber][0].deliveryInfo
                                                                .actualDeliveryDate
                                                        ).format('DD.MM.YYYY')}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} className={classes.fieldLabel}>
                                                        {t('general.packingInfo')}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        {t('general.boxes')}{' '}
                                                        {
                                                            ordersByOrderNumber[orderItem.orderNumber][0].deliveryInfo
                                                                .boxCount
                                                        }
                                                        ,&nbsp;
                                                        {t('general.pallets')}&nbsp;
                                                        {
                                                            ordersByOrderNumber[orderItem.orderNumber][0].deliveryInfo
                                                                .palletCount
                                                        }
                                                    </Grid>
                                                </Grid>
                                            )}
                                    </AccordionDetails>
                                </Accordion>

                                {orderItem.invoices.map((inv) => (
                                    <Accordion onChange={() => onExpand(inv.id)} key={inv.id}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    {inv.invoiceNumber}&nbsp;
                                                    <span style={{ fontSize: '0.75em' }}>
                                                        (
                                                        {inv.salesInvoiceIntegration.charAt(0).toUpperCase() +
                                                            inv.salesInvoiceIntegration.substring(1)}
                                                        )
                                                    </span>
                                                    &nbsp;
                                                    <Chip
                                                        size="small"
                                                        color={inv.status === 1 ? 'primary' : 'secondary'}
                                                        label={inv.statusText}
                                                    />
                                                    &nbsp;
                                                    {inv.originalInvoiceNumber && (
                                                        <Chip size="small" color="primary" label="Hyvitys"></Chip>
                                                    )}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {inv.invoiceDate.format('DD.MM.YYYY')}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {formatCurrency(inv.vatExcluded)}
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {!invoicesById[inv.id] && <Spinner />}
                                            {!!invoicesById[inv.id] && (
                                                <Grid item>
                                                    <InvoiceView
                                                        salesInvoiceIntegrationInUse={
                                                            props.salesInvoiceIntegrationInUse
                                                        }
                                                        invoice={invoicesById[inv.id]}
                                                        history={props.history}
                                                        onStatusChange={onInvoiceStatusChange}
                                                        allowedEInvOperatorsNetvisor={allowedEInvOperatorsNetvisor}
                                                        vatCodes={vatCodes}
                                                    />
                                                </Grid>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </Paper>
                        </Grid>
                    ))}
                {!props.viewOnlyDraft && (
                    <Grid item xs={12} className={classes.addMarginTop}>
                        <Button
                            variant="contained"
                            color="secondary"
                            name="newitem"
                            onClick={loadMoreSentInvoices}
                            disabled={
                                loadingInvoicesOpen ||
                                loadingInvoicesSent ||
                                searchInProgress ||
                                (props.filter && props.filter.length > 3)
                            }>
                            {t('general.showMore')}
                        </Button>
                    </Grid>
                )}
                {(loadingInvoicesOpen || loadingInvoicesSent || searchInProgress) && (
                    <Grid container spacing={0} direction="column" alignItems="center" style={{ minHeight: '20vh' }}>
                        <Grid item>
                            <Spinner size={'2rem'} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
