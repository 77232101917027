import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    useMediaQuery,
    useTheme,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import { round } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function LocationOrderValueSetDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [orderValue, setOrderValue] = useState(props.orderValue);
    const [calculatedDiscount, setCalclulatedDiscount] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.open) {
            setOrderValue(props.orderValue);
        }
    }, [props.open, props.orderValue]);

    useEffect(() => {
        let discount = (1 - orderValue / props.orderValueWithoutDiscount) * 100;
        setCalclulatedDiscount(discount);
    }, [orderValue, props.orderValueWithoutDiscount]);

    const orderValueChange = (e) => {
        let value = e.target.value.toString().replace(',', '.');
        if (!/^\d*[.]?\d{0,2}$/.test(value)) {
            return;
        } else if (value >= 0) {
            setOrderValue(value);
        }
    };

    const onCancel = () => {
        props.cancel();
    };

    const onSave = () => {
        props.save(calculatedDiscount);
    };

    return (
        <Dialog open={props.open} fullWidth={true} maxWidth={'md'} fullScreen={fullScreen}>
            <DialogTitle>{t('location.setLocationValue')}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        {t('general.value')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="orderValue"
                            value={orderValue}
                            onChange={orderValueChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {t('general.calculatedDiscount')} {round(calculatedDiscount, 4)} %
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                        <strong>{t('location.savingChangesDiscountPercentageOfAllProductsInLocation')}</strong>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    name="set-order-value-save"
                    onClick={onSave}
                    disabled={!(orderValue > 0)}>
                    {t('buttons.save')}
                </Button>

                <Button variant="contained" color="default" name="set-order-value-cancel" onClick={onCancel}>
                    {t('buttons.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
