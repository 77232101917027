import Ajax from './ajax';
import User from '../domain/user';
import moment from 'moment';
import CONFIG from '../config';

export const LOGIN_IN_PROGRESS = 'authentication/LOGIN_IN_PROGRESS';
export const LOGOFF_IN_PROGRESS = 'authentication/LOGOFF_IN_PROGRESS';
export const LOGIN_AUTHENTICATED = 'authentication/LOGIN_AUTHENTICATED';
export const POST_AUTHENTICATION = 'authentication/POST_AUTHENTICATION';
export const LOGIN_FAILED = 'authentication/LOGIN_FAILED';
export const LOGIN_NEW_PASSWORD_REQUIRED = 'authentication/LOGIN_NEW_PASSWORD_REQUIRED';
export const LOGIN_PASSWORD_CHANGE_IN_PROGRESS = 'authentication/LOGIN_PASSWORD_CHANGE_IN_PROGRESS';
export const LOGIN_PASSWORD_CHANGE_FAILED = 'authentication/PASSWORD_CHANGE_FAILED';
export const LOGGED_OFF = 'authentication/LOGGED_OFF';
export const JWT_TOKEN = 'accessToken';
export const JWT_REFRESH_TOKEN = 'refreshToken';
export const ACCESS_TOKEN_VALID_UNTIL = 'accessTokenValidUntil';
export const SELECTED_PORTFOLIO = 'selectedPortfolio';
export const SET_WS_CONNECTION_ID = 'auth/SET_WS_CONNECTION_ID';

const initialState = {
    authenticated: false,
    loginFailed: false,
    loginInProgress: false,
    logoffInProgress: false,
    userProfile: {},
    newPasswordRequired: false,
    passwordChangeInProgress: false,
    passwordChangeFailed: false,
    newPasswordRequiredFor: '',
    wsConnectionId: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_IN_PROGRESS:
            return {
                ...state,
                loginInProgress: true,
                authenticated: false,
                loginFailed: false,
                logoffInProgress: false,
                userProfile: {},
            };

        case LOGOFF_IN_PROGRESS:
            return {
                ...state,
                loginInProgress: false,
                authenticated: false,
                loginFailed: false,
                logoffInProgress: true,
                userProfile: {},
            };

        case LOGIN_AUTHENTICATED:
            return {
                ...state,
                loginInProgress: false,
                authenticated: true,
                loginFailed: false,
                logoffInProgress: false,
                userProfile: new User(action.user),
                newPasswordRequired: false,
                passwordChangeFailed: false,
                passwordChangeInProgress: false,
            };

        case LOGIN_FAILED:
            return {
                ...state,
                loginInProgress: false,
                authenticated: false,
                loginFailed: true,
                logoffInProgress: false,
                userProfile: {},
            };
        case LOGIN_NEW_PASSWORD_REQUIRED: {
            return {
                ...state,
                newPasswordRequired: true,
                loginInProgress: false,
                newPasswordRequiredFor: action.for,
            };
        }
        case LOGGED_OFF:
            ClearTokenValuesFromStorage();
            return initialState;
        case LOGIN_PASSWORD_CHANGE_IN_PROGRESS:
            return {
                ...state,
                passwordChangeInProgress: true,
                passwordChangeFailed: false,
            };
        case LOGIN_PASSWORD_CHANGE_FAILED:
            return {
                ...state,
                passwordChangeFailed: true,
                passwordChangeInProgress: false,
            };
        case SET_WS_CONNECTION_ID: {
            return {
                wsConnectionId: action.connectionId,
                ...state,
            };
        }
        default:
            return state;
    }
};

const SetTokenValuesToStorage = (access_token, refresh_token, access_token_valid, selected_portfolio) => {
    sessionStorage.setItem(JWT_TOKEN, access_token);
    sessionStorage.setItem(JWT_REFRESH_TOKEN, refresh_token);
    sessionStorage.setItem(ACCESS_TOKEN_VALID_UNTIL, moment().add(access_token_valid, 'seconds').unix());
};

const ClearTokenValuesFromStorage = () => {
    sessionStorage.removeItem(JWT_TOKEN);
    sessionStorage.removeItem(JWT_REFRESH_TOKEN);
    sessionStorage.removeItem(ACCESS_TOKEN_VALID_UNTIL);
};

const postAuthenticatedActions = (dispatch, user) => {
    dispatch({ type: LOGIN_AUTHENTICATED, user: user });
    dispatch({ type: POST_AUTHENTICATION });
};

export const doLogin = (user, pass) => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_IN_PROGRESS,
        });
        Ajax.post(CONFIG.loginUrl, { username: user, password: pass })
            .then(function (resp) {
                if (resp.data.authenticated === false && resp.data.newpasswordrequired === true) {
                    dispatch({
                        type: LOGIN_NEW_PASSWORD_REQUIRED,
                        for: user,
                    });
                } else {
                    SetTokenValuesToStorage(
                        resp.data.access_token,
                        resp.data.refresh_token,
                        resp.data.access_token_expires_in
                    );
                    postAuthenticatedActions(dispatch, resp.data.user);
                }
            })
            .catch(function (err) {
                ClearTokenValuesFromStorage();
                dispatch({
                    type: LOGIN_FAILED,
                });
            });
    };
};

export const doForcedPasswordChange = (user, oldpassword, newpassword) => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_PASSWORD_CHANGE_IN_PROGRESS,
        });
        Ajax.post(CONFIG.loginUrl, {
            username: user,
            password: oldpassword,
            newPassword: newpassword,
            respondToNewPasswordRequest: true,
        })
            .then(function (resp) {
                SetTokenValuesToStorage(
                    resp.data.access_token,
                    resp.data.refresh_token,
                    resp.data.access_token_expires_in
                );
                postAuthenticatedActions(dispatch, resp.data.user);
            })
            .catch(function (err) {
                ClearTokenValuesFromStorage();
                dispatch({
                    type: LOGIN_PASSWORD_CHANGE_FAILED,
                });
                dispatch({
                    type: LOGIN_FAILED,
                });
            });
    };
};

export const doLogoff = () => {
    return (dispatch) => {
        dispatch({
            type: LOGOFF_IN_PROGRESS,
        });
        Ajax.post(CONFIG.logoutUrl, { refresh_token: sessionStorage.getItem(JWT_REFRESH_TOKEN) })
            .then(function (resp) {
                ClearTokenValuesFromStorage();
                dispatch({
                    type: LOGGED_OFF,
                });
            })
            .catch(function (err) {
                console.log(err);
                ClearTokenValuesFromStorage();
                dispatch({
                    type: LOGGED_OFF,
                });
            });
    };
};

export const AuthenticationFailed = () => {
    return {
        type: LOGGED_OFF,
    };
};

export const CheckAuthentication = () => {
    return (dispatch) => {
        var refreshToken = sessionStorage.getItem(JWT_REFRESH_TOKEN);
        if (refreshToken != null) {
            Ajax.post(CONFIG.loginRefreshUrl, { refresh_token: refreshToken })
                .then(function (resp) {
                    SetTokenValuesToStorage(resp.data.access_token, refreshToken, resp.data.access_token_expires_in);
                    postAuthenticatedActions(dispatch, resp.data.user);
                })
                .catch(function (err) {
                    console.log(err);
                    ClearTokenValuesFromStorage();
                    dispatch({
                        type: LOGGED_OFF,
                    });
                });
        } else {
            dispatch({
                type: LOGGED_OFF,
            });
        }
    };
};

export const RefreshToken = () => {
    return (dispatch) => {
        var refreshToken = sessionStorage.getItem(JWT_REFRESH_TOKEN);
        if (refreshToken != null) {
            Ajax.post(CONFIG.loginRefreshUrl, { refresh_token: refreshToken })
                .then(function (resp) {
                    SetTokenValuesToStorage(resp.data.access_token, refreshToken, resp.data.access_token_expires_in);
                })
                .catch(function (err) {
                    ClearTokenValuesFromStorage();
                    dispatch({
                        type: LOGGED_OFF,
                    });
                });
        } else {
            ClearTokenValuesFromStorage();
            dispatch({
                type: LOGGED_OFF,
            });
        }
    };
};

export const setWsConnectionId = (connectionId) => {
    return (dispatch) => {
        dispatch({
            type: SET_WS_CONNECTION_ID,
            connectionId,
        });
        sessionStorage.setItem('wsConnectionId', connectionId);
    };
};
