import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function CustomerAutocomplete(props) {
    const defaultProps = {
        options: props.customers,
        getOptionLabel: (option) => `${option.name}`,
    };
    const { t } = useTranslation();

    useEffect(() => {
        if (props.customers) {
            const elem = document.getElementById('customerautocomplete');
            if (elem) {
                elem.focus();
            }
        }
    }, [props.customers]);

    const onSelect = (_tmp, elem) => {
        props.selected(elem.id, elem);
    };

    return (
        <Autocomplete
            {...defaultProps}
            id="customerautocomplete"
            name="customerautocomplete"
            loading={!props.customers}
            loadingText={t('general.loading')}
            onChange={onSelect}
            renderInput={(params) => <TextField {...params} label={t('general.customer')} margin="normal" />}
        />
    );
}

CustomerAutocomplete.propTypes = {
    customers: PropTypes.array.isRequired,
    selected: PropTypes.func.isRequired,
};
