import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    InputBase,
    Paper,
    useMediaQuery,
    useTheme,
    Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { GetBisDetails } from '../common/bislookup';
import { cloneDeep } from 'lodash';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../common/tabpanel';
import { TypeSearchByBusinessId, TypeSearchByTerm } from './bislookupcontainer';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    itemTitle: {
        fontWeight: 'bold',
    },
    copyButton: {
        textAlign: 'right',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    searchBar: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    tabBar: {
        marginBottom: theme.spacing(1),
    },
    searchBarPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
}));

function SearchBar(props) {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState(props.searchTerm ? props.searchTerm : '');
    const [searchBusinessId, setSearchBusinessId] = useState(props.searchBusinessId ? props.searchBusinessId : '');
    const { t } = useTranslation();

    const handleSearchChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        switch (name) {
            case 'searchByTerm':
                setSearchTerm(value);
                break;
            case 'searchByBusinessId':
                setSearchBusinessId(value);
                break;
            default:
                break;
        }
    };

    const doSearch = () => {
        props.search(
            props.tab === 0 ? searchTerm : searchBusinessId,
            props.tab === 0 ? TypeSearchByTerm : TypeSearchByBusinessId
        );
    };

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            doSearch();
        }
    };

    return (
        <Paper className={classes.searchBarPaper}>
            <InputBase
                className={classes.input}
                name={props.tab === 0 ? 'searchByTerm' : 'searchByBusinessId'}
                placeholder={props.tab === 0 ? t('general.name') : t('general.businessId')}
                onChange={handleSearchChange}
                value={props.tab === 0 ? searchTerm : searchBusinessId}
                onKeyUp={handleKeyUp}
            />
            <IconButton onClick={doSearch}>
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}

export default function BisLookupView(props) {
    const CHECKBOX_TYPE_NAME = 'name';
    const CHECKBOX_TYPE_ID = 'businessId';
    const classes = useStyles();
    const [searchResult, setSearchResult] = useState(null);
    const [bisDetails, setBisDetails] = useState({});
    const [businessIdResult, setBusinessIdResult] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [checkboxValue, setCheckboxValue] = useState({});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    useEffect(() => {
        setSearchResult(props.bisItems);
        setBusinessIdResult(props.businessIdResult);
        setCheckboxValue({});
    }, [props.bisItems, props.businessIdResult]);

    useEffect(() => {
        if (props.searchTerm !== null && props.searchBusinessId === null) {
            setActiveTab(0);
        }
        if (props.searchTerm === null && props.searchBusinessId !== null) {
            setActiveTab(1);
        }
    }, [props.searchBusinessId, props.searchTerm]);

    const onCancel = () => {
        props.cancel();
    };

    const onAccordionChange = async (uri) => {
        if (uri && !bisDetails[uri]) {
            let details = await GetBisDetails(uri);
            let newBisDetails = cloneDeep(bisDetails);
            newBisDetails[uri] = details;
            setBisDetails(newBisDetails);
        }
    };

    const onTabChange = (event, selectedTab) => {
        setActiveTab(selectedTab);
    };

    const checkboxChange = (id, type, value) => {
        let newCheckboxValue = cloneDeep(checkboxValue);
        if (!!!newCheckboxValue[id]) newCheckboxValue[id] = { name: null, businessId: null };
        switch (type) {
            case CHECKBOX_TYPE_NAME:
                newCheckboxValue[id].name = newCheckboxValue[id].name === null ? value : null;
                break;
            case CHECKBOX_TYPE_ID:
                newCheckboxValue[id].businessId = newCheckboxValue[id].businessId === null ? value : null;
                break;
            default:
                break;
        }
        setCheckboxValue(newCheckboxValue);
    };

    const getCheckedState = (id, type) => {
        switch (type) {
            case CHECKBOX_TYPE_NAME:
                return checkboxValue[id] && checkboxValue[id].name ? true : false;
            case CHECKBOX_TYPE_ID:
                return checkboxValue[id] && checkboxValue[id].businessId ? true : false;
            default:
                return false;
        }
    };

    const handleSaveButton = (id) => {
        props.save(checkboxValue[id].name, checkboxValue[id].businessId);
    };

    const formatRegisterationDate = (regdate) => {
        return moment(regdate, 'YYYY-MM-DD').format('DD.MM.YYYY');
    };

    const BisSearchDetailView = (args) => {
        const result = args.bisItem;
        if (!!!bisDetails[result.detailsUri]) return <CircularProgress color={'secondary'} size={'1rem'} />;
        return (
            <Grid container>
                <Grid item xs={5} className={classes.itemTitle}>
                    {t('general.name')}
                </Grid>
                <Grid item xs={5}>
                    {bisDetails[result.detailsUri].name}
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        checked={getCheckedState(result.businessId, CHECKBOX_TYPE_NAME)}
                        onChange={() =>
                            checkboxChange(result.businessId, CHECKBOX_TYPE_NAME, bisDetails[result.detailsUri].name)
                        }
                    />
                </Grid>

                <Grid item xs={5} className={classes.itemTitle}>
                    {t('general.businessId')}
                </Grid>
                <Grid item xs={5}>
                    {bisDetails[result.detailsUri].businessId}
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        checked={getCheckedState(result.businessId, CHECKBOX_TYPE_ID)}
                        onChange={() =>
                            checkboxChange(
                                result.businessId,
                                CHECKBOX_TYPE_ID,
                                bisDetails[result.detailsUri].businessId
                            )
                        }
                    />
                </Grid>

                <Grid item xs={5} className={classes.itemTitle}>
                    {t('companySearch.companyForm')}
                </Grid>
                <Grid item xs={5}>
                    {bisDetails[result.detailsUri].companyForm}
                </Grid>
                <Grid item xs={2}></Grid>

                <Grid item xs={5} className={classes.itemTitle}>
                    {t('companySearch.registrationDate')}
                </Grid>
                <Grid item xs={5}>
                    {formatRegisterationDate(bisDetails[result.detailsUri].registrationDate)}
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={12} className={classes.copyButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        name="copy"
                        disabled={
                            !getCheckedState(result.businessId, CHECKBOX_TYPE_ID) &&
                            !getCheckedState(result.businessId, CHECKBOX_TYPE_NAME)
                        }
                        onClick={() => handleSaveButton(result.businessId)}>
                        {t('general.copy')}
                    </Button>
                </Grid>
            </Grid>
        );
    };

    const BisDetailView = (args) => {
        const bisItem = args.bisItem;
        const viewprefix = args.viewprefix ? args.viewprefix : '';
        const { t } = useTranslation();

        if (props.busy) return <CircularProgress color={'secondary'} size={'1rem'} />;
        if (!!!bisItem) return <span>Ei hakutuloksia</span>;
        return (
            <Grid container>
                <Grid item xs={5} className={classes.itemTitle}>
                    {t('general.name')}
                </Grid>
                <Grid item xs={5}>
                    {bisItem.name}
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        checked={getCheckedState(viewprefix + bisItem.businessId, CHECKBOX_TYPE_NAME)}
                        onChange={() =>
                            checkboxChange(viewprefix + bisItem.businessId, CHECKBOX_TYPE_NAME, bisItem.name)
                        }
                    />
                </Grid>

                <Grid item xs={5} className={classes.itemTitle}>
                    {t('general.businessId')}
                </Grid>
                <Grid item xs={5}>
                    {bisItem.businessId}
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        checked={getCheckedState(viewprefix + bisItem.businessId, CHECKBOX_TYPE_ID)}
                        onChange={() =>
                            checkboxChange(viewprefix + bisItem.businessId, CHECKBOX_TYPE_ID, bisItem.businessId)
                        }
                    />
                </Grid>

                <Grid item xs={5} className={classes.itemTitle}>
                    {t('companySearch.companyForm')}
                </Grid>
                <Grid item xs={5}>
                    {bisItem.companyForm}
                </Grid>
                <Grid item xs={2}></Grid>

                <Grid item xs={5} className={classes.itemTitle}>
                    {t('companySearch.registrationDate')}
                </Grid>
                <Grid item xs={5}>
                    {formatRegisterationDate(bisItem.registrationDate)}
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={12} className={classes.copyButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        name="copy"
                        disabled={
                            !getCheckedState(viewprefix + bisItem.businessId, CHECKBOX_TYPE_ID) &&
                            !getCheckedState(viewprefix + bisItem.businessId, CHECKBOX_TYPE_NAME)
                        }
                        onClick={() => handleSaveButton(viewprefix + bisItem.businessId)}>
                        {t('general.copy')}
                    </Button>
                </Grid>
            </Grid>
        );
    };

    return (
        <Dialog open={props.open} fullWidth={true} maxWidth={'md'} fullScreen={fullScreen}>
            <DialogContent>
                <AppBar position="static" color="default" className={classes.tabBar}>
                    <Box display="flex" justifyContent="center" width="100%">
                        <Tabs
                            value={activeTab}
                            onChange={onTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto">
                            <Tab value={0} label={t('companySearch.byName')} name="tab-search-by-term" />
                            <Tab value={1} label={t('companySearch.byBusinessId')} name="tab-search-by-businessid" />
                        </Tabs>
                    </Box>
                </AppBar>
                <TabPanel value={activeTab} index={0}>
                    <SearchBar
                        tab={0}
                        searchTerm={props.searchTerm}
                        searchBusinessId={props.searchBusinessId}
                        search={props.search}
                    />
                    {props.busy && <CircularProgress color={'secondary'} size={'1rem'} />}
                    {!props.busy && (searchResult === null || searchResult.totalResults === 0) && (
                        <span>{t('general.noSearchResults')}</span>
                    )}
                    {!props.busy &&
                        searchResult !== null &&
                        searchResult.results.map((result) => (
                            <Accordion
                                key={result.businessId}
                                onChange={() => onAccordionChange(result.detailsUri)}
                                id={result.businessId}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} name="expand">
                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            {result.name} {result.businessId}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {searchResult.totalResults === 1 && <BisDetailView bisItem={result} />}
                                    {searchResult.totalResults !== 1 && <BisSearchDetailView bisItem={result} />}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <SearchBar
                        tab={1}
                        searchTerm={props.searchTerm}
                        searchBusinessId={props.searchBusinessId}
                        search={props.search}
                    />
                    <BisDetailView bisItem={businessIdResult} viewprefix={'ids-'} />
                </TabPanel>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="default" name="bis-lookup-cancel" onClick={onCancel}>
                    {t('buttons.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
