class ContractPriceRequest {
    constructor(json) {
        if (json != null) {
            this.id = json.clusterId;
            this.contractnumberId = json.contractNumberId;
            this.productId = json.productClusterId;
            this.productBundleClusterId = json.productBundleClusterId;
            this.status = json.status;
        } else {
            this.id = 0;
            this.contractnumberId = 0;
            this.productId = null;
            this.productBundleClusterId = null;
            this.status = 0;
        }
    }
}

export default ContractPriceRequest;
