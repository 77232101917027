import React from 'react';
import LocationEditMachine from './locationeditmachine';
import Machine from '../domain/machine';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Add from '@material-ui/icons/Add';
//
// Params
// - machines
// - filterProducts
// - machineChanged
// - machineAdded
// - machineRemoved
export default function LocationEditMachines(props) {
    const { t } = useTranslation();

    const onClickNewMachine = () => {
        Machine.NewObject()
            .then(function (machine) {
                machine.viewOrder = props.machines.length + 1;
                props.machineAdded(machine);
            })
            .catch(function (err) {
                throw new Error('Failed to create object');
            });
    };

    const machineChanged = (machine) => {
        props.machineChanged(machine);
    };

    const machineRemoved = (machine) => {
        props.machineRemoved(machine);
    };

    const findMachine = (id) => {
        for (let m of props.machines) {
            if (m.id === id) return m;
        }
        return null;
    };

    const sortUp = (id) => {
        let m = findMachine(id);
        if (m != null) {
            if (m.viewOrder === 1) return;
            else {
                let prev = props.machines[m.viewOrder - 2];
                prev.viewOrder++;
                m.viewOrder--;
                props.machineChanged(m);
                props.machineChanged(prev);
            }
        }
    };

    const sortDown = (id) => {
        let m = findMachine(id);
        if (m != null) {
            if (m.viewOrder === props.machines.length) return;
            else {
                let next = props.machines[m.viewOrder];
                next.viewOrder--;
                m.viewOrder++;
                props.machineChanged(m);
                props.machineChanged(next);
            }
        }
    };

    const replaceProduct = async (oldProductId, newProductId, filterId, machineId, replaceType) => {
        await props.replaceProduct(oldProductId, newProductId, filterId, machineId, replaceType);
    };

    if (props.machines.length > 0)
        return (
            <div>
                {props.machines.map((machine) => (
                    <LocationEditMachine
                        key={machine.id}
                        machine={machine}
                        filterProducts={props.filterProducts}
                        productBundles={props.productBundles}
                        machineChanged={machineChanged}
                        machineRemoved={machineRemoved}
                        sortUp={sortUp}
                        sortDown={sortDown}
                        getFilterDiscount={props.getFilterDiscount}
                        setFilterDiscount={props.setFilterDiscount}
                        swapDiscountPrice={props.swapDiscountPrice}
                        setFilterPrice={props.setFilterPrice}
                        getFilterPrice={props.getFilterPrice}
                        expand={props.expandDetails}
                        contractPrices={props.contractPrices}
                        contractId={props.contractId}
                        replaceProduct={replaceProduct}
                        showcaseMode={props.showcaseMode}
                    />
                ))}
                <hr style={{ marginTop: '0' }} />
                <Button
                    variant="contained"
                    color="primary"
                    name="add-new-machine"
                    startIcon={<Add />}
                    onClick={onClickNewMachine}>
                    {t('location.machine')}
                </Button>
            </div>
        );
    else
        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    name="add-new-machine"
                    startIcon={<Add />}
                    onClick={onClickNewMachine}>
                    {t('location.machine')}
                </Button>
            </div>
        );
}
