import React from 'react';
import ActionprogresView from './actionprogres';
import ActionCustomerItem from './actioncustomeritem';
import ActionRow from './actionrow';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { formatCurrency } from '../common/common';

const useStyles = makeStyles((theme) => ({
    header: {
        background: '#28a745',
    },
    headerWarning: {
        background: '#dd6035',
    },
    summaryText: {
        fontSize: '1.4em',
        fontWeight: '500',
    },
    accordionDetails: {
        display: 'block',
    },
    progressView: {
        textAlign: 'center',
    },
}));

const ActionsmonthView = (props) => {
    const classes = useStyles();

    const getCompletion = (actions) => {
        //TODO: remember to check old pending which are not for current month
        let done = 0;
        for (let action of actions) {
            if (action.completed != null) {
                done++;
            }
        }
        return (done / actions.length) * 100;
    };

    const getNumberOfCompleted = (actions) => {
        return actions.filter((a) => a.completed != null).length;
    };

    const allCompleted = (actions) => {
        return getNumberOfCompleted(actions) === actions.length;
    };

    const getHeaderClass = () => {
        return props.warning ? classes.headerWarning : classes.header;
    };

    const getCompleted = () => {
        return props.actions.filter((a) => a.completed != null).sort((a, b) => (a.completed > b.completed ? -1 : 1));
    };

    return (
        <Accordion className={getHeaderClass()} defaultExpanded={props.expanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container>
                    <Grid item xs>
                        <h4>{props.monthname}&nbsp;</h4>
                    </Grid>

                    {props.showProgress && !allCompleted(props.actions) && (
                        <Grid item xs={4} sm={6} className={classes.progressView}>
                            <ActionprogresView
                                completion={getCompletion(props.actions)}
                                salesactual={props.sales.actual}
                                salespotential={props.sales.potential}
                            />
                        </Grid>
                    )}

                    {!props.showProgress && (
                        <Grid item xs={4} sm={6}>
                            <span>Myyntipotentiaali: {formatCurrency(props.sales.potential)}</span>
                        </Grid>
                    )}

                    {allCompleted(props.actions) && (
                        <Grid item xs={4} sm={6}>
                            Myyntiarvio {formatCurrency(props.sales.potential)}
                        </Grid>
                    )}

                    {allCompleted(props.actions) && (
                        <Grid item xs>
                            <CheckCircleOutlineIcon />
                        </Grid>
                    )}

                    {!allCompleted(props.actions) && (
                        <Grid item xs>
                            &nbsp;{getNumberOfCompleted(props.actions)}/{props.actions.length}
                        </Grid>
                    )}
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <Grid container>
                    {props.actions.length > 0 && (
                        <Grid item xs={12}>
                            {props.cusByLocCount.withcustomer.map((item) => (
                                <ActionCustomerItem
                                    key={item.id}
                                    customer={item}
                                    pendingOrders={props.pendingOrders}
                                    newOrderFromLocations={props.newOrderFromLocations}
                                    history={props.history}
                                />
                            ))}
                            {props.cusByLocCount.nocustomer.map((item) => (
                                <ActionRow
                                    key={item.id}
                                    type="info"
                                    action={item}
                                    showCustomer={true}
                                    newOrderFromLocations={props.newOrderFromLocations}
                                    history={props.history}
                                />
                            ))}
                        </Grid>
                    )}
                    {props.actions.length === 0 && (
                        <Grid item xs={12}>
                            <Alert severity="info">Ei muistutteita tälle kuukaudelle</Alert>
                        </Grid>
                    )}
                    {getNumberOfCompleted(props.actions) > 0 && (
                        <Grid item xs={12}>
                            <br />
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <span className={classes.summaryText}>
                                        Suoritetut ({getNumberOfCompleted(props.actions)})
                                    </span>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    {getCompleted().map((a) => (
                                        <ActionRow
                                            key={a.id}
                                            type="info"
                                            action={a}
                                            showCustomer={false}
                                            newOrderFromLocations={props.newOrderFromLocations}
                                            history={props.history}
                                        />
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default ActionsmonthView;
