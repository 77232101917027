import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { CalculateMaterialSpeed } from '../common/machinematerialspeed';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { markAsLegacyProduct } from '../modules/productdata';
import FilterProductWarning from './filterproductwarning';
import FilterProductChangeDialog from './filterproductchangedialog';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
    },
    warning: {
        color: 'orange',
        verticalAlign: 'middle',
        display: 'inline-flex',
        fontSize: '0.9em',
    },
    warningLegacy: {
        color: 'orange',
        verticalAlign: 'middle',
        display: 'inline-flex',
        fontSize: '1.25em',
    },
    headingContainer: {
        paddingRight: '16px',
    },
    withPadding: {
        paddingBottom: '1em',
    },
    bundleDetails: {
        fontSize: '0.75em',
    },
}));

export default function LocationViewMachineInfo(props) {
    const machines = props.machines;
    const containsDrafts = props.containsDrafts;
    const classes = useStyles();
    const viewOnly = props.viewOnly;
    const customerUi = props.customerUi;
    const dispatch = useDispatch();
    const [myProducts, setMyProducts] = React.useState([]);
    const [showReplaceFilterSearch, setShowReplaceFilterSearch] = React.useState(false);
    const [toBeReplacedFilter, setToBeReplacedFilter] = React.useState(null);
    const [productRequestHint, setProductRequestHint] = React.useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (props.products && props.products.length > 0) {
            const myProd = [];
            for (let m of machines) {
                for (let f of m.filters) {
                    if (f.product) {
                        const prod = props.products.find((p) => p.id === f.product.id);
                        if (prod && !myProd.find((p) => p.id === prod.id)) {
                            myProd.push(prod);
                        }
                    }
                }
            }
            setMyProducts(myProd);
        }
    }, [props.products, machines]);

    const onAddFilterToCart = (filterId, machineId) => {
        props.addFilterToCart(filterId, machineId);
    };

    const onAddMachineToCart = (machineId) => {
        props.addMachineToCart(machineId);
    };

    const getProductCount = () => {
        if (machines != null && machines.length > 0) {
            let totalcount = 0;
            machines.map((m) => m.filters.map((f) => (totalcount += f.count)));
            return totalcount;
        }
        return 0;
    };

    const isMarkedToBeReplaced = (product) => {
        const prod = myProducts.find((p) => p.id === product.id);
        if (prod) {
            return prod.toBeReplaced;
        }
        return false;
    };

    const markToBeReplaced = (product) => {
        markAsLegacyProduct(product)(dispatch);
    };

    const showReplaceProduct = (machineId, filter) => {
        setToBeReplacedFilter({ machineId, ...filter });
        setProductRequestHint(filter.product ? filter.product.productName : filter.bundle.name);
        setShowReplaceFilterSearch(true);
    };

    const replaceFilterProductWithNewProduct = async (newProductId, replaceType) => {
        await props.replaceProduct(
            toBeReplacedFilter.product ? toBeReplacedFilter.product.id : toBeReplacedFilter.bundle.id,
            newProductId,
            toBeReplacedFilter.id,
            toBeReplacedFilter.machineId,
            replaceType
        );
        setToBeReplacedFilter(null);
        setProductRequestHint('');
        setShowReplaceFilterSearch(false);
    };

    return (
        <Grid item>
            <h5>{t('location.machineWithProductCount', { productCount: getProductCount() })}</h5>
            {machines &&
                machines.map((m) => (
                    <div key={m.id} style={{ paddingBottom: '10px' }}>
                        <Grid container justifyContent={'space-between'} className={classes.headingContainer}>
                            <Grid item>
                                <h5>
                                    {m.name}&nbsp;
                                    {m.containsRemovedProducts() ? (
                                        <Tooltip title={t('location.hints.containsExpiredProducts')}>
                                            <WarningIcon className={classes.warning} name="warningIcon" />
                                        </Tooltip>
                                    ) : null}
                                </h5>
                            </Grid>
                            <Grid item>
                                <Tooltip title={t('location.hints.addMachineToCart')}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        name="btn-add-machine-to-cart"
                                        disabled={containsDrafts}
                                        onClick={() => onAddMachineToCart(m.id)}>
                                        <i className="fas fa-cart-plus" />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        {t('location.airVolume')}: <strong>{m.airVolume} m&#179;/s</strong>,{' '}
                        {t('location.materialSpeed')}:{' '}
                        <strong>{CalculateMaterialSpeed(m.airVolume, m.filters)} m/s</strong>, {t('location.position')}:{' '}
                        <strong>{m.machineLocation}</strong>, {t('location.serviceArea')}:{' '}
                        <strong>{m.machineArea}</strong>
                        <br />
                        <Table className={classes.table} size="small">
                            <colgroup>
                                <col width="20%" />
                                <col width="60%" />
                                <col width="10%" />
                                <col width="10%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('product.code')}</TableCell>
                                    <TableCell>{t('product.name')}</TableCell>
                                    <TableCell>{t('pcs')}</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {m.filters.map((f) => (
                                    <TableRow key={f.id}>
                                        <TableCell>
                                            {!customerUi && (
                                                <FilterProductWarning
                                                    product={f.product}
                                                    bundle={f.bundle}
                                                    locContractPriceInUse={props.contractPriceInUse}
                                                    contractPriceInUse={f.contractPricesInUse}
                                                />
                                            )}
                                            <span>{f.product ? f.product.productCode : f.bundle.code}</span>
                                        </TableCell>
                                        <TableCell>
                                            {f.product ? f.product.productName : f.bundle.name}
                                            {f.bundle && (
                                                <div>
                                                    {f.bundle.products.map((p) => (
                                                        <div className={classes.bundleDetails}>
                                                            {p.productCount} x {p.product.productName}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {!customerUi &&
                                                f.product &&
                                                f.originalProductName &&
                                                f.originalProductName.length > 0 && (
                                                    <span>({f.originalProductName})</span>
                                                )}
                                        </TableCell>
                                        <TableCell>{f.count}</TableCell>
                                        <TableCell align="right">
                                            {!customerUi && !viewOnly && !!f.expired() && (
                                                <Tooltip title={t('location.hints.replaceProductBtn')}>
                                                    <div className={classes.withPadding}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            name="btn-replace-in-all-machines"
                                                            onClick={() => showReplaceProduct(m.id, f)}
                                                            startIcon={<SwapHorizIcon />}>
                                                            {t('buttons.switch')}
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            )}
                                            {!customerUi &&
                                                f.product &&
                                                !viewOnly &&
                                                !f.expired() &&
                                                f.product.productCode !== '0' && (
                                                    <Tooltip
                                                        title={
                                                            isMarkedToBeReplaced(f.product)
                                                                ? t('location.hints.productMarkedAsReplaced')
                                                                : t('location.hints.productExpiredMarkToBeReplaced')
                                                        }>
                                                        <div className={classes.withPadding}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                name="btn-mark-to-be-replaced"
                                                                onClick={() => markToBeReplaced(f.product)}
                                                                startIcon={<RefreshIcon />}
                                                                disabled={isMarkedToBeReplaced(f.product)}>
                                                                {t('buttons.markToBeRenewed')}
                                                            </Button>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            {!viewOnly && (
                                                <Tooltip title={t('location.hints.addProductToCart')}>
                                                    <span>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            name="btn-add-filter-to-cart"
                                                            disabled={containsDrafts}
                                                            onClick={() => onAddFilterToCart(f.id, m.id)}>
                                                            <i className="fas fa-cart-plus" />
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                ))}
            <div>
                {showReplaceFilterSearch && (
                    <FilterProductChangeDialog
                        selected={replaceFilterProductWithNewProduct}
                        show={showReplaceFilterSearch}
                        allowNewProd={true}
                        productRequestHint={productRequestHint}
                    />
                )}
            </div>
        </Grid>
    );
}
