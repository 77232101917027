import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    oldinfo: {
        textDecoration: 'line-through',
    },
    padding: {
        paddingTop: '1em',
        paddingBottom: '1em',
    },
}));

export default function VatSelectorDialog(props) {
    const classes = useStyles();
    const open = props.open;
    const confirm = props.confirm;
    const cancel = props.cancel;
    const vatInfos = props.vatInfos;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const [vatHandling, setVatHandling] = React.useState('KOMY');
    const [vatRates, setVatRates] = React.useState([]);
    const [vatRate, setVatRate] = React.useState('');
    const [okToSave, setOkToSave] = React.useState(false);

    useEffect(() => {
        if (vatInfos && vatInfos.length > 0) {
            const vat24 = vatInfos.find((i) => i.vatCode === 'KOMY');
            if (vat24) {
                setVatRates(vat24.allowedVatPercentages);
            }
        }
    }, [vatInfos]);

    const vatHandlingChange = (evt) => {
        const vatCode = evt.target.value;
        setVatHandling(vatCode);
        const vatItem = vatInfos.find((i) => i.vatCode === vatCode);
        if (vatItem) {
            setVatRates(vatItem.allowedVatPercentages);
            setOkToSave(false);
            setVatRate('');
        }
    };

    const vatRateChange = (evt) => {
        const rate = evt.target.value;
        const vatItem = vatInfos.find((i) => i.vatCode === vatHandling);
        if (vatItem && vatItem.allowedVatPercentages.includes(rate)) {
            setVatRate(rate);
            setOkToSave(true);
        }
    };

    if (open)
        return (
            <Dialog
                onClose={cancel}
                aria-labelledby="simple-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                fullScreen={fullScreen}>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12} className={classes.padding}>
                            {t('invoice.setVatRateForRows')}
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel id="vat-handling-label">{t('invoice.vatHandling')}</InputLabel>

                            <Select
                                required
                                labelId="vat-handling-label"
                                id="vatHandling"
                                name="vatHandling"
                                value={vatHandling}
                                onChange={vatHandlingChange}>
                                {vatInfos.map((opt) => (
                                    <MenuItem key={opt.vatCode} value={opt.vatCode}>
                                        {t('invoice.vatHandlingOpt.' + opt.vatId)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel id="vat-rates-label">{t('invoice.vatPercentage')}</InputLabel>
                            <Select
                                required
                                labelId="vat-rates-label"
                                id="vatRates"
                                name="vatRates"
                                value={vatRate}
                                onChange={vatRateChange}>
                                {vatRates.map((opt) => (
                                    <MenuItem key={opt} value={opt}>
                                        {opt} %
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        name="btn-modal-confirm"
                        onClick={() => {
                            confirm(vatHandling, vatRate);
                        }}
                        disabled={!okToSave}>
                        {t('buttons.update')}
                    </Button>
                    &nbsp;&nbsp;
                    <Button variant="contained" color="default" name="btn-modal-cancel" onClick={cancel}>
                        {t('buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    return null;
}

VatSelectorDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    confirm: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    vatInfos: PropTypes.array.isRequired,
};
