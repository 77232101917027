import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';
import ParameterValue from '../domain/parametervalue';
export const PARAMETERDATA_LOADED = 'parameterdata/loaded';
export const PARAMETER_ADDED = 'parameterdata/added';
export const PARAMETER_CHANGED = 'parameterdata/changed';
const _ = require('lodash');

const initialState = {
    parameters: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PARAMETERDATA_LOADED:
            const parameters = {};
            const keys = Object.keys(action.parameters);
            for (const k of keys) {
                parameters[k] = [];
                for (const item of action.parameters[k]) {
                    parameters[k].push(new ParameterValue(item));
                }
                parameters[k] = _.sortBy(parameters[k], ['value']);
            }
            return {
                ...state,
                parameters: parameters,
            };
        case PARAMETER_ADDED: {
            const keys = Object.keys(state.parameters);
            const newParams = _.clone(state.parameters);
            if (keys.includes(action.parameter.category)) {
                newParams[action.parameter.category].push(new ParameterValue(action.parameter));
                newParams[action.parameter.category] = _.sortBy(newParams[action.parameter.category], ['value']);
            } else {
                throw new Error('error');
            }
            return {
                ...state,
                parameters: newParams,
            };
        }
        case PARAMETER_CHANGED: {
            const keys = Object.keys(state.parameters);
            const newParams = _.clone(state.parameters);
            if (keys.includes(action.parameter.category)) {
                newParams[action.parameter.category] = _.filter(
                    newParams[action.parameter.category],
                    (p) => p.id !== action.parameter.id
                );
                newParams[action.parameter.category].push(new ParameterValue(action.parameter));
                newParams[action.parameter.category] = _.sortBy(newParams[action.parameter.category], ['value']);
            } else {
                throw new Error('error');
            }
            return {
                ...state,
                parameters: newParams,
            };
        }
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const getParameters = () => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get('api/parameters/');
            dispatch({
                type: PARAMETERDATA_LOADED,
                parameters: resp.data,
            });
        } catch (err) {
            console.log(err);
        }
    };
};

export const newParameter = (category, value) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.post('api/parameters/', { category: category, value: value });
            dispatch({
                type: PARAMETER_ADDED,
                parameter: resp.data,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const updateParameter = (parameter) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.put('api/parameters/' + parameter.id, parameter);
            dispatch({
                type: PARAMETER_CHANGED,
                parameter: resp.data,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};
