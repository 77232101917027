import React from 'react';
import { makeStyles, TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    searchField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
        marginTop: '10px',
    },
}));

export default function SearchInput(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <TextField
            className={props.class ? props.class : classes.searchField}
            placeholder={props.placeholder ? props.placeholder : t('searchHint')}
            value={props.searchInput}
            onChange={props.onChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            variant="standard"
        />
    );
}

SearchInput.propTypes = {
    searchInput: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    class: PropTypes.object,
};
