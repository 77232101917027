import React, { useState } from 'react';
import imgLogo from '../img/viti-crm-w.png';
import VadoLogoLogin from '../main/vadologologin';
import backgroundImg from '../img/building.jpg';
import TextField from '@material-ui/core/TextField';
import { verifyPasswordStrength } from '../common/common';
import { Alert } from '@material-ui/lab';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import SpinnerButton from '../common/spinnerbutton';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '95%',
    },
    withTopMargin: {
        marginTop: theme.spacing(),
    },
    button: {
        margin: theme.spacing(),
        width: '100%',
    },
    header: {
        backgroundImage: `url(${backgroundImg})`,
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //filter: "blur(8px)",
        //webkitFilter: "blur(8px)",
    },
    containerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
}));

export default function ChangePassword(props) {
    const classes = useStyles();
    const [newpassword, setNewpassword] = useState('');
    const [oldpassword, setOldpassword] = useState('');
    const [newpasswordrepeat, setNewpasswordrepeat] = useState('');
    const { t } = useTranslation();
    const isCustomerSite = process.env.REACT_APP_TARGET === 'customerui';

    const inputChange = (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;

        switch (name) {
            case 'oldpassword':
                setOldpassword(value);
                break;
            case 'newpassword':
                setNewpassword(value);
                break;
            case 'newpasswordrepeat':
                setNewpasswordrepeat(value);
                break;
            default:
        }
    };

    const onClick = () => {
        if (newpassword !== newpasswordrepeat) {
            return;
        }
        if (newpassword.length < 8) {
            return;
        }
        props.forcedChangePassword(props.username, oldpassword, newpassword);
    };

    let errorText1 = '';
    let errorText2 = '';
    if (!verifyPasswordStrength(newpassword)) {
        errorText1 = t('general.passwordDoesNotMeetCriterias');
    }
    if (newpassword !== newpasswordrepeat) {
        errorText2 = t('general.passwordsDoNotMatch');
    }
    return (
        <div className={classes.header}>
            <div className={classes.containerStyle}>
                <Grid container style={{ marginTop: '50px' }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        {isCustomerSite && (
                            <div style={{ paddingBottom: '10px' }}>
                                <VadoLogoLogin />
                            </div>
                        )}
                        {!isCustomerSite && <img src={imgLogo} alt="logo" style={{ paddingBottom: '10px' }} />}
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', margin: '10px' }}>
                        <Paper
                            style={{
                                margin: 'auto',
                                textAlign: 'center',
                                backgroundColor: 'white',
                                maxWidth: '500px',
                                borderRadius: '10px',
                                padding: '1em',
                            }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <h4>{t('general.changePassword')}</h4>
                                    {props.username}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="oldpassword"
                                        name="oldpassword"
                                        label={t('general.oldPassword')}
                                        className={classes.textField}
                                        margin="normal"
                                        type="password"
                                        autoComplete=""
                                        onChange={inputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="newpassword"
                                        name="newpassword"
                                        label={t('general.newPassword')}
                                        className={classes.textField}
                                        type="password"
                                        autoComplete=""
                                        margin="normal"
                                        onChange={inputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="newpasswordrepeat"
                                        name="newpasswordrepeat"
                                        label={t('general.newPassword')}
                                        className={classes.textField}
                                        type="password"
                                        autoComplete=""
                                        margin="normal"
                                        onChange={inputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <br />
                                    {errorText1.length > 0 && <Alert severity="warning">{errorText1}</Alert>}
                                    {errorText2.length > 0 && <Alert severity="warning">{errorText2}</Alert>}
                                    {props.passwordChangeFailed && (
                                        <Alert severity="warning">
                                            {t('general.passwordChangeFailedCheckPasswords')}
                                        </Alert>
                                    )}
                                </Grid>
                                <Grid item xs={12} className={classes.withTopMargin}>
                                    <SpinnerButton
                                        inProgress={props.passwordChangeInProgress}
                                        text={t('general.changePassword')}
                                        fullWidth={true}
                                        onClick={onClick}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <br />
                        <br />
                        {isCustomerSite && (
                            <div style={{ color: 'white' }}>
                                VADO myyntitilaukset <br />
                                Powered by MyynninTuki&trade; &copy;&nbsp;2022 Vitilumi Oy
                            </div>
                        )}
                        {!isCustomerSite && (
                            <div style={{ color: 'white' }}>MyynninTuki&trade; &copy;&nbsp;2022 Vitilumi Oy</div>
                        )}
                        <br />
                        <br />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

ChangePassword.propTypes = {
    passwordChangeInProgress: PropTypes.bool,
    forcedChangePassword: PropTypes.func.isRequired,
    passwordChangeFailed: PropTypes.bool.isRequired,
    username: PropTypes.string,
};
