import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { DialogActions, DialogContent } from '@material-ui/core';
import Spinner from '../common/spinner';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const OrderSendStatusDialog = ({ open, orderSendDone, orderSendFailed, okBtn }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogContent dividers>
                {!orderSendDone && <Spinner padding="20px" title={t('general.sending')} />}
                {orderSendDone && (
                    <div>
                        {orderSendFailed && <div>{t('order.orderSendFailedRetry')}</div>}
                        {!orderSendFailed && <div>{t('order.orderSendSuccess')}</div>}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" id="order-send-modal-ok" onClick={okBtn}>
                    {t('buttons.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default OrderSendStatusDialog;
