import { BisLookup, BisDetails } from '../domain/bislookupitem';

//API description https://avoindata.prh.fi/ytj.html#
const byTermUrl = 'https://avoindata.prh.fi/bis/v1?totalResults=true&maxResults=5&resultsFrom=0&name=';
const byIdUrl = 'https://avoindata.prh.fi/opendata/bis/v1/';

export function SearchByTerm(term) {
    let promise = new Promise((resolve, reject) => {
        fetch(byTermUrl + encodeURIComponent(term))
            .then((resp) => resp.json())
            .then((data) => {
                resolve(new BisLookup(data));
            })
            .catch(function (e) {
                console.log('error: ' + e);
                reject();
            });
    });
    return promise;
}

export function SearchByBusinessId(id) {
    let promise = new Promise((resolve, reject) => {
        fetch(byIdUrl + id)
            .then((resp) => resp.json())
            .then((data) => {
                let result = null;
                if (data.results != null && data.results.length > 0) {
                    result = new BisDetails(data.results[0]);
                }
                resolve(result);
            })
            .catch(function (e) {
                console.log('error: ' + e);
                reject();
            });
    });
    return promise;
}

export function GetBisDetails(url) {
    //*fix* url
    url = url.replace('http:', 'https:');
    let promise = new Promise((resolve, reject) => {
        fetch(url)
            .then((resp) => resp.json())
            .then((data) => {
                let result = null;
                if (data.results != null && data.results.length > 0) {
                    result = new BisDetails(data.results[0]);
                }
                resolve(result);
            })
            .catch(function (e) {
                console.log('error: ' + e);
                reject();
            });
    });
    return promise;
}
