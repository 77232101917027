import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import resources from './translations';
import translationFI from './00translationFI.json';
import translationEN from './01translationEN.json';
import translationET from './02translationET.json';

const availableLanguages = ['fi', 'en', 'et'];

// the translations
const resources = {
    fi: {
        translation: translationFI,
    },
    en: {
        translation: translationEN,
    },
    et: {
        translation: translationET,
    },
};

function writeDefaultLangCookie() {
    document.cookie = 'lang=fi;max-age=31536000;path=/';
}

function refreshCookie(lang) {
    document.cookie = `lang=${lang};max-age=31536000;path=/`;
}

function tryReadLang() {
    if (document.cookie && document.cookie.length > 0) {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            const parts = cookie.split('=');
            if (parts[0].trim() === 'lang' && availableLanguages.includes(parts[1].trim())) {
                refreshCookie(parts[1].trim());
                return parts[1].trim();
            }
        }
    }
    writeDefaultLangCookie();
    return 'fi';
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        lng: tryReadLang(),
        fallbackLng: 'fi',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources,
        returnEmptyString: false,
    });

export default i18n;
