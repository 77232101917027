import i18next from 'i18next';

function translate(key) {
    return i18next.t(key) ? i18next.t(key) : key;
}

class ParameterValue {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.category = json.category;
            this.categoryText = translate(`parameterCategories.${this.category}`);
            this.value = json.value;
            this.storageId = json.storageId;
        } else {
            throw new Error('error');
        }
    }
}

export default ParameterValue;
