import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { verifyPasswordStrength, pseudoRandomPasswordGenerate } from '../common/common';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Grid from '@material-ui/core/Grid';
import { resetPassword } from '../modules/userdataactions';
import EmailSender from '../modules/emailsender';
import CONFIG from '../config';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    textField: {
        width: '95%',
    },
    button: {
        margin: theme.spacing(1),
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

export default function ResetPasswordDialog(props) {
    const user = props.user;
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [newPassword, setNewPassword] = useState('');
    const [saving, setSaving] = useState(false);
    const [reseted, setReseted] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setNewPassword('');
        setReseted(false);
        setEmailSent(false);
        setSaving(false);
    }, [props.open]);

    const cancel = () => {
        props.cancel();
    };
    const save = () => {
        setSaving(true);
        dispatch(resetPassword(user, newPassword))
            .then(() => {
                setSaving(false);
                setReseted(true);
            })
            .catch((err) => {
                setSaving(false);
            });
    };
    const handleChange = (event) => {
        setNewPassword(event.target.value);
    };
    const generatePassword = () => {
        setNewPassword(pseudoRandomPasswordGenerate(1, 6, 1));
    };
    const closeReseted = () => {
        props.passwordReseted();
    };
    const sendEmail = async () => {
        setSaving(true);
        await EmailSender.send(
            'passwordreset',
            { PASSWORD: newPassword, URL: user.isCustomerUser ? CONFIG.frontendUrlCustomer : CONFIG.frontendUrl },
            user.username,
            'Salasanan nollaus'
        );
        setEmailSent(true);
        setSaving(false);
    };

    return (
        <Dialog
            onClose={cancel}
            aria-labelledby="simple-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogContent dividers>
                {reseted && (
                    <Grid container>
                        <Grid xs={12} item>
                            <em>{t('admin.passwordReseted')}:</em>&nbsp;<strong>{newPassword}</strong>
                            <br />
                        </Grid>
                        <Grid xs={12} item>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                disabled={emailSent || saving}
                                name="btn-send-email"
                                onClick={sendEmail}>
                                {!saving && <i className="fas fa-envelope"></i>}
                                {saving && <i className="fas fa-spinner fa-spin"></i>}
                                &nbsp;{t('admin.sendEmail')}
                            </Button>
                            {emailSent && <em>{t('admin.emailSent')}</em>}
                        </Grid>
                    </Grid>
                )}
                {!reseted && (
                    <FormControl className={classes.textField}>
                        <InputLabel htmlFor="standard-adornment-password">{t('login.password')}</InputLabel>
                        <Input
                            className={classes.textField}
                            id="standard-adornment-password"
                            name="password-input"
                            type={'text'}
                            value={newPassword}
                            error={!verifyPasswordStrength(newPassword)}
                            onChange={handleChange}
                            disabled={saving}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="generate password" onClick={generatePassword}>
                                        <AutorenewIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions>
                {!reseted && (
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!verifyPasswordStrength(newPassword) || saving}
                        className={classes.button}
                        name="btn-execute-change"
                        onClick={save}>
                        {saving && <i className="fas fa-spinner fa-spin"></i>}
                        {!saving && <i className="fas fa-save"></i>}
                        &nbsp;{t('buttons.save')}
                    </Button>
                )}
                {!reseted && (
                    <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        disabled={saving}
                        name="btn-cancel"
                        onClick={cancel}>
                        {t('buttons.cancel')}
                    </Button>
                )}
                {reseted && (
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={saving}
                        name="btn-close-reseted"
                        onClick={closeReseted}>
                        {t('buttons.close')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
