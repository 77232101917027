import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { getSalesReportExcel } from '../modules/reportdata';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import fiLocale from 'date-fns/locale/fi';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

const delay = async (ms) => {
    await new Promise((r) => setTimeout(r, ms));
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    btn: {
        marginTop: theme.spacing(2),
    },
}));

const SalesReportExcel = (props) => {
    const classes = useStyles();
    const [blobUrl, setBlobUrl] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [reff] = React.useState(React.createRef());
    const isActive = props.active;
    const { t } = useTranslation();

    useEffect(() => {
        if (isActive && !startDate && !endDate) {
            var daysInMonth = moment().subtract(1, 'month').daysInMonth();
            setStartDate(moment().subtract(1, 'month').date(1).utc());
            setEndDate(moment().subtract(1, 'month').date(daysInMonth).utc());
        }
    }, [isActive, endDate, startDate]);

    const loadSalesReport = async (e) => {
        if (blobUrl) {
            e.stopPropagation();
            await delay(100);
            setBlobUrl('');
            setLoading(false);
            return;
        }
        setLoading(true);
        const xlsx = await getSalesReportExcel(startDate.format(), endDate.format());

        const buff = Buffer.from(xlsx, 'base64');
        const blob = new Blob([buff.buffer], {
            type: 'application/vnd.openxmlformats',
        });
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);

        setTimeout(() => {
            reff.current.click();
        }, 10);
    };

    const startDateChange = (date) => {
        setStartDate(moment(date).utc());
    };

    const endDateChange = (date) => {
        setEndDate(moment(date).utc());
    };

    if (!isActive) return null;

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                    <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd.MM.yyyy"
                            margin="normal"
                            id="date-picker-inline-start"
                            label={t('reports.startDate')}
                            value={startDate}
                            onChange={startDateChange}
                            invalidDateMessage={t('general.invalidDateMsg')}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd.MM.yyyy"
                            margin="normal"
                            id="date-picker-inline-end"
                            label={t('reports.endDate')}
                            value={endDate}
                            onChange={endDateChange}
                            invalidDateMessage={t('general.invalidDateMsg')}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
                <Button
                    ref={reff}
                    variant="contained"
                    color="primary"
                    disabled={loading || !startDate || !endDate}
                    name="btn-load-sales-report"
                    onClick={loadSalesReport}
                    href={blobUrl.length > 0 ? blobUrl : null}
                    download={
                        blobUrl.length > 0
                            ? `sales_${startDate.format('YYYY-MM-DD')}-${endDate.format('YYYY-MM-DD')}.xlsx`
                            : null
                    }
                    startIcon={loading ? <CircularProgress color={'secondary'} size={'1rem'} /> : null}>
                    {t('location.buttons.download')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default SalesReportExcel;
