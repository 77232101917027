import React from 'react';
import AddToCartIcon from '@material-ui/icons/AddShoppingCart';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LocationTreeItem from './locationtreeitem';
import LocationDetails from './locationdetails';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    customerRoot: {
        marginBottom: '15px',
    },
    fab: {
        margin: theme.spacing(1),
    },
    title: {
        fontWeigth: 'bold',
        fontSize: 14,
    },
    titleWarning: {
        background: 'rgba(125, 0, 0, 0.5)',
    },
    card: {
        minWidth: 275,
        background: theme.palette.primary.main,
        margin: '5px',
    },
    cardExpanded: {
        minWidth: 275,
        width: '100%',
        background: theme.palette.primary.main,
        margin: '5px',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
    },
    expanderHeader: {},
    expander: {},
    locationcontainer: {
        marginTop: '10px',
    },
    btn: {
        marginBottom: '5px',
    },
}));

export default function CustomerHomeLocations(props) {
    const [expandedId, setExpandedId] = React.useState(null);
    const { t } = useTranslation();
    const classes = useStyles();
    const { customers, locationsById } = { ...props };

    const onClickAddToCart = (id) => {
        let idList = [id];
        props.addToCart(idList);
    };

    const getAllLocationIdsFromFromFolder = (customerId, folderId) => {
        //scoop up all locations in this folder and sub folders
        const customer = customers.find((c) => c.id === customerId);
        let targetFol = findFolderFromTree(customer.treeData, folderId);
        let locIds = [];
        locIds = collectLocIds(targetFol, locIds);
        return locIds;
    };

    const onClickAddToCartMulti = (customerId, folderId) => {
        let locIds = getAllLocationIdsFromFromFolder(customerId, folderId);
        props.addToCart(locIds);
    };

    const findFolderFromTree = (node, folId) => {
        let targetFol = null;
        if (node.id === folId) targetFol = node;
        if (targetFol == null && node.childFolders.length > 0) {
            for (let i = 0; i < node.childFolders.length; i++) {
                let retFol = findFolderFromTree(node.childFolders[i], folId);
                if (retFol != null) {
                    targetFol = retFol;
                    break;
                }
            }
        }
        return targetFol;
    };

    const collectLocIds = (node, collection) => {
        if (node.locations != null && node.locations.length > 0) {
            for (let loc of node.locations) {
                collection.push(loc.id);
            }
        }
        if (node.childFolders != null && node.childFolders.length > 0) {
            for (let cf of node.childFolders) {
                collectLocIds(cf, collection);
            }
        }
        return collection;
    };

    const isOrderAlreadyInCart = (locId) => {
        return false;
    };

    const isOrderAlreadyInCartFromFolder = (folderId, deepScan = true) => {
        return false;
    };

    const onExpandCard = (id) => {
        setExpandedId(id);
    };

    const onCloseCard = (id) => {
        setExpandedId(null);
    };

    const getCardClass = (id) => {
        if (expandedId === id) return classes.cardExpanded;
        return classes.card;
    };

    const addMachineToCart = (locId, mId) => {
        props.addMachineToCart(locId, mId);
    };

    const addFilterToCart = (locId, mId, fId) => {
        props.addFilterToCart(locId, mId, fId);
    };

    return (
        <Grid container>
            {customers.map((c) => (
                <Grid container item key={c.id} className={classes.customerRoot}>
                    <Grid item xs={12}>
                        <h3>{c.name}</h3>
                    </Grid>

                    {!!c.treeData && (
                        <Grid container>
                            {c.treeData.childFolders.map((item) => (
                                <Grid item key={item.id}>
                                    <Card key={item.id} className={getCardClass(item.id)}>
                                        <CardContent>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                {item.name}
                                            </Typography>
                                            <CardActions>
                                                <Fab
                                                    color="primary"
                                                    className={classes.fab}
                                                    disabled={isOrderAlreadyInCartFromFolder(item.id)}
                                                    onClick={() => onClickAddToCartMulti(c.id, item.id)}>
                                                    <AddToCartIcon />
                                                </Fab>
                                            </CardActions>
                                            {expandedId !== item.id && (
                                                <Button
                                                    variant="contained"
                                                    name={'show-folder-' + item.id}
                                                    onClick={() => onExpandCard(item.id)}>
                                                    {t('buttons.show')}
                                                </Button>
                                            )}
                                            {expandedId === item.id && (
                                                <Button
                                                    className={classes.btn}
                                                    variant="contained"
                                                    onClick={() => onCloseCard(item.id)}>
                                                    {t('buttons.hide')}
                                                </Button>
                                            )}
                                            {expandedId === item.id &&
                                                item.childFolders != null &&
                                                item.childFolders.length > 0 &&
                                                item.childFolders.map((child) => (
                                                    <LocationTreeItem
                                                        addToCart={props.addToCart}
                                                        addToCartMulti={(treeId) => onClickAddToCartMulti(c.id, treeId)}
                                                        isInCart={isOrderAlreadyInCart}
                                                        isInCartFromFolder={isOrderAlreadyInCartFromFolder}
                                                        key={child.id}
                                                        data={child}
                                                        getLocationFull={props.getLocationFull}
                                                        locationsById={locationsById}
                                                        addMachineToCart={addMachineToCart}
                                                        addFilterToCart={addFilterToCart}
                                                    />
                                                ))}

                                            {expandedId === item.id &&
                                                item.locations != null &&
                                                item.locations.length > 0 && (
                                                    <div className={classes.locationcontainer}>
                                                        {item.locations.map((loc) => (
                                                            <LocationDetails
                                                                key={loc.id}
                                                                location={loc}
                                                                isInCart={isOrderAlreadyInCart}
                                                                addToCart={onClickAddToCart}
                                                                getLocationFull={props.getLocationFull}
                                                                locationFull={locationsById[loc.id]}
                                                                addMachineToCart={addMachineToCart}
                                                                addFilterToCart={addFilterToCart}
                                                            />
                                                        ))}
                                                    </div>
                                                )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                            {c.treeData.locations != null && c.treeData.locations.length > 0 && (
                                <Card className={getCardClass(c.treeData.id)}>
                                    <CardContent>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            - EI KANSIOTA -
                                        </Typography>
                                        <CardActions>
                                            <Fab
                                                color="primary"
                                                className={classes.fab}
                                                disabled={isOrderAlreadyInCartFromFolder(c.treeData.id, false)}
                                                onClick={() => onClickAddToCartMulti(c.id, c.treeData.id)}>
                                                <AddToCartIcon />
                                            </Fab>
                                        </CardActions>
                                        {expandedId !== c.treeData.id && (
                                            <Button variant="contained" onClick={() => onExpandCard(c.treeData.id)}>
                                                {t('buttons.show')}
                                            </Button>
                                        )}
                                        {expandedId === c.treeData.id && (
                                            <Button
                                                className={classes.btn}
                                                variant="contained"
                                                onClick={() => onCloseCard(c.treeData.id)}>
                                                {t('buttons.hide')}
                                            </Button>
                                        )}
                                        {expandedId === c.treeData.id &&
                                            c.treeData.locations != null &&
                                            c.treeData.locations.length > 0 && (
                                                <div className={classes.locationcontainer}>
                                                    {c.treeData.locations.map((loc) => (
                                                        <LocationDetails
                                                            key={loc.id}
                                                            location={loc}
                                                            isInCart={() => isOrderAlreadyInCart(loc.id)}
                                                            addToCart={onClickAddToCart}
                                                            getLocationFull={props.getLocationFull}
                                                            locationFull={locationsById[loc.id]}
                                                            addMachineToCart={addMachineToCart}
                                                            addFilterToCart={addFilterToCart}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                    </CardContent>
                                </Card>
                            )}
                        </Grid>
                    )}
                    {!c.treeData && <div></div>}
                </Grid>
            ))}
        </Grid>
    );
}
