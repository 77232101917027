import React from 'react';
import Grid from '@material-ui/core/Grid';

export default function InvoiceAttribute(props) {
    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <span style={{ fontWeight: 'bold' }}>{props.name}</span>
            </Grid>
            <Grid item xs={12} sm={6}>
                <span>{props.value}</span>
            </Grid>
        </React.Fragment>
    );
}
