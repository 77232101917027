import React from 'react';

class CustomerItem extends React.Component {
    constructor(props) {
        super(props);
        this.onClickItem = this.onClickItem.bind(this);
    }

    onClickItem() {
        this.props.history.push('/customers/' + this.props.customerDesc.id);
    }

    render() {
        let customerDesc = this.props.customerDesc;
        return (
            <div>
                <div className="location-item" onClick={this.onClickItem}>
                    {customerDesc.name}
                </div>
            </div>
        );
    }
}

export default CustomerItem;
