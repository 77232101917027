import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ActionAllowed } from '../rbac/rbacutil';
import { rbacactions } from '../rbac/rbacrules';
import {
    getContractPrices,
    saveContractPrice,
    deleteContractPrice,
    uploadContractPriceUpdateExcel,
    doContractPriceUpdates,
} from '../modules/productdata';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContractPrice from '../domain/contractprice';
import ContractPricesProduct from './contractpricesproduct';
import { filter, sortBy, some } from 'lodash';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import FilterProductSelectDialog from '../location/filterproductselectdialog';
import DeleteConfirmDialog from '../common/deleteconfirmdialog';
import ConfirmationDialog from '../common/confirmationdialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { getProductListXlsxForContract } from '../modules/productdata';
import { CircularProgressWithLabel } from '../common/circularprogresslabel';
import { removeContractPricePricingRequest } from '../modules/contractpricerequestdata';
const dayjs = require('dayjs');

const useStyles = makeStyles((theme) => ({
    accordion: {
        marginTop: '0.5em',
    },
    addbutton: {
        marginBottom: '1em',
        marginTop: '0.5em',
    },
    uploadBtn: {
        marginTop: '7px',
    },
}));

export default function ContractPrices(props) {
    const contractPrices = useSelector((state) => state.productdata.contractPricesByContractId);
    const parameters = useSelector((state) => state.parameterdata.parameters);
    const user = useSelector((state) => state.authentication.userProfile);
    const allProducts = useSelector((state) => state.productdata.filterProducts);
    const bundles = useSelector((state) => state.productdata.productBundles);
    const pricingRequestByContractNumberId = useSelector(
        (state) => state.contractpricetequestdata.pricingRequestsByContractNumberId
    );
    const updateStatus = useSelector((state) => state.productdata.contractpricingUpdateStatus);
    const [selectedRow, setSelectedRow] = useState({});
    const [editOpenRow, setEditOpenRow] = useState({});
    const [priceInEdit, setPriceInEdit] = useState(null);
    const [saving, setSaving] = useState(false);

    const [reff] = useState(React.createRef());
    const [blobUrl, setBlobUrl] = useState('');
    const [downloadFilename, setDownloadFilename] = useState('');
    const [loadingXlsx, setLoadingXlsx] = useState(false);

    // for adding new contract price
    const [showNewProdSelector, setShowNewProdSelector] = useState(false);
    const [contractIdInScope, setContractIdInScope] = useState(null);
    const [addNewProdTitle, setAddNewProdTitle] = useState('');

    // for removing contract price
    const [deleteConfirmTitle, setDeleteConfirmTitle] = useState('');
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    // for changing the pricing scheme between fixed <-> percentage based
    const [confirmChangePricingSchemeText, setConfirmChangePricingSchemeText] = useState('');
    const [confirmChangeSchemeOpen, setConfirmChangeSchemeOpen] = useState(false);
    const [confirmSchemeChangeParams, setConfirmSchemeChangeParams] = useState({});

    // contract price update via excel
    const [uploading, setUploading] = useState(false);
    const [uploadResults, setUploadResults] = useState(null);
    const [cpUpdateUid, setCpUpdateUid] = useState(null);
    const [updateContractId, setUpdateContractId] = useState(null);

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onAccordionChange = (evt, expanded, contractId) => {
        if (expanded && !contractPrices[contractId]) {
            getContractPrices(contractId)(dispatch);
        }
    };

    const onSelectRow = (contractId, productId, productBundleId) => {
        if (!ActionAllowed(rbacactions.AdjustContractPricing, user)) return;

        if (editOpenRow.contractId) return;
        setSelectedRow({ contractId, productId, productBundleId });
    };

    const editRow = (contractId, productId, productBundleId, pricingRequest = false) => {
        if (!ActionAllowed(rbacactions.AdjustContractPricing, user)) return;

        let cp;
        if (pricingRequest) {
            cp = createContractPriceRow(contractId, productId, productBundleId);
        } else {
            cp = productId
                ? contractPrices[contractId].find((p) => p.product && p.product.id === productId)
                : contractPrices[contractId].find((p) => p.productBundle && p.productBundle.id === productBundleId);
        }
        const toEdit = new ContractPrice(cp);
        if (toEdit.price === null && toEdit.discount === null) {
            toEdit.price = productId ? toEdit.product.priceWithoutVat : toEdit.productBundle.priceWithoutVat();
        }

        setPriceInEdit(new ContractPrice(toEdit));
        setEditOpenRow({ contractId, productId, productBundleId });
    };

    const cancelEdit = () => {
        setEditOpenRow({});
        setPriceInEdit(null);
    };

    const saveRow = async (row, pricingRequestId = null) => {
        await saveContractPrice(row)(dispatch);
        if (pricingRequestId) {
            await removeContractPricePricingRequest(pricingRequestId, row.contractId)(dispatch);
        }
    };

    const isSelected = (contractId, productId, productBundleId) => {
        return (
            selectedRow.contractId === contractId &&
            selectedRow.productId === productId &&
            selectedRow.productBundleId === productBundleId
        );
    };

    const isEditOpen = (contractId, productId, productBundleId) => {
        return (
            editOpenRow.contractId === contractId &&
            editOpenRow.productId === productId &&
            editOpenRow.productBundleId === productBundleId
        );
    };

    const onChangePriceRow = (priceRow) => {
        setPriceInEdit(priceRow);
    };

    const sortPrices = (prices, pricingRequests) => {
        // when pricing request are given, the contract prices which do not have
        // price or discount set will be filtered if there is pricing request for
        // that product/bundle
        if (pricingRequests) {
            prices = filter(prices, (p) => {
                if (!p.price && !p.discount) {
                    if (
                        p.product &&
                        some(pricingRequests, (r) => {
                            return r.productId === p.product.storageId;
                        })
                    ) {
                        return false;
                    }
                    if (
                        p.productBundle &&
                        some(pricingRequests, (r) => {
                            return r.productBundleClusterId === p.productBundle.clusterId;
                        })
                    ) {
                        return false;
                    }
                }
                return true;
            });
        }

        return sortBy(prices, (o) => {
            if (o.price === null && o.discount === null) {
                if (o.product) return 'Ö' + o.product.productName;
                return 'Ö' + o.productBundle.name;
            } else {
                if (o.product) return o.product.productName;
                return o.productBundle.name;
            }
        });
    };

    const addNewProduct = (contractId) => {
        setAddNewProdTitle(
            t('product.addProductToContract', {
                contractNumber: parameters['ContractNumber'].find((p) => p.id === contractId).value,
            })
        );
        setShowNewProdSelector(true);
        setContractIdInScope(contractId);
    };

    const getProduct = (id) => {
        //haku storageId:llä
        let product = allProducts.find((p) => p.storageId === id);
        return product;
    };

    const getBundle = (id) => {
        //haku clusterId:llä
        let bundle = bundles.find((b) => b.clusterId === id);
        return bundle;
    };

    const createContractPriceRow = (contractId, productId, bundleId) => {
        const product = getProduct(productId);
        const productBundle = getBundle(bundleId);

        const row = new ContractPrice({
            contractId: contractId,
            product: product,
            productBundle: productBundle,
            price: null,
            discount: null,
        });
        return row;
    };

    const onSelectNewProd = async (newId) => {
        setShowNewProdSelector(false);

        if (newId) {
            let product = allProducts.find((p) => p.id === newId);
            let isBundle = false;
            if (!product) {
                product = bundles.find((b) => b.id === newId);
                isBundle = true;
            }
            if (!product) throw new Error('product not found');

            if (!isBundle && contractPrices[contractIdInScope].find((p) => p.product && p.product.id === newId)) {
                toast.error(t('product.productIsAlreadyIncludedInContractPrices'), {
                    autoClose: 5000,
                    hideProgressBar: false,
                });
            } else if (
                isBundle &&
                contractPrices[contractIdInScope].find((p) => p.productBundle && p.productBundle.id === newId)
            ) {
            } else {
                const newPrice = {
                    contractId: contractIdInScope,
                    discount: 0.0,
                    price: null,
                    inUse: false,
                    product: !isBundle ? product : null,
                    productBundle: isBundle ? product : null,
                };
                await saveContractPrice(newPrice)(dispatch);
                toast.info(t('product.productWasAddedToContractPricing'), {
                    autoClose: 2000,
                    hideProgressBar: true,
                });
            }
        }
        setContractIdInScope(null);
    };

    const confirmDeleteContractPrice = async () => {
        await deleteContractPrice(itemToDelete)(dispatch);
        setItemToDelete(null);
        setDeleteConfirmTitle('');
        setConfirmDeleteOpen(false);
    };

    const onDeleteContractPrice = (contractPrice) => {
        setItemToDelete(contractPrice);
        setDeleteConfirmTitle(
            `Sopimushinnan tuotteelle "${
                contractPrice.product ? contractPrice.product.productName : contractPrice.productBundle.name
            }"`
        );
        setConfirmDeleteOpen(true);
    };

    const toFixedPrices = (contractId) => {
        setConfirmChangePricingSchemeText(t('product.confirmChangePricingToUseNetPrices'));
        setConfirmSchemeChangeParams({ toFixed: true, contractId });
        setConfirmChangeSchemeOpen(true);
    };

    const toPercentagePrices = (contractId) => {
        setConfirmChangePricingSchemeText(t('product.confirmChangePricingToUseDiscounts'));
        setConfirmSchemeChangeParams({ toFixed: false, contractId });
        setConfirmChangeSchemeOpen(true);
    };

    const doPricingSchemeChange = async () => {
        setConfirmChangeSchemeOpen(false);
        setSaving(true);
        for (const p of sortPrices(contractPrices[confirmSchemeChangeParams.contractId])) {
            if (p.price || p.discount) {
                let newRow = new ContractPrice(p);
                if (confirmSchemeChangeParams.toFixed && !p.price) {
                    newRow.price = newRow.calculateDiscountPrice();
                    newRow.discount = null;
                } else if (!confirmSchemeChangeParams.toFixed && !p.discount) {
                    newRow.discount = newRow.calculateDiscountPercent();
                    newRow.price = null;
                } else {
                    continue;
                }
                await saveContractPrice(newRow)(dispatch);
            }
        }
        setSaving(false);
    };

    const loadProductListXlsx = async (e, contractId) => {
        if (blobUrl) {
            e.stopPropagation();
            return;
        }
        setLoadingXlsx(true);
        const xlsx = await getProductListXlsxForContract(contractId);

        const buff = Buffer.from(xlsx, 'base64');
        const blob = new Blob([buff.buffer], {
            type: 'application/vnd.openxmlformats',
        });
        const url = URL.createObjectURL(blob);
        const contractNumber = parameters['ContractNumber'].find((c) => c.id === contractId).value;
        setBlobUrl(url);
        setDownloadFilename(`Tuotteet_${contractNumber}_${dayjs().format()}.xlsx`);

        setTimeout(() => {
            reff.current.click();
            setTimeout(() => {
                setBlobUrl('');
                setLoadingXlsx(false);
            }, 1000);
        }, 10);
    };

    const fileUpload = async (evt, contractId) => {
        const fileReader = new FileReader();
        setUploading(true);
        setUploadResults(null);
        fileReader.readAsDataURL(evt.target.files[0]);
        fileReader.onload = async (e) => {
            try {
                const resp = await uploadContractPriceUpdateExcel(fileReader.result, contractId)(dispatch);
                setUploadResults(resp.result);
                setUpdateContractId(contractId);
                setCpUpdateUid(resp.uid);
                setUploading(false);
            } catch (err) {
                setUploading(false);
                setUploadResults(null);
                toast.error(t('product.fileLoadFailed'), { autoClose: 5000 });
            }
        };
    };

    if (parameters == null) return <div>{t('general.loading')}</div>;
    if (!parameters['ContractNumber']) return null;

    if (updateStatus && !updateStatus.updateFinished) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '50vh' }}>
                <Grid item xs={3}>
                    {t('product.contractPricingUpdateOngoing')}
                    {dayjs(updateStatus.updateStarted).format('DD.MM.YYYY HH:mm')}
                    <br />
                    <br />
                    <CircularProgressWithLabel value={updateStatus.progressPercent} size="5rem" />
                </Grid>
            </Grid>
        );
    }

    const parametersContractNumbers = filter(parameters['ContractNumber'], (p) => {
        //filter out "ei sopimustunnusta"
        return p.id !== 1 && p.storageId !== 0;
    });

    return (
        <Grid container alignItems={'flex-end'} alignContent={'flex-end'} justifyContent={'flex-end'}>
            <div style={{ display: 'hidden' }}>
                <a // eslint-disable-line
                    ref={reff}
                    href={blobUrl.length > 0 ? blobUrl : null}
                    download={blobUrl.length > 0 ? downloadFilename : null}></a>
            </div>
            {parametersContractNumbers.map((c) => (
                <Grid key={c.id} item xs={12}>
                    <Accordion
                        className={classes.accordion}
                        onChange={(evt, expanded) => onAccordionChange(evt, expanded, c.id)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} name={'contract-prices-' + c.id}>
                            <strong>{c.value}</strong>&nbsp;
                            {pricingRequestByContractNumberId[c.id] && (
                                <span>
                                    {t('product.pricingRequests')}&nbsp;
                                    {pricingRequestByContractNumberId[c.id].length}
                                </span>
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                            {!contractPrices[c.id] && <span>{t('general.loading')}</span>}
                            {!!contractPrices[c.id] && (
                                <Grid container>
                                    {contractPrices[c.id].length === 0 && (
                                        <span>{t('product.noPricingInformation')}</span>
                                    )}
                                    {contractPrices[c.id].length > 0 && (
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Button
                                                    disabled={saving}
                                                    className={classes.addbutton}
                                                    startIcon={
                                                        saving ? (
                                                            <CircularProgress size={'1rem'} />
                                                        ) : (
                                                            <SwapHorizontalCircleIcon />
                                                        )
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    name="btn-to-fixed-prices"
                                                    onClick={() => toFixedPrices(c.id)}>
                                                    {t('product.netPrices')}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    disabled={saving}
                                                    className={classes.addbutton}
                                                    startIcon={
                                                        saving ? (
                                                            <CircularProgress size={'1rem'} />
                                                        ) : (
                                                            <SwapHorizontalCircleIcon />
                                                        )
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    name="btn-to-percentage-pricing"
                                                    onClick={() => toPercentagePrices(c.id)}>
                                                    {t('product.percentageDiscount')}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.addbutton}
                                                    disabled={loadingXlsx}
                                                    name="btn-load-product-xsls"
                                                    onClick={(e) => loadProductListXlsx(e, c.id)}
                                                    startIcon={
                                                        loadingXlsx ? (
                                                            <CircularProgress color={'secondary'} size={'1rem'} />
                                                        ) : (
                                                            <CloudDownloadIcon />
                                                        )
                                                    }>
                                                    {t('location.buttons.download')}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <label htmlFor="upload-excel">
                                                    <input
                                                        style={{ display: 'none' }}
                                                        id="upload-excel"
                                                        name="upload-excel"
                                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                        onChange={(e) => fileUpload(e, c.id)}
                                                        type="file"
                                                    />

                                                    <Button
                                                        className={classes.uploadBtn}
                                                        color="primary"
                                                        disabled={uploading}
                                                        component="span"
                                                        aria-label="add"
                                                        variant="contained"
                                                        startIcon={
                                                            uploading ? (
                                                                <CircularProgress color={'secondary'} size={'1rem'} />
                                                            ) : (
                                                                <CloudUploadIcon />
                                                            )
                                                        }>
                                                        {t('buttons.update')}
                                                    </Button>
                                                </label>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {pricingRequestByContractNumberId[c.id] && (
                                        <React.Fragment key={c.id}>
                                            <strong>{t('product.pricingRequests')}</strong>
                                            <br />
                                            {pricingRequestByContractNumberId[c.id].map((cp) => (
                                                <ContractPricesProduct
                                                    priceRow={
                                                        isEditOpen(
                                                            c.id,
                                                            cp.productId ? cp.productId : null,
                                                            cp.productBundleClusterId ? cp.productBundleClusterId : null
                                                        )
                                                            ? priceInEdit
                                                            : createContractPriceRow(
                                                                  c.id,
                                                                  cp.productId,
                                                                  cp.productBundleClusterId
                                                              )
                                                    }
                                                    key={cp.productId ? cp.productId : cp.productBundleClusterId}
                                                    edit={isEditOpen(
                                                        c.id,
                                                        cp.productId ? cp.productId : null,
                                                        cp.productBundleClusterId ? cp.productBundleClusterId : null
                                                    )}
                                                    highlight={isSelected(
                                                        c.id,
                                                        cp.productId ? cp.productId : null,
                                                        cp.productBundleClusterId ? cp.productBundleClusterId : null
                                                    )}
                                                    selectRow={() =>
                                                        onSelectRow(
                                                            c.id,
                                                            cp.productId ? cp.productId : null,
                                                            cp.productBundleClusterId ? cp.productBundleClusterId : null
                                                        )
                                                    }
                                                    editRow={() =>
                                                        editRow(
                                                            c.id,
                                                            cp.productId ? cp.productId : null,
                                                            cp.productBundleClusterId
                                                                ? cp.productBundleClusterId
                                                                : null,
                                                            true
                                                        )
                                                    }
                                                    save={async (row) => await saveRow(row, cp.id)}
                                                    cancel={cancelEdit}
                                                    onChange={onChangePriceRow}
                                                    delete={onDeleteContractPrice}
                                                    pricingRequest={true}
                                                />
                                            ))}
                                        </React.Fragment>
                                    )}

                                    {contractPrices[c.id].length !== 0 && (
                                        <Grid item xs={12}>
                                            <strong>{t('product.contractPrices')}</strong>
                                        </Grid>
                                    )}

                                    {sortPrices(contractPrices[c.id], pricingRequestByContractNumberId[c.id]).map(
                                        (p) => (
                                            <React.Fragment key={p.product ? p.product.id : p.productBundle.id}>
                                                {p.product ? (
                                                    <ContractPricesProduct
                                                        priceRow={
                                                            isEditOpen(c.id, p.product.id, null) ? priceInEdit : p
                                                        }
                                                        key={p.product.id}
                                                        edit={isEditOpen(c.id, p.product.id, null)}
                                                        highlight={isSelected(c.id, p.product.id, null)}
                                                        selectRow={() => onSelectRow(c.id, p.product.id, null)}
                                                        editRow={() => editRow(c.id, p.product.id, null)}
                                                        save={async (row) => await saveRow(row)}
                                                        cancel={cancelEdit}
                                                        onChange={onChangePriceRow}
                                                        delete={onDeleteContractPrice}
                                                    />
                                                ) : (
                                                    <ContractPricesProduct
                                                        priceRow={
                                                            isEditOpen(c.id, null, p.productBundle.id) ? priceInEdit : p
                                                        }
                                                        key={p.productBundle.id}
                                                        edit={isEditOpen(c.id, null, p.productBundle.id)}
                                                        highlight={isSelected(c.id, null, p.productBundle.id)}
                                                        selectRow={() => onSelectRow(c.id, null, p.productBundle.id)}
                                                        editRow={() => editRow(c.id, null, p.productBundle.id)}
                                                        save={async (row) => await saveRow(row)}
                                                        cancel={cancelEdit}
                                                        onChange={onChangePriceRow}
                                                        delete={onDeleteContractPrice}
                                                    />
                                                )}
                                            </React.Fragment>
                                        )
                                    )}
                                    <Grid item xs={12}>
                                        <Button
                                            disabled={saving}
                                            className={classes.addbutton}
                                            startIcon={<AddIcon />}
                                            variant="contained"
                                            color="primary"
                                            name="btn-add-new-product"
                                            onClick={() => addNewProduct(c.id)}>
                                            {t('general.product')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            ))}
            <FilterProductSelectDialog
                allowNewProd={false}
                title={addNewProdTitle}
                show={showNewProdSelector}
                selected={onSelectNewProd}
                showBundles={true}
            />
            <DeleteConfirmDialog
                open={confirmDeleteOpen}
                itemToDelete={deleteConfirmTitle}
                cancel={() => {
                    setConfirmDeleteOpen(false);
                    setItemToDelete(null);
                    setDeleteConfirmTitle('');
                }}
                confirmDelete={confirmDeleteContractPrice}
            />
            <ConfirmationDialog
                open={confirmChangeSchemeOpen}
                confirm={doPricingSchemeChange}
                cancel={() => setConfirmChangeSchemeOpen(false)}
                confirmText={t('product.change')}>
                {confirmChangePricingSchemeText}
            </ConfirmationDialog>
            <ConfirmationDialog
                open={uploadResults}
                confirm={() => {
                    setUploadResults(null);
                    doContractPriceUpdates(cpUpdateUid, updateContractId)(dispatch);
                }}
                cancel={() => {
                    setUploadResults(null);
                }}
                confirmText={t('buttons.update')}>
                {uploadResults && (
                    <Grid item container>
                        <Grid item xs={12}>
                            <strong>{t('product.fileLoaded')}</strong>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {t('parameterCategories.ContractNumber')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {uploadResults.contractNumber.value}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {t('product.rowsAltogether')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {uploadResults.totalLinesRead}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {t('product.changes')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {uploadResults.changedPricesCount}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {t('product.newContractPricesToCreate')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {uploadResults.addedPricesCount}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {t('product.productNotFoundInCatalog')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {uploadResults.notFoundProducts.length}
                        </Grid>
                    </Grid>
                )}
            </ConfirmationDialog>
        </Grid>
    );
}
