import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Downshift from 'downshift';
import { verifyEmailAddress } from './common';

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
}));

export default function TagsInput({ ...props }) {
    const classes = useStyles();
    const { selectedTags, placeholder, tags, ...other } = props;
    const [inputValue, setInputValue] = React.useState('');
    const [selectedItem, setSelectedItem] = React.useState([]);
    const skipInputValueUpdate = React.useRef(false);

    useEffect(() => {
        setSelectedItem(tags);
    }, [tags]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ' || event.key === ',' || event.key === ';') {
            handleNewItem(event);
            skipInputValueUpdate.current = true;
        } else if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
            setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
            skipInputValueUpdate.current = true;
        } else {
            skipInputValueUpdate.current = false;
        }
    };
    const handleChange = (item) => {
        let newSelectedItem = [...selectedItem];
        if (newSelectedItem.indexOf(item) === -1) {
            newSelectedItem = [...newSelectedItem, item];
        }
        setInputValue('');
        setSelectedItem(newSelectedItem);
    };
    const handleBlur = (event) => {
        handleNewItem(event);
    };
    const handleNewItem = (event) => {
        if (!verifyEmailAddress(event.target.value)) {
            return;
        }
        const newSelectedItem = [...selectedItem];
        const duplicatedValues = newSelectedItem.indexOf(event.target.value.trim());

        if (duplicatedValues !== -1) {
            setInputValue('');
            return;
        }
        if (!event.target.value.replace(/\s/g, '').length) return;

        newSelectedItem.push(event.target.value.trim());
        setSelectedItem(newSelectedItem);
        setInputValue('');
        selectedTags(newSelectedItem);
    };

    const handleDelete = (item) => () => {
        const newSelectedItem = [...selectedItem];
        newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
        setSelectedItem(newSelectedItem);
        selectedTags(newSelectedItem);
    };

    function handleInputChange(event) {
        if (!skipInputValueUpdate.current) setInputValue(event.target.value);
    }
    return (
        <React.Fragment>
            <Downshift
                id="downshift-multiple"
                name="emailinput"
                inputValue={inputValue}
                onChange={handleChange}
                selectedItem={selectedItem}>
                {({ getInputProps }) => {
                    const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                        onKeyDown: handleKeyDown,
                        placeholder,
                    });
                    return (
                        <div>
                            <TextField
                                InputProps={{
                                    startAdornment: selectedItem.map((item) => (
                                        <Chip
                                            key={item}
                                            tabIndex={-1}
                                            label={item}
                                            className={classes.chip}
                                            onDelete={handleDelete(item)}
                                        />
                                    )),
                                    onBlur: (event) => {
                                        handleBlur(event);
                                    },
                                    onChange: (event) => {
                                        handleInputChange(event);
                                        onChange(event);
                                    },
                                    onFocus,
                                }}
                                {...other}
                                {...inputProps}
                            />
                        </div>
                    );
                }}
            </Downshift>
        </React.Fragment>
    );
}
TagsInput.defaultProps = {
    tags: [],
};
TagsInput.propTypes = {
    selectedTags: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
};
