import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import CustomerAutoComplete from '../common/customerautocomplete';
import ConfirmationDialog from '../common/confirmationdialog';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    formControl: {
        margin: theme.spacing(1),
        width: '95%',
    },
    removeRep: {
        color: 'red',
    },
}));

export default function RepresentationsList(props) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const customers = useSelector((state) => state.customerdata.allCustomers);
    const [showAddNewRep, setShowAddNewRep] = useState(false);
    const [toRemoveRep, setToRemoveRep] = useState({});
    const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
    const representations = props.representations;
    const allowEdit = props.allowEdit;
    const { t } = useTranslation();

    useEffect(() => {});

    const removeRep = (rep) => {
        setToRemoveRep(rep);
        setShowRemoveConfirm(true);
    };

    const customerSelected = (_id, customer) => {
        setShowAddNewRep(false);
        const newReps = cloneDeep(representations);
        newReps.push({ customerId: customer.id, customerName: customer.name });
        props.representationsChanged(newReps);
    };

    const confirmRemoveRep = (rep) => {
        setShowRemoveConfirm(false);
        const newReps = cloneDeep(representations);
        newReps.splice(
            newReps.findIndex((elem) => elem.customerId === rep.customerId),
            1
        );
        props.representationsChanged(newReps);
        setToRemoveRep({});
    };

    const cancelRemoveRep = () => {
        setToRemoveRep({});
        setShowRemoveConfirm(false);
    };

    return (
        <Grid container>
            {!!representations && representations.length > 0 && (
                <Grid container item>
                    <Grid item>
                        {representations.map((rep) => (
                            <Grid item key={rep.customerId}>
                                <em>{rep.customerName}</em>
                                &nbsp;
                                {allowEdit && (
                                    <Button
                                        size="small"
                                        name="remove-representation"
                                        className={classes.removeRep}
                                        onClick={() => removeRep(rep)}
                                        title={t('buttons.remove')}>
                                        <DeleteIcon />
                                    </Button>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
            {allowEdit && (
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowAddNewRep(true)}
                        name="add-representation">
                        <AddIcon />
                    </Button>
                </Grid>
            )}

            {allowEdit && (
                <Dialog open={showAddNewRep} fullWidth={true} maxWidth={'md'} fullScreen={fullScreen}>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <CustomerAutoComplete
                                    customers={customers ? customers : []}
                                    selected={customerSelected}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="default"
                            name="add-rep-cancel"
                            onClick={() => setShowAddNewRep(false)}>
                            {t('buttons.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {allowEdit && (
                <ConfirmationDialog
                    open={showRemoveConfirm}
                    cancel={cancelRemoveRep}
                    confirm={() => confirmRemoveRep(toRemoveRep)}>
                    {t('admin.confirmRemoveRep')} <strong>{toRemoveRep.customerName}</strong>{' '}
                </ConfirmationDialog>
            )}
        </Grid>
    );
}

RepresentationsList.propTypes = {
    representations: PropTypes.array.isRequired,
    allowEdit: PropTypes.bool.isRequired,
    representationsChanged: PropTypes.func,
};
