import React, { useState } from 'react';
import LocationItem from './locationitem';
import moment from 'moment';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

const TimeSortedLocationGroup = (props) => {
    const [toggle, setToggle] = useState(props.openByDefault);
    const { t } = useTranslation();

    const getTimeStamp = (location) => {
        if (props.useOrderTime) {
            return moment(location.latestOrder).isValid()
                ? moment(location.latestOrder).format('DD.MM.YYYY HH:mm')
                : null;
        }
        return moment(location.updateDate).format('DD.MM.YYYY HH:mm');
    };

    return (
        <div className="locationgroup">
            <Accordion expanded={toggle}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setToggle(!toggle)}>
                    <Grid container>
                        <Grid item xs={6}>
                            <h4>{props.group.title}</h4>
                        </Grid>
                        <Grid item xs={6}>
                            {' '}
                            <em>
                                {props.group.locations.length} {t('pcs')}
                            </em>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            {props.group.locations.length === 0 && <span>{t('location.noLocationsEdited')}</span>}
                            {props.group.locations.map((loc) => (
                                <LocationItem
                                    key={loc.id}
                                    locationDesc={loc}
                                    history={props.history}
                                    showUpdateDate={getTimeStamp(loc)}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default TimeSortedLocationGroup;
