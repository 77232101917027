import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import LocationItem from './locationitem';
const _ = require('lodash');

function getAlphabeticallySorted(locations, filter) {
    let sortedLocations = {};
    let result = [];
    if (locations == null) return result;

    // Filter
    const locationsFiltered = _.filter(locations, (l) => {
        return _.deburr(l.name).toLowerCase().indexOf(_.deburr(filter).toLowerCase()) !== -1;
    });

    for (let loc of locationsFiltered) {
        var firstLetter = loc.name.substring(0, 1).toUpperCase();
        if (sortedLocations[firstLetter] == null) {
            sortedLocations[firstLetter] = {
                key: firstLetter,
                locations: [loc],
            };
        } else {
            sortedLocations[firstLetter].locations.push(loc);
        }
    }

    let keys = Object.keys(sortedLocations);
    keys.sort();
    for (let c of keys) {
        result.push(sortedLocations[c]);
    }
    for (let s of result) {
        s.locations = s.locations.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
    return result;
}

class AlphabeticalLocationList extends React.Component {
    render() {
        if (this.props.locationDescriptions != null && this.props.locationDescriptions.length === 0) {
            return (
                <div style={{ padding: '20px' }}>
                    <Alert severity="info">{this.props.emptyListText}</Alert>
                </div>
            );
        }
        return (
            <React.Fragment>
                {getAlphabeticallySorted(this.props.locationDescriptions, this.props.filter).map((item) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={item.key}>
                        <h2>{item.key}</h2>
                        {item.locations.map((loc) => (
                            <LocationItem key={loc.id} locationDesc={loc} history={this.props.history} />
                        ))}
                    </Grid>
                ))}
            </React.Fragment>
        );
    }
}

export default AlphabeticalLocationList;
