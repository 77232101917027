import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import LocationDetails from './locationdetails';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontWeight: 'bold',
    },
    details: {
        width: '100%',
        display: 'block',
    },
}));

export default function LocationTreeItem(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const onClickAddToCart = (id) => {
        props.addToCart([id]);
    };

    const onClickAddToCartMulti = (folderId) => {
        props.addToCartMulti(folderId);
    };

    const isInCart = (locId) => {
        props.isInCart(locId);
    };

    const isInCartFromFolder = (folderId) => {
        props.isInCartFromFolder(folderId);
    };

    let tree = props.data;
    let locCountText = '';
    if (tree.locations != null) {
        locCountText = t('general.locationCount', { locationCount: tree.locations.length });
    }
    return (
        <Accordion key={tree.id} className={classes.root}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>
                    {tree.name}
                    <span>&nbsp;({locCountText})</span>
                    {tree.locations != null && tree.locations.length > 0 && (
                        <IconButton
                            color="secondary"
                            aria-label="Lisää tilaukseen"
                            disabled={isInCartFromFolder(tree.id)}
                            onClick={() => onClickAddToCartMulti(tree.id)}>
                            <AddShoppingCartIcon />
                        </IconButton>
                    )}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                {tree.childFolders != null &&
                    tree.childFolders.length > 0 &&
                    tree.childFolders.map((child) => (
                        <div key={child.id} style={{ display: 'block', width: '100%' }}>
                            <LocationTreeItem {...props} data={child} />
                        </div>
                    ))}
                {tree.locations != null &&
                    tree.locations.length > 0 &&
                    tree.locations.map((loc) => (
                        <LocationDetails
                            key={loc.id}
                            location={loc}
                            isInCart={() => isInCart(loc.id)}
                            addToCart={onClickAddToCart}
                            getLocationFull={props.getLocationFull}
                            locationFull={props.locationsById[loc.id]}
                            addMachineToCart={props.addMachineToCart}
                            addFilterToCart={props.addFilterToCart}
                        />
                    ))}
                {(tree.locations == null || tree.locations.length === 0) && <span>{t('customer.noLocations')}</span>}
            </AccordionDetails>
        </Accordion>
    );
}
