import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../common/spinner';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import LocationViewBasicInfo from '../location/locationviewbasicinfo';
import { getLocationFull } from '../modules/locationdata';
import LocationViewMachineInfo from '../location/locationviewmachineinfo';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 'bold',
    },
}));

export default function SearchResultsLocationDetails(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const locations = useSelector((state) => state.locationdata.locationsById);
    const locationFull = locations[props.location.id];
    const [expanded, setExpanded] = useState(false);
    const location = props.location;
    const userProfile = useSelector((state) => state.authentication.userProfile);
    const portfolios = useSelector((state) => state.portfoliodata.portfolioDescriptions);

    const onExpand = () => {
        setExpanded(!expanded);
        if (!locationFull) dispatch(getLocationFull(location.id));
    };

    const onExpandLimited = () => {
        setExpanded(!expanded);
    };

    const openLocation = (event) => {
        props.history.push('/locations/' + location.id);
        event.stopPropagation();
    };

    const openCustomer = (event) => {
        props.history.push('/customers/' + location.customerId);
        event.stopPropagation();
    };

    const inUserPortfolio = (location) => {
        return userProfile.portfolioDescriptions.some((p) => p.id === location.portfolio.id);
    };

    const getPortfolioName = (id) => {
        const portfolio = find(portfolios, { id: id });
        return portfolio.name;
    };

    return (
        <React.Fragment>
            {inUserPortfolio(location) ? (
                <Accordion expanded={expanded} onChange={onExpand}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <OpenInNewIcon name="open-location" onClick={(event) => openLocation(event)} />
                            </Grid>
                            <Grid item>{location.name}</Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item>
                            {locationFull && (
                                <LocationViewBasicInfo
                                    location={locationFull}
                                    viewOnly={true}
                                    history={props.history}
                                />
                            )}
                            {locationFull && (
                                <LocationViewMachineInfo machines={locationFull.machines} viewOnly={true} />
                            )}
                            {!locationFull && <Spinner />}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ) : (
                <Accordion expanded={expanded} onChange={onExpandLimited}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item>{location.name}</Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid item xs={12} md={6} className={classes.bold}>
                                {t('general.portfolio')}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {getPortfolioName(location.portfolio.id)}
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.bold}>
                                {t('general.customer')}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <OpenInNewIcon name="open-customer" onClick={(event) => openCustomer(event)} />
                                    </Grid>
                                    <Grid item>{location.customer}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            )}
        </React.Fragment>
    );
}
