import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustOrderReports } from '../modules/customerreportdata';
import { getOrderReports } from '../modules/reportdata';
import moment from 'moment';
import OrderHistory from './orderhistory';
import User from '../domain/user';
import { includes } from 'lodash';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import fiLocale from 'date-fns/locale/fi';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

export default function OrderHistoryContainer(props) {
    const dispatch = useDispatch();
    const customerUi = process.env.REACT_APP_TARGET === 'customerui';
    const orderReports = useSelector((state) =>
        customerUi ? state.customerreportdata.orderReports : state.reportdata.orderReports
    );
    const orderReportRange = useSelector((state) =>
        customerUi ? state.customerreportdata.orderReportRange : state.reportdata.orderReportRange
    );
    const userProfile = useSelector((state) => state.authentication.userProfile);
    const [historyStart, setHistoryStart] = useState(null);
    const [historyEnd, setHistoryEnd] = useState(null);
    const [searchFilter, setSearchFilter] = useState('');
    const { t } = useTranslation();

    const fetchOrderData = useCallback(
        (start, end) => {
            if (
                !orderReports ||
                !moment(orderReportRange[0]).isSame(start, 'day') ||
                !moment(orderReportRange[1]).isSame(end, 'day')
            )
                customerUi ? dispatch(getCustOrderReports(start, end)) : dispatch(getOrderReports(start, end));
        },
        [customerUi, orderReports, dispatch, orderReportRange]
    );

    useEffect(() => {
        let hash = props.history.location.hash.split('-');
        if (hash[1] && moment(hash[1]).isValid()) {
            setHistoryStart(moment(hash[1]));
        } else {
            setHistoryStart(moment().add(-6, 'M'));
        }
        if (hash[2] && moment(hash[2]).isValid()) {
            setHistoryEnd(moment(hash[2]));
        } else {
            setHistoryEnd(moment());
        }
        if (hash[3]) {
            setSearchFilter(decodeURI(hash[3]));
        }
    }, [props.history.location.hash]);

    useEffect(() => {
        if (historyStart && historyEnd) {
            props.history.replace(
                '/orderhistory#history-' +
                    moment(historyStart).format('YYYY/MM/DD') +
                    '-' +
                    moment(historyEnd).format('YYYY/MM/DD') +
                    '-' +
                    searchFilter
            );
            fetchOrderData(historyStart, historyEnd);
        }
    }, [historyStart, historyEnd, searchFilter, props.history, fetchOrderData]);

    const onChangeStartTime = (date) => {
        let newDate = moment(date);
        if (!historyStart.isSame(newDate)) {
            setHistoryStart(newDate);
        }
    };

    const onChangeEndTime = (date) => {
        let newDate = moment(date);
        if (!historyStart.isSame(newDate)) {
            setHistoryEnd(newDate);
        }
    };

    const handleSearchChange = (event) => {
        setSearchFilter(event.target.value);
    };

    return (
        <Grid container>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                <Grid container justifyContent="space-around">
                    <TextField
                        className="MuiFormControl-marginNormal"
                        id="input-with-icon-textfield"
                        label={t('reports.search')}
                        placeholder={t('searchHint')}
                        value={searchFilter}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="date-picker-inline-start"
                        label={t('reports.startDate')}
                        value={historyStart}
                        onChange={onChangeStartTime}
                        invalidDateMessage={t('general.invalidDateMsg')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="date-picker-inline-end"
                        label={t('reports.endDate')}
                        value={historyEnd}
                        onChange={onChangeEndTime}
                        invalidDateMessage={t('general.invalidDateMsg')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
            <Grid item xs={12}>
                <OrderHistory
                    reports={orderReports}
                    history={props.history}
                    customerui={customerUi}
                    allowPricing={customerUi ? includes(userProfile.roles, User.RoleAllowPricing()) : true}
                    searchFilter={searchFilter.toLowerCase()}
                />
            </Grid>
        </Grid>
    );
}
