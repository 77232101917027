import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { getInvoicingReports } from '../modules/reportdata';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import fiLocale from 'date-fns/locale/fi';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    btn: {
        marginTop: theme.spacing(2),
    },
}));

const delay = async (ms) => {
    await new Promise((r) => setTimeout(r, ms));
};

const InvoicingReports = (props) => {
    const classes = useStyles();
    const [blobUrl, setBlobUrl] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [invStart, setInvStart] = React.useState(null);
    const [invEnd, setInvEnd] = React.useState(null);
    const [reff] = React.useState(React.createRef());
    const parameters = useSelector((state) => state.parameterdata.parameters);
    const [contractNumbers, setContractNumbers] = React.useState([]);
    const { t } = useTranslation();
    const [selectedContractNumber, setSelectedContractNumber] = React.useState('all');

    useEffect(() => {
        if (parameters) {
            setContractNumbers(parameters['ContractNumber']);
        }
    }, [parameters]);

    const clickLoad = async (e) => {
        if (blobUrl) {
            e.stopPropagation();
            await delay(100);
            setBlobUrl('');
            setLoading(false);
            return;
        }
        setLoading(true);
        var xlsx = await getInvoicingReports(
            invStart,
            invEnd,
            selectedContractNumber === 'all' ? null : selectedContractNumber
        );
        const buff = Buffer.from(xlsx, 'base64');
        const blob = new Blob([buff.buffer], {
            type: 'application/vnd.openxmlformats',
        });
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);

        setTimeout(() => {
            reff.current.click();
        }, 10);
    };

    const onChangeDate = (target, value) => {
        if (target === 'start') {
            setInvStart(moment(value));
        } else if (target === 'end') {
            setInvEnd(moment(value));
        }
    };

    const handleChange = (e) => {
        setSelectedContractNumber(e.target.value);
    };

    if (!props.active) return null;

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} md={6}>
                <FormControl className={classes.select}>
                    <InputLabel id="month-selector-label">{t('parameterCategories.ContractNumber')}</InputLabel>
                    <Select
                        required
                        disabled={loading}
                        labelId="month-selector-label"
                        id="contract-selector"
                        name="contract-selector"
                        value={selectedContractNumber}
                        onChange={handleChange}>
                        <MenuItem value={'all'}>{t('general.all')}</MenuItem>
                        {contractNumbers.map((m) => (
                            <MenuItem key={m.id} value={m.value}>
                                {m.value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid container spacing={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                    <Grid item>
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd.MM.yyyy"
                            margin="normal"
                            id="date-picker-inline-start"
                            label={t('reports.startDate')}
                            value={invStart}
                            onChange={(value) => onChangeDate('start', value)}
                            invalidDateMessage={t('general.invalidDateMsg')}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <KeyboardDatePicker
                            disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd.MM.yyyy"
                            margin="normal"
                            id="date-picker-inline-end"
                            label={t('reports.endDate')}
                            value={invEnd}
                            onChange={(value) => onChangeDate('end', value)}
                            invalidDateMessage={t('general.invalidDateMsg')}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
                <Button
                    ref={reff}
                    className={classes.btn}
                    href={blobUrl}
                    disabled={loading || !invStart || !invEnd}
                    variant="contained"
                    onClick={clickLoad}
                    color="primary"
                    download={
                        blobUrl.length > 0
                            ? `laskutusraportti_${selectedContractNumber}_${invStart.format(
                                  'DD.MM.YYYY'
                              )}-${invEnd.format('DD.MM.YYYY')}.xlsx`
                            : null
                    }
                    startIcon={loading ? <CircularProgress color={'secondary'} size={'1rem'} /> : null}>
                    {t('location.buttons.download')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default InvoicingReports;
