import { rbacrules } from './rbacrules';

export function ActionAllowed(action, user) {
    let allowedActions = [];
    if (user != null && user.roles != null) {
        for (let r of user.roles) {
            allowedActions = rbacrules[r].actions.concat(allowedActions);
        }
    }

    return allowedActions.indexOf(action) !== -1;
}
