import Party from './party';
import i18next from 'i18next';

function translate(key) {
    return i18next.t(key) ? i18next.t(key) : key;
}

class PartyRelation {
    constructor(json) {
        if (json) {
            this.relatedParty = new Party(json.relatedParty);
            this.relation = json.relation;
        }
    }
    static PartyRelationText = (relation) => {
        switch (relation) {
            case 1:
                return translate('general.recipient');
            case 2:
                return translate('general.orderer');
            case 3:
                return translate('general.other');
            default:
                throw new Error('invalid parameter');
        }
    };
    static DeliveryRecipient = () => 1;
    static Orderer = () => 2;
    static Other = () => 3;

    static AllRelations = () => [
        { value: 1, text: translate('general.recipient') },
        { value: 2, text: translate('general.orderer') },
        { value: 3, text: translate('general.other') },
    ];
}

export default PartyRelation;
