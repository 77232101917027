import React, { useState } from 'react';
import { formatCurrency } from '../common/common';
import { Grid } from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const _ = require('lodash');

export default function ValueSortedCustomerGroup(props) {
    const [toggle, setToggle] = useState(props.openByDefault);

    if (props.group.customers.length === 0) return null;

    const onClickItem = (id) => {
        props.history.push(`/customers/${id}`);
    };
    const customers = _.orderBy(props.group.customers, ['value'], ['desc']);

    return (
        <Grid item xs={12}>
            <Accordion expanded={toggle}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setToggle(!toggle)}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <h4>{props.group.title}</h4>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <em>{props.group.customers.length} kpl</em>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container>
                        {customers.map((customer) => (
                            <Grid item xs={12} key={customer.id}>
                                <div className="location-item" onClick={() => onClickItem(customer.id)}>
                                    {customer.name}
                                    <span style={{ float: 'right' }}>{formatCurrency(customer.value)}</span>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}
