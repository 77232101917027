import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../common/spinner';
import { listPortfolios, savePortfolio, deletePortfolio } from '../modules/portfoliodata';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import { makeStyles, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { Portfolio, PortfolioUser } from '../domain/portfolio';
import User from '../domain/user';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import { getUserData } from '../modules/userdataactions';
import { toast } from 'react-toastify';
import { Alert } from '@material-ui/lab';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function PortfolioEditView(props) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const portfolioId =
        props.match.params.portfolioId === 'new'
            ? props.match.params.portfolioId
            : parseInt(props.match.params.portfolioId, 10);

    const portfolios = useSelector((state) => state.portfoliodata.portfolios);
    const users = useSelector((state) => state.userdata.orgUsers);
    const gettingUsers = useSelector((state) => state.userdata.getUserDataInProgress);
    const isFetchingPortfolios = useSelector((state) => state.portfoliodata.isFetching);

    const [saving, setSaving] = useState(false);
    const [createOnProgress, setCreateOnProgress] = useState(false);
    const [portfolio, setPortfolio] = useState(null);
    const [formOk, setFormOk] = useState(false);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

    useEffect(() => {
        if (portfolioId === 'new' && !portfolio) {
            setCreateOnProgress(true);
            Portfolio.NewObject('').then(function (pf) {
                setPortfolio(pf);
                setCreateOnProgress(false);
            });
        }
    }, [portfolioId, portfolio]);

    useEffect(() => {
        //TODO: portfolio fetch failed case
        if (isFetchingPortfolios === false && (portfolios == null || portfolios.length === 0)) {
            dispatch(listPortfolios());
        } else if (portfolioId !== 'new' && !isFetchingPortfolios) {
            let portfolioToEdit = _.find(portfolios, ['id', portfolioId]);
            setPortfolio(portfolioToEdit);
            setFormOk(true);
        }
    }, [portfolios, dispatch, isFetchingPortfolios, portfolioId]);

    useEffect(() => {
        if ((!users || users.length === 0) && !gettingUsers) {
            dispatch(getUserData());
        }
    });

    const checkIfFormOk = (newPortfolio) => {
        let ok = true;
        if (newPortfolio.name == null || newPortfolio.name.length === 0) ok = false;
        if (ok !== formOk) {
            setFormOk(ok);
        }
    };

    const handleChange = (event) => {
        let newPortfolio = new Portfolio(portfolio);
        const target = event.target;
        const value = target.value;
        const name = target.name;
        switch (name) {
            case 'portfolioName':
                newPortfolio.name = value;
                break;
            case 'portfolioUsers':
                newPortfolio.users = [];
                for (let val of value) {
                    let selectedUser = _.find(users, ['id', val]);
                    let newUser = new PortfolioUser();
                    if (selectedUser) {
                        newUser.id = selectedUser.id;
                        newUser.portfolioStorageId = newPortfolio.id;
                        newUser.user = new User(selectedUser);
                        newUser.storageId = selectedUser.storageId;
                        newUser.userStorageId = selectedUser.storageId;
                        newPortfolio.users.push(newUser);
                    }
                }
                break;
            default:
                break;
        }

        setPortfolio(newPortfolio);
        checkIfFormOk(newPortfolio);
    };

    const onCancel = () => {
        props.history.goBack();
    };

    const onSubmit = () => {
        let toBeSavePortfolio = new Portfolio(portfolio);
        toast.info('Tallennetaan..', { autoClose: 1000, hideProgressBar: true });
        setSaving(true);
        dispatch(savePortfolio(toBeSavePortfolio))
            .then(() => {
                if (portfolioId === 'new') {
                    props.history.replace('/admin/portfolio/edit/' + portfolio.id);
                }
                toast.info('Salkun tiedot tallennettu.', { autoClose: 1500, hideProgressBar: true });
                setSaving(false);
            })
            .catch(() => {
                toast.error('Salkun tallennus epäonnistui!', { autoClose: 5000, hideProgressBar: false });
                setSaving(false);
            });
    };

    const onRemove = () => {
        setConfirmDeleteModalOpen(true);
    };

    const confirmDelete = () => {
        let toBeRemovedPortfolio = new Portfolio(portfolio);
        setSaving(true);
        toast.info('Poistetaan..', { autoClose: 1500, hideProgressBar: true });
        dispatch(deletePortfolio(toBeRemovedPortfolio.storageId))
            .then(() => {
                toast.info('Salkun tiedot poistettu.', { autoClose: 2000, hideProgressBar: true });
                //TODO: won't work if deleted immediately after creating new portfolio
                props.history.go(-2);
            })
            .catch(() => {
                toast.error('Salkun poisto epäonnistui!', { autoClose: 5000, hideProgressBar: false });
            });
    };

    const cancelDelete = () => {
        setConfirmDeleteModalOpen(false);
    };

    const ConfirmationDialog = () => {
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
        if (confirmDeleteModalOpen)
            return (
                <Dialog
                    onClose={cancelDelete}
                    aria-labelledby="simple-dialog-title"
                    open={confirmDeleteModalOpen}
                    fullWidth={true}
                    maxWidth={'md'}
                    fullScreen={fullScreen}>
                    <DialogContent dividers>Oletko varma, että haluat poistaa salkun tiedot?</DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            name="btn-modal-poista"
                            id="modal-poista"
                            onClick={confirmDelete}
                            disabled={saving}>
                            Poista
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            variant="contained"
                            color="default"
                            name="btn-modal-peruuta"
                            id="modal-peruuta"
                            onClick={cancelDelete}
                            disabled={saving}>
                            Peruuta
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        return null;
    };

    const getHeader = () => {
        if (portfolio == null) return null;
        return (
            <h2>
                <Button onClick={onCancel}>
                    <i className="fas fa-chevron-left fa-2x"></i>&nbsp;&nbsp;
                </Button>
                {portfolio.name}
            </h2>
        );
    };

    const getStyles = (id) => {
        return {
            fontWeight:
                _.find(portfolio.users, ['id', id]) === undefined
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    };

    const renderView = () => {
        if (createOnProgress) {
            return <Spinner padding="20px" title="Alustetaan salkun tietoja" />;
        }
        if (isFetchingPortfolios) {
            return <Spinner padding="20px" title="Haetaan salkun tietoja" />;
        } else if (!portfolio) {
            //TODO: proper fail case handling, no data failure information is currently available
            return (
                <div style={{ padding: '20px' }}>
                    <Alert severity="error">Salkku haku epäonnistui. Kokeile ladata sivu uudestaan (F5).</Alert>
                </div>
            );
        }

        return (
            <fieldset disabled={saving}>
                <Grid container>
                    <Grid item xs={12}>
                        {getHeader()}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            error={portfolio.name == null || portfolio.name.length === 0}
                            id="portfolioName"
                            name="portfolioName"
                            label="Nimi"
                            value={portfolio.name}
                            className={classes.textField}
                            margin="normal"
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="portfolioUsersLabel">Käyttäjät</InputLabel>
                            <Select
                                labelId="portfolioUsersLabel"
                                id="portfolioUsers"
                                name="portfolioUsers"
                                multiple
                                value={portfolio.users.map((puser) => puser.user.id)}
                                onChange={handleChange}
                                input={<Input id="selectPortfolioUsers" />}
                                renderValue={() => (
                                    <div className={classes.chips}>
                                        {portfolio.users.map((puser) => (
                                            <Chip
                                                key={puser.user.id}
                                                label={puser.user.name}
                                                className={classes.chip}
                                            />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}>
                                {users.map((user) => (
                                    <MenuItem key={user.id} value={user.id} style={getStyles(user.id)}>
                                        {user.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Footer>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                name="delete-portfolio"
                                onClick={onRemove}
                                disabled={portfolioId === 'new'}>
                                <i className="fas fa-trash"></i> &nbsp; Poista
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                name="save-portfolio"
                                onClick={onSubmit}
                                disabled={saving || !formOk}>
                                {saving && <i className="fas fa-spinner fa-spin"></i>}
                                {!saving && <i className="fas fa-save"></i>}
                                &nbsp;Tallenna
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                name="close"
                                onClick={onCancel}
                                disabled={saving}>
                                <i className="far fa-window-close"></i>&nbsp;Sulje
                            </Button>
                        </Grid>
                    </Footer>
                    <FooterPadding />

                    <ConfirmationDialog />
                </Grid>
            </fieldset>
        );
    };

    return renderView();
}
