import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { MapDataToMonthlyChart } from './mapDataToChartData';
import { chartLabelTooltip } from './charttooltip';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',
    },
}));

const MonthlyReportView = (props) => {
    const { t } = useTranslation();
    const plotWidth = window.innerWidth - 100;
    const yAxisTitle = t('reports.salesKEur');
    const xAxisTitle = t('reports.month');
    const classes = useStyles();
    const [reportPortfolioId, setReportPortfolioId] = useState(props.userProfile.selectedPortfolioId);
    const [chartData, setChartData] = useState(MapDataToMonthlyChart(props.reports, reportPortfolioId));

    useEffect(() => {
        setChartData(MapDataToMonthlyChart(props.reports, reportPortfolioId));
    }, [reportPortfolioId, props.reports]);

    const handleChange = (evt) => {
        setReportPortfolioId(evt.target.value);
    };

    return (
        <Grid container className={classes.root}>
            <Grid item>
                {t('reports.monthlyReportForPortfolio')}&nbsp;
                <Select id="demo-simple-select" value={reportPortfolioId} onChange={handleChange}>
                    {props.userProfile.portfolioDescriptions.map((p) => (
                        <MenuItem key={p.id} value={p.id}>
                            {p.name}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            {chartData != null && (
                <Bar
                    data={chartData}
                    width={plotWidth}
                    height={500}
                    options={{
                        scales: {
                            xAxes: [
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: xAxisTitle,
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: yAxisTitle,
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        callback: (value, index, values) => {
                                            return value.toLocaleString();
                                        },
                                    },
                                },
                            ],
                        },
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    return chartLabelTooltip(tooltipItem, data);
                                },
                            },
                        },
                    }}
                />
            )}
        </Grid>
    );
};

export default MonthlyReportView;
