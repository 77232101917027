import React, { useEffect, useState } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import TextEditBlock from '../invoice/texteditblock';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function AddNoteDialog(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [noteText, setNoteText] = useState('');
    const [onSave, setOnSave] = useState(false);
    const [suggested, setSuggested] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.edit && props.note != null) {
            setNoteText(props.note.text);
        }
        if (!props.open) {
            setNoteText('');
            setOnSave(false);
        }
    }, [props.note, props.open, props.edit]);

    useEffect(() => {
        if (noteText && noteText.length > 2 && props.suggestions && props.suggestions.length > 0) {
            const sugg = props.suggestions.filter((item) => item.toLowerCase().includes(noteText.toLowerCase()));
            setSuggested(sugg);
        } else {
            setSuggested([]);
        }
    }, [noteText, props.suggestions]);

    const handleChange = (evt) => {
        setNoteText(evt.target.value);
    };

    const saveNote = () => {
        props.save(noteText, !!props.note ? props.note.storageId : null);
        setOnSave(true);
    };

    const cancelNote = () => {
        props.cancel();
    };

    return (
        <Dialog
            onClose={cancelNote}
            aria-labelledby="simple-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}
            disableEscapeKeyDown={false}>
            <DialogContent dividers>
                <Grid container>
                    <TextEditBlock
                        name="note-text"
                        label={t('general.note')}
                        value={noteText}
                        onChange={handleChange}
                        md={12}
                    />
                    {suggested && suggested.length > 0 && (
                        <Grid item xs={12}>
                            <strong>{t('general.suggestion')}</strong>
                            {suggested.map((sugg) => (
                                <Button size="small" key={sugg} onClick={() => setNoteText(sugg)}>
                                    {sugg}
                                </Button>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    name="btn-save-note"
                    onClick={saveNote}
                    disabled={onSave}
                    endIcon={onSave ? <CircularProgress color={'secondary'} size={'1rem'} /> : ''}>
                    {t('buttons.save')}
                </Button>
                <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    name="btn-cancel-note"
                    onClick={cancelNote}
                    disabled={onSave}>
                    {t('buttons.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddNoteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    maxLength: PropTypes.number,
    suggestions: PropTypes.array,
};
