import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        fontFamily: 'Open Sans Condensed',
        fontSize: '22px',
    },
};

class VadoLogo extends React.Component {
    render() {
        return <span className={this.props.classes.root}>VADO</span>;
    }
}

export default withStyles(styles)(VadoLogo);
