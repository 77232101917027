import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';
import { toast } from 'react-toastify';
import i18next from 'i18next';

export const SEND_FEEDBACK_IN_PROGRESS = 'feedbackdata/sendfeedbackinprog';
export const SEND_FEEDBACK_SUCCESS = 'feedbackdata/sendfeedbacksucces';
export const SEND_FEEDBACK_FAILED = 'feedbackdata/sendfeedbackfailed';

export const FeedbackSendStatus = {
    Sending: 1,
    SendSuccess: 2,
    SendFailed: 3,
};

const initialState = {
    sendFeedbackStatus: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEND_FEEDBACK_IN_PROGRESS:
            return {
                ...state,
                sendFeedbackStatus: FeedbackSendStatus.Sending,
            };
        case SEND_FEEDBACK_SUCCESS:
            return {
                ...state,
                sendFeedbackStatus: FeedbackSendStatus.SendSuccess,
            };
        case SEND_FEEDBACK_FAILED:
            return {
                ...state,
                sendFeedbackStatus: FeedbackSendStatus.SendFailed,
            };
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const sendFeedback = (feedback) => {
    return (dispatch) => {
        dispatch({
            type: SEND_FEEDBACK_IN_PROGRESS,
        });
        toast(i18next.t('toast.feedbackSent'), { timeout: 1000, hideProgressBar: true });
        Ajax.post('api/customerfeedback', feedback)
            .then(function (data) {
                if (data.data.status !== 'OK') {
                    dispatch({
                        type: SEND_FEEDBACK_FAILED,
                    });
                } else {
                    dispatch({
                        type: SEND_FEEDBACK_SUCCESS,
                    });
                }
            })
            .catch(function (err) {
                console.log(err);
                dispatch({
                    type: SEND_FEEDBACK_FAILED,
                });
            });
    };
};
