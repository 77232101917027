import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { verifyPhonenumber, verifyEmailAddress } from '../common/common';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

export default function PartyEditDialog(props) {
    const classes = useStyles();
    const open = props.open;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [name, setName] = React.useState('');
    const [phonenumber, setPhonenumber] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [saving, setSaving] = React.useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setName('');
        setPhonenumber('');
        setEmail('');
    }, [props.open]);

    const cancel = () => {
        props.cancel();
    };

    return (
        <Dialog
            onClose={cancel}
            aria-labelledby="simple-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogTitle>{t('general.createNewContact')}</DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            disabled={saving}
                            error={!name}
                            id="partyName"
                            name="name"
                            label={t('general.name')}
                            value={name}
                            className={classes.textField}
                            margin="normal"
                            onChange={(evt) => setName(evt.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            disabled={saving}
                            error={!verifyPhonenumber(phonenumber)}
                            id="partyTel"
                            name="phonenumber"
                            label={t('general.phone')}
                            value={phonenumber}
                            className={classes.textField}
                            margin="normal"
                            onChange={(evt) => setPhonenumber(evt.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            disabled={saving}
                            error={!verifyEmailAddress(email)}
                            id="partyEmail"
                            name="email"
                            label={t('general.email')}
                            value={email}
                            className={classes.textField}
                            margin="normal"
                            onChange={(evt) => setEmail(evt.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={
                        saving || !name || !verifyPhonenumber(phonenumber) || (!!email && !verifyEmailAddress(email))
                    }
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    name="btn-party-save"
                    onClick={async () => {
                        setSaving(true);
                        await props.save({ name, phonenumber, email });
                        setSaving(false);
                    }}
                    startIcon={saving ? <CircularProgress color={'secondary'} size={'1rem'} /> : null}>
                    {t('buttons.save')}
                </Button>
                <Button
                    disabled={saving}
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    name="btn-party-cancel"
                    onClick={cancel}>
                    {t('buttons.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
