import React from 'react';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    oldinfo: {
        textDecoration: 'line-through',
    },
    sectionMargin: {
        marginTop: '1em',
    },
}));

export default function UpdateCustomerInfoDialog(props) {
    const classes = useStyles();
    const open = props.open;
    const confirm = props.confirm;
    const cancel = props.cancel;
    const updateInfo = props.updateInfo;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    if (!updateInfo.invoiceAddrUpdateNeeded && !updateInfo.eInvAddrUpdateNeeded && !updateInfo.emailInvAddrUpdateNeeded)
        return null;

    if (open)
        return (
            <Dialog
                onClose={cancel}
                aria-labelledby="simple-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                fullScreen={fullScreen}>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <h4>{t('invoice.updateFollowingToCustomerData')}</h4>
                        </Grid>
                        {updateInfo.invoiceAddrUpdateNeeded && (
                            <Grid item container className={classes.sectionMargin}>
                                <Grid item xs={12}>
                                    <h5>{t('general.invoicingAddress')}</h5>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    {updateInfo.newInvoiceAddrDest === 'customer' && (
                                        <div>
                                            {t('general.customer')}: {updateInfo.customer.name}
                                            <div className={classes.oldinfo}>
                                                "{updateInfo.customer.billingAddress.streetAddress},&nbsp;
                                                {updateInfo.customer.billingAddress.zipCode}{' '}
                                                {updateInfo.customer.billingAddress.city}"
                                            </div>
                                        </div>
                                    )}
                                    {updateInfo.newInvoiceAddrDest === 'billingcustomer' && (
                                        <div>
                                            {t('general.customer')}: {updateInfo.billingCustomer.name}
                                            <div className={classes.oldinfo}>
                                                "{updateInfo.billingCustomer.billingAddress.streetAddress},&nbsp;
                                                {updateInfo.billingCustomer.billingAddress.zipCode}{' '}
                                                {updateInfo.billingCustomer.billingAddress.city}"
                                            </div>
                                        </div>
                                    )}
                                    {updateInfo.newInvoiceAddrDest === 'location' && (
                                        <div>
                                            {t('general.location')}: {updateInfo.location.name}
                                            <div className={classes.oldinfo}>
                                                "{updateInfo.location.billingAddress.streetAddress},&nbsp;
                                                {updateInfo.location.billingAddress.zipCode}{' '}
                                                {updateInfo.location.billingAddress.city}"
                                            </div>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ArrowForwardIcon />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    "{updateInfo.invoice.billingAddress.streetAddress},&nbsp;
                                    {updateInfo.invoice.billingAddress.zipCode} {updateInfo.invoice.billingAddress.city}
                                    "
                                </Grid>
                            </Grid>
                        )}
                        {updateInfo.eInvAddrUpdateNeeded && (
                            <Grid item container className={classes.sectionMargin}>
                                <Grid item xs={12}>
                                    <h5>{t('general.eInvoiceAddress')}</h5>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    {updateInfo.newEInvAddrDest === 'customer' && (
                                        <div>
                                            {t('general.customer')}: {updateInfo.customer.name}
                                            <div className={classes.oldinfo}>
                                                "{updateInfo.customer.electronicInvoiceAddress.ovt},&nbsp;
                                                {updateInfo.customer.electronicInvoiceAddress.operatorBrokerId}
                                                ,&nbsp;
                                                {updateInfo.customer.electronicInvoiceAddress.operator}"
                                            </div>
                                        </div>
                                    )}
                                    {updateInfo.newEInvAddrDest === 'billingcustomer' && (
                                        <div>
                                            {t('general.customer')}: {updateInfo.billingCustomer.name}
                                            <div className={classes.oldinfo}>
                                                "{updateInfo.billingCustomer.electronicInvoiceAddress.ovt},&nbsp;
                                                {updateInfo.billingCustomer.electronicInvoiceAddress.operatorBrokerId}
                                                ,&nbsp;
                                                {updateInfo.billingCustomer.electronicInvoiceAddress.operator}"
                                            </div>
                                        </div>
                                    )}
                                    {updateInfo.newEInvAddrDest === 'location' && (
                                        <div>
                                            {t('general.location')}: {updateInfo.location.name}
                                            <div className={classes.oldinfo}>
                                                {updateInfo.location.electronicInvoiceAddress ? (
                                                    <span>
                                                        "{updateInfo.location.electronicInvoiceAddress.ovt},&nbsp;
                                                        {updateInfo.location.electronicInvoiceAddress.operatorBrokerId}
                                                        ,&nbsp;
                                                        {updateInfo.location.electronicInvoiceAddress.operator}"
                                                    </span>
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ArrowForwardIcon />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    "{updateInfo.invoice.electronicInvoiceAddress.ovt},&nbsp;
                                    {updateInfo.invoice.electronicInvoiceAddress.operatorBrokerId},&nbsp;
                                    {updateInfo.invoice.electronicInvoiceAddress.operator}"
                                </Grid>
                            </Grid>
                        )}
                        {updateInfo.emailInvAddrUpdateNeeded && (
                            <Grid item container className={classes.sectionMargin}>
                                <Grid item xs={12}>
                                    <h5>{t('invoice.emailInvoiceAddress')}</h5>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    {updateInfo.newEmailInvAddrDest === 'customer' && (
                                        <div>
                                            {t('general.customer')}: {updateInfo.customer.name}
                                            <div className={classes.oldinfo}>
                                                "{updateInfo.customer.emailInvoiceAddress}"
                                            </div>
                                        </div>
                                    )}
                                    {updateInfo.newEmailInvAddrDest === 'billingcustomer' && (
                                        <div>
                                            {t('general.customer')}: {updateInfo.billingCustomer.name}
                                            <div className={classes.oldinfo}>
                                                "{updateInfo.billingCustomer.emailInvoiceAddress}"
                                            </div>
                                        </div>
                                    )}
                                    {updateInfo.newEmailInvAddrDest === 'location' && (
                                        <div>
                                            {t('general.location')}: {updateInfo.location.name}
                                            <div className={classes.oldinfo}>
                                                {updateInfo.location.emailInvoiceAddress != null ? (
                                                    <span>"{updateInfo.location.emailInvoiceAddress}"</span>
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ArrowForwardIcon />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    "{updateInfo.invoice.emailInvoiceAddress}"
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        name="btn-modal-confirm"
                        onClick={confirm}
                        startIcon={props.busy ? <CircularProgress color={'secondary'} size={'1rem'} /> : null}
                        disabled={props.busy}>
                        {t('buttons.update')}
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        variant="contained"
                        color="default"
                        name="btn-modal-cancel"
                        onClick={cancel}
                        disabled={props.busy}>
                        {t('buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    return null;
}

UpdateCustomerInfoDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    confirm: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    updateInfo: PropTypes.object.isRequired,
    busy: PropTypes.bool.isRequired,
};
