import moment from 'moment';
import i18next from 'i18next';
import { monthNumberToText } from '../common/common';
const _ = require('lodash');

const colorPalette = ['#c98411', '#281300', '#991e00', '#470000', '#ff921f', '#feb950', '#704700', '#8a773c'];
const defaultBarColor = 'rgba(18,147,154,1)';
const defaultLineColor = 'rgba(1,97,101,1)';

function t(key) {
    return i18next.t(key) ? i18next.t(key) : key;
}

/**
 * Formats input data to react-chartjs-2 supported format
 * @param {JSON} reports
 */
export const MapDataToCustomerYearlyChart = (reports) => {
    let colorPaletteIndex = 0;
    let colorList = [];
    let yearIndex = [];
    let yearlyTotalValue = [];

    const getColor = (label) => {
        let index = _.findIndex(colorList, ['label', label]);
        if (index >= 0) {
            //get data from array
            return this.colorList[index].color;
        } else {
            //add value to array
            if (colorPaletteIndex >= colorPalette.length) {
                colorPaletteIndex = 0; //reset running index
            }
            let color = colorPalette[colorPaletteIndex];
            colorPaletteIndex++;
            colorList.push({
                label: label,
                color: color,
            });
            return color;
        }
    };

    const updateYearlyTotal = (year, totalvalue) => {
        let index = _.findIndex(yearlyTotalValue, ['year', year]);
        if (index < 0) {
            //no entry, create one
            yearlyTotalValue.push({
                year: year,
                totalvalue: totalvalue,
            });
        }
    };

    const addOrUpdateData = (reportItem, dataArray, orderValue) => {
        //check does dataArray contain entry
        let index = _.findIndex(dataArray, ['label', reportItem.folderName]);
        let yIndex = yearIndex.indexOf(reportItem.year);
        if (index >= 0) {
            //append to data
            dataArray[index].data[yIndex] = orderValue;
        } else {
            //create new entry
            let dataItem = [];
            dataItem[yIndex] = orderValue;
            dataArray.push({
                label: reportItem.folderName,
                backgroundColor: getColor(reportItem.folderName),
                data: dataItem,
            });
        }
    };

    if (reports != null && reports.length > 0) {
        let reportData = reports;
        //build year index
        yearIndex = reportData.map((r) => r.year).sort();
        let data = [];
        //loop trough report data
        for (let or of reportData) {
            updateYearlyTotal(or.year, or.collectionOrderValue);

            // Add top level folder only in case it has some orders directly under it
            if (or.folderOrderValue) {
                addOrUpdateData(or, data, or.folderOrderValue);
            }

            for (let o of or.orderReport) {
                //total value is picked from previous level
                addOrUpdateData(o, data, o.collectionOrderValue);
            }
        }
        let chartData = {
            labels: yearIndex,
            datasets: data,
            totalvalue: yearlyTotalValue,
        };
        return chartData;
    }
    //if not valid data, return null
    return null;
};

/**
 * Formats input data to react-chartjs-2 supported format
 * @param {JSON} reports
 */
export const MapDataToYearlyChart = (reports) => {
    let colorPaletteIndex = 0;
    let colorList = [];
    let yearIndex = [];
    let yearlyTotalValue = [];

    const getColor = (label) => {
        let index = _.findIndex(colorList, ['label', label]);
        if (index >= 0) {
            //get data from array
            return this.colorList[index].color;
        } else {
            //add value to array
            if (colorPaletteIndex >= colorPalette.length) {
                colorPaletteIndex = 0; //reset running index
            }
            let color = colorPalette[colorPaletteIndex];
            colorPaletteIndex++;
            colorList.push({
                label: label,
                color: color,
            });
            return color;
        }
    };

    const updateYearlyTotal = (year, value) => {
        let index = _.findIndex(yearlyTotalValue, ['year', year]);
        if (index < 0) {
            //no entry, create one
            yearlyTotalValue.push({
                year: year,
                totalvalue: value,
            });
        } else {
            yearlyTotalValue[index].totalvalue += value;
        }
    };

    const addOrUpdateData = (reportItem, dataArray) => {
        //check does dataArray contain entry
        let index = _.findIndex(dataArray, ['label', reportItem.salesPortfolio.name]);
        let yIndex = yearIndex.indexOf(reportItem.year);
        if (index >= 0) {
            //append to data
            dataArray[index].data[yIndex] = reportItem.sales;
        } else {
            //create new entry
            let dataItem = [];
            dataItem[yIndex] = reportItem.sales;
            dataArray.push({
                label: reportItem.salesPortfolio.name,
                backgroundColor: getColor(reportItem.salesPortfolio.name),
                data: dataItem,
            });
        }
    };

    if (reports != null && reports.reports != null && reports.reports.length > 0) {
        let reportData = reports.reports;
        reportData = _.orderBy(reportData, 'year', 'asc');
        const years = _.uniqBy(reportData, 'year');
        yearIndex = years.map((r) => r.year).sort();
        let data = [];

        for (let or of reportData) {
            addOrUpdateData(or, data);
            updateYearlyTotal(or.year, or.sales);
        }
        let chartData = {
            labels: yearIndex,
            datasets: data,
            totalvalue: yearlyTotalValue,
        };
        return chartData;
    }
    return null;
};

/**
 * Formats input data to react-chartjs-2 supported format
 * Input data format:
 *  [{
 *      "year": number,
 *      "data": array [{
 *          "x": number,
 *          "y": decimal}]
 *  }]
 * @param {JSON} reports
 */
export const MapDataToMonthlyChart = (reports, selectedPortfolioId) => {
    let months = [];
    for (let i = 1; i <= 12; i++) {
        months.push(monthNumberToText(i));
    }

    const createDataArray = (data) => {
        let dataArray = [];
        for (let i = 0; i < 12; i++) {
            let dataitems = _.filter(data, function (d) {
                return moment(d.date).month() === i && d.salesPortfolio.id === selectedPortfolioId;
            });
            dataArray[i] = dataitems != null && dataitems.length > 0 ? _.sumBy(dataitems, 'sales') : null;
        }
        return dataArray;
    };
    const buildBarData = (data) => {
        const dataArr = createDataArray(data.reportData);
        return {
            label: data.year.toString(),
            data: dataArr,
            backgroundColor: defaultBarColor,
            order: 2,
        };
    };

    const buildLineData = (data) => {
        const dataArr = createDataArray(data.reportData);
        return {
            label: data.year.toString(),
            data: dataArr,
            backgroundColor: defaultLineColor,
            borderColor: defaultLineColor,
            pointRadius: 5,
            type: 'line',
            order: 1,
            fill: false,
        };
    };

    if (reports !== null && reports.length > 0) {
        const lastyear = _.find(reports, function (o) {
            return o.year === moment().add(-1, 'years').year();
        });
        const currentyear = _.find(reports, function (o) {
            return o.year === moment().year();
        });

        if (!lastyear && !currentyear) return null;

        let datasetArr = [];
        if (lastyear) {
            datasetArr.push(buildLineData(lastyear));
        }
        if (currentyear) {
            datasetArr.push(buildBarData(currentyear));
        }
        let chartData = {
            labels: months,
            datasets: datasetArr,
        };

        return chartData;
    }
    return null;
};

/**
 * Formats input data to react-chartjs-2 supported format
 * @param {JSON} reports
 */
export const MapDataToSalesChart = (reports) => {
    if (reports == null || reports.length === 0) return null;

    let colorPaletteIndex = 0;
    let colorList = [];

    //TODO: new color palette for for sales report or extend color amount
    //TODO: getColor to common function
    const getColor = (label) => {
        let index = _.findIndex(colorList, ['label', label]);
        if (index >= 0) {
            //get data from array
            return this.colorList[index].color;
        } else {
            //add value to array
            if (colorPaletteIndex >= colorPalette.length) {
                colorPaletteIndex = 0; //reset running index
            }
            let color = colorPalette[colorPaletteIndex];
            colorPaletteIndex++;
            colorList.push({
                label: label,
                color: color,
            });
            return color;
        }
    };

    const initnewdataset = (portfoliodatarow, offset = 0) => {
        let data = [];
        if (offset > 0) {
            for (let i = 0; i < offset; i++) {
                data.push(0);
            }
        }
        data.push(portfoliodatarow.sales);
        return {
            label: portfoliodatarow.portfolioName,
            data: data,
            backgroundColor: getColor(portfoliodatarow.portfolioName),
            portfolioid: portfoliodatarow.portfolioId,
        };
    };
    let reportdata = _.sortBy(reports, ['year']);

    let labels = [];
    let datasets = [];
    let portfolioTotal = {};
    let count = 0;

    for (let reportrow of reportdata) {
        labels.push(moment(reportrow.startDate).format('D.M.') + ' - ' + moment(reportrow.endDate).format('D.M.YYYY'));
        let reportPortfolioIds = [];
        for (let portfoliodatarow of reportrow.portfolioData) {
            reportPortfolioIds.push(portfoliodatarow.portfolioId);
            let dataset = _.find(datasets, ['portfolioid', portfoliodatarow.portfolioId]);
            if (dataset === undefined) {
                dataset = initnewdataset(portfoliodatarow, count);
                datasets.push(dataset);
            } else {
                dataset.data.push(portfoliodatarow.sales);
            }
            if (!(portfoliodatarow.portfolioId in portfolioTotal)) {
                portfolioTotal[portfoliodatarow.portfolioId] = portfoliodatarow.sales;
            } else {
                portfolioTotal[portfoliodatarow.portfolioId] += portfoliodatarow.sales;
            }
        }
        //check "removed" portfolios
        for (let dataset of datasets) {
            if (!_.includes(reportPortfolioIds, dataset.portfolioid)) {
                dataset.data.push(0);
            }
        }
        count++;
    }
    //build total
    labels.push(t('reports.altogether'));
    for (let dataitem of datasets) {
        dataitem.data.push(portfolioTotal[dataitem.portfolioid]);
    }
    return {
        labels: labels,
        datasets: datasets,
    };
};

/**
 * Formats input data to react-chartjs-2 supported format
 * @param {JSON} reports
 */
export const MapDataToSalesTotalChart = (reports) => {
    if (reports == null || reports.length === 0) return null;

    let reportdata = _.sortBy(reports, ['year']);
    let labels = [];
    let data = [];

    for (let reportrow of reportdata) {
        labels.push(moment(reportrow.startDate).format('D.M.') + ' - ' + moment(reportrow.endDate).format('D.M.YYYY'));
        let sumOfPortfolios = 0;
        for (let portfoliodatarow of reportrow.portfolioData) {
            sumOfPortfolios += portfoliodatarow.sales;
        }
        data.push(sumOfPortfolios);
    }

    let dataset = {
        label: t('general.invoicing'),
        data: data,
        backgroundColor: defaultBarColor,
    };

    return {
        labels: labels,
        datasets: [dataset],
    };
};

export const MapDataToSalesTable = (reports) => {
    if (reports == null || reports.length === 0) return null;

    let reportdata = _.sortBy(reports, ['year']);
    let tablehead = [t('general.portfolio')];
    let tabledatarows = [];
    let index = 0;
    for (let reportrow of reportdata) {
        tablehead.push(
            moment(reportrow.startDate).format('D.M.') + ' - ' + moment(reportrow.endDate).format('D.M.YYYY')
        );
        for (let datarow of reportrow.portfolioData) {
            let tabledata = _.find(tabledatarows, ['id', datarow.portfolioId]);
            if (tabledata) {
                tabledata.data.push(datarow.sales);
            } else {
                let newentry = { id: datarow.portfolioId, data: [datarow.portfolioName] };
                for (let i = 0; i < index; i++) {
                    newentry.data.push(0);
                }
                newentry.data.push(datarow.sales);
                tabledatarows.push(newentry);
            }
        }
        index++;
        //fill missing data (portfolio removed or no orders)
        for (let tbldatarow of tabledatarows) {
            if (tbldatarow.data.length < index + 1) tbldatarow.data.push(0);
        }
    }

    let tablesumrow = [t('reports.altogether')];
    for (let ri = 1; ri < index + 1; ri++) {
        tablesumrow.push(0);
        for (let row of tabledatarows) {
            tablesumrow[ri] += row.data[ri];
        }
    }

    let tabledata = [];
    for (let row of tabledatarows) {
        tabledata.push(row.data);
    }
    tabledata.push(tablesumrow);

    return {
        tablehead: tablehead,
        tabledata: tabledata,
    };
};
