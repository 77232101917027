import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actActions from '../modules/actiondata';
import * as orderActions from '../modules/orderdata';
import * as locationActions from '../modules/locationdata';
import Home from './home';

class HomeContainer extends React.Component {
    render() {
        return (
            <Home
                actions={this.props.actions}
                overdueActions={this.props.overdueActions}
                fetching={this.props.fetching}
                authenticated={this.props.authenticated}
                pendingOrders={this.props.pendingOrders}
                getActionDataByMonthYear={this.props.getActionDataByMonthYear}
                getOverdueActionData={this.props.getOverdueActionData}
                newOrderFromLocations={this.props.newOrderFromLocations}
                history={this.props.history}
                userProfile={this.props.userProfile}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    actions: state.actiondata.actions,
    overdueActions: state.actiondata.overdueActions,
    fetching: state.actiondata.isFetchingActionData,
    authenticated: state.authentication.authenticated,
    pendingOrders: state.orderdata.pendingOrders,
    locationsById: state.locationdata.locationsById,
    userProfile: state.authentication.userProfile,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ ...actActions, ...orderActions, ...locationActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
