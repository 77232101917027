import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';
const _ = require('lodash');

export const UPDATING_SEARCH_RESULTS = 'search/updating';
export const UPDATE_SEARCH_TERM = 'search/updateterm';
export const UPDATE_DONE = 'search/done';
export const UPDATE_FAILED = 'search/failed';
export const CLEAR_SEARCH = 'search/clear';

export class DataItem {
    constructor(name) {
        this.name = name;
        this.results = [];
        this.loading = false;
    }
}

const initialState = {
    data: [new DataItem('orders'), new DataItem('locations'), new DataItem('customers')],
    searchTerm: '',
};

const setLoadingForEachAndResetData = (array, loading) => {
    for (let item of array) {
        item.loading = loading;
        item.results = [];
    }
};

const setDataForItem = (array, name, data) => {
    _.find(array, (item) => item.name === name).results = data;
    _.find(array, (item) => item.name === name).loading = false;
};

export default (state = initialState, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            if (action.payload.location.pathname.toLowerCase().search('search') === -1) {
                return {
                    ...state,
                    searchTerm: '',
                };
            }
            return state;
        case UPDATE_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.term,
            };
        case UPDATING_SEARCH_RESULTS: {
            let newData = _.clone(state.data);
            setLoadingForEachAndResetData(newData, true);
            return {
                ...state,
                data: newData,
            };
        }
        case UPDATE_DONE: {
            let newData = _.clone(state.data);
            setDataForItem(newData, action.name, action.data);
            return {
                ...state,
                results: null,
                data: newData,
            };
        }
        case UPDATE_FAILED: {
            let newData = _.clone(state.data);
            setLoadingForEachAndResetData(newData, false);
            return {
                ...state,
                results: null,
                data: newData,
            };
        }
        case CLEAR_SEARCH:
            return initialState;
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const updateTerm = (term) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SEARCH_TERM,
            term: term,
        });
    };
};

export const clear = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SEARCH,
        });
    };
};

export const searchUpdate = (term) => {
    return (dispatch) => {
        dispatch({
            type: UPDATING_SEARCH_RESULTS,
        });
        Ajax.post('api/search/order/', { term: term })
            .then(function (data) {
                dispatch({ type: UPDATE_DONE, name: 'orders', data: data.data });
            })
            .catch(function (err) {
                dispatch({ type: UPDATE_FAILED });
            });
        Ajax.post('api/search/location/', { term: term })
            .then(function (data) {
                dispatch({ type: UPDATE_DONE, name: 'locations', data: data.data });
            })
            .catch(function (err) {
                dispatch({ type: UPDATE_FAILED });
            });
        Ajax.post('api/search/customer/', { term: term })
            .then(function (data) {
                dispatch({ type: UPDATE_DONE, name: 'customers', data: data.data });
            })
            .catch(function (err) {
                dispatch({ type: UPDATE_FAILED });
            });
    };
};
