import React from 'react';
import i18next from 'i18next';

function t(key, params) {
    return i18next.t(key, params) ? i18next.t(key, params) : key;
}

class LocationItem extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.props.history.push('/locations/' + this.props.locationDesc.id);
    }

    render() {
        let location = this.props.locationDesc;
        const title = location.usesRemovedProducts ? t('location.expiredProductsInUse') : '';
        return (
            <div>
                <div className="location-item" onClick={this.onClick} title={title}>
                    {location.name}
                    {location.usesRemovedProducts && (
                        <span>
                            &nbsp;
                            <i className="fas fa-exclamation-triangle" style={{ color: 'orange' }}></i>
                        </span>
                    )}
                    {this.props.showUpdateDate && <span style={{ float: 'right' }}>{this.props.showUpdateDate}</span>}
                </div>
            </div>
        );
    }
}

export default LocationItem;
