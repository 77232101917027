import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import { getUser, saveUser, removeUser } from '../modules/userdataactions';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import User from '../domain/user';
import ResetPasswordDialog from './resetpassworddialog';
import DeleteConfirmDialog from '../common/deleteconfirmdialog';
import { verifyPhonenumber } from '../common/common';
import RepresentationsList from './representationslist';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    formControl: {
        margin: theme.spacing(1),
        width: '95%',
    },
    removeRep: {
        color: 'red',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function UserEditView(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const getUserByIdInProgress = useSelector((state) => state.userdata.getUserByIdInProgress);
    const usersById = useSelector((state) => state.userdata.usersById);
    const userId = parseInt(props.match.params.userId, 10);
    const [user, setUser] = useState(null);
    const [saving, setSaving] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const userFromStore = usersById[userId];
    const rolesForOrgUsers = User.RolesForOrgUsers();
    const rolesForCustomers = User.RolesForCcUsers();
    const { t } = useTranslation();
    let availableRoles = null;

    useEffect(() => {
        if (usersById[userId] == null && !getUserByIdInProgress) {
            getUser(userId)(dispatch);
        } else if (usersById[userId] != null) {
            setUser(usersById[userId]);
        }
    }, [usersById, getUserByIdInProgress, dispatch, userId]);

    const close = () => {
        props.history.goBack();
    };

    const handleChange = (evt) => {
        const value = evt.target.value;
        const name = evt.target.name;
        let newUser = new User(user);
        switch (name) {
            case 'rolesselect':
                newUser.roles = [];
                for (let r of value) {
                    newUser.roles.push(r);
                }
                break;
            case 'userStatus':
                newUser.enabled = value;
                break;
            case 'userName':
                newUser.name = value;
                break;
            case 'userPhonenumber':
                newUser.phoneNumber = value.trim();
                break;
            default:
                break;
        }
        setUser(newUser);
    };

    const save = () => {
        setSaving(true);
        saveUser(user)(dispatch)
            .then(() => {
                setSaving(false);
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const hasChanges = () => {
        return !_.isEqual(user, userFromStore);
    };
    const hasErrors = () => {
        return !verifyPhonenumber(user.phoneNumber);
    };
    const passwordReseted = () => {
        setShowResetPassword(false);
    };
    const remove = () => {
        setShowDeleteConfirm(true);
    };
    const doRemoveUser = () => {
        setShowDeleteConfirm(false);
        setSaving(true);
        removeUser(user)(dispatch)
            .then(() => {
                props.history.goBack();
            })
            .catch(() => {
                setSaving(false);
            });
    };

    const repChanged = (newRep) => {
        let newUser = new User(user);
        newUser.representations = newRep;
        setUser(newUser);
    };

    if (user) {
        if (user.isCustomerUser) {
            availableRoles = rolesForCustomers;
        } else {
            availableRoles = rolesForOrgUsers;
        }
    }

    return (
        <Grid container>
            {user == null && getUserByIdInProgress && <em>{t('general.retrievingInformation')}</em>}
            {user != null && (
                <Grid container item>
                    <Grid item xs={12}>
                        <h5>
                            <Button onClick={close}>
                                <i className="fas fa-chevron-left fa-2x"></i>&nbsp;&nbsp;
                            </Button>
                            {user.username}
                        </h5>
                    </Grid>

                    <Grid item xs={12}>
                        <span className={classes.textField}>Amazon: {user.amazonStatus}</span>
                    </Grid>

                    {user.isCustomerUser && (
                        <Grid item container>
                            <Grid item xs={12} md={6} style={{ fontWeight: 'bold' }}>
                                {t('admin.represents')}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RepresentationsList
                                    representations={user.representations}
                                    allowEdit={true}
                                    representationsChanged={repChanged}
                                />
                            </Grid>
                        </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                        <FormControl className={classes.select}>
                            <InputLabel id="user-status-label">{t('general.state')}</InputLabel>
                            <Select
                                required
                                error={false}
                                labelId="user-status-label"
                                id="userStatus"
                                name="userStatus"
                                value={user.enabled}
                                onChange={handleChange}>
                                <MenuItem value={false}>{t('general.stateDisabled')}</MenuItem>
                                <MenuItem value={true}>{t('general.stateActive')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            error={user.name == null || user.name.length === 0}
                            id="userName"
                            name="userName"
                            label={t('general.name')}
                            value={user.name}
                            className={classes.textField}
                            margin="normal"
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            error={user.phoneNumber == null || !verifyPhonenumber(user.phoneNumber)}
                            id="userPhonenumber"
                            name="userPhonenumber"
                            label={t('general.phone')}
                            value={user.phoneNumber}
                            className={classes.textField}
                            margin="normal"
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="rolesLabel">{t('admin.roles')}</InputLabel>
                            <Select
                                labelId="rolesLabel"
                                id="rolesselect"
                                name="rolesselect"
                                multiple
                                value={user.roles.map((role) => role)}
                                onChange={handleChange}
                                input={<Input id="selectRoles" />}
                                renderValue={() => (
                                    <div className={classes.chips}>
                                        {user.roles.map((role) => (
                                            <Chip key={role} label={role} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}>
                                {availableRoles.map((role) => (
                                    <MenuItem key={role} value={role}>
                                        {role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            )}
            <Footer>
                <Grid item>
                    <Button
                        variant="contained"
                        name="poista"
                        color="secondary"
                        onClick={remove}
                        disabled={saving || !userFromStore || userFromStore.enabled}>
                        <i className="fas fa-trash"></i> &nbsp;{t('buttons.remove')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        name="btn-save"
                        disabled={saving || !user || !hasChanges() || hasErrors()}
                        onClick={save}>
                        <i className="fas fa-save"></i>&nbsp;{t('buttons.save')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        name="btn-reset-password"
                        onClick={() => setShowResetPassword(true)}
                        disabled={saving || !user}>
                        &nbsp;{t('admin.resetPassword')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        name="btn-close"
                        disabled={saving || !user}
                        onClick={close}>
                        {t('buttons.close')}
                    </Button>
                </Grid>
            </Footer>
            <FooterPadding />
            <ResetPasswordDialog
                open={showResetPassword}
                user={user}
                passwordReseted={passwordReseted}
                cancel={() => setShowResetPassword(false)}
            />
            {user && (
                <DeleteConfirmDialog
                    open={showDeleteConfirm}
                    itemToDelete={`käyttäjän ${user.username}`}
                    cancel={() => setShowDeleteConfirm(false)}
                    confirmDelete={doRemoveUser}
                />
            )}
        </Grid>
    );
}
