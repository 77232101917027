import ObjectIdGenerator from '../modules/objectidgenerator';
import User from './user';

class Portfolio {
    constructor(json) {
        if (json) {
            this.id = json.id;
            this.name = json.name;
            this.users = Portfolio.loadUsers(json.users);
            this.storageId = json.storageId;
        } else {
            this.id = 0;
            this.name = '';
            this.users = [];
        }
    }

    addUser(user, callback) {
        PortfolioUser.NewObject(this.id, user).then(
            (portfolio_user) => {
                this.users.push(portfolio_user);
                callback(portfolio_user);
            },
            (failureReason) => {
                console.error(failureReason);
                callback(null);
            }
        );
    }

    static NewObject(name) {
        const promise = new Promise(function (resolve, reject) {
            ObjectIdGenerator.newId()
                .then(function (id) {
                    resolve(new Portfolio({ id, name }));
                })
                .catch(function (err) {
                    console.log(err);
                    reject();
                });
        });
        return promise;
    }

    static loadUsers(users) {
        let list = [];
        if (users) {
            for (var key in users) {
                list.push(new PortfolioUser(users[key]));
            }
        }
        return list;
    }
}

class PortfolioUser {
    constructor(json) {
        if (json) {
            this.id = json.id;
            this.portfolioStorageId = json.portfolioStorageId;
            this.user = new User(json.user);
            this.storageId = json.storageId;
            this.userStorageId = this.user ? this.user.storageId : 0;
        } else {
            this.id = 0;
            this.portfolioStorageId = 0;
            this.user = null;
            this.storageId = 0;
            this.userStorageId = 0;
        }
    }
    static NewObject(portfolioStorageId, user) {
        const promise = new Promise(function (resolve, reject) {
            ObjectIdGenerator.newId()
                .then(function (id) {
                    resolve(new PortfolioUser({ id, portfolioStorageId, user }));
                })
                .catch(function (err) {
                    console.log(err);
                    reject();
                });
        });
        return promise;
    }
}

class PortfolioDescription {
    constructor(json) {
        if (json) {
            this.id = json.id;
            this.name = json.name;
        } else {
            this.id = 0;
            this.name = '';
        }
    }
}

export { Portfolio, PortfolioUser, PortfolioDescription };
