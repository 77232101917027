import React from 'react';
import { rbacrules } from './rbacrules';

class RbacMenuItemAuth extends React.Component {
    render() {
        const user = this.props.user;
        const menuitem = this.props.menuitem;
        const children = this.props.children;

        let allowedMenuItems = [];
        if (user != null && user.roles != null) {
            for (let r of user.roles) {
                allowedMenuItems = rbacrules[r].menuitems.concat(allowedMenuItems);
            }
        } else {
            return null;
        }

        if (allowedMenuItems.indexOf(menuitem) !== -1) return <React.Fragment>{children}</React.Fragment>;
        else return null;
    }
}

export default RbacMenuItemAuth;
