import Ajax from './ajax';

export async function createHtmlToPdf(base64html, fileName = 'document.pdf') {
    try {
        const response = await Ajax.postBase64ReturnBlob('api/pdf', base64html, 'application/pdf');

        if (response.status === 200) {
            var blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(url);
            return true;
        }
        return false;
    } catch (err) {
        console.log(err);
        return false;
    }
}
