import LocationDescription from './locationdescription';

class LocationSearchOutput {
    constructor(json) {
        if (json != null) {
            if (json.duplicatesByName && json.duplicatesByName.length > 0) {
                this.duplicatesByName = json.duplicatesByName.map((l) => new LocationDescription(l));
            } else {
                this.duplicatesByName = [];
            }
            if (json.duplicatesByBusinessId && json.duplicatesByBusinessId.length > 0) {
                this.duplicatesByBusinessId = json.duplicatesByBusinessId.map((l) => new LocationDescription(l));
            } else {
                this.duplicatesByBusinessId = [];
            }
        }
    }
}

export default LocationSearchOutput;
