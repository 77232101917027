import React, { useState } from 'react';
import TreeItem from './treeitem';
import TreeDescription from '../domain/treedescription';
import * as locationActions from '../modules/locationdata';
import Location from '../domain/location';
import store from '../store';
import LocationNameInputDialog from './locationNameInputDialog';
import { useTranslation } from 'react-i18next';
import TextInputDialog from '../common/textinputdialog';
import ParameterValue from '../domain/parametervalue';
const _ = require('lodash');

export const Tree = (props) => {
    const [targetFolderId, setTargetFolderId] = useState(null);
    const [folderNameDialogOpen, setFolderNameDialogOpen] = useState(false);
    const [locationNameModalOpen, setLocationNameModalOpen] = useState(false);
    const [folderNameEditMode, setFolderNameEditMode] = useState(false);
    const [folderItemOnEdit, setFolderItemOnEdit] = useState(null);
    const { t } = useTranslation();

    const createId = () => {
        let rnd = Math.random() * 999999;
        return Math.floor(rnd);
    };

    const findItemNode = (node, locId) => {
        let locNode = null;
        if (node.locations.length > 0) {
            locNode = node.locations.find((loc) => loc.id === locId);
        }
        if (locNode == null && node.childFolders.length > 0) {
            for (let i = 0; i < node.childFolders.length; i++) {
                let retLoc = findItemNode(node.childFolders[i], locId);
                if (retLoc != null) {
                    locNode = retLoc;
                    break;
                }
            }
        }
        return locNode;
    };

    const findFolder = (node, folId) => {
        let targetFol = null;
        if (node.childFolders.length > 0) {
            targetFol = node.childFolders.find((fol) => fol.id === folId);
            if (targetFol == null && node.childFolders.length > 0) {
                for (let i = 0; i < node.childFolders.length; i++) {
                    let retFolder = findFolder(node.childFolders[i], folId);
                    if (retFolder != null) {
                        return retFolder;
                    }
                }
            }
        }
        return targetFol;
    };

    const updateFolderName = (node, folId, newName) => {
        if (node.id === folId) {
            node.name = newName;
        } else if (node.childFolders.length > 0) {
            for (let i = 0; i < node.childFolders.length; i++) {
                updateFolderName(node.childFolders[i], folId, newName);
            }
        }
        return node;
    };

    const removeLoc = (node, locId) => {
        let locNode = null;
        if (node.locations.length > 0) {
            locNode = node.locations.find((loc) => loc.id === locId);
            if (locNode) {
                node.locations = node.locations.filter((loc) => loc.id !== locId);
            }
        }
        if (locNode == null && node.childFolders.length > 0) {
            locNode = node.childFolders.map((node) => removeLoc(node, locId));
        }
        return node;
    };

    const removeFolderFromTree = (node, folId) => {
        let targetFol = null;
        if (node.childFolders.length > 0) {
            targetFol = node.childFolders.find((fol) => fol.id === folId);
            if (targetFol) {
                node.childFolders = node.childFolders.filter((fol) => fol.id !== folId);
            }
        }
        if (targetFol == null && node.childFolders.length > 0) {
            targetFol = node.childFolders.map((node) => removeFolderFromTree(node, folId));
        }
        return node;
    };

    const findNodeAddLoc = (node, destinationFolderId, loc) => {
        if (node.id === destinationFolderId) {
            node.locations.push(loc);
            return node;
        } else if (node.childFolders.length > 0) {
            node.childFolders.map((node) => findNodeAddLoc(node, destinationFolderId, loc));
        }
        return node;
    };

    const findNodeAddFolder = (node, destinationFolderId, newFolder) => {
        if (node.id === destinationFolderId) {
            node.childFolders.push(newFolder);
            return node;
        } else if (node.childFolders.length > 0) {
            node.childFolders.map((node) => findNodeAddFolder(node, destinationFolderId, newFolder));
        }
        return node;
    };

    const moveItem = async (fromId, toId) => {
        if (fromId === toId) return;
        let treenodes = _.cloneDeep(await getCustomerTreeData());
        let toBeMoved = findItemNode(treenodes, fromId);
        treenodes = removeLoc(treenodes, fromId);
        let newnodes = findNodeAddLoc(treenodes, toId, toBeMoved);
        props.updateCustomerTreedata(props.customer.id, newnodes);
    };

    const removeFolder = async (folderId) => {
        let treenodes = _.cloneDeep(await getCustomerTreeData());
        let newnodes = removeFolderFromTree(treenodes, folderId);
        props.updateCustomerTreedata(props.customer.id, newnodes);
    };

    const addNewFolder = (targetFolderId) => {
        setFolderNameDialogOpen(true);
        setTargetFolderId(targetFolderId);
    };

    const renameFolder = async (folderId) => {
        //acquire target folder object
        let targetFolder = findFolder(await getCustomerTreeData(), folderId);
        //update state to open folder name modal and set state to match folder edit
        setFolderNameDialogOpen(true);
        setFolderNameEditMode(true);
        setFolderItemOnEdit(targetFolder);
    };

    const addNewLocation = (targetFolderId) => {
        getCustomerFull(props.customer.id);
        setLocationNameModalOpen(true);
        setTargetFolderId(targetFolderId);
    };

    const saveNewFolder = async (folderName) => {
        let newId = createId();
        let folderId = targetFolderId;
        let newFolder = new TreeDescription({
            id: newId,
            customerId: props.customer.id,
            isRoot: false,
            name: folderName,
        });
        let treenodes = _.cloneDeep(await getCustomerTreeData());
        let newnodes = findNodeAddFolder(treenodes, folderId, newFolder);

        props.updateCustomerTreedata(props.customer.id, newnodes);
        setFolderNameDialogOpen(false);
        setTargetFolderId(null);
    };

    const saveNewFolderName = async (folderName) => {
        let treenodes = _.cloneDeep(await getCustomerTreeData());
        let newnodes = updateFolderName(treenodes, folderItemOnEdit.id, folderName);
        props.updateCustomerTreedata(props.customer.id, newnodes);
        setFolderNameDialogOpen(false);
        setFolderNameEditMode(false);
        setFolderItemOnEdit(null);
    };

    const confirmFoldername = (folderName) => {
        if (folderNameEditMode) {
            saveNewFolderName(folderName);
        } else {
            saveNewFolder(folderName);
        }
    };

    const cancelFolderName = () => {
        setFolderNameDialogOpen(false);
        setFolderNameEditMode(false);
        setFolderItemOnEdit(null);
    };

    const cancelLocationName = () => {
        setLocationNameModalOpen(false);
    };

    const confirmLocationName = async (name) => {
        Location.NewObject().then((loc) => {
            loc.name = name;
            loc.customer = props.customersById[props.customer.id];
            loc.useCustomerEInvoiceInfo = props.customersById[props.customer.id].electronicInvoiceAddress != null;
            loc.useCustomerBillingAddress = props.customersById[props.customer.id].billingAddress != null;
            loc.contractNumber =
                props.customersById[props.customer.id].contractNumber != null
                    ? props.customersById[props.customer.id].contractNumber
                    : new ParameterValue({ category: 'ContractNumber', id: 1, value: 'Ei sopimustunnusta' });
            // save location
            store.dispatch(locationActions.saveLocation(loc, false, false)).then(async (savedLoc) => {
                let treenodes = _.cloneDeep(await getCustomerTreeData());
                let newnodes = findNodeAddLoc(treenodes, targetFolderId, savedLoc);
                props.updateCustomerTreedata(props.customer.id, newnodes);
                setLocationNameModalOpen(false);
                setTargetFolderId(null);
            });
        });
    };

    const addToCart = (ids) => {
        props.newOrderFromLocations(ids);
    };

    const getCustomerTreeData = async () => {
        if (!props.customersById[props.customer.id]) await getCustomerFull(props.customer.id);

        return props.customersById[props.customer.id].treeData;
    };

    const getCustomerFull = async (customerId) => {
        if (!props.customersById[customerId]) await props.getCustomerFull(customerId);
    };

    if (!props.customer) return null;

    const customer = !!props.customersById[props.customer.id] ? props.customersById[props.customer.id] : props.customer;

    const treenodes = customer.treeData;
    // muodostetaan asiakastason tilauksen arvo jos data sisältää tiedon.
    let orderValue = undefined;
    if (customer.treeData) {
        const customerOrderValue = customer.treeData.orderValue;
        orderValue = customerOrderValue ? customerOrderValue : undefined;
    }

    return (
        <div>
            <TreeItem
                id={treenodes ? treenodes.id : customer.id}
                treedata={treenodes}
                customerId={customer.id}
                name={customer.name}
                onNewFolder={addNewFolder}
                onRenameFolder={renameFolder}
                onRemoveFolder={removeFolder}
                onNewLocation={addNewLocation}
                type={'root'}
                onMoveItem={moveItem}
                editMode={props.edit}
                history={props.history}
                addToCart={addToCart}
                getCustomerFull={getCustomerFull}
                store={store}
                userPortfolios={props.userPortfolios}
                orderValue={orderValue}
            />
            <TextInputDialog
                textFieldLabel={t('general.folderName')}
                open={folderNameDialogOpen}
                confirm={confirmFoldername}
                cancel={cancelFolderName}
                text={folderItemOnEdit ? folderItemOnEdit.name : ''}
            />
            <LocationNameInputDialog
                dialogOpen={locationNameModalOpen}
                cancel={cancelLocationName}
                confirm={confirmLocationName}
            />
        </div>
    );
};
