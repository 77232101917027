import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import InvoiceListView from './invoicelistview';
import InvoiceListSearch from './invoicelistsearch';
import { Switch, makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { searchInvoices } from '../modules/invoicedata';

const useStyles = makeStyles((theme) => ({
    switch: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function InvoiceListContainer(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const features = useSelector((state) => state.settingsdata.features);
    const invoiceParameters = useSelector((state) => state.settingsdata.invoiceParameters);
    const searchResults = useSelector((state) => state.invoicedata.searchResults);
    const [filter, setFilter] = React.useState('');
    const [viewFilterDraftOnly, setViewFilterDraftOnly] = React.useState(true);
    const { t } = useTranslation();
    const salesInvoiceIntegrationInUse =
        features && features.salesInvoiceIntegration ? features.salesInvoiceIntegration : '';
    const allowedEInvOperatorsNetvisor = invoiceParameters
        ? invoiceParameters.eInvoiceOperatorsNetvisor.allowedOperatorBrokerIds
        : null;
    const vatCodes = invoiceParameters ? invoiceParameters.invoiceVatParamsNetvisor.vatCodes : null;
    const timerHandle = React.useRef(null);

    const onFilterChange = (evt) => {
        const newFilt = evt.target.value;
        if (timerHandle.current) clearTimeout(timerHandle.current);
        setFilter(evt.target.value);
        timerHandle.current = setTimeout(() => {
            dispatch(searchInvoices(newFilt));
        }, 500);
    };

    const onCheckBoxChange = () => {
        setViewFilterDraftOnly(!viewFilterDraftOnly);
    };

    return (
        <div>
            <Grid container>
                <InvoiceListSearch onFilterChange={onFilterChange} filter={filter} />
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                checked={viewFilterDraftOnly}
                                onChange={onCheckBoxChange}
                                name="draft-orders-only"
                                color="primary"
                            />
                        }
                        label={t('invoice.showOnlyOpenInvoices')}
                    />
                </Grid>
                <InvoiceListView
                    salesInvoiceIntegrationInUse={salesInvoiceIntegrationInUse}
                    searchResults={searchResults}
                    filter={filter}
                    history={props.history}
                    viewOnlyDraft={viewFilterDraftOnly}
                    allowedEInvOperatorsNetvisor={allowedEInvOperatorsNetvisor}
                    vatCodes={vatCodes}
                />
            </Grid>
        </div>
    );
}
