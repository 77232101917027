import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as orderActions from '../modules/orderdata';
import * as locationActions from '../modules/locationdata';
import ShoppingBasket from './shoppingbasket';

class ShoppingBasketContainer extends React.Component {
    constructor(props) {
        super(props);
        this.removeOrder = this.removeOrder.bind(this);
        this.saveOrder = this.saveOrder.bind(this);
        this.saveOrderShallow = this.saveOrderShallow.bind(this);
        this.sendOrders = this.sendOrders.bind(this);
        this.sendOrdersCombined = this.sendOrdersCombined.bind(this);
        this.getLocationFull = this.getLocationFull.bind(this);
        this.saveFilterOrder = this.saveFilterOrder.bind(this);

        this.state = {
            sendingOrdersModalOpen: false,
            combineOrders: false,
            combinedOrder: {},
        };
    }

    removeOrder(id) {
        this.props.removeOrder(id);
    }
    saveOrder(order) {
        this.sanitizeOrder(order);
        this.props.saveOrder(order);
    }
    async saveOrderShallow(order) {
        this.sanitizeOrder(order);
        await this.props.saveOrderShallow(order);
    }
    async saveFilterOrder(order, fOrder) {
        this.sanitizeOrder(order);
        this.sanitizeFo(fOrder);
        await this.props.saveFilterOrder(order, fOrder);
    }
    sendOrders(orders, options, language) {
        this.props.sendOrders(orders, options, language);
    }
    sendOrdersCombined(orders, options, language, reservations) {
        this.props.sendOrdersCombined(orders, options, language, reservations);
    }
    getLocationFull(id) {
        this.props.getLocationFull(id);
    }

    sanitizeFo = (fo) => {
        fo.count = parseInt(fo.count);
    };

    sanitizeOrder = (order) => {
        if (order.orderedFilters != null && order.orderedFilters.length > 0) {
            for (let fo of order.orderedFilters) {
                fo.count = parseInt(fo.count);
            }
        }
    };

    render() {
        return (
            <ShoppingBasket
                customerView={false}
                pendingOrders={this.props.pendingOrders}
                sendingOrdersInProgress={this.props.sendingOrdersInProgress}
                orderSendFailed={this.props.orderSendFailed}
                locationsById={this.props.locationsById}
                removeOrder={this.removeOrder}
                saveOrder={this.saveOrder}
                saveOrderShallow={this.saveOrderShallow}
                sendOrders={this.sendOrders}
                sendOrdersCombined={this.sendOrdersCombined}
                getLocationFull={this.getLocationFull}
                saveFilterOrder={this.saveFilterOrder}
                userProfile={this.props.userProfile}
                savingCombinedOrder={this.props.savingCombinedOrder}
                updateOrdersCanBeCombined={this.updateOrdersCanBeCombined}
                saveOrderShallowBatch={this.props.saveOrderShallowBatch}
                parameters={this.props.parameters}
                savingOrder={this.props.savingOrder}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    pendingOrders: state.orderdata.pendingOrders,
    sendingOrdersInProgress: state.orderdata.sendingOrdersInProgress,
    orderSendFailed: state.orderdata.orderSendFailed,
    locationsById: state.locationdata.locationsById,
    userProfile: state.authentication.userProfile,
    savingCombinedOrder: state.orderdata.savingCombinedOrder,
    parameters: state.parameterdata.parameters,
    savingOrder: state.orderdata.orderSavingInProgress,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...orderActions, ...locationActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingBasketContainer);
