import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import LocationEditViewContainer from '../location/locationeditviewcontainer';
import CustomerEditViewContainer from '../customer/customereditviewcontainer';
import LocationPrintView from '../location/locationprintview';
import CustomerView from '../customer/customerview';
import LocationView from '../location/locationview';
import LocationViewEnergy from '../location/locationviewenergy';
import ProductionOrderViewContainer from '../order/productionorderviewcontainer';
import PortfolioView from '../portfolio/portfolioview';
import PortfolioEditView from '../portfolio/portfolioeditview';
import AppRoutesStatic from '../routes/routes';
import GlobalSpinner from '../common/globalspinner';
import MenuBar from './menubar';
import RbacRoute from '../rbac/rbacroute';
import UserEditView from '../admin/usereditview';
import SearchResults from './searchresults';
import NewUserView from '../admin/newuserview';
import InvoiceEditView from '../invoice/invoiceeditview';
import ProductEditView from '../products/producteditview';
import OrderEditViewContainer from '../order/ordereditviewcontainer';
import ProductBundleEditView from '../products/productbundleeditview';
import WebSocketConnector from './websocketconnector';
import LifecycleMonitor from './lifecyclemonitor';
import SurveyNtf from '../survey/surveyntf';

class Main extends Component {
    state = { isOpen: true };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.getContentClassName = this.getContentClassName.bind(this);
        this.state = {
            isOpen: false,
        };
        this.isCustomerSite = process.env.REACT_APP_TARGET === 'customerui';
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    getContentClassName() {
        if (this.props.location.search === '?sidebar=false') return 'page-content-full';
        return 'page-content';
    }

    render() {
        let shoppingCartContent = this.isCustomerSite
            ? this.props.pendingCustomerOrders != null && this.props.pendingCustomerOrders.length > 0
                ? this.props.pendingCustomerOrders.length
                : null
            : this.props.pendingOrders != null && this.props.pendingOrders.length > 0
            ? this.props.pendingOrders.length
            : null;
        return (
            <div>
                <GlobalSpinner />
                <WebSocketConnector authenticated={this.props.authenticated} />
                <LifecycleMonitor authenticated={this.props.authenticated} />
                <MenuBar
                    location={this.props.location}
                    shoppingCartContent={shoppingCartContent}
                    history={this.props.history}
                    profile={this.props.userProfile}
                />

                {!this.isCustomerSite && <SurveyNtf />}

                <div className={this.getContentClassName()}>
                    <Route exact path="/" render={() => <Redirect to="/home" />} />

                    {AppRoutesStatic.map((route) => (
                        <RbacRoute
                            key={route.Id}
                            user={this.props.userProfile}
                            path={route.Path}
                            component={route.Component}
                        />
                    ))}
                    {!this.isCustomerSite && (
                        <div>
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/locations/edit/:locationId"
                                component={LocationEditViewContainer}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/customers/edit/:customerId"
                                component={CustomerEditViewContainer}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/locations/print/:locationId"
                                component={LocationPrintView}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/customers/:customerId"
                                render={(props) => <CustomerView {...props} history={this.props.history} />}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/locations/:locationId"
                                component={LocationView}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/locations/:locationId/energy"
                                component={LocationViewEnergy}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/ordernumber/:orderNumber"
                                component={ProductionOrderViewContainer}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/admin/portfolio/:portfolioId"
                                component={PortfolioView}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/admin/portfolio/edit/:portfolioId"
                                component={PortfolioEditView}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/admin/user/edit/:userId"
                                component={UserEditView}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/product/edit/:productId"
                                component={ProductEditView}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/search/:term"
                                render={(props) => <SearchResults {...props} history={this.props.history} />}
                            />
                            <RbacRoute user={this.props.userProfile} path="/admin/user/new" component={NewUserView} />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/invoices/edit/:invoiceid"
                                component={InvoiceEditView}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/ordernumber/edit/:orderNumber"
                                component={OrderEditViewContainer}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/product/bundleedit/:bundleId"
                                component={ProductBundleEditView}
                            />
                        </div>
                    )}
                    {this.isCustomerSite && (
                        <div>
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/locations/:locationId"
                                component={LocationView}
                            />
                            <RbacRoute
                                user={this.props.userProfile}
                                path="/ordernumber/:orderNumber"
                                component={ProductionOrderViewContainer}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    pendingOrders: state.orderdata.pendingOrders,
    pendingCustomerOrders: state.customerorderdata.pendingOrders,
    userProfile: state.authentication.userProfile,
    searchTerm: state.searchdata.searchTerm,
    authenticated: state.authentication.authenticated,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
