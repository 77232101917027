import React from 'react';
import Grid from '@material-ui/core/Grid';
import SearchInput from '../common/searchinput';

export default function InvoiceListSearch(props) {
    return (
        <Grid xs={12} item>
            <SearchInput searchInput={props.filter} onChange={props.onFilterChange} />
        </Grid>
    );
}
