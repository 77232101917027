import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { lighten, withStyles } from '@material-ui/core/styles';
import { formatCurrency } from '../common/common';

const BorderLinearProgress = withStyles({
    root: {
        height: 20,
        backgroundColor: lighten('#ff6c5c', 0.8),
    },
    bar: {
        backgroundColor: '#ff6c5c',
    },
})(LinearProgress);

const ActionprogresView = (props) => {
    const getPercentActualFromPotential = (actual, potential) => {
        if (potential === 0) return 0;
        return (actual / potential) * 100;
    };

    return (
        <span>
            <BorderLinearProgress
                variant="determinate"
                value={getPercentActualFromPotential(props.salesactual, props.salespotential)}
            />
            <span>
                {formatCurrency(Math.round(props.salesactual))} / {formatCurrency(Math.round(props.salespotential))}
            </span>
        </span>
    );
};

export default ActionprogresView;
