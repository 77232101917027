import Filter from './filter';
import FilterProduct from './filterproduct';
import ObjectIdGenerator from '../modules/objectidgenerator';
import { every, flatten, some, sumBy } from 'lodash';

const sortByViewOrder = (a, b) => {
    return a.viewOrder - b.viewOrder;
};

class Machine {
    constructor(jsonInput) {
        if (jsonInput != null) {
            this.id = jsonInput.id;
            this.storageId = jsonInput.storageId;
            this.name = jsonInput.name;
            this.additionalInfo = jsonInput.additionalInfo;
            this.machineLocation = jsonInput.machineLocation;
            this.machineArea = jsonInput.machineArea;
            this.viewOrder = jsonInput.viewOrder;
            this.filters = [];
            if (jsonInput.filters != null && jsonInput.filters.length > 0) {
                for (var filt of jsonInput.filters) {
                    this.filters.push(new Filter(filt));
                }
                this.filters.sort(sortByViewOrder);
            }
            this.airVolume = jsonInput.airVolume;
        } else {
            this.id = 0;
            this.storageId = 0;
            this.name = '';
            this.additionalInfo = '';
            this.machineLocation = '';
            this.machineArea = '';
            this.filters = [];
            this.airVolume = 0.0;
            this.viewOrder = 1;
        }
    }

    static NewObject() {
        const promise = new Promise(function (resolve, reject) {
            const newMachine = new Machine();
            ObjectIdGenerator.newId()
                .then(function (id) {
                    newMachine.id = id;
                    resolve(newMachine);
                })
                .catch(function (err) {
                    reject();
                });
        });
        return promise;
    }

    containsRemovedProducts() {
        if (this.filters.length > 0) {
            return some(this.filters, function (f) {
                return f.expired();
            });
        }
        return false;
    }

    containsDraftProducts() {
        const filtWithProds = this.filters.filter((f) => f.product);
        return !every(filtWithProds, ['product.status', FilterProduct.StatusInUse()]);
    }

    totalSurfaceArea() {
        return sumBy(flatten(this.filters), (item) => item.fabricSurfaceArea() * item.count);
    }
}

export default Machine;
