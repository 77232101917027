import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, FormControl, InputLabel, NativeSelect } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import OrderConfirmationOptions from '../domain/orderconfirmationoptions';
import { useTranslation } from 'react-i18next';
import languages from '../i18n/languages';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

export default function OrderConfirmationMailInfoInputDialog(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [priceChecked, setPriceChecked] = useState(props.orders.map((o) => o.id));
    const [unitPriceChecked, setUnitPriceChecked] = useState([]);
    const [productNameChecked, setProductNameChecked] = useState([]);
    const [deliveryTimeChecked, setDeliveryTimeChecked] = useState([]);
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const onConfirm = () => {
        const selectedOptions = props.orders.map(
            (order) =>
                new OrderConfirmationOptions({
                    orderId: order.id,
                    basicPriceInfo: priceChecked.indexOf(order.id) > -1,
                    unitPriceInfo: unitPriceChecked.indexOf(order.id) > -1,
                    productNameInfo: productNameChecked.indexOf(order.id) > -1,
                    deliveryTimeInfo: deliveryTimeChecked.indexOf(order.id) > -1,
                })
        );
        props.save(language, selectedOptions);
    };

    const onCancel = () => {
        props.cancel();
    };

    const onPriceCheckBoxChange = (orderid) => {
        if (props.combineOrders) {
            if (priceChecked.length > 0) {
                setPriceChecked([]);
                setUnitPriceChecked([]);
            } else {
                setPriceChecked(props.orders.map((o) => o.id));
            }
            return;
        }

        const currentIndex = priceChecked.indexOf(orderid);
        const newPriceChecked = [...priceChecked];

        if (currentIndex === -1) {
            newPriceChecked.push(orderid);
        } else {
            newPriceChecked.splice(currentIndex, 1);
            const unitPriceIndex = unitPriceChecked.indexOf(orderid);
            if (unitPriceIndex > -1) {
                const newUnitPriceChecked = [...unitPriceChecked];
                newUnitPriceChecked.splice(unitPriceIndex, 1);
                setUnitPriceChecked(newUnitPriceChecked);
            }
        }
        setPriceChecked(newPriceChecked);
    };

    const onUnitPriceCheckBoxChange = (orderid) => {
        if (props.combineOrders) {
            unitPriceChecked.length > 0 ? setUnitPriceChecked([]) : setUnitPriceChecked(props.orders.map((o) => o.id));
            return;
        }

        const currentIndex = unitPriceChecked.indexOf(orderid);
        const newUnitPriceChecked = [...unitPriceChecked];

        if (currentIndex === -1) {
            newUnitPriceChecked.push(orderid);
        } else {
            newUnitPriceChecked.splice(currentIndex, 1);
        }
        setUnitPriceChecked(newUnitPriceChecked);
    };

    const onProductNameCheckBoxChange = (orderId) => {
        if (props.combineOrders) {
            if (productNameChecked.length > 0) {
                setProductNameChecked([]);
            } else {
                setProductNameChecked(props.orders.map((o) => o.id));
            }
            return;
        }

        const currentIndex = productNameChecked.indexOf(orderId);
        const newProductNameChecked = [...productNameChecked];

        if (currentIndex === -1) {
            newProductNameChecked.push(orderId);
        } else {
            newProductNameChecked.splice(currentIndex, 1);
        }
        setProductNameChecked(newProductNameChecked);
    };

    const onDeliveryTimeCheckboxChange = (orderId) => {
        if (props.combineOrders) {
            if (deliveryTimeChecked.length > 0) {
                setDeliveryTimeChecked([]);
            } else {
                setDeliveryTimeChecked(props.orders.map((o) => o.id));
            }
            return;
        }

        const currentIndex = deliveryTimeChecked.indexOf(orderId);
        const newDeliveryTimeChecked = [...deliveryTimeChecked];

        if (currentIndex === -1) {
            newDeliveryTimeChecked.push(orderId);
        } else {
            newDeliveryTimeChecked.splice(currentIndex, 1);
        }
        setDeliveryTimeChecked(newDeliveryTimeChecked);
    };

    const onLanguageChange = (selectedLanguage) => {
        setLanguage(selectedLanguage);
    };

    return (
        <Dialog
            onClose={onCancel}
            aria-labelledby="simple-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogContent dividers>
                <h3>{t('order.orderConfirmationContent')}</h3>
                <p>
                    <strong>{t('order.totalPrice')}: </strong>
                    {t('order.confirmationContentHint')}
                    <br />
                    <strong>{t('order.unitPrices')}: </strong>
                    {t('order.filterlinesContainTotalPricesForAFilter')}
                    <br />
                    <strong>{t('order.productNames')}: </strong>
                    {t('order.productNamesHint')}
                    <br />
                    <strong>{t('order.deliveryTimes')}: </strong>
                    {t('order.deliveryTimesHint')}
                </p>
                <FormControl className={classes.select}>
                    <InputLabel id={'select-confirmation-email-language'}>
                        {t('order.orderConfirmationLanguage')}
                    </InputLabel>
                    <NativeSelect
                        id="confirmation-language-select"
                        name="confirmation-language-select"
                        required={true}
                        value={language}
                        onChange={(evt) => onLanguageChange(evt.target.value)}>
                        {languages.map((lang) => (
                            <option key={lang.lang} value={lang.lang}>
                                {t(lang.name)}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('general.location')}</TableCell>
                                <TableCell align="right">{t('order.totalPrice')}</TableCell>
                                <TableCell align="right">{t('order.unitPrices')}</TableCell>
                                <TableCell align="right">{t('order.productNames')}</TableCell>
                                <TableCell align="right">{t('order.deliveryTimes')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.combineOrders ? (
                                <TableRow>
                                    <TableCell>{t('order.combinedOrder')}</TableCell>
                                    <TableCell align="right">
                                        <Checkbox
                                            checked={priceChecked.length > 0}
                                            onChange={() => onPriceCheckBoxChange(0)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Checkbox
                                            checked={unitPriceChecked.length > 0}
                                            onChange={() => onUnitPriceCheckBoxChange(0)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Checkbox
                                            checked={productNameChecked.length > 0}
                                            onChange={() => onProductNameCheckBoxChange(0)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Checkbox
                                            checked={deliveryTimeChecked.length > 0}
                                            onChange={() => onDeliveryTimeCheckboxChange(0)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                props.orders.map((order) => (
                                    <TableRow key={order.id}>
                                        <TableCell>
                                            {order.location ? order.location.name : order.deliveryRecipient}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Checkbox
                                                checked={priceChecked.indexOf(order.id) > -1}
                                                onChange={() => onPriceCheckBoxChange(order.id)}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Checkbox
                                                disabled={priceChecked.indexOf(order.id) < 0}
                                                checked={unitPriceChecked.indexOf(order.id) > -1}
                                                onChange={() => onUnitPriceCheckBoxChange(order.id)}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Checkbox
                                                checked={productNameChecked.indexOf(order.id) > -1}
                                                onChange={() => onProductNameCheckBoxChange(order.id)}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Checkbox
                                                checked={deliveryTimeChecked.indexOf(order.id) > -1}
                                                onChange={() => onDeliveryTimeCheckboxChange(order.id)}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    name="btn-confirm-options"
                    onClick={onConfirm}
                    className={classes.button}
                    endIcon={<SendIcon />}>
                    {t('buttons.send')}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    name="btn-cancel-options"
                    onClick={onCancel}
                    className={classes.button}>
                    {t('buttons.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
