import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextEditBlock from '../invoice/texteditblock';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

export default function DeleteFromHistoryConfirmationDialog(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [reason, setReason] = useState('');
    const { t } = useTranslation();

    const onConfirm = () => {
        props.confirm(reason);
    };

    const onCancel = () => {
        props.cancel();
    };

    const onChange = (event) => {
        setReason(event.target.value);
    };

    return (
        <Dialog
            onClose={onCancel}
            aria-labelledby="simple-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12}>
                        <h3>{t('order.confirmRemoveOrder')}</h3>
                        {t('order.removeOrderReasonHint')}
                    </Grid>
                    <Grid item xs={12}>
                        <TextEditBlock
                            xs={12}
                            md={12}
                            name={'remove-reason'}
                            label={t('order.removeOrderReason')}
                            value={reason}
                            onChange={onChange}
                            maxLength={250}
                            required={true}
                            showCounter={true}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!reason || reason.length < 3}
                    variant="contained"
                    color="primary"
                    name="btn-confirm"
                    onClick={onConfirm}
                    className={classes.button}>
                    {t('buttons.remove')}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    name="btn-cancel"
                    onClick={onCancel}
                    className={classes.button}>
                    {t('buttons.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
