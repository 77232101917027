import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import CONFIG from '../config';
import lifecycle from 'page-lifecycle/dist/lifecycle.es5';
import { addBrowserLifecycleEvent } from '../modules/appstate';
import { refreshLocationFullDataIfNeeded, clearLocationFullDatas } from '../modules/locationdata';
import { refreshCustomerFullDataIfNeeded, clearCustomerFullDatas } from '../modules/customerdata';
import { getProductListVersionFromBackend, getFilterProducts } from '../modules/productdata';

const ACTIVE = 'active';
const PASSIVE = 'passive';
const HIDDEN = 'hidden';
const FROZEN = 'frozen';

export default function LifecycleMonitor(props) {
    const dispatch = useDispatch();
    const monitorAdded = useRef(false);
    const history = useHistory();

    const locations = useSelector((state) => state.locationdata.locationsById);
    const customers = useSelector((state) => state.customerdata.customersById);
    const products = useSelector((state) => state.productdata.filterProducts);
    const fetchingProducts = useSelector((state) => state.productdata.fetchingFilterProducts);
    const contractPrices = useSelector((state) => state.productdata.contractPricesByContractId);
    const productlistVersion = useSelector((state) => state.productdata.filterProductsVersion);

    const customersRef = useRef(customers);
    const locationsRef = useRef(locations);
    const productsRef = useRef(null);
    const fetchingProductsRef = useRef(null);
    const contractPricesRef = useRef(null);
    const productlistVersionRef = useRef(null);

    locationsRef.current = locations;
    customersRef.current = customers;
    productsRef.current = products;
    fetchingProductsRef.current = fetchingProducts;
    contractPricesRef.current = contractPrices;
    productlistVersionRef.current = productlistVersion;

    const handleLocationPageRefresh = () => {
        let locId = undefined;
        let skipLocId = undefined;
        if (
            history.location.pathname.startsWith('/locations/edit/') ||
            history.location.pathname.startsWith('/locations/print/')
        ) {
            locId = history.location.pathname.split('/')[3];
        } else {
            locId = history.location.pathname.split('/')[2];
        }
        skipLocId = parseInt(locId);
        if (locationsRef.current[locId]) {
            dispatch(
                refreshLocationFullDataIfNeeded(locationsRef.current[locId].id, locationsRef.current[locId].storageId)
            );
        }
        dispatch(clearLocationFullDatas(skipLocId));
    };

    const handleCustomerPageRefresh = () => {
        let skipCustId = undefined;
        let custId = undefined;
        if (history.location.pathname.includes('/customers/edit/')) {
            custId = history.location.pathname.split('/')[3];
        } else {
            custId = history.location.pathname.split('/')[2];
        }
        skipCustId = parseInt(custId);
        if (customersRef.current[custId]) {
            dispatch(
                refreshCustomerFullDataIfNeeded(customersRef.current[custId].id, customersRef.current[custId].storageId)
            );
        }
        dispatch(clearCustomerFullDatas(skipCustId));
    };

    const handleProductListRefresh = async () => {
        if (!props.authenticated) return;
        const backendVersion = await getProductListVersionFromBackend()(dispatch);
        if (backendVersion !== productlistVersionRef.current && !fetchingProducts.current) {
            dispatch(getFilterProducts());
        }
    };

    function eventHandler(event) {
        //
        // HIDDEN to visible
        //
        if (process.env.REACT_APP_TARGET === 'customerui') return;

        if (
            (event.oldState === HIDDEN || event.oldState === FROZEN) &&
            (event.newState === PASSIVE || event.newState === ACTIVE)
        ) {
            if (history.location.pathname.startsWith('/locations/')) {
                handleLocationPageRefresh();
            } else if (history.location.pathname.startsWith('/customers/')) {
                handleCustomerPageRefresh();
            }
            handleProductListRefresh();
        }

        // console.log(event.oldState, event.newState);
        if (CONFIG.features.debugUi) {
            dispatch(
                addBrowserLifecycleEvent({
                    timestamp: new Date().toISOString(),
                    old: event.oldState,
                    new: event.newState,
                })
            );
        }
    }

    useEffect(() => {
        if (!monitorAdded.current) {
            lifecycle.addEventListener('statechange', eventHandler);
            monitorAdded.current = true;
        }
        return function cleanup() {
            lifecycle.removeEventListener('statechange', eventHandler);
        };
        // eslint-disable-next-line
    }, []);

    return null;
}
