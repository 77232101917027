import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { lighten, withStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles({
    root: {
        height: 20,
        backgroundColor: lighten('#ff6c5c', 0.8),
    },
    bar: {
        backgroundColor: '#ff6c5c',
    },
})(LinearProgress);

const ProductUpdateProgress = (props) => {
    const getPercentActualFromPotential = (updated, total) => {
        if (updated === 0) return 0;
        return (updated / total) * 100;
    };

    return (
        <span>
            <BorderLinearProgress
                variant="determinate"
                value={getPercentActualFromPotential(props.updated, props.total)}
            />
            <span style={{ textAlign: 'center' }}>
                {props.updated} / {props.total}
            </span>
        </span>
    );
};

export default ProductUpdateProgress;
