class FeedbackItem {
    constructor(json) {
        if (json != null) {
            this.locationId = json.locationId;
            this.customerId = json.customerId;
            this.message = json.message;
            this.messageCategory = json.messageCategory;
        } else {
            this.locationId = null;
            this.customerId = null;
            this.message = '';
            this.messageCategory = 0;
        }
    }
}

export default FeedbackItem;
