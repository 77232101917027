import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: '0',
        right: '2px',
        zIndex: '999',
        color: 'Tomato',
    },
    icon: {},
}));

export default function SaveButton(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const disabled = props.disabled;
    const hasChanges = props.hasChanges;
    const onSubmit = props.onSubmit;
    const saving = props.saving;
    const text = props.text ? props.text : t('buttons.save');
    const name = props.name ? props.name : 'tallenna';

    return (
        <Button
            variant="contained"
            color="primary"
            name={name}
            onClick={onSubmit}
            disabled={disabled}
            startIcon={saving ? <CircularProgress color={'secondary'} size={'1rem'} /> : <SaveIcon />}>
            {text}
            {!disabled && !!hasChanges && (
                <div className={classes.container}>
                    <i className="fas fa-exclamation-circle"></i>
                </div>
            )}
            &nbsp;
        </Button>
    );
}
