import React from 'react';
const _ = require('lodash');

export default function RbacFragment(props) {
    const user = props.user;
    const children = props.children;
    const requiredRole = props.requiredRole;

    if (user && _.find(user.roles, (r) => r === requiredRole) != null)
        return <React.Fragment>{children}</React.Fragment>;

    return null;
}
