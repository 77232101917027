import { createTheme } from '@material-ui/core/styles';

const vadoTheme = createTheme({
    palette: {
        primary: {
            light: '#dae6de',
            main: '#b1cdbb',
        },
        secondary: {
            main: '#599670',
        },
        warning: {
            main: '#CF8A7B',
        },
    },
    typography: {
        useNextVariants: true,
    },
});

export default vadoTheme;
