import Customer from './customer';
import Location from './location';

class ActionItem {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.storageId = json.storageId;
            if (json.customer != null) this.customer = new Customer(json.customer);
            if (json.location != null) this.location = new Location(json.location);
            this.due = json.due;
            this.completed = json.completed;
            this.comments = json.comments;
            this.locationOrderValue = json.locationOrderValue;
        } else {
            this.id = 0;
            this.storageId = 0;
            this.customer = null;
            this.location = null;
            this.due = null;
            this.completed = null;
            this.comments = '';
            this.locationOrderValue = 0;
        }
    }
}

export default ActionItem;
