import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

//TODO: generic input dialog for location and folder
export default function LocationNameInputDialog(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [newLocationName, setNewLocationName] = useState('');
    const [locationNameErrorMsg, setLocationNameErrorMsg] = useState('');
    const [savingOnProgress, setSavingOnProgress] = useState(false);
    const { t } = useTranslation();

    const handleChange = (event) => {
        setLocationNameErrorMsg('');
        setNewLocationName(event.target.value);
    };

    const confirmLocationName = () => {
        if (!!newLocationName && newLocationName.length > 3) {
            setSavingOnProgress(true);
            props.confirm(newLocationName);
        } else {
            setLocationNameErrorMsg(t('general.locationNameTooShort'));
        }
    };

    const cancelLocationName = () => {
        props.cancel();
    };

    const onClose = () => {
        if (!savingOnProgress) props.cancel();
    };

    useEffect(() => {
        if (!props.dialogOpen) {
            setSavingOnProgress(false);
        }
    }, [props.dialogOpen]);

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="simple-dialog-title"
            open={props.dialogOpen}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogContent dividers>
                <TextField
                    error={locationNameErrorMsg.length > 0}
                    id="standard-multiline-flexible"
                    label={t('location.locationName')}
                    className={classes.root}
                    onChange={handleChange}
                    margin="normal"
                    name={'location-name'}
                />
                {locationNameErrorMsg.length > 0 && <Alert severity="warning">{locationNameErrorMsg}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    name="btn-confirm-location-name"
                    onClick={confirmLocationName}
                    className={classes.button}
                    disabled={savingOnProgress}
                    endIcon={savingOnProgress ? <CircularProgress color={'secondary'} size={'1rem'} /> : ''}>
                    {t('buttons.save')}&nbsp;
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    name="btn-cancel-location-name"
                    onClick={cancelLocationName}
                    className={classes.button}
                    disabled={savingOnProgress}>
                    {t('buttons.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
