import moment from 'moment';

class Note {
    constructor(json) {
        if (json) {
            this.storageId = json.storageId;
            this.locationId = json.locationId;
            this.customerId = json.customerId;
            this.text = json.text;
            this.noteSaved = moment(json.noteSaved);
            this.userId = json.userId;
        } else {
            this.locationId = null;
            this.customerId = null;
            this.text = '';
        }
    }
}

export default Note;
