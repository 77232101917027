class CustomerLocationDescription {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.name = json.name;
            this.locationNumber = json.locationNumber;
            this.contactPersonName = json.contactPersonName;
            this.contactPersonTel = json.contactPersonTel;
            this.additionalInfo = json.additionalInfo;
            this.customer = json.customer;
            this.latestOrder = json.latestOrder;
        }
    }
}

export default CustomerLocationDescription;
