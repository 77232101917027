import React from 'react';
import { useSelector } from 'react-redux';

const FooterPadding = ({ props }) => {
    const paddingInPx = useSelector((state) => state.appstate.footerHeightInPx);

    return <div style={{ height: paddingInPx }}>&nbsp;</div>;
};

export default FooterPadding;
