import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';
import { cloneDeep } from 'lodash';
import CustomerSearchOutput from '../domain/customersearchoutput';
import LocationSearchOutput from '../domain/locationsearchoutput';

export const GET_DUPLICATES_SUCCESS = 'duplicatedata/getcustomerduplicatesuccess';

const initialState = {
    duplicates: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DUPLICATES_SUCCESS:
            let newDuplicates = cloneDeep(state.duplicates);
            newDuplicates[action.id] = {
                customerDuplicates: new CustomerSearchOutput(action.result.customerDuplicates),
                locationDuplicates: new LocationSearchOutput(action.result.locationDuplicates),
            };
            return { ...state, duplicates: newDuplicates };
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const getDuplicates = (id, entityId, name, businessId) => (dispatch) => {
    let promise = new Promise((resolve, reject) => {
        let searchdata = {
            id: entityId,
            name: name,
            businessId: businessId,
        };

        Ajax.post('api/search/duplicatecheck', searchdata)
            .then(function (data) {
                dispatch({
                    type: GET_DUPLICATES_SUCCESS,
                    id: id,
                    result: data.data,
                });
                resolve();
            })
            .catch(function (err) {
                console.log(err);
                reject();
            });
    });
    return promise;
};
