import React from 'react';
import { CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        position: 'relative',
    },
    wrapperFullWidth: {
        width: '100%',
    },
    buttonFullWidth: {
        width: '95%',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

//TODO: start adorment option later
export default function SpinnerButton(props) {
    const classes = useStyles();

    const buttonClassname = clsx({
        [classes.buttonFullWidth]: props.fullWidth,
    });

    const wrapperClassname = clsx({
        [classes.wrapper]: true,
        [classes.wrapperFullWidth]: props.fullWidth,
    });

    return (
        <div className={classes.root}>
            <div className={wrapperClassname}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={buttonClassname}
                    disabled={props.inProgress}
                    onClick={props.onClick}>
                    {props.text}
                </Button>
                {props.inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </div>
    );
}

SpinnerButton.propTypes = {
    inProgress: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
};
