import moment from 'moment';
import {
    loadCapacityAllocations,
    loadCapacityReservations,
    saveCapacityReservations,
    removeCapacityReservationsForOrder,
} from '../modules/prodplanningdata';
import Order from '../domain/order';
import CapacityReservation from '../domain/capacityreservation';
import { cloneDeep, filter } from 'lodash';
import Logger from '../common/logger';

const dayjs = require('dayjs');
var isoWeek = require('dayjs/plugin/isoWeek');
var advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
require('dayjs/locale/fi');

// Delivery delay in weekdays for VPF products
const DeliveryDelayInDaysVPF = 2;

// Delivery delay in weekdays for VMP and VCP products
const DeliveryDelayInDaysVMP = 5;

// Skip capacity handling for these customers altogether
const SkipCapacityHandlingCustomers = [29739, 20892, 29477]; // order.customer.customerNumber

// default delay for other products
const DeliveryDelayInDaysDefault = 5;

export function generateContinuousWeekList(startWeek, endWeek, inclusive = true) {
    if (!inclusive) throw new Error('param error');

    let weeks = [];
    const startY = getYearFromIdentifier(startWeek);
    const startW = getWeekFromIdentifier(startWeek);

    let year = startY;
    let week = startW;
    let weeksInYear = moment(`${startY}-01-01`).weeksInYear();
    let id = '';
    do {
        id = formatWeekIdentifierInt(year, week);
        weeks.push(`${id}`);
        if (week === weeksInYear) {
            year++;
            week = 1;
            weeksInYear = moment(`${year}-01-01`).weeksInYear();
        } else {
            week++;
        }
    } while (id < endWeek);
    return weeks;
}

export function getWeekFromIdentifier(id) {
    const week = id.split('-')[1];
    return parseInt(week);
}

export function getYearFromIdentifier(id) {
    const year = id.split('-')[0];
    return parseInt(year);
}

export function formatWeekIdentifierDayjs(dayjsObj) {
    return dayjsObj.format('YYYY-WW');
}

export function formatWeekIdentifierMoment(momentObj) {
    const dj = dayjs(momentObj.format());
    const ret = dj.format('YYYY-WW');
    return ret;
}

export function formatWeekIdentifierInt(year, week) {
    if (week < 10) week = `0${week}`;
    return `${year}-${week}`;
}

async function availableCapacity(store, date, orderType, portfolioId, orderIds, pendingCapaReservations) {
    let capacityAllocationsByWeek = store.getState().prodplanningdata.capacityAllocationsByWeek;
    let capacityReservations = store.getState().prodplanningdata.capacityReservations;

    // TODO
    // Pitäisi käsitellä tilanne, että kapasiteettitietoja tarvitaan +6kk tulevaisuuteen. Ehkä.
    // Voi olla ylisuunnittelua.
    if (!capacityAllocationsByWeek || Object.keys(capacityAllocationsByWeek).length === 0) {
        await loadCapacityAllocations()(store.dispatch);
        capacityAllocationsByWeek = store.getState().prodplanningdata.capacityAllocationsByWeek;
    }
    if (!capacityAllocationsByWeek[formatWeekIdentifierMoment(date)]) {
        Logger.LogError(
            `capacityallocations does not contain data for given date: ${date.format()}, week:${formatWeekIdentifierMoment(
                date
            )}`
        );
        throw new Error('capacityallocations does not contain data for given date.');
    }
    if (!capacityReservations) {
        await loadCapacityReservations()(store.dispatch);
        capacityReservations = store.getState().prodplanningdata.capacityReservations;
    }
    if (pendingCapaReservations && pendingCapaReservations.length > 0) {
        capacityReservations = capacityReservations.concat(pendingCapaReservations);
    }
    if (date.day() === 6 || date.day() === 0) return 0;

    const capacityDay = getCapacityForDay(
        capacityAllocationsByWeek,
        date,
        orderType === Order.OrderTypeNormal() ? portfolioId : 0
    );
    const reservationsDay = getReservationsForDay(capacityReservations, date, orderType, portfolioId, orderIds);
    return capacityDay - reservationsDay;
}

export async function earliestDeliveryDateForOrder(store, orderItem) {
    let reservationsNeeded = totalNumberOfPocketFilters([orderItem]);
    const nonVPFproductsCount = totalNumberOfOtherProducts([orderItem]);
    const VMPProductsInOrder = numberOfVMPProducts([orderItem]);
    const runningDate = moment().utc();
    runningDate.hour(12).minute(0).second(0).millisecond(0);
    const maxDays = 180;
    let days = 0;

    if (shallBypassCapacityHandling(orderItem)) {
        return runningDate;
    }

    if (!reservationsNeeded) {
        if (VMPProductsInOrder) return addWeekdays(DeliveryDelayInDaysVMP, runningDate);

        return addWeekdays(DeliveryDelayInDaysDefault, runningDate);
    }

    if (!orderItem.usesCapacityAllocation) return moment().utc();

    do {
        const availableCapaDay = await availableCapacity(
            store,
            runningDate,
            orderItem.orderType,
            orderItem.portfolioId,
            [orderItem.id]
        );
        if (availableCapaDay >= reservationsNeeded) {
            reservationsNeeded = 0;
            break;
        } else if (availableCapaDay > 0) {
            reservationsNeeded -= availableCapaDay;
        }
        runningDate.add(1, 'days');
        days++;
    } while (days <= maxDays);
    if (!reservationsNeeded && days < maxDays) {
        const dateToReturn = addWeekdays(DeliveryDelayInDaysVPF, runningDate);
        if (nonVPFproductsCount) {
            const earliestDateWhenNonVPFsIncludedInOrder = addWeekdays(
                VMPProductsInOrder ? DeliveryDelayInDaysVMP : DeliveryDelayInDaysDefault,
                moment().utc().hour(12)
            );
            if (dateToReturn.isBefore(earliestDateWhenNonVPFsIncludedInOrder, 'day')) {
                return earliestDateWhenNonVPFsIncludedInOrder;
            }
        }
        return dateToReturn;
    }
    return null;
}

export async function earliestDeliveryDateForCombinedOrder(store, orders, combinedOrderInfo) {
    let reservationsNeeded = totalNumberOfPocketFilters(orders);
    const nonVPFproductsCount = totalNumberOfOtherProducts(orders);
    const VMPProductsInOrder = numberOfVMPProducts(orders);
    const runningDate = moment().utc();
    runningDate.hour(12).minute(0).second(0).millisecond(0);
    const maxDays = 200;
    let days = 0;

    if (shallBypassCapacityHandling(orders[0])) {
        return runningDate;
    }

    if (!reservationsNeeded) {
        if (VMPProductsInOrder) return addWeekdays(DeliveryDelayInDaysVMP, runningDate);

        return addWeekdays(DeliveryDelayInDaysDefault, runningDate);
    }

    if (filter(orders, { usesCapacityAllocation: true }).length === 0) return runningDate;

    do {
        const availableCapaDay = await availableCapacity(
            store,
            runningDate,
            combinedOrderInfo.orderType,
            orders[0].portfolioId,
            orders.map((o) => o.id)
        );
        if (availableCapaDay >= reservationsNeeded) {
            reservationsNeeded = 0;
            break;
        } else if (availableCapaDay > 0) {
            reservationsNeeded -= availableCapaDay;
        }
        runningDate.add(1, 'days');
        days++;
    } while (days <= maxDays);
    if (!reservationsNeeded && days < maxDays) {
        const dateToReturn = addWeekdays(DeliveryDelayInDaysVPF, runningDate);
        if (nonVPFproductsCount) {
            const earliestDateWhenNonVPFsIncludedInOrder = addWeekdays(
                VMPProductsInOrder ? DeliveryDelayInDaysVMP : DeliveryDelayInDaysDefault,
                moment().utc().hour(12)
            );
            if (dateToReturn.isBefore(earliestDateWhenNonVPFsIncludedInOrder, 'day')) {
                return earliestDateWhenNonVPFsIncludedInOrder;
            }
        }
        return dateToReturn;
    }
    return null;
}

function getReservationsForDay(reservations, day, orderType, orderPortfolioId, orderIds) {
    const portfolioId = orderType === Order.OrderTypeNormal() ? orderPortfolioId : 0;
    let reservationsDay = reservations.filter(
        (r) => r.portfolioId === portfolioId && moment(r.dateOfReservation).isSame(day, 'date')
    );
    reservationsDay = reservationsDay.filter((r) => !orderIds.includes(r.orderId));
    return reservationsDay.length > 0 ? reservationsDay.reduce((acc, curr) => acc + curr.reservedCapacity, 0) : 0;
}

function getCapacityForDay(allocations, day, portfolioId) {
    const portfolioAllocation = allocations[`${formatWeekIdentifierMoment(day)}`].filter(
        (a) => a.portfolioId === portfolioId
    );
    if (portfolioAllocation.length > 1) {
        Logger.LogError('invalid capacity data, portfolioAllocation.length > 1');
        throw new Error('invalid capacity data');
    }
    if (portfolioAllocation.length === 0) {
        return 0;
    }
    return Math.floor(portfolioAllocation[0].allocatedCapacity / 5);
}

export async function makeCapacityReservations(store, orderItem, estimatedDeliveryDate) {
    if (shallBypassCapacityHandling(orderItem)) return true;
    const earliestDeliveryDate = await earliestDeliveryDateForOrder(store, orderItem);
    if (!earliestDeliveryDate) return false;
    if (!earliestDeliveryDate.isSameOrBefore(estimatedDeliveryDate, 'day')) {
        Logger.LogError(
            `Trying to set deliverydate earlier than possible, earlies: ${earliestDeliveryDate.format()}, input:${estimatedDeliveryDate.format()}`
        );
        return false;
    }

    let reservationsNeeded = totalNumberOfPocketFilters([orderItem]);

    const startDate = earliestDeliveryDate.isSame(estimatedDeliveryDate, 'day')
        ? await earliesDateWithFreeCapacity(store, orderItem.orderType, orderItem.portfolioId, [orderItem.id])
        : subtractWeekdays(DeliveryDelayInDaysVPF, estimatedDeliveryDate);
    const reverseDirection = !earliestDeliveryDate.isSame(estimatedDeliveryDate, 'day');
    const runningDate = moment(startDate).hour(12);
    const maxDays = 60;
    const reservations = [];
    let days = 0;

    if (!reservationsNeeded) return true;
    if (!orderItem.usesCapacityAllocation) return true;

    do {
        const reserv = new CapacityReservation();
        const availableCapaDay = await availableCapacity(
            store,
            runningDate,
            orderItem.orderType,
            orderItem.portfolioId,
            [orderItem.id]
        );
        reserv.portfolioId = orderItem.orderType === Order.OrderTypeNormal() ? orderItem.portfolioId : 0;
        reserv.orderId = orderItem.id;
        reserv.dateOfReservation = moment(runningDate);

        if (availableCapaDay >= reservationsNeeded) {
            reserv.reservedCapacity = reservationsNeeded;
            reservations.push(reserv);
            break;
        } else if (availableCapaDay > 0) {
            reserv.reservedCapacity = availableCapaDay;
            reservationsNeeded -= availableCapaDay;
            reservations.push(reserv);
        }
        if (reverseDirection) {
            runningDate.subtract(1, 'days');
        } else {
            runningDate.add(1, 'days');
        }
        days++;
    } while (days <= maxDays);

    if (reservations.length > 0 && days <= maxDays) {
        if (!(await saveCapacityReservations(reservations)(store.dispatch))) {
            return false;
        }
    } else if (days > maxDays) {
        return false;
    }
    return true;
}

export async function makeCapacityReservationsCombinedOrder(store, orders, estimatedDeliveryDate, combinedOrderInfo) {
    if (shallBypassCapacityHandling(orders[0])) return [];
    const earliestDeliveryDate = await earliestDeliveryDateForCombinedOrder(store, orders, combinedOrderInfo);
    if (!earliestDeliveryDate.isSameOrBefore(estimatedDeliveryDate, 'day')) {
        Logger.LogError(
            `Combined Order, Trying to set deliverydate earlier than possible, earlies: ${earliestDeliveryDate.format()}, input:${estimatedDeliveryDate.format()}`
        );
        return false;
    }
    let reservationsNeededTotal = totalNumberOfPocketFilters(orders);

    const startDate = earliestDeliveryDate.isSame(estimatedDeliveryDate, 'day')
        ? await earliesDateWithFreeCapacity(
              store,
              combinedOrderInfo.orderType,
              orders[0].portfolioId,
              orders.map((o) => o.id)
          )
        : subtractWeekdays(DeliveryDelayInDaysVPF, estimatedDeliveryDate);
    const reverseDirection = !earliestDeliveryDate.isSame(estimatedDeliveryDate, 'day');
    const runningDate = moment(startDate).hour(12);
    const maxDays = 60;
    const reservations = [];
    const ordersClone = cloneDeep(orders);
    let days = 0;

    if (!reservationsNeededTotal) return reservations;
    if (filter(orders, { usesCapacityAllocation: true }).length === 0) return reservations;

    let reservationsNeeded = numberOfPocketFiltersForOrder(ordersClone[0]);

    let availableCapaDay = 0;
    do {
        const reserv = new CapacityReservation();
        availableCapaDay = !availableCapaDay
            ? await availableCapacity(
                  store,
                  runningDate,
                  combinedOrderInfo.orderType,
                  orders[0].portfolioId,
                  orders.map((o) => o.id),
                  reservations
              )
            : availableCapaDay;
        reserv.portfolioId = combinedOrderInfo.orderType === Order.OrderTypeNormal() ? ordersClone[0].portfolioId : 0;
        reserv.orderId = ordersClone[0].id;
        reserv.dateOfReservation = moment(runningDate);

        if (availableCapaDay >= reservationsNeeded) {
            if (reservationsNeeded) {
                reserv.reservedCapacity = reservationsNeeded;
                reservations.push(reserv);
            }
            if (ordersClone.length > 1) {
                reservationsNeeded = numberOfPocketFiltersForOrder(ordersClone[1]);
                ordersClone.splice(0, 1);
                availableCapaDay -= reserv.reservedCapacity;
                continue;
            } else {
                break;
            }
        } else if (availableCapaDay > 0) {
            reserv.reservedCapacity = availableCapaDay;
            reservationsNeeded -= availableCapaDay;
            availableCapaDay = 0;
            reservations.push(reserv);
        } else {
            availableCapaDay = 0;
        }

        if (reverseDirection) {
            runningDate.subtract(1, 'days');
        } else {
            runningDate.add(1, 'days');
        }
        days++;
    } while (days <= maxDays && ordersClone.length > 0);

    if (days <= maxDays) {
        return reservations;
    }
    return null;
}

export async function earliesDateWithFreeCapacity(store, orderType, portfolioId, orderIds) {
    const runningDate = moment().utc();
    runningDate.hour(0).minute(0).second(0).millisecond(0);
    const maxDays = 200;
    let days = 0;

    do {
        const availableCapaDay = await availableCapacity(store, runningDate, orderType, portfolioId, orderIds);
        if (availableCapaDay > 0) break;
        runningDate.add(1, 'days');
        days++;
    } while (days <= maxDays);
    if (days <= maxDays) {
        return runningDate;
    }
    return null;
}

export async function removeCapacityReservations(store, orderId) {
    await removeCapacityReservationsForOrder(orderId)(store.dispatch);
}

export async function updateCapacityReservationsIfNeeded(store, order, deliveryDate) {
    if (!order.usesCapacityAllocation) return true;

    const totalNeeded = totalNumberOfPocketFilters([order]);
    if (!totalNeeded) return true;

    let capacityReservations = store.getState().prodplanningdata.capacityReservations;

    if (!capacityReservations) {
        capacityReservations = await loadCapacityReservations()(store.dispatch);
    }

    const reservedCapa = capacityReservations
        .filter((r) => r.orderId === order.id)
        .reduce((acc, curr) => acc + curr.reservedCapacity, 0);

    if (reservedCapa !== totalNeeded) {
        return await makeCapacityReservations(store, order, deliveryDate);
    }
    return true;
}

function numberOfPocketFiltersForOrder(order) {
    return order.orderedFilters
        .map((f) => {
            if (f.product && f.product.productName.startsWith('VPF')) {
                return f.count;
            } else if (f.productBundle) {
                let count = 0;
                for (let p of f.productBundle.products) {
                    if (p.product.productName.startsWith('VPF')) count += p.productCount;
                }
                return count * f.count;
            }
            return 0;
        })
        .reduce((acc, curr) => {
            return acc + curr;
        });
}

function totalNumberOfPocketFilters(orders) {
    return orders
        .map((o) => {
            return o.orderedFilters.map((f) => {
                if (f.product && f.product.productName.startsWith('VPF')) {
                    return f.count;
                } else if (f.productBundle) {
                    let count = 0;
                    for (let p of f.productBundle.products) {
                        if (p.product.productName.startsWith('VPF')) count += p.productCount;
                    }
                    return count * f.count;
                }
                return 0;
            });
        })
        .flat()
        .reduce((acc, curr) => acc + curr, 0);
}

function numberOfVMPProducts(orders) {
    return orders
        .map((o) => {
            return o.orderedFilters.map((f) => {
                if (f.product && (f.product.productName.startsWith('VMP') || f.product.productName.startsWith('VCP'))) {
                    return f.count;
                } else if (f.productBundle) {
                    let count = 0;
                    for (let p of f.productBundle.products) {
                        if (p.product.productName.startsWith('VMP')) count += p.productCount;
                    }
                    return count * f.count;
                }
                return 0;
            });
        })
        .flat()
        .reduce((acc, curr) => acc + curr, 0);
}

function totalNumberOfOtherProducts(orders) {
    return orders
        .map((o) => {
            return o.orderedFilters.map((f) => {
                if (f.product && !f.product.productName.startsWith('VPF')) {
                    return f.count;
                } else if (f.productBundle) {
                    let count = 0;
                    for (let p of f.productBundle.products) {
                        if (!p.product.productName.startsWith('VPF')) count += p.productCount;
                    }
                    return count * f.count;
                }
                return 0;
            });
        })
        .flat()
        .reduce((acc, curr) => acc + curr, 0);
}

function addWeekdays(days, dateToModify) {
    let daysToGo = days;

    if (days <= 0) return dateToModify;

    do {
        if (dateToModify.day() === 5) {
            dateToModify.add(3, 'days');
        } else {
            dateToModify.add(1, 'days');
        }
        daysToGo--;
    } while (daysToGo);

    return dateToModify;
}

function subtractWeekdays(days, dateInput) {
    let daysToGo = days;
    let dateToModify = moment(dateInput);
    if (days <= 0) return dateToModify;

    do {
        if (dateToModify.day() === 1) {
            dateToModify.subtract(3, 'days');
        } else {
            dateToModify.subtract(1, 'days');
        }
        daysToGo--;
    } while (daysToGo);

    return dateToModify;
}

function shallBypassCapacityHandling(order) {
    if (!order) return false;
    return SkipCapacityHandlingCustomers.includes(order.customer.customerNumber);
}
