import React from 'react';
import { Bar } from 'react-chartjs-2';
import { MapDataToYearlyChart } from './mapDataToChartData';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

const YearlyReport = (props) => {
    const { t } = useTranslation();
    const plotWidth = window.innerWidth - 100;
    let yAxisTitle = t('reports.salesKEur');
    let xAxisTitle = t('reports.year');
    const chartData = MapDataToYearlyChart(props.reports);

    const getYearlyTotal = (year, yearlyTotalValue) => {
        let index = _.findIndex(yearlyTotalValue, ['year', year]);
        if (index >= 0) {
            return yearlyTotalValue[index];
        }
        return '';
    };

    const chartLabelTooltip = (tooltipItem, data) => {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) {
            label += ': ';
        }
        label += tooltipItem.yLabel.toLocaleString();
        label += ' €';
        return label;
    };

    const chartFooterTooltip = (tooltipItem, data) => {
        if (tooltipItem.length > 0) {
            let total = t('reports.salesAltogether') + ': ';
            total += getYearlyTotal(tooltipItem[0].xLabel, data.totalvalue).totalvalue.toLocaleString();
            total += ' €';
            return total;
        }
        return;
    };

    if (chartData === null) {
        return <div>{t('reports.noReports')}</div>;
    }

    return (
        <Bar
            redraw={props.refresh}
            data={chartData}
            width={plotWidth}
            height={500}
            options={{
                title: {
                    display: true,
                    text: t('reports.salesPerYear'),
                    fontSize: 20,
                },
                maintainAspectRatio: false,
                legend: {
                    display: true,
                },
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            scaleLabel: {
                                display: true,
                                labelString: xAxisTitle,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            scaleLabel: {
                                display: true,
                                labelString: yAxisTitle,
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: (value, index, values) => {
                                    return value.toLocaleString();
                                },
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            return chartLabelTooltip(tooltipItem, data);
                        },
                        footer: (tooltipItem, data) => {
                            return chartFooterTooltip(tooltipItem, data);
                        },
                    },
                },
            }}
        />
    );
};

export default YearlyReport;
