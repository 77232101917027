import React from 'react';
import ParameterSelector from '../common/parameterselector';
import { useTranslation } from 'react-i18next';

const idToValue = {
    1: 'Normal',
    2: 'Urgent',
};

const valueToId = {
    Normal: 1,
    Urgent: 2,
};

const OrderTypeSelector = ({ handleChange, selected }) => {
    const { t } = useTranslation();

    return (
        <ParameterSelector
            category="OrderType"
            values={{
                OrderType: [
                    { id: 1, value: t('order.orderTypeNormal') },
                    { id: 2, value: t('order.orderTypeBusy') },
                ],
            }}
            handleChange={(_cat, value) => handleChange(idToValue[value])}
            selected={{ id: valueToId[selected] }}
            requireValue={true}
        />
    );
};

export default OrderTypeSelector;
