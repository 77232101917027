import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';

export default function PartyAutocomplete(props) {
    const parties = useSelector((state) => state.partydata.parties);
    const { t } = useTranslation();

    const defaultProps = {
        options: parties,
        getOptionLabel: (option) => `${option.name}, ${option.phonenumber}`,
    };

    const onSelect = (event, value, reason) => {
        if (reason === 'select-option') {
            props.selected(value);
        } else if (reason === 'clear') {
            props.dismiss();
        }
    };

    return (
        <Autocomplete
            {...defaultProps}
            id="partyautocomplete"
            name="partyautocomplete"
            loading={!parties}
            noOptionsText={
                <Button variant="contained" color="primary" onMouseDown={props.newParty}>
                    {t('general.createNewContact')}
                </Button>
            }
            onChange={onSelect}
            renderInput={(params) => <TextField {...params} label={t('general.person')} margin="normal" />}
        />
    );
}
