import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store from './store';
import history from './history';
import App from './main/App';
import register from './registerServiceWorker';
import Logger from './common/logger';
import withTracker from './withTracker';
import { Route } from 'react-router-dom';
import './css/index.css';
import './css/sidemenunav.css';
//import { CssBaseline } from '@material-ui/core';

import './i18n/i18n';

const target = document.querySelector('#root');

window.onerror = function (message, url, lineNumber, col, errobj) {
    Logger.LogError(message, errobj.stack);
    return true;
};

register();

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <div>
                <Route component={withTracker(App, {})} />
            </div>
        </ConnectedRouter>
    </Provider>,
    target
);
