import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NumericInputElement, { TypeOnBlur } from '../common/numericinput';
import { formatCurrency } from '../common/common';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { verifyAndReturnDecimal } from '../common/common';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    filterCountEdit: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100px',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'table-row',
        },
    },
    sectionMobile: {
        display: 'table-row',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    totalPriceMobile: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function FilterOrderRowEdit(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const saving = props.saving;

    // Editing = editing order from order history.
    const editing = props.editingOrder;
    const [foInEdit, setFoInEdit] = React.useState({});
    const foCache = React.useRef(null);

    useEffect(() => {
        if (props.filterOrder && foCache.current) {
            if (
                props.filterOrder.filterCount !== foCache.current.filterCount ||
                props.filterOrder.totalPriceWithoutVat !== foCache.current.totalPriceWithoutVat ||
                props.filterOrder.unitPriceWithoutVat !== foCache.current.unitPriceWithoutVat
            ) {
                foCache.current.filterCount = props.filterOrder.filterCount;
                foCache.current.totalPriceWithoutVat = props.filterOrder.totalPriceWithoutVat;
                foCache.current.unitPriceWithoutVat = props.filterOrder.unitPriceWithoutVat;
                setFoInEdit(foCache.current);
            }
        } else if (props.filterOrder && !foCache.current) {
            foCache.current = cloneDeep(props.filterOrder);
            setFoInEdit(foCache.current);
        }
    }, [props.filterOrder]);

    const getRowClass = () => {
        if (props.selected) return 'highlight';
        return '';
    };

    const filterCountChanged = async (id, value) => {
        await props.filterCountChanged(props.filterOrder.id, value);
    };

    const onChange = (e) => {
        const fo = cloneDeep(foCache.current);
        fo[e.target.name] = e.target.value;
        foCache.current = fo;
        setFoInEdit(fo);
        if (editing) {
            props.filterOrderChanged(fo);
        }
    };

    const closeEdit = () => {
        if (!editing) {
            props.filterOrderChanged(foCache.current);
            foCache.current = null;
        }
        props.closeRowEdit();
    };

    const handlePriceChange = (e) => {
        const value = verifyAndReturnDecimal(e.target.value);
        if (value && value >= 0) {
            const fo = cloneDeep(foCache.current);
            fo.unitPriceWithoutVat = value;
            foCache.current = fo;
            props.filterOrderChanged(fo);
        }
    };

    return (
        <Fragment>
            <tr className={getRowClass() + ' ' + classes.sectionDesktop}>
                <td></td>
                <td>
                    {!props.priceOnly && (
                        <div>
                            <TextField
                                required
                                error={false}
                                name="machineName"
                                label={t('location.machine')}
                                className={classes.textField}
                                margin="normal"
                                value={foInEdit.machineName}
                                onChange={onChange}
                            />
                            <TextField
                                required
                                error={false}
                                name="machineLocation"
                                label={t('location.position')}
                                className={classes.textField}
                                margin="normal"
                                value={foInEdit.machineLocation}
                                onChange={onChange}
                            />
                            <TextField
                                required
                                error={false}
                                name="machineArea"
                                label={t('location.serviceArea')}
                                className={classes.textField}
                                margin="normal"
                                value={foInEdit.machineArea}
                                onChange={onChange}
                            />
                            <TextField
                                required
                                error={false}
                                name="palletInformation"
                                label={t('order.palletGroup')}
                                className={classes.textField}
                                margin="normal"
                                value={foInEdit.palletInformation}
                                onChange={onChange}
                            />
                        </div>
                    )}
                    <br />
                    <Button color="primary" variant="contained" onClick={closeEdit}>
                        {t('buttons.close')}
                    </Button>
                </td>

                <td>
                    <strong>
                        {props.filterOrder.product
                            ? props.filterOrder.product.productName
                            : props.filterOrder.productBundle.name}
                    </strong>
                    {props.filterOrder.filterAdditionalInfo && (
                        <span>
                            <br />
                            <em>{props.filterOrder.filterAdditionalInfo}</em>
                        </span>
                    )}
                </td>
                <td className={'filter-count-' + props.filterOrder.id}>
                    {!props.priceOnly && (
                        <NumericInputElement
                            id={null}
                            value={props.filterCount}
                            onChange={filterCountChanged}
                            type={TypeOnBlur}
                            min={1}
                            saving={saving}
                        />
                    )}
                </td>
                <td>
                    <TextField
                        name={`filterPrice-${props.filterOrder.id}`}
                        defaultValue={props.filterOrder.unitPriceRounded()}
                        onBlur={(e) => handlePriceChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€/kpl</InputAdornment>,
                        }}
                    />
                </td>
                <td>{formatCurrency(props.filterOrder.totalPriceWithoutVat)}</td>
            </tr>

            <tr className={getRowClass() + ' ' + classes.sectionMobile}>
                <td></td>
                <td>
                    <div>
                        <div>
                            <strong>
                                {props.filterOrder.product
                                    ? props.filterOrder.product.productName
                                    : props.filterOrder.productBundle.name}
                            </strong>
                        </div>
                        {!props.priceOnly && (
                            <React.Fragment>
                                <TextField
                                    required
                                    error={false}
                                    name="machineName"
                                    label={t('location.machine')}
                                    className={classes.textField}
                                    margin="normal"
                                    value={props.filterOrder.machineName}
                                    onChange={onChange}
                                />
                                <TextField
                                    required
                                    error={false}
                                    name="machineLocation"
                                    label={t('location.position')}
                                    className={classes.textField}
                                    margin="normal"
                                    value={props.filterOrder.machineLocation}
                                    onChange={onChange}
                                />
                                <TextField
                                    required
                                    error={false}
                                    name="machineArea"
                                    label={t('location.serviceArea')}
                                    className={classes.textField}
                                    margin="normal"
                                    value={props.filterOrder.machineArea}
                                    onChange={onChange}
                                />
                                <TextField
                                    required
                                    error={false}
                                    name="palletInformation"
                                    label={t('order.palletGroup')}
                                    className={classes.textField}
                                    margin="normal"
                                    value={props.filterOrder.palletInformation}
                                    onChange={onChange}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    {!props.priceOnly && (
                        <NumericInputElement
                            id={null}
                            value={props.filterCount}
                            onChange={filterCountChanged}
                            type={TypeOnBlur}
                            min={1}
                            saving={saving}
                        />
                    )}
                    <TextField
                        className={classes.textField}
                        name={`filterPrice-${props.filterOrder.id}`}
                        defaultValue={props.filterOrder.unitPriceRounded()}
                        onBlur={(e) => handlePriceChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€/kpl</InputAdornment>,
                        }}
                    />
                    <br />
                    <Grid item xs={12} className={classes.totalPriceMobile}>
                        {t('order.totalShort')}{' '}
                        <strong>{formatCurrency(props.filterOrder.totalPriceWithoutVat)}</strong>
                    </Grid>
                    <br />
                    <Button color="primary" variant="contained" onClick={closeEdit}>
                        {t('buttons.close')}
                    </Button>
                </td>
            </tr>
        </Fragment>
    );
}
