import Ajax from './ajax';
import ActionItem from '../domain/actionitem';
import { LOGGED_OFF } from './authentication';
import moment from 'moment';
import i18next from 'i18next';
import { toast } from 'react-toastify';

export const FETCH_OVERDUE_ACTION_DATA_REQUESTED = 'actiondata/FETCHOVERDUEACTIONDATA';
export const FETCHED_OVERDUE_ACTION_DATA = 'actiondata/FETCHEDOVERDUEACTIONDATA';
export const FETCH_OVERDUE_ACTION_DATA_FAILED = 'actiondata/FETCHOVERDUEACTIONDATAFAILED';

export const FETCH_ACTION_DATA_REQUESTED = 'actiondata/FETCHACTIONDATA';
export const FETCHED_ACTION_DATA = 'actiondata/FETCHEDACTIONDATA';
export const FETCH_ACTION_DATA_FAILED = 'actiondata/FETCHACTIONDATAFAILED';

export const REMINDER_BYPASSED = 'actiondata/REMINDER_BYPASSED';

const initialState = {
    actions: [],
    overdueActions: [],
    isFetchingActionData: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTION_DATA_REQUESTED:
            return {
                ...state,
                isFetchingActionData: true,
            };
        case FETCHED_ACTION_DATA:
            let actions = [];
            if (action.data != null && action.data.length > 0) {
                for (let act of action.data) {
                    actions.push(new ActionItem(act));
                }
            }
            return {
                ...state,
                actions: actions,
                isFetchingActionData: false,
            };
        case FETCH_ACTION_DATA_FAILED:
            return {
                ...state,
                actions: [],
                isFetchingActionData: false,
            };

        case FETCH_OVERDUE_ACTION_DATA_REQUESTED:
            return {
                ...state,
                isFetchingActionData: true,
            };
        case FETCHED_OVERDUE_ACTION_DATA:
            let overdueActions = [];
            if (action.data != null && action.data.length > 0) {
                for (let act of action.data) {
                    overdueActions.push(new ActionItem(act));
                }
            }
            return {
                ...state,
                overdueActions: overdueActions,
                isFetchingActionData: false,
            };
        case FETCH_OVERDUE_ACTION_DATA_FAILED:
            return {
                ...state,
                overdueActions: [],
                isFetchingActionData: false,
            };
        case REMINDER_BYPASSED: {
            let newActions = [];
            let newOverdue = [];
            for (const act of state.actions) {
                if (
                    act.location.id !== action.data.locationId ||
                    moment(act.due).month() + 1 !== action.data.month ||
                    moment(act.due).year() !== action.data.year
                ) {
                    newActions.push(new ActionItem(act));
                }
            }
            for (const act of state.overdueActions) {
                if (
                    act.location.id !== action.data.locationId ||
                    moment(act.due).month() + 1 !== action.data.month ||
                    moment(act.due).year() !== action.data.year
                ) {
                    newOverdue.push(new ActionItem(act));
                }
            }
            return {
                ...state,
                actions: newActions,
                overdueActions: newOverdue,
            };
        }
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const getOverdueActionData = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_OVERDUE_ACTION_DATA_REQUESTED,
        });
        Ajax.get('api/Action/overdue')
            .then(function (data) {
                dispatch({
                    type: FETCHED_OVERDUE_ACTION_DATA,
                    data: data.data,
                });
            })
            .catch(function (err) {
                dispatch({
                    type: FETCH_OVERDUE_ACTION_DATA_FAILED,
                });
            });
    };
};

export const getActionDataByMonthYear = (month, year, monthsAhead = 0) => {
    return (dispatch) => {
        dispatch({
            type: FETCH_ACTION_DATA_REQUESTED,
        });
        Ajax.get('api/Action/year/' + year + '/month/' + month + '/months/' + monthsAhead)
            .then(function (data) {
                dispatch({
                    type: FETCHED_ACTION_DATA,
                    data: data.data,
                });
            })
            .catch(function (err) {
                dispatch({
                    type: FETCH_ACTION_DATA_FAILED,
                });
            });
    };
};

export const bypassReminder = (month, year, locationId) => {
    return (dispatch) => {
        const promise = new Promise((resolve, reject) => {
            const input = { locationId: locationId, year: year, month: month };
            Ajax.post('api/action/bypass/', input)
                .then(function (data) {
                    resolve();
                    dispatch({
                        type: REMINDER_BYPASSED,
                        data: input,
                    });
                })
                .catch(function (err) {
                    console.log(err);
                    if (err.response && err.response.status === 404) {
                        toast.error(i18next.t('errors.locationMissingOrRemoved'), {
                            autoClose: 5000,
                            hideProgressBar: true,
                        });
                    }
                    reject();
                });
        });
        return promise;
    };
};
