import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

const filterOpts = createFilterOptions({
    stringify: (option) => option.name + option.code + (option.notes ? option.notes.map((n) => n.text).join(',') : ''),
});

export default function ProductSelectorAutocomplete(props) {
    const products = useSelector((state) => state.productdata.filterProducts);
    const bundles = useSelector((state) => state.productdata.productBundles);
    const showBundles = props.showBundles;
    const hideProducts = props.hideProducts;
    const allowNewProd = props.allowNewProd;
    const { t } = useTranslation();

    const getProducts = () => {
        let optionsArray = [];
        const bundleArray =
            bundles && bundles.length > 0
                ? bundles.map((b) => {
                      return { name: b.name + ' (' + t('general.bundle') + ')', code: b.code, id: b.id, bundle: true };
                  })
                : [];
        const prodArray =
            products && products.length > 0
                ? products.map((p) => {
                      return { name: p.productName, code: p.productCode, notes: p.notes, id: p.id, bundle: false };
                  })
                : [];
        if (!hideProducts) optionsArray = optionsArray.concat(prodArray);
        if (showBundles) optionsArray = optionsArray.concat(bundleArray);

        return optionsArray;
    };

    const defaultProps = {
        options: getProducts(),
        getOptionLabel: (option) =>
            option.notes && option.notes.length > 0
                ? `${option.name} (${option.notes.map((n) => n.text).join(',')})`
                : `${option.name}`,
    };

    const onSelect = (event, value, reason) => {
        if (reason === 'select-option') {
            props.selected(value.id, value);
        } else if (reason === 'clear') {
            props.dismiss();
        }
    };

    const noOptions = () => {
        if (!!allowNewProd) {
            return (
                <Button
                    name="btn-new-product"
                    variant="contained"
                    color="primary"
                    onMouseDown={props.newProduct}
                    onClick={props.newProduct}
                    startIcon={<AddIcon />}>
                    {t('general.product')}
                </Button>
            );
        }
        return '-';
    };

    useEffect(() => {
        if (products && bundles) {
            const elem = document.getElementById('productautocomplete');
            if (elem) {
                elem.focus();
            }
        }
    }, [products, bundles]);

    return (
        <Autocomplete
            {...defaultProps}
            id="productautocomplete"
            name="productautocomplete"
            loading={!products}
            loadingText={t('general.loading')}
            noOptionsText={noOptions()}
            onChange={onSelect}
            filterOptions={filterOpts}
            renderInput={(params) => <TextField {...params} label={t('general.product')} margin="normal" />}
        />
    );
}
