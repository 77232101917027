import FilterProduct from './filterproduct';
import moment from 'moment';

class ProductBundle {
    constructor(json) {
        if (json != null) {
            this.clusterId = json.clusterId;
            this.id = json.id;
            this.name = json.name;
            this.code = json.code;
            this.products = [];
            this.expired = json.expired;
            if (json.products != null && json.products.length > 0) {
                for (let prod of json.products) {
                    this.products.push(new ProductBundleProduct(new FilterProduct(prod.product), prod.productCount));
                }
            }
            this.update = json.update;
        } else {
            this.cluster_id = 0;
            this.id = 0;
            this.name = '';
            this.code = '';
            this.products = [];
            this.expired = false;
            this.update = moment();
        }
    }

    productType() {
        return 'bundle';
    }

    priceWithoutVat() {
        let price = 0;
        for (const p of this.products) {
            price += p.product.priceWithoutVat * p.productCount;
        }
        return price;
    }

    productionPrice() {
        let price = 0;
        for (const p of this.products) {
            price += p.product.productionPrice * p.productCount;
        }
        return price;
    }

    isExpired() {
        if (this.expired) return true;
        for (const p of this.products) {
            if (p.product.expired) return true;
        }
        return false;
    }

    fabricSurfaceArea() {
        let area = 0;
        for (const p of this.products) {
            area += p.product.fabricSurfaceArea * p.productCount;
        }
        return area;
    }
}

class ProductBundleProduct {
    constructor(product, productCount) {
        this.product = product;
        this.productCount = productCount;
    }
}

export { ProductBundle, ProductBundleProduct };
