import Ajax from './ajax';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { LOGGED_OFF } from './authentication';

const initialState = {
    surveys: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const getOpenSurveysForUserPortfolio = () => {
    return async (dispatch) => {
        /*dispatch({
            type: SETTINGS_FETCHING,
        });*/
        try {
            const resp = await Ajax.get('api/survey');
            return resp.data;
        } catch (err) {
            return null;
        }
    };
};

export const getSurveyPdf = (surveyId, fileName = 'document.pdf') => {
    return async (dispatch) => {
        try {
            const response = await Ajax.get('api/survey/pdf/' + surveyId, {
                responseType: 'blob',
                acceptHeader: 'application/pdf',
            });

            if (response.status === 200) {
                var blob = new Blob([response.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
                toast.info(i18next.t('createPdf.createPdfSuccess'), { autoClose: 1500, hideProgressBar: true });
                URL.revokeObjectURL(url);
            } else {
                toast.info(i18next.t('createPdf.createPdfFail'), { autoClose: 5000, hideProgressBar: true });
            }
        } catch (err) {
            console.log(err);
            toast.info(i18next.t('createPdf.createPdfFail'), { autoClose: 5000, hideProgressBar: true });
        }
    };
};

export const getImageUrl = (imageId) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get('api/survey/image/' + imageId);
            return resp.data;
        } catch (err) {
            return null;
        }
    };
};
