import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';
import InvoiceDescription from '../domain/invoicedescription';
import Invoice from '../domain/invoice';
import { cloneDeep } from 'lodash';

export const LOADING_INVOICELIST_SENT = 'invoicedata/loadinginvoicelistsent';
export const INVOICELIST_LOADED_SENT = 'invoicedata/invoicelistloadedsent';
export const INVOICELIST_LOAD_FAILED_SENT = 'invoicedata/invoicelistloadfailedsent';
export const LOADING_INVOICELIST_OPEN = 'invoicedata/loadinginvoicelistopen';
export const INVOICELIST_LOADED_OPEN = 'invoicedata/invoicelistloadedopen';
export const INVOICELIST_LOAD_FAILED_OPEN = 'invoicedata/invoicelistloadfailedopen';
export const GET_INVOICE_BY_ID_DONE = 'invoicedata/getbyiddone';
export const SAVE_INVOICE_DONE = 'invoicedata/saveinvoicedone';
export const SAVE_INVOICE_IN_PROGRESS = 'invoicedata/saveinvoiceinprogress';
export const SAVE_INVOICE_FAIL = 'invoicedata/saveinvoicefail';
export const INVOICE_FROM_ORDER_IN_PROGRESS = 'invoicedata/invoicefromorderinprogress';
export const INVOICE_FROM_ORDER_DONE = 'invoicedata/invoicefromorderdone';
export const CREDITINVOICE_CREATED = 'invoicedata/creditinvoicedone';
export const DELETE_INVOICE_DONE = 'invoicedata/deleteinvoicedone';

export const SEARCHING_INVOICES = 'invoicedata/searching';
export const SEARCH_DONE = 'invoicedata/searchdone';
export const SEARCH_RESET = 'invoicedata/searchreset';

export const RESET_INVOICE_DATA = 'invoicedata/resetalldata';

const initialState = {
    invoicesSent: null,
    invoicesOpen: null,
    invoicesById: {},
    loadingInvoicesSent: false,
    loadingInvoicesOpen: false,
    savingInvoice: false,
    generatingInvoice: false,
    searchTerm: '',
    searchResults: [],
    searchInProgress: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_INVOICELIST_SENT:
            return {
                ...state,
                loadingInvoicesSent: true,
            };
        case INVOICELIST_LOAD_FAILED_SENT:
            return {
                ...state,
                invoicesSent: null,
                loadingInvoicesSent: false,
            };
        case INVOICELIST_LOADED_SENT: {
            let newInv = state.invoicesSent ? state.invoicesSent : [];
            for (let i of action.invoices) {
                newInv.push(new InvoiceDescription(i));
            }
            return {
                ...state,
                loadingInvoicesSent: false,
                invoicesSent: newInv,
            };
        }
        case LOADING_INVOICELIST_OPEN:
            return {
                ...state,
                invoicesOpen: null,
                loadingInvoicesOpen: true,
            };
        case INVOICELIST_LOAD_FAILED_OPEN:
            return {
                ...state,
                invoicesOpen: null,
                loadingInvoicesOpen: false,
            };
        case INVOICELIST_LOADED_OPEN: {
            let newInv = [];
            for (let i of action.invoices) {
                newInv.push(new InvoiceDescription(i));
            }
            return {
                ...state,
                loadingInvoicesOpen: false,
                invoicesOpen: newInv,
            };
        }
        case GET_INVOICE_BY_ID_DONE:
        case SAVE_INVOICE_DONE:
            const invoices = cloneDeep(state.invoicesById);
            invoices[action.invoice.id] = new Invoice(action.invoice);

            return {
                ...state,
                invoicesById: invoices,
                savingInvoice: false,
            };
        case INVOICE_FROM_ORDER_IN_PROGRESS:
            return {
                ...state,
                generatingInvoice: true,
            };
        case INVOICE_FROM_ORDER_DONE:
            return {
                ...state,
                invoicesById: {},
                invoices: null,
                generatingInvoice: false,
            };
        case SAVE_INVOICE_IN_PROGRESS: {
            return {
                ...state,
                savingInvoice: true,
            };
        }
        case SAVE_INVOICE_FAIL: {
            return {
                ...state,
                savingInvoice: false,
            };
        }
        case CREDITINVOICE_CREATED: {
            let invoices = Object.assign({}, state.invoicesById);
            invoices[action.invoice.id] = new Invoice(action.invoice);
            return {
                ...state,
                invoicesOpen: null,
                invoicesSent: null,
                invoicesById: invoices,
            };
        }
        case DELETE_INVOICE_DONE: {
            let newInv = [];
            let invoices = Object.assign({}, state.invoicesById);
            invoices[action.invoiceNumber] = null;
            for (let i of state.invoices) {
                if (i.invoiceNumber !== action.invoiceNumber) newInv.push(new InvoiceDescription(i));
            }
            return {
                ...state,
                invoices: newInv,
                invoicesById: invoices,
            };
        }
        case SEARCHING_INVOICES:
            return {
                ...state,
                searchInProgress: true,
                searchTerm: action.term,
                searchResults: [],
            };
        case SEARCH_DONE:
            return {
                ...state,
                searchInProgress: false,
                searchResults: action.results.map((r) => new InvoiceDescription(r)),
            };
        case SEARCH_RESET:
            return {
                ...state,
                searchInProgress: false,
                searchResults: [],
            };
        case RESET_INVOICE_DATA:
            return {
                invoicesOpen: null,
                invoicesSent: null,
                invoicesById: {},
            };
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const getMoreInvoicesSent = (offset, count) => {
    return (dispatch) => {
        dispatch({
            type: LOADING_INVOICELIST_SENT,
        });
        Ajax.get(`api/invoice/descriptions/offset/${offset}/count/${count}`)
            .then((resp) => {
                dispatch({
                    type: INVOICELIST_LOADED_SENT,
                    invoices: resp.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: INVOICELIST_LOAD_FAILED_SENT,
                });
            });
    };
};

export const getInvoicesOpen = () => {
    return (dispatch) => {
        dispatch({
            type: LOADING_INVOICELIST_OPEN,
        });
        Ajax.get('api/invoice/descriptions-open/')
            .then((resp) => {
                dispatch({
                    type: INVOICELIST_LOADED_OPEN,
                    invoices: resp.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: INVOICELIST_LOAD_FAILED_OPEN,
                });
            });
    };
};

export const getInvoiceById = (invoiceId) => {
    return async (dispatch) => {
        try {
            let resp = await Ajax.get('api/invoice/' + invoiceId);
            // resp.data.id = invoiceId; // TODO, temp hack
            dispatch({
                type: GET_INVOICE_BY_ID_DONE,
                invoice: resp.data,
            });
        } catch (err) {
            console.log(err);
        }
    };
};

export const saveInvoice = (invoice) => {
    return async (dispatch) => {
        dispatch({ type: SAVE_INVOICE_IN_PROGRESS });
        try {
            let resp = await Ajax.put('api/invoice/' + invoice.id, invoice);
            dispatch({
                type: SAVE_INVOICE_DONE,
                invoice: resp.data,
            });
            return true;
        } catch (err) {
            dispatch({ type: SAVE_INVOICE_FAIL });
            console.log(err);
            return false;
        }
    };
};

export const invoiceFromOrder = (orderNumber) => {
    return async (dispatch) => {
        dispatch({ type: INVOICE_FROM_ORDER_IN_PROGRESS });
        try {
            const resp = await Ajax.get('api/invoice/fromorder/' + orderNumber);
            dispatch({ type: INVOICE_FROM_ORDER_DONE });
            return new Invoice(resp.data);
        } catch (err) {
            dispatch({ type: INVOICE_FROM_ORDER_DONE });
            console.log(err);
            return null;
        }
    };
};

export const sendInvoice = (invoice) => {
    return async (dispatch) => {
        try {
            let resp = await Ajax.post('api/invoice/send/' + invoice.id, {});
            dispatch({
                type: SAVE_INVOICE_DONE,
                invoice: resp.data,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const createCreditInvoice = (invoiceNumber) => {
    return async (dispatch) => {
        try {
            let resp = await Ajax.get(`api/invoice/credit/${invoiceNumber}`);
            dispatch({ type: CREDITINVOICE_CREATED, invoice: resp.data });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const deleteInvoice = (invoiceNumber) => {
    return async (dispatch) => {
        try {
            await Ajax.delete(`api/invoice/${invoiceNumber}`);
            dispatch({
                type: DELETE_INVOICE_DONE,
                invoiceNumber: invoiceNumber,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const checkIfCustomerInformationShouldBeUpdate = (invoiceNumber) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get(`api/invoice/customerDataUpdateNeededFor/${invoiceNumber}`);
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

export const updateCustomerInformationFromInvoice = (invoiceNumber) => {
    return async (dispatch) => {
        try {
            await Ajax.post(`api/invoice/updatecustomerdata/${invoiceNumber}`);
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const searchInvoices = (term) => {
    return (dispatch) => {
        if (term && term.length >= 3) {
            dispatch({
                type: SEARCHING_INVOICES,
                term,
            });
            Ajax.post('api/search/invoice/', { term })
                .then((resp) => {
                    dispatch({
                        type: SEARCH_DONE,
                        results: resp.data,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: SEARCH_DONE,
                        results: [],
                    });
                });
        } else {
            dispatch({ type: SEARCH_RESET });
        }
    };
};
