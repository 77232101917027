import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, DialogTitle, FormGroup } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/date-fns';
import fiLocale from 'date-fns/locale/fi';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextEditBlock from '../invoice/texteditblock';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    datePicker: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    checkbox: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

export default function ProductionCompleteDialog(props) {
    const classes = useStyles();
    const open = props.open;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [palletCount, setPalletCount] = React.useState(0);
    const [boxCount, setBoxCount] = React.useState(0);
    const [deliveryDate, setDeliveryDate] = React.useState(moment());
    const [saving, setSaving] = React.useState(false);
    const [notInvoiced, setNotInvoiced] = React.useState(false);
    const [notInvoicedReason, setNotInvoicedReason] = React.useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setPalletCount(0);
        setBoxCount(0);
        setDeliveryDate(moment());
        setSaving(false);
    }, [props.open]);

    const cancel = () => {
        props.cancel();
    };

    const save = async () => {
        if (
            palletCount >= 0 &&
            boxCount >= 0 &&
            moment(deliveryDate).isValid() &&
            (!notInvoiced || (notInvoiced && notInvoicedReason.length > 0))
        ) {
            setSaving(true);
            await props.save(
                boxCount,
                palletCount,
                moment(deliveryDate).hour(12).minute(0).second(0).millisecond(0),
                notInvoiced,
                notInvoicedReason
            );
        }
    };

    return (
        <Dialog
            onClose={cancel}
            aria-labelledby="simple-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogTitle>{t('production.orderReady')}</DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            type="number"
                            disabled={saving}
                            id="box-count"
                            name="box-count"
                            label={t('production.boxes')}
                            value={boxCount}
                            className={classes.textField}
                            margin="normal"
                            onChange={(evt) => setBoxCount(parseInt(evt.target.value))}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            type="number"
                            disabled={saving}
                            id="palletCount"
                            name="palletCount"
                            label={t('production.pallets')}
                            value={palletCount}
                            className={classes.textField}
                            margin="normal"
                            onChange={(evt) => setPalletCount(parseInt(evt.target.value))}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                            <KeyboardDatePicker
                                id="deliveryDate"
                                className={classes.datePicker}
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd.MM.yyyy"
                                margin="normal"
                                name="deliveryDate"
                                label={t('general.deliveryDate') + ' *'}
                                value={deliveryDate}
                                onChange={(val) => {
                                    const date = moment(val);
                                    if (date.isValid()) {
                                        setDeliveryDate(date);
                                        console.log(date);
                                    }
                                }}
                                invalidDateMessage={t('general.invalidDateMsg')}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12} md={6}>
                            <FormGroup row>
                                <FormControlLabel
                                    className={classes.checkbox}
                                    control={
                                        <Checkbox
                                            checked={notInvoiced}
                                            onChange={() => {
                                                setNotInvoiced(!notInvoiced);
                                            }}
                                            name="eilaskuteta"
                                        />
                                    }
                                    label={t('general.willNotBeInvoiced')}
                                />
                            </FormGroup>
                        </Grid>

                        {notInvoiced && (
                            <TextEditBlock
                                className={classes.textField}
                                name={'notinvoiced-selite'}
                                label={t('general.definition')}
                                value={notInvoicedReason}
                                onChange={(e) => {
                                    setNotInvoicedReason(e.target.value);
                                }}
                                number={false}
                                required={true}
                                xs={12}
                                md={6}
                            />
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={saving || (notInvoiced && notInvoicedReason.length === 0)}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    name="btn-save"
                    onClick={save}
                    startIcon={saving ? <CircularProgress color={'secondary'} size={'1rem'} /> : null}>
                    {t('buttons.save')}
                </Button>
                <Button
                    disabled={saving}
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    name="btn-cancel"
                    onClick={cancel}>
                    {t('buttons.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
