import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { groupBy, some, clone } from 'lodash';
import useTheme from '@material-ui/core/styles/useTheme';
import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import OrderHistoryRow from './orderhistoryrow';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    bolded: {
        fontWeight: 'bold',
    },
    table: {
        width: '100%',
    },
    orderRow: {
        cursor: 'pointer',
    },
    receiptIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
}));

export default function OrderHistory(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
    const [orderGroups, setOrderGroups] = useState(null);
    const [orderNumbers, setOrderNumbers] = useState(null);
    const [orderNumberVisible, setOrderNumberVisible] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.reports) {
            const groups = groupBy(props.reports.orderHistoryReport, 'orderNumber');
            setOrderGroups(groups);
            let orderNums = Object.keys(groups).sort().reverse();
            let orderNumsVisibleStatus = {};
            for (let orderNum of orderNums) {
                orderNumsVisibleStatus[orderNum] = true;
            }
            setOrderNumberVisible(orderNumsVisibleStatus);
            setOrderNumbers(orderNums);
        }
    }, [props.reports]);

    const isSame = useCallback((prev, next) => {
        if (prev.length !== next.length) return false;
        if (JSON.stringify(prev) !== JSON.stringify(next)) return false;
        return true;
    }, []);

    useEffect(() => {
        if (!orderNumbers) return;
        let orderNumberVisibleClone = clone(orderNumberVisible);
        if (props.searchFilter.length > 0) {
            for (const [key, value] of Object.entries(orderGroups)) {
                orderNumberVisibleClone[key] = some(value, function (o) {
                    return (
                        o.customerName.toLowerCase().includes(props.searchFilter) ||
                        (o.locName && o.locName.toLowerCase().includes(props.searchFilter)) ||
                        o.orderNumber.toString().includes(props.searchFilter)
                    );
                })
                    ? true
                    : false;
            }
        } else {
            for (let orderNum of orderNumbers) {
                orderNumberVisibleClone[orderNum] = true;
            }
        }
        if (!isSame(orderNumberVisible, orderNumberVisibleClone)) {
            setOrderNumberVisible(orderNumberVisibleClone);
        }
    }, [props.searchFilter, props.reports, orderGroups, orderNumberVisible, orderNumbers, isSame]);

    if (props.reports == null) {
        return <p>{t('reports.noOrderHistory')}</p>;
    }

    const openOrder = (orderNumber) => {
        props.history.push('/ordernumber/' + orderNumber);
    };

    if (isDesktop)
        return (
            <Grid container>
                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('general.orderDate')}</TableCell>
                            <TableCell>{t('general.orderNumber')}</TableCell>
                            <TableCell>{t('general.customer')}</TableCell>
                            <TableCell>{t('general.orderer')}</TableCell>
                            {props.allowPricing && <TableCell>{t('invoice.totalPriceVatZero')}</TableCell>}
                            {!props.customerui && <TableCell>{t('general.portfolio')}</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderNumbers &&
                            orderNumbers.length > 0 &&
                            orderNumbers.map((orderNum) => (
                                <OrderHistoryRow
                                    key={orderNum}
                                    orderNumber={orderNum}
                                    openOrder={openOrder}
                                    orderGroup={orderGroups[orderNum]}
                                    isDesktop={isDesktop}
                                    customerui={props.customerui}
                                    allowPricing={props.allowPricing}
                                    show={orderNumberVisible[orderNum]}
                                />
                            ))}
                    </TableBody>
                </Table>
            </Grid>
        );
    //mobile view
    else
        return (
            <Grid container>
                {orderNumbers &&
                    orderNumbers.length > 0 &&
                    orderNumbers.map((orderNum) => (
                        <OrderHistoryRow
                            key={orderNum}
                            orderNumber={orderNum}
                            openOrder={openOrder}
                            orderGroup={orderGroups[orderNum]}
                            isDesktop={isDesktop}
                            customerui={props.customerui}
                            allowPricing={props.allowPricing}
                            show={orderNumberVisible[orderNum]}
                        />
                    ))}
            </Grid>
        );
}

OrderHistory.propTypes = {
    reports: PropTypes.object,
    history: PropTypes.object.isRequired,
    customerui: PropTypes.bool,
    allowPricing: PropTypes.bool,
    searchFilter: PropTypes.string,
};
