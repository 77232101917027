class OrderConfirmationOptions {
    constructor(json) {
        if (json != null) {
            this.orderId = json.orderId;
            this.basicPriceInfo = json.basicPriceInfo;
            this.unitPriceInfo = json.unitPriceInfo;
            this.productNameInfo = json.productNameInfo;
            this.deliveryTimeInfo = json.deliveryTimeInfo;
        } else {
            this.orderId = 0;
            this.basicPriceInfo = false;
            this.unitPriceInfo = false;
            this.productNameInfo = false;
            this.deliveryTimeInfo = false;
        }
    }
}

export default OrderConfirmationOptions;
