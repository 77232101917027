import React, { useCallback, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { verifyAndReturnDecimal } from '../common/common';
import Tooltip from '@material-ui/core/Tooltip';
import { cloneDeep } from 'lodash';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    sidenote: {
        marginLeft: '10px',
        fontSize: '0.8em',
    },
}));

export default function TextEditBlock(props) {
    const classes = useStyles();
    const typeNumber = !!props.number;
    const xs = props.xs ? props.xs : 12;
    const md = props.md ? props.md : 6;
    const maxLen = props.maxLength;
    const requiredLen = props.requiredLen;
    const showCounter = props.showCounter;
    const sidenote = props.sidenote;
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [fieldValue, setFieldValue] = React.useState('');
    const [hasError, setHasError] = React.useState(false);
    const [counterStr, setCounterStr] = React.useState('');
    const tooltipText = `Kentän maksimipituus ${props.maxLength} merkkiä ylitetty.`;

    const hasErrorInLength = useCallback(
        (value, max) => {
            if (props.required && (!value || value.length === 0)) {
                return true;
            } else {
                if (requiredLen && value && value.length !== requiredLen) {
                    return true;
                }
                if (!max) return false;
                if (value && value.length > max) return true;
            }
            return false;
        },
        [props.required, requiredLen]
    );

    useEffect(() => {
        setHasError(hasErrorInLength(props.value, maxLen));
        setFieldValue(props.value);
        if (showCounter) {
            if (maxLen && props.value) {
                setCounterStr(`${props.value.length} / ${maxLen}`);
            } else if (props.value) {
                setCounterStr(props.value.length);
            } else if (!props.value && maxLen) {
                setCounterStr(`0 / ${maxLen}`);
            }
        }
    }, [props.value, hasErrorInLength, maxLen, showCounter]);

    const onBlur = (evt) => {
        if (typeNumber) {
            let parsed = verifyAndReturnDecimal(evt.target.value);
            if (parsed === null) parsed = 0;
            props.onChange({ target: { name: evt.target.name, value: parsed } });
            if (props.onBlur) props.onBlur({ target: { name: evt.target.name, value: parsed } });
        } else if (props.onBlur) {
            const evtClone = cloneDeep(evt);
            if (props.defaultValue && maxLen && evt.target.value && evt.target.value.length > maxLen) {
                evtClone.target.value = evtClone.target.value.substring(0, maxLen);
            } else if (
                maxLen &&
                evt.target.value &&
                evt.target.value.length > maxLen &&
                evt.target.value.length > fieldValue.length
            ) {
                evtClone.target.value = evtClone.target.value.substring(0, maxLen);
            }
            props.onBlur(evtClone);
            setFieldValue(evtClone.target.value);
        }
    };

    const handleTooltipClose = () => {};

    const onChange = (e) => {
        setFieldValue(e.target.value);
        if (maxLen) {
            if (e.target.value && e.target.value.length > maxLen) {
                setTooltipOpen(true);
                setTimeout(() => {
                    setTooltipOpen(false);
                }, 2000);
            }
            if (e.target.value && e.target.value.length > maxLen && e.target.value.length >= fieldValue.length) {
                return;
            }
        }
        if (props.onChange) {
            props.onChange(e);
        }
    };

    if (props.required)
        return (
            <Grid item xs={xs} md={md}>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={tooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    title={tooltipText}>
                    <div>
                        <TextField
                            required
                            id={props.id}
                            name={props.name}
                            label={props.label}
                            value={props.value}
                            defaultValue={props.defaultValue}
                            className={classes.textField}
                            margin="normal"
                            onChange={onChange}
                            onBlur={onBlur}
                            error={hasError}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{counterStr}</InputAdornment>,
                            }}
                        />
                        {sidenote && sidenote.length > 0 && <em className={classes.sidenote}>{sidenote}</em>}
                    </div>
                </Tooltip>
            </Grid>
        );
    return (
        <Grid item xs={xs} md={md}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement="top"
                title={tooltipText}>
                <div>
                    <TextField
                        id={props.id}
                        name={props.name}
                        label={props.label}
                        value={props.value}
                        defaultValue={props.defaultValue}
                        className={classes.textField}
                        margin="normal"
                        onChange={onChange}
                        onBlur={onBlur}
                        error={hasError}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{counterStr}</InputAdornment>,
                        }}
                    />
                    {sidenote && sidenote.length > 0 && <em className={classes.sidenote}>{sidenote}</em>}
                </div>
            </Tooltip>
        </Grid>
    );
}
