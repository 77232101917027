import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TagsInput from './tagsinput';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    inputField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
        marginTop: '14px',
    },
    inputFieldFullWidt: {
        width: '100% !important',
        marginLeft: '0px !important',
        marginRight: '0px !important',
    },
    inputMissing: {
        color: 'red',
    },
    rootHelperText: {
        color: 'red',
    },
}));

export default function EmailInput(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <TagsInput
            selectedTags={(items) => props.onChange(items.join(';'))}
            fullWidth
            variant="standard"
            id="emailinput"
            name="emailinput"
            label={t('order.confirmationEmail')}
            tags={props.emails && props.emails.length > 0 ? props.emails.split(';') : []}
            className={clsx(classes.inputField, { [classes.inputFieldFullWidt]: props.fullWidth })}
        />
    );
}
