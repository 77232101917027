import React from 'react';
import CustomerItem from './customeritem';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

export function getAlphabeticallySorted(customers, filter) {
    let sortedCustomers = {};
    let result = [];
    if (customers == null) return result;

    // Filter
    const customersFiltered = _.filter(customers, (c) => {
        return _.deburr(c.name).toLowerCase().indexOf(_.deburr(filter).toLowerCase()) !== -1;
    });

    for (let customer of customersFiltered) {
        var firstLetter = customer.name.substring(0, 1).toUpperCase();
        if (sortedCustomers[firstLetter] == null) {
            sortedCustomers[firstLetter] = {
                key: firstLetter,
                customers: [customer],
            };
        } else {
            sortedCustomers[firstLetter].customers.push(customer);
        }
    }
    let keys = Object.keys(sortedCustomers);
    keys.sort();
    for (let c of keys) {
        result.push(sortedCustomers[c]);
    }
    return result;
}

export default function AlphabeticalCustomerList(props) {
    const { t } = useTranslation();

    if (props.customerDescriptions != null && props.customerDescriptions.length === 0) {
        return (
            <div style={{ padding: '20px' }}>
                <Alert severity="info">{t('customer.noCustomersHint')}</Alert>
            </div>
        );
    }
    return (
        <Grid container>
            {getAlphabeticallySorted(props.customerDescriptions, props.filter).map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={item.key}>
                    <h2>{item.key}</h2>
                    {item.customers.map((customer) => (
                        <CustomerItem key={customer.id} customerDesc={customer} history={props.history} />
                    ))}
                </Grid>
            ))}
        </Grid>
    );
}
