import React, { useEffect, useState } from 'react';
import { SearchByTerm, SearchByBusinessId } from './bislookup';
import BisLookupView from './bislookupview';

export const TypeSearchByTerm = 0;
export const TypeSearchByBusinessId = 1;

export default function BisLookupContainer(props) {
    const [termResult, setTermResult] = useState(null);
    const [businessIdResult, setBusinessIdResult] = useState(null);
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        if (props.open && props.searchTerm) executeSearchByTerm(props.searchTerm);
        if (props.open && props.searchBusinessId) executeSearchByBusinessId(props.searchBusinessId);
        if (!props.open) {
            setTermResult(null);
            setBusinessIdResult(null);
        }
    }, [props.open, props.searchTerm, props.searchBusinessId]);

    const executeSearchByTerm = (term) => {
        setBusy(true);
        SearchByTerm(term)
            .then((data) => {
                setTermResult(data);
                setBusy(false);
            })
            .catch(() => {
                setTermResult(null);
                setBusy(false);
            });
    };

    const executeSearchByBusinessId = (businessId) => {
        setBusy(true);
        SearchByBusinessId(businessId)
            .then((data) => {
                setBusinessIdResult(data);
                setBusy(false);
            })
            .catch(() => {
                setBusinessIdResult(null);
                setBusy(false);
            });
    };

    const executeSearch = (term, type) => {
        if (type === TypeSearchByTerm) executeSearchByTerm(term);
        else executeSearchByBusinessId(term);
    };

    const onCancel = () => {
        props.cancel();
    };

    const onSave = (name, businessId) => {
        props.save(name, businessId);
    };

    return (
        <BisLookupView
            open={props.open}
            bisItems={termResult}
            businessIdResult={businessIdResult}
            cancel={onCancel}
            save={onSave}
            busy={busy}
            searchTerm={props.searchTerm}
            searchBusinessId={props.searchBusinessId}
            search={executeSearch}
        />
    );
}
