import React, { Fragment } from 'react';
import FilterOrderRowView from './filterorderrowview';
import FilterOrderRowEdit from './filterorderrowedit';

export default function FilterOrder(props) {
    const editingOrder = props.editOrderedItem;

    const onClickRow = () => {
        if (props.selected) return;
        props.clickRow(props.filterOrder.id);
    };
    const onClickToSelect = (id) => {
        props.clickSelect(id);
    };

    const filterCountChanged = async (id, value) => {
        if (props.filterCount !== value) {
            await props.filterCountChange(props.filterOrder.id, value);
            await props.filterCountChangeDone(props.filterOrder.id, value);
        }
    };

    return (
        <Fragment>
            {!props.editRow ? (
                <FilterOrderRowView
                    index={props.index}
                    editingOrder={editingOrder}
                    selected={props.selected}
                    filterOrder={props.filterOrder}
                    startEditRow={onClickRow}
                    selectRow={onClickToSelect}
                    customerView={props.customerView}
                    allowPricing={props.allowPricing}
                    filterCountChanged={filterCountChanged}
                    editFoPalletInfo={props.editFoPalletInfo}
                    fadeRow={props.fadeRow}
                    filterCount={props.filterCount}
                    priceOnly={props.priceOnly}
                    saving={props.saving}
                />
            ) : (
                <FilterOrderRowEdit
                    index={props.index}
                    editingOrder={editingOrder}
                    selected={props.selected}
                    filterOrder={props.filterOrder}
                    customerView={props.customerView}
                    allowPricing={props.allowPricing}
                    filterCountChanged={filterCountChanged}
                    filterCount={props.filterCount}
                    closeRowEdit={props.closeRowEdit}
                    filterOrderChanged={props.filterOrderChanged}
                    priceOnly={props.priceOnly}
                    saving={props.saving}
                />
            )}
        </Fragment>
    );
}
