class CapacityAllocation {
    constructor(json) {
        if (json) {
            this.portfolioId = json.portfolioId;
            this.year = json.year;
            this.week = json.week;
            this.allocatedCapacity = json.allocatedCapacity;
        } else {
            this.portfolioId = 0;
            this.year = 0;
            this.week = 0;
            this.allocatedCapacity = 0;
        }
    }
}

export default CapacityAllocation;
