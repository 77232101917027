import Ajax from './ajax';

class ObjectIdGenerator {
    static newId() {
        let p = new Promise(function (resolve, reject) {
            Ajax.get('api/ObjectIdGenerator/')
                .then(function (data) {
                    resolve(data.data);
                })
                .catch(function (err) {
                    reject();
                });
        });
        return p;
    }

    static newIdMultiple(count) {
        let p = new Promise(function (resolve, reject) {
            Ajax.get('api/ObjectIdGenerator/' + count)
                .then(function (data) {
                    resolve(data.data);
                })
                .catch(function (err) {
                    reject();
                });
        });
        return p;
    }

    static async newCustomerNumber() {
        try {
            const resp = await Ajax.get('api/ObjectIdGenerator/customernumber/');
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    }
}

export default ObjectIdGenerator;
