import Location from '../domain/location';
import CustomerLocationDescription from '../domain/customerlocationdescription';
import Ajax from './ajax';
import Customer from '../domain/customer';
import history from '../history';
import { LOGGED_OFF } from './authentication';
import { toast } from 'react-toastify';
import i18next from 'i18next';

export const GET_DESCRIPTIVE_INFO_IN_PROGRESS = 'customerlocationdata/getdescinfoinprogress';
export const GET_DESCRIPTIVE_INFO_FAIL = 'customerlocationdata/getdescinfofail';
export const GET_DESCRIPTIVE_INFO_SUCCESS = 'customerlocationdata/getdescinfosuccess';

export const GET_LOCATION_FULL_IN_PROGRESS = 'customerlocationdata/getlocfullinproc';
export const GET_LOCATION_FULL_SUCCESS = 'customerlocationdata/getlocfullsucces';
export const GET_LOCATION_FULL_FAILED = 'customerlocationdata/getlocfullfailed';
export const GET_LOCATION_FULL_NOT_FOUND = 'customerlocationdata/getlocfullnotfound';

export const GET_CUSTOMER_TREE_IN_PROGRESS = 'customerlocationdata/gettreeinproc';
export const GET_CUSTOMER_TREE_SUCCESS = 'customerlocationdata/gettreesuccess';
export const GET_CUSTOMER_TREE_FAILED = 'customerlocationdata/gettreefailed';

const initialState = {
    locationDescriptions: null,
    getLocationDescriptionsInProgress: false,
    getLocationDescriptionsFailed: false,
    locationsById: {},
    getLocByIdInProgress: false,
    getLocByIdFailed: false,
    customers: null,
    getCustomerTreeInProgress: false,
    getCustomerTreeFailed: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DESCRIPTIVE_INFO_IN_PROGRESS:
            return {
                ...state,
                getLocationDescriptionsInProgress: true,
                getLocationDescriptionsFailed: false,
            };
        case GET_DESCRIPTIVE_INFO_FAIL:
            return {
                ...state,
                getLocationDescriptionsFailed: true,
                getLocationDescriptionsInProgress: false,
                locationDescriptions: null,
            };
        case GET_DESCRIPTIVE_INFO_SUCCESS: {
            let data = [];
            if (action.data != null && action.data.length > 0) {
                for (let loc of action.data) {
                    data.push(new CustomerLocationDescription(loc));
                }
            }
            return {
                ...state,
                locationDescriptions: data,
                getLocationDescriptionsInProgress: false,
            };
        }
        case GET_LOCATION_FULL_IN_PROGRESS:
            return {
                ...state,
                getLocByIdInProgress: true,
                getLocByIdFailed: false,
            };
        case GET_LOCATION_FULL_SUCCESS:
            let newArr = Object.assign({}, state.locationsById);
            newArr[action.data.id] = new Location(action.data);
            return {
                ...state,
                locationsById: newArr,
                getLocByIdFailed: false,
                getLocByIdInProgress: false,
            };
        case GET_LOCATION_FULL_NOT_FOUND:
            return {
                ...state,
                getLocByIdInProgress: false,
                getLocByIdFailed: false,
            };
        case GET_LOCATION_FULL_FAILED:
            return {
                ...state,
                getLocByIdFailed: true,
                getLocByIdInProgress: false,
            };
        case GET_CUSTOMER_TREE_IN_PROGRESS: {
            return {
                ...state,
                getCustomerTreeInProgress: true,
                getCustomerTreeFailed: false,
            };
        }
        case GET_CUSTOMER_TREE_SUCCESS: {
            const customers = [];
            if (action.customers && action.customers.length > 0) {
                for (const c of action.customers) {
                    customers.push(new Customer(c));
                }
            }
            return {
                ...state,
                customers: customers,
                getCustomerTreeInProgress: false,
                getCustomerTreeFailed: false,
            };
        }
        case GET_CUSTOMER_TREE_FAILED: {
            return {
                ...state,
                getCustomerTreeInProgress: false,
                getCustomerTreeFailed: true,
            };
        }
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const getCustomerLocationDescriptiveInfo = () => {
    return (dispatch) => {
        dispatch({
            type: GET_DESCRIPTIVE_INFO_IN_PROGRESS,
        });
        Ajax.get('api/customerlocation')
            .then(function (data) {
                dispatch({
                    type: GET_DESCRIPTIVE_INFO_SUCCESS,
                    data: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
                dispatch({
                    type: GET_DESCRIPTIVE_INFO_FAIL,
                });
            });
    };
};

export const getLocationFull = (id) => {
    return (dispatch) => {
        dispatch({
            type: GET_LOCATION_FULL_IN_PROGRESS,
        });
        Ajax.get('api/customerlocation/' + id)
            .then(function (data) {
                dispatch({
                    type: GET_LOCATION_FULL_SUCCESS,
                    data: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
                // 404 = not found => not existing or deleted location
                if (err.response && err.response.status === 404) {
                    toastError('errors.locationMissingOrRemoved');
                    history.replace('/locations');
                    dispatch({ type: GET_LOCATION_FULL_NOT_FOUND });
                } else {
                    dispatch({
                        type: GET_LOCATION_FULL_FAILED,
                    });
                    toastError('general.fetchFailedRetry');
                }
            });
    };
};

export const getCustomerTree = () => {
    return (dispatch) => {
        dispatch({
            type: GET_CUSTOMER_TREE_IN_PROGRESS,
        });
        Ajax.get('api/customerlocation/tree')
            .then((data) => {
                dispatch({
                    type: GET_CUSTOMER_TREE_SUCCESS,
                    customers: data.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_CUSTOMER_TREE_FAILED,
                });
            });
    };
};

const toastError = (message) => {
    toast.error(i18next.t(message), { timeout: 5000, hideProgressBar: true });
};
