import moment from 'moment';

class ProductNote {
    constructor(json) {
        if (json) {
            this.storageId = json.storageId;
            this.productId = json.productId;
            this.text = json.text;
            this.noteSaved = moment(json.noteSaved);
            this.userId = json.userId;
        } else {
            this.productId = null;
            this.text = '';
        }
    }
}

export default ProductNote;
