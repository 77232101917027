import Ajax from './ajax';
import { REMOVE_ORDER_HISTORY_DATA_SUCCESS, SEND_ORDERS_SUCCESS } from './orderdata';
import { LOGGED_OFF } from './authentication';

export const GET_YEARLY_REPORT_IN_PROGRESS = 'reportdata/getyearlydatainproc';
export const GET_YEARLY_REPORT_SUCCESS = 'reportdata/getyearlydatasuccess';
export const GET_YEARLY_REPORT_FAILED = 'reportdata/getyearlydatafailed';
export const GET_ORDER_REPORT_IN_PROGRESS = 'reportdata/getorderreportinproc';
export const GET_ORDER_REPORT_SUCCESS = 'reportdata/getorderreportsuccess';
export const GET_ORDER_REPORT_FAILED = 'reportdata/getorderreportfailed';
export const GET_MONTHLY_REPORT_IN_PROGRESS = 'reportdata/getmonthlydatainproc';
export const GET_MONTHLY_REPORT_SUCCESS = 'reportdata/getmonthlydatasuccess';
export const GET_MONTHLY_REPORT_FAILED = 'reportdata/getmonthlydatafailed';
export const GET_SALES_REPORT_IN_PROGRESS = 'reportdata/getosalesreportinproc';
export const GET_SALES_REPORT_SUCCESS = 'reportdata/getsalesreportsuccess';
export const GET_SALES_REPORT_FAILED = 'reportdata/getsalesreportfailed';

// todo: custom maxClusterId for each report!

const initialState = {
    getYearlyReportInProgress: false,
    yearlyReports: null,
    getOrderReportInProgress: false,
    orderReports: null,
    orderReportRange: [null, null],
    getMonthlyReportInProgress: false,
    monthlyReports: null,
    salesReport: null,
    yearlyReportsMaxClusterId: 0,
    monthlyReportsMaxClusterId: 0,
};

const setValue = (state, key, serverMaxId, serverData) => {
    var maxIdKey = key + 'MaxClusterId';
    var localMaxId = state[maxIdKey];
    var currentValue = state[key];
    if (!currentValue || serverMaxId > localMaxId) {
        state[key] = serverData;
        state[maxIdKey] = serverMaxId;
        console.log(key + ' new data loaded! server: ' + serverMaxId + ' local: ' + localMaxId);
    } else {
        console.log(key + ' old data loaded! server: ' + serverMaxId + ' local: ' + localMaxId);
    }
};

export default (state = initialState, action) => {
    let newState = null; // hide warnings
    switch (action.type) {
        case GET_YEARLY_REPORT_FAILED:
            return {
                ...state,
                getYearlyReportInProgress: false,
            };
        case GET_YEARLY_REPORT_IN_PROGRESS:
            return {
                ...state,
                getYearlyReportInProgress: true,
            };
        case GET_YEARLY_REPORT_SUCCESS:
            newState = {
                ...state,
                getYearlyReportInProgress: false,
            };
            setValue(newState, 'yearlyReports', action.reports.maxClusterId, action.reports);
            return newState;
        case GET_ORDER_REPORT_IN_PROGRESS:
            return {
                ...state,
                getOrderReportInProgress: true,
            };
        case GET_ORDER_REPORT_SUCCESS:
            return {
                ...state,
                getOrderReportInProgress: false,
                orderReports: action.reports,
                orderReportRange: action.range,
            };
        case GET_ORDER_REPORT_FAILED:
            return {
                ...state,
                getOrderReportInProgress: false,
            };
        case SEND_ORDERS_SUCCESS:
        case REMOVE_ORDER_HISTORY_DATA_SUCCESS:
            return {
                ...state,
                orderReports: null,
                orderReportRange: [null, null],
            };
        case GET_MONTHLY_REPORT_IN_PROGRESS:
            return {
                ...state,
                getMonthlyReportInProgress: true,
            };
        case GET_MONTHLY_REPORT_SUCCESS:
            newState = {
                ...state,
                getMonthlyReportInProgress: false,
            };
            setValue(newState, 'monthlyReports', action.reports.maxClusterId, action.reports.reports);
            return newState;
        case GET_MONTHLY_REPORT_FAILED:
            return {
                ...state,
                getMonthlyReportInProgress: false,
            };
        case GET_SALES_REPORT_IN_PROGRESS:
            return {
                ...state,
            };
        case GET_SALES_REPORT_SUCCESS:
            return {
                ...state,
                salesReport: action.reports,
            };
        case GET_SALES_REPORT_FAILED:
            return {
                ...state,
            };
        case LOGGED_OFF:
            return initialState;
        default:
            return { ...state };
    }
};

export const getYearlyReports = (max_cluster_id) => {
    if (!Number.isInteger(max_cluster_id)) max_cluster_id = 0;
    return (dispatch) => {
        dispatch({
            type: GET_YEARLY_REPORT_IN_PROGRESS,
        });
        return Ajax.get('api/report/v1/year/' + max_cluster_id)
            .then((data) => {
                dispatch({
                    type: GET_YEARLY_REPORT_SUCCESS,
                    reports: data.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_YEARLY_REPORT_FAILED,
                });
            });
    };
};

export const getOrderReports = (start, end) => {
    return (dispatch) => {
        dispatch({
            type: GET_ORDER_REPORT_IN_PROGRESS,
        });
        return Ajax.get(
            'api/report/v1/orderhistory/start/' + start.format('YYYY-MM-DD') + '/end/' + end.format('YYYY-MM-DD')
        )
            .then((data) => {
                dispatch({
                    type: GET_ORDER_REPORT_SUCCESS,
                    reports: data.data,
                    range: [start, end],
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_ORDER_REPORT_FAILED,
                });
            });
    };
};

export const getMonthlyReports = (max_cluster_id) => {
    if (!Number.isInteger(max_cluster_id)) max_cluster_id = 0;
    return (dispatch) => {
        dispatch({
            type: GET_MONTHLY_REPORT_IN_PROGRESS,
        });
        return Ajax.get('api/report/v1/month/' + max_cluster_id)
            .then((data) => {
                dispatch({
                    type: GET_MONTHLY_REPORT_SUCCESS,
                    reports: data.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_MONTHLY_REPORT_FAILED,
                });
            });
    };
};

export const getSalesReport = () => {
    return (dispatch) => {
        dispatch({
            type: GET_SALES_REPORT_IN_PROGRESS,
        });
        return Ajax.get('api/report/v1/sales/')
            .then((data) => {
                dispatch({
                    type: GET_SALES_REPORT_SUCCESS,
                    reports: data.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_SALES_REPORT_FAILED,
                });
            });
    };
};

export const getInvoicingReports = async (start, end, contractNumber) => {
    const url = contractNumber
        ? `api/invoicereport/${start.format()}/${end.format()}/${contractNumber}`
        : `api/invoicereport/${start.format()}/${end.format()}`;
    try {
        const resp = await Ajax.get(url);
        return resp.data;
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const getSalesReportExcel = async (start, end) => {
    try {
        const resp = await Ajax.get(`api/salesreport/${start}/${end}`);
        return resp.data;
    } catch (err) {
        console.log(err);
        return null;
    }
};
