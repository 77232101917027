import ObjectIdGenerator from '../modules/objectidgenerator';

class ElectronicInvoiceInfo {
    constructor(jsonInput) {
        if (jsonInput != null) {
            this.id = jsonInput.id;
            this.ovt = jsonInput.ovt;
            this.operator = jsonInput.operator;
            this.operatorBrokerId = jsonInput.operatorBrokerId;
        } else {
            this.id = 0;
            this.ovt = '';
            this.operator = '';
            this.operatorBrokerId = '';
        }
    }

    static areEqual(one, other) {
        if (one == null && other != null) return false;
        if (one != null && other == null) return false;
        if (one == null && other == null) return true;
        if (one.ovt !== other.ovt) return false;
        if (one.operator !== other.operator) return false;
        if (one.operatorBrokerId !== other.operatorBrokerId) return false;

        return true;
    }

    isValid() {
        return this.ovt && this.ovt.length > 0 && this.operatorBrokerId && this.operatorBrokerId.length > 0;
    }

    isEmpty() {
        return (!this.ovt || this.ovt.length === 0) && (!this.operatorBrokerId || this.operatorBrokerId.length === 0);
    }

    static NewObject() {
        const promise = new Promise(function (resolve, reject) {
            const info = new ElectronicInvoiceInfo();
            ObjectIdGenerator.newId()
                .then(function (id) {
                    info.id = id;
                    resolve(info);
                })
                .catch(function (err) {
                    console.log(err);
                    reject();
                });
        });
        return promise;
    }
}

export default ElectronicInvoiceInfo;
