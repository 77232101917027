import LocationDescription from './locationdescription';

class TreeDescription {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.customerId = json.customerId;
            this.isRoot = json.isRoot;
            this.name = json.name;
            this.parentId = json.parentId; //not needed?
            this.orderValue = json.orderValue;
            if (json.childFolders != null && json.childFolders.length > 0) {
                this.childFolders = [];
                for (let f of json.childFolders) {
                    this.childFolders.push(new TreeDescription(f));
                }
            } else {
                this.childFolders = [];
            }

            if (json.locations != null && json.locations.length > 0) {
                this.locations = [];
                for (let l of json.locations) {
                    this.locations.push(new LocationDescription(l));
                }
            } else {
                this.locations = [];
            }
        }
    }
}

export default TreeDescription;
