import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';
export const LOADING_COUNTRY_CODES = 'codedata/loadingcountrycodes';
export const COUNTRY_CODES_LOADED = 'codedata/countrycodesloaded';
export const LOADING_PALLET_INFO_CODES = 'codedata/loadingpalletinfocodes';
export const PALLET_INFO_CODES_LOADED = 'codedata/palletinfocodesloaded';

const initialState = {
    countryCodes: null,
    loadingCountryCodes: false,
    palletInfoCodes: null,
    loadingPalletInfoCodes: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_COUNTRY_CODES:
            return {
                ...state,
                loadingCountryCodes: true,
            };
        case COUNTRY_CODES_LOADED:
            return {
                ...state,
                loadingCountryCodes: false,
                countryCodes: action.codes,
            };
        case LOADING_PALLET_INFO_CODES:
            return {
                ...state,
                loadingPalletInfoCodes: true,
            };
        case PALLET_INFO_CODES_LOADED:
            return {
                ...state,
                loadingPalletInfoCodes: false,
                palletInfoCodes: action.codes,
            };
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const getCountryCodes = () => {
    return (dispatch) => {
        dispatch({
            type: LOADING_COUNTRY_CODES,
        });
        Ajax.get('api/code/countrycodes/')
            .then((resp) => {
                dispatch({
                    type: COUNTRY_CODES_LOADED,
                    codes: resp.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const getPalletInfoCodes = () => {
    return async (dispatch) => {
        dispatch({
            type: LOADING_PALLET_INFO_CODES,
        });
        try {
            const resp = await Ajax.get('api/code/palletcodes/');
            dispatch({
                type: PALLET_INFO_CODES_LOADED,
                codes: resp.data,
            });
        } catch (err) {
            console.log(err);
        }
    };
};
