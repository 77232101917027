import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { earliestDeliveryDateForOrder } from './capacityplanner';
import OrderItem from './orderitem';
import { IsProductStandard } from '../common/productdataparse';
import Chip from '@material-ui/core/Chip';
import { uniq, some } from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: '0.5em',
    },
    checkbox: {
        marginRight: '7px',
        padding: 0,
    },
    warning: {
        color: 'orange',
        verticalAlign: 'middle',
        display: 'inline-flex',
        fontSize: '1.25em',
    },
    warningUnselected: {
        color: 'gray',
        verticalAlign: 'middle',
        display: 'inline-flex',
        fontSize: '1.25em',
    },
}));

export default function OrderItemContainer(props) {
    const classes = useStyles();
    const store = useStore();
    const [expanded, setExpanded] = useState(false);
    const [expanding, setExpanding] = useState(false);
    const [earliestDeliveryDate, setEarliestDeliveryDate] = useState(null);
    const [formOk, setFormOk] = useState(false);
    const [containsNonStandardProducts, setContainsNonStandardProducts] = useState(false);
    const capacityAllocations = useSelector((state) => state.capacityAllocations);
    const capacityReservations = useSelector((state) => state.capacityReservations);
    const portfolios = useSelector((state) => state.portfoliodata.portfolioDescriptions);
    const [portfolio, setPortfolio] = useState({});
    const order = props.orders[0];
    const { t } = useTranslation();

    const onClickCheckbox = (e) => {
        e.stopPropagation();
        props.selectOrder(order.id);
    };

    useEffect(() => {
        async function getEarliestDeliveryDate() {
            const earliestTime = await earliestDeliveryDateForOrder(store, order);
            if (earliestTime) {
                setEarliestDeliveryDate(earliestTime.format());
            } else {
                setEarliestDeliveryDate(null);
            }
        }
        if (expanded) {
            getEarliestDeliveryDate();
        }
    }, [order, capacityAllocations, capacityReservations, expanded, store]);

    useEffect(() => {
        if (order && portfolios && portfolios.length > 0) {
            setPortfolio(portfolios.find((p) => p.id === order.portfolioId));
        }
        if (order && !props.customerView) {
            // TODO: miten non-standard käsittely ulotetaan sarjoihin, vai pitääkö?
            setContainsNonStandardProducts(
                some(
                    order.orderedFilters.filter((f) => f.product).map((m) => m.product.productName),
                    (productName) => {
                        return !IsProductStandard(productName);
                    }
                )
            );
        }
    }, [props.orders, portfolios, order, props.customerView]);

    const onExpand = async () => {
        if (!expanded) {
            setExpanding(true);
            const earliestTime = await earliestDeliveryDateForOrder(store, order);
            if (earliestTime) {
                setEarliestDeliveryDate(earliestTime.format());
            }
        }
        setExpanded(!expanded);
        setExpanding(false);
    };

    const updateOrderFormState = (state, orderId) => {
        setFormOk(state);
        props.updateOrderFormState(state, orderId);
    };

    const getPalletGroups = () => {
        if (order.orderedFilters && order.orderedFilters.length > 0) {
            return uniq(
                order.orderedFilters.map((fo) => {
                    return fo.palletInformation;
                })
            ).join(',');
        }
        return '-';
    };

    const editPalletInfo = (e) => {
        e.stopPropagation();
        props.editOrderPalletInformation(order.id);
    };

    const editFoPalletInfo = (foIds) => {
        props.editFoPalletInformation(order.id, foIds);
    };

    const getOrderTitle = () => {
        return order.location ? order.location.name : order.deliveryRecipient;
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Accordion expanded={expanded}>
                    <AccordionSummary
                        name="accordion-summary"
                        expandIcon={
                            expanding ? <CircularProgress color={'secondary'} size={'1rem'} /> : <ExpandMoreIcon />
                        }
                        onClick={onExpand}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Checkbox
                                    name="select-order-item"
                                    checked={Boolean(props.selected)}
                                    className={classes.checkbox}
                                    onClick={onClickCheckbox}
                                />
                                {containsNonStandardProducts && (
                                    <Tooltip title={t('order.orderContainsNonStandardProducts')}>
                                        <WarningIcon
                                            className={props.selected ? classes.warning : classes.warningUnselected}
                                            name="warningIcon"
                                        />
                                    </Tooltip>
                                )}
                                {props.selected && (
                                    <span>
                                        {(formOk || props.combineOrders) && <span>{getOrderTitle()}</span>}
                                        {!formOk && !props.combineOrders && (
                                            <span style={{ color: 'red' }}>{getOrderTitle()}&nbsp;*</span>
                                        )}
                                    </span>
                                )}
                                {!props.selected && <span style={{ color: 'lightgray' }}>{getOrderTitle()}</span>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Chip
                                    disabled={!props.selected /*!selected*/}
                                    label={t('order.palletGroup') + ': ' + getPalletGroups()}
                                    variant="outlined"
                                    name={'edit-pallet-info-' + /*props.order.id*/ order.id}
                                    onClick={editPalletInfo}
                                />
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <OrderItem
                            customerView={props.customerView}
                            key={order.id}
                            orders={props.orders}
                            removeOrder={props.removeOrder}
                            saveOrder={props.saveOrder}
                            saveOrderShallow={props.saveOrderShallow}
                            updateOrderFormState={updateOrderFormState}
                            saveFilterOrder={props.saveFilterOrder}
                            allowPricing={props.allowPricing}
                            customers={props.customers}
                            customersById={props.customersById}
                            makeCapacityReservations={props.makeCapacityReservations}
                            removeCapacityReservations={props.removeCapacityReservations}
                            earliestDeliveryDate={earliestDeliveryDate}
                            editOrderedItem={false}
                            parameters={props.parameters}
                            editFoPalletInfo={editFoPalletInfo}
                            savingOrder={props.savingOrder}
                            portfolio={portfolio}
                            addProductWithCode={props.addProductWithCode}
                            addProductWithId={props.addProductWithId}
                            updateCapacityReservationsIfNeeded={props.updateCapacityReservationsIfNeeded}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}
