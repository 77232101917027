import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { getAllProductBundle } from '../modules/productdata';
import { ActionAllowed } from '../rbac/rbacutil';
import { rbacactions } from '../rbac/rbacrules';
import { useTranslation } from 'react-i18next';
import Footer from '../main/footer';
import { formatCurrency } from '../common/common';
import Spinner from '../common/spinner';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    withPadding: {
        padding: '0.5em',
    },
}));

export default function ProductBundleList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const productBundleList = useSelector((state) => state.productdata.productBundles);
    const userProfile = useSelector((state) => state.authentication.userProfile);

    useEffect(() => {
        if (productBundleList == null) {
            getAllProductBundle()(dispatch);
        }
    }, [productBundleList, dispatch]);

    const calculateTotalPrice = (bundle) => {
        let sum = 0;
        if (bundle.products.length > 0) {
            for (let bundleProduct of bundle.products) {
                sum += bundleProduct.product.priceWithoutVat * bundleProduct.productCount;
            }
        }
        return sum;
    };

    const editBundle = (bundleId) => {
        history.push(`/product/bundleedit/${bundleId}`);
    };

    return (
        <Grid container>
            {productBundleList !== null && productBundleList.length === 0 && (
                <Grid item xs={12} className={classes.withPadding}>
                    {t('product.noPrpductBundles')}
                </Grid>
            )}
            <Grid container className={classes.withPadding}>
                <Grid item xs={5}>
                    <strong>{t('general.name')}</strong>
                </Grid>
                <Grid item xs={2}>
                    <strong>{t('product.code')}</strong>
                </Grid>
                <Grid item xs={1}>
                    <strong>{t('general.price')}</strong>
                </Grid>
                <Grid item xs={2}>
                    <strong>{t('general.edited')}</strong>
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
            {productBundleList === null && <Spinner />}
            {productBundleList !== null && productBundleList.length > 0 && (
                <React.Fragment>
                    {productBundleList.map((b) => (
                        <Grid container key={b.id} className={classes.withPadding}>
                            <Grid item xs={5}>
                                {b.name}
                            </Grid>
                            <Grid item xs={2}>
                                {b.code}
                            </Grid>
                            <Grid item xs={1}>
                                {formatCurrency(calculateTotalPrice(b))}
                            </Grid>
                            <Grid item xs={2}>
                                <span>{moment(b.update).format('D.M.YYYY HH:mm')}</span>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    variant="contained"
                                    name="btn-edit-bundle"
                                    color="primary"
                                    onClick={() => editBundle(b.id)}
                                    startIcon={<Edit />}>
                                    {t('buttons.edit')}
                                </Button>
                            </Grid>
                        </Grid>
                    ))}
                </React.Fragment>
            )}
            <Footer>
                <Button
                    variant="contained"
                    color="primary"
                    name="btn-new-bundle"
                    disabled={!ActionAllowed(rbacactions.CreateNewProduct, userProfile)}
                    onClick={() => history.push('/product/bundleedit/new')}
                    startIcon={<Add />}>
                    {t('general.bundle')}
                </Button>
            </Footer>
        </Grid>
    );
}
