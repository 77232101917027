class BisLookup {
    constructor(json) {
        if (json != null) {
            this.version = json.version;
            this.totalResults = json.totalResults;
            this.resultsFrom = json.resultsFrom;
            this.previousResultsUri = json.previousResultsUri;
            this.nextResultsUri = json.nextResultsUri;
            this.exceptionNoticeUri = json.exceptionNoticeUri;
            this.results = [];
            //if by name search finds exactly one result, the returned data array contains detailed data result
            if (json.results != null && json.results.length === 1) {
                this.results.push(new BisDetails(json.results[0]));
            } else if (json.results != null && json.results.length > 0) {
                for (let result of json.results) {
                    this.results.push(new BisLoookupResult(result));
                }
            }
        }
    }
}

class BisLoookupResult {
    constructor(json) {
        if (json != null) {
            this.businessId = json.businessId;
            this.registrationDate = json.registrationDate;
            this.companyForm = json.companyForm;
            this.detailsUri = json.detailsUri;
            this.name = json.name;
        }
    }
}

class BisDetails {
    constructor(json) {
        if (json != null) {
            this.names = [];
            if (json.names != null && json.names.length > 0) {
                for (let name of json.names) {
                    this.names.push(new BisCompanyName(name));
                }
            }
            //auxiliaryNames (Array[BisCompanyName], optional): Aputoiminimi ja käännökset ,
            this.addresses = [];
            if (json.addresses != null && json.addresses.length > 0) {
                for (let address of json.addresses) {
                    this.addresses.push(new BisAddress(address));
                }
            }
            //companyForms (Array[BisCompanyForm], optional): Yritysmuoto ja käännökset ,
            this.companyForms = [];
            if (json.companyForms != null && json.companyForm.length > 0) {
                for (let companyForm of json.companyForms) {
                    this.companyForms.push(new BisCompanyForm(companyForm));
                }
            }
            //liquidations (Array[BisCompanyLiquidation], optional): Tieto mahdollisesta konkurssi-, selvitystila- tai saneerausmenettelystä ,
            //businessLines (Array[BisCompanyBusinessLine], optional): Yrityksen toimialat ja käännökset ,
            //languages (Array[BisCompanyLanguage], optional): Yrityksen kieli/kielet ,
            //registeredOffices (Array[BisCompanyRegisteredOffice], optional): Yrityksen kotipaikka ja sen käännökset ,
            //contactDetails (Array[BisCompanyContactDetail], optional): Yrityksen yhteystiedot ja käännökset ,
            //registeredEntries (Array[BisCompanyRegisteredEntry], optional): Yrityksen rekisterimerkinnät ,
            //businessIdChanges (Array[BisCompanyBusinessIdChange], optional): Muutokset yrityksen Y-tunnukseen ,
            this.businessId = json.businessId; // (string): Y-tunnus ,
            this.registrationDate = json.registrationDate; // (string): Rekisteröintipäivämäärä ,
            this.companyForm = json.companyForm; // (string, optional): Yritysmuoto ,
            this.detailsUri = json.detailsUri; // (string, optional): URI lisätietoja varten, jos ne eivät jo sisälly ,
            this.name = json.name; // (string): Ensisijainen toiminimi
        }
    }
}

class BisCompanyName {
    constructor(json) {
        this.source = json.source; // (integer, optional): Nolla tarkoittaa yhteistä, yksi Patentti- ja rekisterihallitusta, kaksi Verohallintoa ja kolme yritys- ja yhteisötietojärjestelmää ,
        this.order = json.order; // (integer): Nolla tarkoittaa ensisijaista toiminimeä, muu ensisijaisten toiminimien käännöksiä ja aputoiminimiä ,
        this.version = json.version; // (integer): Yksi tarkoittaa nykyistä versiota ja >1 historiallisia toiminimiä ,
        this.registrationDate = json.registrationDate; // (string): Rekisteröintipäivämäärä ,
        this.endDate = json.endDate; // (string, optional): Rekisteröinnin loppupäivämäärä ,
        this.name = json.name; // (string): Toiminimi ,
        this.language = json.language; // (string, optional): Kaksikirjaiminen kielikoodi
    }
}

class BisAddress {
    constructor(json) {
        if (json != null) {
            this.source = json.source; //(integer, optional): Nolla tarkoittaa yhteistä, yksi Patentti- ja rekisterihallitusta, kaksi Verohallintoa ja kolme yritys- ja yhteisötietojärjestelmää ,
            this.version = json.version; // (integer): Yksi tarkoittaa nykyistä versiota ja >1 historiallisia osoitteita ,
            this.registrationDate = json.registrationDate; // (string): Rekisteröintipäivämäärä ,
            this.endDate = json.endDate; // (string, optional): Rekisteröinnin loppupäivämäärä ,
            this.careOf = json.careOf; // (string, optional): c/o-osoite ,
            this.street = json.street; // (string, optional): Katuosoite ,
            this.postCode = json.postCode; // (string, optional): Postinumero ,
            this.city = json.city; // (string, optional): Postitoimipaikka ,
            this.language = json.language; // (string, optional): Kaksikirjaiminen kielikoodi ,
            this.type = json.type; // (integer): Osoitteen laji, käyntiosoite 1 , postiosoite 2 ,
            this.country = json.country; // (string, optional): Kaksikirjaiminen maakoodi
        }
    }
}

class BisCompanyForm {
    constructor(json) {
        this.source = json.source; // (integer, optional): Nolla tarkoittaa yhteistä, yksi Patentti- ja rekisterihallitusta, kaksi Verohallintoa ja kolme yritys- ja yhteisötietojärjestelmää ,
        this.version = json.version; // (integer): Yksi tarkoittaa nykyistä versiota ja >1 historiallisia yritysmuotoja ,
        this.registrationDate = json.registrationDate; // (string): Rekisteröintipäivämäärä ,
        this.endDate = json.endDate; // (string, optional): Rekisteröinnin loppupäivämäärä ,
        this.name = json.name; // (string): Yritysmuodon nimi ,
        this.language = json.language; // (string, optional): Kaksikirjaiminen kielikoodi ,
        this.type = json.type; // (string): Yritysmuodon laji}
    }
}

export default BisDetails;
/*
BisCompanyDetails {
    names (Array[BisCompanyName]): Ensisijainen toiminimi ja käännökset ,
    auxiliaryNames (Array[BisCompanyName], optional): Aputoiminimi ja käännökset ,
    addresses (Array[BisAddress], optional): Yrityksen käynti- ja postiosoitteet ,
    companyForms (Array[BisCompanyForm], optional): Yritysmuoto ja käännökset ,
    liquidations (Array[BisCompanyLiquidation], optional): Tieto mahdollisesta konkurssi-, selvitystila- tai saneerausmenettelystä ,
    businessLines (Array[BisCompanyBusinessLine], optional): Yrityksen toimialat ja käännökset ,
    languages (Array[BisCompanyLanguage], optional): Yrityksen kieli/kielet ,
    registeredOffices (Array[BisCompanyRegisteredOffice], optional): Yrityksen kotipaikka ja sen käännökset ,
    contactDetails (Array[BisCompanyContactDetail], optional): Yrityksen yhteystiedot ja käännökset ,
    registeredEntries (Array[BisCompanyRegisteredEntry], optional): Yrityksen rekisterimerkinnät ,
    businessIdChanges (Array[BisCompanyBusinessIdChange], optional): Muutokset yrityksen Y-tunnukseen ,
    businessId (string): Y-tunnus ,
    registrationDate (string): Rekisteröintipäivämäärä ,
    companyForm (string, optional): Yritysmuoto ,
    detailsUri (string, optional): URI lisätietoja varten, jos ne eivät jo sisälly ,
    name (string): Ensisijainen toiminimi
    }
    
    
    BisCompanyLiquidation {
    source (integer, optional): Nolla tarkoittaa yhteistä, yksi Patentti- ja rekisterihallitusta, kaksi Verohallintoa ja kolme yritys- ja yhteisötietojärjestelmää ,
    version (integer): Yksi tarkoittaa nykyistä versiota ja >1 historiallisia yritysmuotoja ,
    registrationDate (string): Rekisteröintipäivämäärä ,
    endDate (string, optional): Rekisteröinnin loppupäivämäärä ,
    name (string): Tieto mahdollisesta konkurssi-, selvitystila- tai saneerausmenettelystä ,
    language (string, optional): Kaksikirjaiminen kielikoodi ,
    type (string): Selvitystilan laji
    }
    BisCompanyBusinessLine {
    source (integer, optional): Nolla tarkoittaa yhteistä, yksi Patentti- ja rekisterihallitusta, kaksi Verohallintoa ja kolme yritys- ja yhteisötietojärjestelmää ,
    order (integer): Nolla tarkoittaa päätoimialaa, positiivinen muita ,
    version (integer): Yksi tarkoittaa nykyistä versiota ja >1 historiallisia toimialoja ,
    registrationDate (string): Rekisteröintipäivämäärä ,
    endDate (string, optional): Rekisteröinnin loppupäivämäärä ,
    name (string): Toimialan nimi ,
    language (string, optional): Kaksikirjaiminen kielikoodi
    }
    BisCompanyLanguage {
    source (integer, optional): Nolla tarkoittaa yhteistä, yksi Patentti- ja rekisterihallitusta, kaksi Verohallintoa ja kolme yritys- ja yhteisötietojärjestelmää ,
    version (integer): Yksi tarkoittaa nykyistä versiota ja >1 historiallisia kieliä ,
    registrationDate (string): Rekisteröintipäivämäärä ,
    endDate (string, optional): Rekisteröinnin loppupäivämäärä ,
    name (string): Kielen nimi ,
    language (string, optional): Kaksikirjaiminen kielikoodi
    }
    BisCompanyRegisteredOffice {
    source (integer, optional): Nolla tarkoittaa yhteistä, yksi Patentti- ja rekisterihallitusta, kaksi Verohallintoa ja kolme yritys- ja yhteisötietojärjestelmää ,
    order (integer): Nolla tarkoittaa ensisijaista kotipaikkaa, positiivinen muita ,
    version (integer): Yksi tarkoittaa nykyistä versiota ja >1 historiallisia kotipaikkoja ,
    registrationDate (string): Rekisteröintipäivämäärä ,
    endDate (string, optional): Rekisteröinnin loppupäivämäärä ,
    name (string): Kotipaikan nimi ,
    language (string, optional): Kaksikirjaiminen kielikoodi
    }
    BisCompanyContactDetail {
    source (integer, optional): Nolla tarkoittaa yhteistä, yksi Patentti- ja rekisterihallitusta, kaksi Verohallintoa ja kolme yritys- ja yhteisötietojärjestelmää ,
    version (integer): Yksi tarkoittaa nykyistä versiota ja >1 historiallisia yhteystietoja ,
    registrationDate (string): Rekisteröintipäivämäärä ,
    endDate (string, optional): Rekisteröinnin loppupäivämäärä ,
    language (string, optional): Kaksikirjaiminen kielikoodi ,
    value (string): Yhteystiedon arvo ,
    type (string): Yhteystiedon laji
    }
    BisCompanyRegisteredEntry {
    description (string): Merkinnän kuvaus ,
    status (integer): Nolla tarkoittaa yhteisiä merkintöjä, yksi ”Rekisteröimätön” ja kaksi ”Rekisteröity” ,
    registrationDate (string): Rekisteröintipäivämäärä ,
    endDate (string, optional): Rekisteröinnin loppupäivämäärä ,
    register (integer): Yksi tarkoittaa kaupparekisteriä, kaksi säätiörekisteriä, kolme yhdistysrekisteriä, neljä Verohallintoa, viisi ennakkoperintärekisteriä, kuusi arvonlisäverovelvollisten rekisteriä, seitsemän työnantajarekisteriä ja kahdeksan vakuutusmaksuverovelvollisten rekisteriä ,
    language (string, optional): Kaksikirjaiminen kielikoodi ,
    authority (integer): Yksi tarkoittaa Verohallintoa, kaksi Patentti- ja rekisterihallitusta ja kolme väestörekisteriä
    }
    BisCompanyBusinessIdChange {
    source (integer, optional): Nolla tarkoittaa yhteistä, yksi Patentti- ja rekisterihallitusta, kaksi Verohallintoa ja kolme yritys- ja yhteisötietojärjestelmää ,
    description (string): Syyn kuvaus ,
    reason (string): Syykoodi ,
    changeDate (string, optional): Y-tunnuksen muutoksen päivämäärä ,
    change (integer): 2 = Yritystunnusten käytöstä poisto, 3 = Tuplatunnusten yhdistäminen, 5 = Tunnusmuutos, 44 = Sulautuminen, 45 = Alv-toiminnan jatkaja, 46 = Edeltäjä-suhde, 47 = Jakautuminen, 48 = Konkurssisuhde, 49 = Jatkaminen yksityisenä elinkeinonharjoittajana, 57 = Osittaisjakautuminen, DIF = Jakautuminen, FUU = Sulautuminen ,
    oldBusinessId (string): Vanha Y-tunnus ,
    newBusinessId (string): Uusi Y-tunnus ,
    language (string, optional): Kaksikirjaiminen kielikoodi
    }
*/

export { BisLookup, BisDetails };
