import { Portfolio } from './portfolio';

class CustomerDescription {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.storageId = json.storageId;
            this.name = json.name;
            this.status = json.status;
            this.portfolio = new Portfolio(json.portfolio);
            this.deliveryAddressId = json.deliveryAddressId;
            this.businessId = json.businessId;
        }
    }
}

export default CustomerDescription;
