import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../modules/userdataactions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import UserView from './userview';
import UserStatusBoolean from './userstatusboolean';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import Button from '@material-ui/core/Button';
import Spinner from '../common/spinner';
import { useTranslation } from 'react-i18next';

const UserManagementView = (props) => {
    const orgUsers = useSelector((state) => state.userdata.orgUsers);
    const ccUsers = useSelector((state) => state.userdata.contractCustomerUsers);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.active) {
            dispatch(getUserData());
        }
    }, [props.active, dispatch]);

    const newUser = () => {
        props.history.push('/admin/user/new');
    };

    return (
        <Grid item xs={12}>
            <br />
            <h4>{t('admin.users')}</h4>
            {orgUsers == null && <Spinner />}
            {orgUsers != null && orgUsers.length > 0 && (
                <div>
                    {orgUsers.map((user) => (
                        <Accordion key={user.id}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        {user.name} -{' '}
                                        <UserStatusBoolean status={{ enabled: user.enabled, amazonStatus: null }} />
                                    </Grid>
                                    <Grid item>{user.username}</Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UserView user={user} history={props.history} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            )}
            <br />
            <h4>{t('admin.contractCustomers')}</h4>
            {ccUsers == null && <Spinner />}
            {ccUsers != null && ccUsers.length > 0 && (
                <div>
                    {ccUsers.map((user) => (
                        <Accordion key={user.id}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        {user.name} -{' '}
                                        <UserStatusBoolean status={{ enabled: user.enabled, amazonStatus: null }} />
                                    </Grid>
                                    <Grid item>{user.username}</Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UserView user={user} history={props.history} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            )}
            <Footer>
                <Grid item>
                    <Button variant="contained" color="primary" name="btn-new-user" onClick={newUser}>
                        <i className="fas fa-plus"></i>&nbsp;{t('admin.newUser')}
                    </Button>
                </Grid>
            </Footer>
            <FooterPadding />
        </Grid>
    );
};

export default UserManagementView;
