import { PortfolioDescription } from './portfolio';

class User {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.username = json.username;
            this.name = json.name;
            this.organization = json.organization;
            this.phoneNumber = json.phoneNumber;
            this.roles = [];
            this.storageId = json.storageId;
            if (json.roles && json.roles.length > 0) {
                for (let r of json.roles) {
                    this.roles.push(r);
                }
            }
            this.selectedPortfolioId = json.selectedPortfolioId;
            this.portfolioDescriptions = [];
            if (json.portfolioDescriptions && json.portfolioDescriptions.length > 0) {
                for (let p of json.portfolioDescriptions) {
                    this.portfolioDescriptions.push(new PortfolioDescription(p));
                }
            }
            this.amazonStatus = json.amazonStatus;
            this.enabled = json.enabled;
            if (json.representations && json.representations.length > 0) {
                this.representations = [];
                for (const rep of json.representations) {
                    this.representations.push({ customerId: rep.customerId, customerName: rep.customerName });
                }
            } else {
                this.representations = [];
            }
            this.isAdmin = json.isAdmin;
            this.isCustomerUser = json.isCustomerUser;
            this.isSalesUser = json.isSalesUser;
            this.isProductionUser = json.isProductionUser;
            this.isInvoicingUser = json.isInvoicingUser;
        } else {
            this.id = 0;
            this.username = '';
            this.name = '';
            this.organization = '';
            this.phoneNumber = '';
            this.roles = [];
            this.storageId = 0;
            this.selectedPortfolioId = 0;
            this.portfolioDescriptions = [];
            this.amazonStatus = '';
            this.enabled = true;
            this.representations = [];
            this.isAdmin = false;
            this.isCustomerUser = false;
            this.isSalesUser = false;
            this.isProductionUser = false;
            this.isInvoicingUser = false;
        }
    }

    static RoleUser = () => 'User';
    static RoleCustomerUser = () => 'UserCustomer';
    static RoleAdmin = () => 'Admin';
    static RoleAllowPricing = () => 'AllowPricing';
    static RoleProduction = () => 'Production';
    static RoleInvoicing = () => 'Invoicing';
    static RoleReporting = () => 'Reporting';
    static RoleProductEditor = () => 'ProductEditor';

    static RolesForOrgUsers = () => [
        this.RoleUser(),
        this.RoleAdmin(),
        this.RoleProduction(),
        this.RoleInvoicing(),
        this.RoleReporting(),
        this.RoleProductEditor(),
    ];
    static RolesForCcUsers = () => [this.RoleCustomerUser(), this.RoleAllowPricing()];
}

export default User;
