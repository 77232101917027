import React, { useEffect } from 'react';
import ProductSelectorAutocomplete from '../products/productselectorautocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Dialog, DialogContent } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import NewProductRequiredView from '../products/newproductrequiredview';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const MyDialogTitle = withStyles(styles)((props) => {
    const { classes, onClose, title } = props;

    return (
        <DialogTitle id="simple-dialog-title">
            {title}
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
});

export default function FilterProductChangeDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [title, setTitle] = React.useState('');
    const [showNewProdEdit, setShowNewProdEdit] = React.useState(false);
    const [replaceType, setReplaceType] = React.useState('filter');
    const { t } = useTranslation();

    useEffect(() => {
        if (!!props.title) setTitle(props.title);
        else setTitle(t('location.replaceFilter.pickNewFilter'));
    }, [props.show, props.title, t]);

    const handleClose = () => {
        props.selected(null);
    };

    const newProduct = () => {
        setShowNewProdEdit(true);
        setTitle(t('location.replaceFilter.newFilterProduct'));
    };

    const cancel = () => {
        setShowNewProdEdit(false);
        setTitle(t('location.replaceFilter.pickNewFilter'));
    };

    const onChangeReplaceType = (e) => {
        setReplaceType(e.target.value);
    };

    const selected = (productId) => {
        props.selected(productId, replaceType);
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={props.show}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <MyDialogTitle onClose={handleClose} title={title} />
            <DialogContent>
                <FormControl>
                    <FormLabel id="replace-selection-switch">{t('location.replaceFilter.typeTitle')}</FormLabel>
                    <RadioGroup
                        aria-labelledby="replace-selection-switch"
                        defaultValue="filter"
                        onChange={onChangeReplaceType}
                        name="radio-buttons-group">
                        <FormControlLabel
                            value="filter"
                            control={<Radio name="radio-filter" />}
                            label={t('location.replaceFilter.typeFilter')}
                        />
                        <FormControlLabel
                            value="machine"
                            control={<Radio name="radio-machine" />}
                            label={t('location.replaceFilter.typeMachine')}
                        />
                        <FormControlLabel
                            value="location"
                            control={<Radio name="radio-location" />}
                            label={t('location.replaceFilter.typeLocation')}
                        />
                    </RadioGroup>
                </FormControl>
                {!showNewProdEdit && (
                    <ProductSelectorAutocomplete
                        dismiss={handleClose}
                        selected={selected}
                        allowNewProd={props.allowNewProd}
                        newProduct={newProduct}
                        showBundles={true}
                    />
                )}
                {showNewProdEdit && (
                    <NewProductRequiredView
                        selected={selected}
                        cancel={cancel}
                        productRequestHint={props.productRequestHint}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}
