import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as locationActions from '../modules/locationdata';
import * as customerActions from '../modules/customerdata';
import * as appActions from '../modules/appstate';
import * as prodDataActions from '../modules/productdata';
import * as orderActions from '../modules/orderdata';
import Spinner from '../common/spinner';
import AlphabeticalLocationList from './alphabeticallocationlist';
import TimeSortedLocationList from './timesortedlocationlist';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ConnectedTree from '../treeview/connectedtree';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { TREE_SCROLL_Y_POS_UPDATE } from '../modules/customerdata';
import { TREE_VIEW_FILTER_UPDATE, LOCATION_VIEW_FILTER_UPDATE } from '../modules/locationdata';
import store from '../store';
import TabPanel from '../common/tabpanel';
import { Grid, Tooltip } from '@material-ui/core';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import CustomershipStatus from '../domain/customershipstatus';
import Alert from '@material-ui/lab/Alert';
import OrderSortedLocationList from './ordersortedlocationlist';
import { toast } from 'react-toastify';
import SearchInput from '../common/searchinput';
import i18next from 'i18next';
import Add from '@material-ui/icons/Add';

function t(key, params) {
    return i18next.t(key, params) ? i18next.t(key, params) : key;
}

const _ = require('lodash');

const styles = (theme) => ({
    root: {
        position: 'relative',
    },
    tabcontainer: {
        marginTop: '10px',
        width: '98%',
    },
    button: {
        marginTop: '5px',
    },
    icon: {
        margin: '5px',
    },
    togglebuttongroup: {
        margin: '5px',
        float: 'right',
    },
    searchField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
        marginTop: '10px',
    },
});

const LocationSearch = (props) => {
    return (
        <Grid item xs={6} md={6}>
            <SearchInput searchInput={props.locationFilter} onChange={props.onLocationFilterChange} />
        </Grid>
    );
};

const LocationList = (props) => {
    return (
        <Grid container>
            <br />
            {!props.getLocationDescriptionsInProgress && !props.getLocationDescriptionsFailed && (
                <GetSelectedView
                    view={props.view}
                    viewOrder={props.viewOrder}
                    locationDescriptions={props.locations}
                    filter={props.filter}
                    history={props.history}
                />
            )}
        </Grid>
    );
};

const GroupBySelector = (props) => {
    return (
        <Grid item xs={6} md={6}>
            {props.locationDescriptions != null && props.locationDescriptions.length > 0 && (
                <ToggleButtonGroup
                    className={props.togglebuttongroup}
                    exclusive
                    value={props.orderBy}
                    onChange={props.onOrderByChange}
                    size={'small'}>
                    <Tooltip title={t('location.sortByName')}>
                        <ToggleButton value="abc">
                            <SortByAlphaIcon className={props.icon} />
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip title={t('location.sortByLastEdit')}>
                        <ToggleButton value="time">
                            <AccessTimeIcon className={props.icon} />
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip title={t('location.sortByLastOrderDate')}>
                        <ToggleButton value="order" disabled={props.selectedTab !== 1}>
                            <ReceiptIcon className={props.icon} />
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
            )}
        </Grid>
    );
};

const LoadingInfo = (props) => {
    if (props.getLocationDescriptionsInProgress) {
        return <Spinner padding="20px" title={t('general.loading')} />;
    }
    if (!props.getLocationDescriptionsInProgress && props.getLocationDescriptionsFailed) {
        return (
            <div style={{ padding: '20px' }}>
                <Alert severity="error">{t('general.fetchFailedRetry')}</Alert>
            </div>
        );
    }
    return null;
};

const FooterSection = (props) => {
    return (
        <div>
            <Footer>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        name="btn-new-location"
                        startIcon={<Add />}
                        onClick={props.onClickNewLocation}>
                        {t('general.location')}
                    </Button>
                </Grid>
            </Footer>
            <FooterPadding />
        </div>
    );
};

function GetSelectedView(props) {
    if (props.view === 'Nimi') {
        if (props.viewOrder === 'time') {
            return (
                <TimeSortedLocationList
                    locationDescriptions={props.locationDescriptions}
                    filter={props.filter}
                    history={props.history}
                    emptyListText={t('customer.noLocations')}
                />
            );
        } else if (props.viewOrder === 'order') {
            return (
                <OrderSortedLocationList
                    locationDescriptions={props.locationDescriptions}
                    filter={props.filter}
                    history={props.history}
                    emptyListText={t('customer.noLocations')}
                />
            );
        } else {
            return (
                <AlphabeticalLocationList
                    locationDescriptions={props.locationDescriptions}
                    filter={props.filter}
                    history={props.history}
                    emptyListText={t('customer.noLocations')}
                />
            );
        }
    }
    return null;
}

class Locations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedView: 'Nimi',
            selectedTab: 1,
            editTree: false,
            orderBy: 'abc',
        };
        this.getButtonClass = this.getButtonClass.bind(this);
        this.onClickNewLocation = this.onClickNewLocation.bind(this);
        this.onOrderByChange = this.onOrderByChange.bind(this);
    }

    componentDidMount() {
        this.props.getDescriptiveInfo();
        this.props.getCustomerDescriptiveInfo();

        if (this.props.history.location.hash != null && this.props.history.location.hash.length > 0)
            this.setStateBasedOnHash(this.props.history.location.hash);
    }

    setStateBasedOnHash = (hash) => {
        let selectedTab = 1;
        let order = 'abc';
        switch (hash) {
            case '#grouped':
                selectedTab = 2;
                break;
            case '#active-time':
                selectedTab = 1;
                order = 'time';
                break;
            case '#active-abc':
                selectedTab = 1;
                order = 'abc';
                break;
            case '#active-order':
                selectedTab = 1;
                order = 'order';
                break;
            case '#potential-time':
                selectedTab = 0;
                order = 'time';
                break;
            case '#potential-abc':
                selectedTab = 0;
                order = 'abc';
                break;
            default:
                break;
        }
        this.setState({ ...this.state, selectedTab: selectedTab, orderBy: order });
    };

    setCurrentHashBasedOnState = () => {
        let _this = this;
        setTimeout(() => {
            let hash = '/locations#';
            switch (_this.state.selectedTab) {
                case 0:
                    if (this.state.orderBy === 'time') hash += 'potential-time';
                    else hash += 'potential-abc';
                    break;
                case 1:
                    if (this.state.orderBy === 'time') hash += 'active-time';
                    else if (this.state.orderBy === 'order') hash += 'active-order';
                    else hash += 'active-abc';
                    break;
                case 2:
                    hash += 'grouped';
                    break;
                default:
                    break;
            }
            _this.props.history.replace(hash);
        }, 1);
    };

    onOrderByChange(evt, newOrder) {
        if (newOrder && newOrder !== this.state.orderBy) {
            this.setState({ ...this.state, orderBy: newOrder });
            this.setCurrentHashBasedOnState();
        }
    }

    getButtonClass(text) {
        return this.state.selectedView === text ? 'btn btn-primary' : 'btn btn-secondary';
    }

    onLocationFilterChange = (evt) => {
        store.dispatch({ type: LOCATION_VIEW_FILTER_UPDATE, filter: evt.target.value });
    };

    onCustomerFilterChange = (evt) => {
        store.dispatch({ type: TREE_VIEW_FILTER_UPDATE, filter: evt.target.value });
    };

    onClickNewLocation() {
        this.props.history.push('/locations/edit/new');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.fetchFail && !prevProps.fetchFail) {
            toast.error(t('general.fetchFailedRetry'), { autoClose: 5000 });
        }
        if (
            this.props.customerDescriptions != null &&
            prevProps.customerDescriptions == null &&
            this.state.selectedTab === 1
        ) {
            const _this = this;
            setTimeout(() => {
                window.scrollTo(0, _this.props.treeScrollYValue);
            }, 500);
        }
    }

    handleChange = (event, selectedTab) => {
        this.setState({ ...this.state, selectedTab: selectedTab });
        if (selectedTab !== 1) {
            store.dispatch({ type: TREE_SCROLL_Y_POS_UPDATE, scrollY: 0 });
        }
        this.setCurrentHashBasedOnState();
    };

    filterCustomerDescriptions = (customers, filter) => {
        let ret = _.filter(customers, (c) => {
            return _.deburr(c.name).toLowerCase().indexOf(_.deburr(filter).toLowerCase()) !== -1;
        });
        return ret;
    };

    getActiveLocations = (locations) => {
        if (locations !== null) return _.filter(locations, { status: CustomershipStatus.Active() });

        return null;
    };

    getPotentialLocations = (locations) => {
        if (locations !== null) return _.filter(locations, { status: CustomershipStatus.Potential() });

        return null;
    };

    Potentials = () => {
        return (
            <div className={this.props.classes.tabcontainer}>
                <Grid container>
                    <LocationSearch
                        locationFilter={this.props.locationFilter}
                        onLocationFilterChange={this.onLocationFilterChange}
                        class={this.props.classes.searchField}
                    />
                    <GroupBySelector
                        locationDescriptions={this.props.locationDescriptions}
                        togglebuttongroup={this.props.classes.togglebuttongroup}
                        orderBy={this.state.orderBy}
                        onOrderByChange={this.onOrderByChange}
                        icon={this.props.classes.icon}
                        selectedTab={this.state.selectedTab}
                    />

                    <LoadingInfo
                        getLocationDescriptionsInProgress={this.props.getLocationDescriptionsInProgress}
                        getLocationDescriptionsFailed={this.props.getLocationDescriptionsFailed}
                    />
                </Grid>
                <LocationList
                    locations={this.getPotentialLocations(this.props.locationDescriptions)}
                    getLocationDescriptionsInProgress={this.props.getLocationDescriptionsInProgress}
                    getLocationDescriptionsFailed={this.props.getLocationDescriptionsFailed}
                    view={this.state.selectedView}
                    viewOrder={this.state.orderBy}
                    filter={this.props.locationFilter}
                    history={this.props.history}
                />

                <FooterSection onClickNewLocation={this.onClickNewLocation} />
            </div>
        );
    };

    Active = () => {
        return (
            <div className={this.props.classes.tabcontainer}>
                <Grid container>
                    <LocationSearch
                        locationFilter={this.props.locationFilter}
                        onLocationFilterChange={this.onLocationFilterChange}
                        class={this.props.classes.searchField}
                    />
                    <GroupBySelector
                        locationDescriptions={this.props.locationDescriptions}
                        togglebuttongroup={this.props.classes.togglebuttongroup}
                        orderBy={this.state.orderBy}
                        onOrderByChange={this.onOrderByChange}
                        icon={this.props.classes.icon}
                        selectedTab={this.state.selectedTab}
                    />

                    <LoadingInfo
                        getLocationDescriptionsInProgress={this.props.getLocationDescriptionsInProgress}
                        getLocationDescriptionsFailed={this.props.getLocationDescriptionsFailed}
                    />
                </Grid>
                <LocationList
                    locations={this.getActiveLocations(this.props.locationDescriptions)}
                    getLocationDescriptionsInProgress={this.props.getLocationDescriptionsInProgress}
                    getLocationDescriptionsFailed={this.props.getLocationDescriptionsFailed}
                    view={this.state.selectedView}
                    viewOrder={this.state.orderBy}
                    filter={this.props.locationFilter}
                    history={this.props.history}
                />
                <FooterSection onClickNewLocation={this.onClickNewLocation} />
            </div>
        );
    };
    getActiveCustomers = (allcustomers) => {
        if (allcustomers !== null) return _.filter(allcustomers, { status: CustomershipStatus.Active() });

        return null;
    };

    GroupedView = () => {
        if (this.props.customerDescriptions == null || this.props.customerDescriptions.length === 0) return null;
        const customers = this.getActiveCustomers(this.props.customerDescriptions);

        return (
            <div className={this.props.classes.tabcontainer}>
                {/*<this.LocationSearch />*/}
                {!this.state.editTree && (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={this.props.classes.button}
                            onClick={this.onActivateEdit}
                            size={'small'}>
                            <EditIcon className={this.props.classes.icon} />
                            {t('buttons.edit')}
                        </Button>
                    </Grid>
                )}
                {this.state.editTree && (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={this.props.classes.button}
                            onClick={this.onEditComplete}
                            size={'small'}>
                            <CheckIcon className={this.props.classes.icon} />
                            {t('buttons.close')}
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {this.filterCustomerDescriptions(customers, this.props.treeViewCustomerFilter).map((cust) => (
                        <Grid item xs={12} key={cust.id}>
                            <ConnectedTree
                                customer={cust}
                                edit={this.state.editTree}
                                history={this.props.history}
                                userPortfolios={this.props.userProfile.portfolioDescriptions}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    onActivateEdit = () => {
        this.setState({ ...this.state, editTree: true });
    };
    onEditComplete = () => {
        this.setState({ ...this.state, editTree: false });
    };
    warningIfLocationsHaveRemovedProducts = () => {
        if (this.props.locationDescriptions != null && this.props.locationDescriptions.length > 0) {
            let locationsWithProblems = 0;
            for (let loc of this.props.locationDescriptions) {
                if (loc.usesRemovedProducts === true) {
                    locationsWithProblems++;
                }
            }
            if (locationsWithProblems > 0) {
                return (
                    <div style={{ padding: '20px' }}>
                        <Alert severity="error">
                            {t('location.locationsWithExpiredProducts', {
                                locationsWithExpiredProductsCount: locationsWithProblems,
                            })}
                        </Alert>
                    </div>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    render() {
        return (
            <div className={this.props.classes.root}>
                <this.warningIfLocationsHaveRemovedProducts />
                <AppBar position="static" color="default">
                    <Box display="flex" justifyContent="center" width="100%">
                        <Tabs
                            value={this.state.selectedTab}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto">
                            <Tab value={0} label={t('general.potentials')} />
                            <Tab value={1} label={t('general.actives')} />
                            <Tab value={2} label={t('general.treeView')} />
                        </Tabs>
                    </Box>
                </AppBar>
                <TabPanel value={this.state.selectedTab} index={0}>
                    <this.Potentials />
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={1}>
                    <this.Active />
                </TabPanel>
                <TabPanel value={this.state.selectedTab} index={2}>
                    <this.GroupedView />
                </TabPanel>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    locationDescriptions: state.locationdata.locationDescriptions,
    getLocationDescriptionsInProgress: state.locationdata.getLocationDescriptionsInProgress,
    getLocationDescriptionsFailed: state.locationdata.getLocationDescriptionsFailed,
    customerDescriptions: state.customerdata.customerDescriptions,
    getCustomerDescriptionsInProgress: state.customerdata.getCustomerDescriptionsInProgress,
    getCustomerDescriptionsFailed: state.customerdata.getCustomerDescriptionsFailed,
    saveLocationFail: state.locationdata.saveDataFailed,
    saveLocationOnProgress: state.locationdata.savingData,
    saveLocationDone: state.locationdata.saveDone,
    userProfile: state.authentication.userProfile,
    treeviewOpenedFolderIds: state.customerdata.treeviewOpenedFolderIds,
    treeScrollYValue: state.customerdata.treeScrollYValue,
    locationFilter: state.locationdata.locationFilter,
    treeViewCustomerFilter: state.locationdata.treeViewCustomerFilter,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            ...locationActions,
            ...customerActions,
            ...appActions,
            ...prodDataActions,
            ...orderActions,
        },
        dispatch
    );

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Locations));
