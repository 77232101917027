import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AlphabeticalCustomerList from './alphabeticalcustomerlist';
import Spinner from '../common/spinner';
import { CUSTOMER_VIEW_FILTER_UPDATE } from '../modules/customerdata';
import FooterPadding from '../main/footerpadding';
import Footer from '../main/footer';
import { Grid, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import TabPanel from '../common/tabpanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CustomershipStatus from '../domain/customershipstatus';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import EuroIcon from '@material-ui/icons/Euro';
import Tooltip from '@material-ui/core/Tooltip';
import ValueSortedCustomerGroup from './valuesortedcustomergroup';
import SearchInput from '../common/searchinput';
import { useTranslation } from 'react-i18next';
import { getCustomerDescriptiveInfo } from '../modules/customerdata';
import CustomerItem from './customeritem';
import { getAlphabeticallySorted } from './alphabeticalcustomerlist';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    tabcontainer: {
        marginTop: '10px',
        width: '98%',
    },
    button: {
        marginTop: '5px',
    },
    icon: {
        margin: '5px',
    },
    togglebuttongroup: {
        margin: '5px',
        float: 'right',
    },
    searchField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
        marginTop: '10px',
    },
    accordionPanel: {
        marginTop: '3em',
    },
}));

const CustomerSearch = (props) => {
    return (
        <Grid xs={8} md={6} item>
            <SearchInput searchInput={props.customerFilter} onChange={props.onFilterChange} />
        </Grid>
    );
};

const GroupBySelector = (props) => {
    const { t } = useTranslation();

    return (
        <Grid item xs={4} md={6}>
            <ToggleButtonGroup
                className={props.togglebuttongroup}
                exclusive
                value={props.orderBy}
                onChange={props.onOrderByChange}
                size={'small'}>
                <ToggleButton value="abc">
                    <Tooltip title={t('general.alphabeticalOrder')}>
                        <SortByAlphaIcon className={props.icon} />
                    </Tooltip>
                </ToggleButton>

                <ToggleButton value="money">
                    <Tooltip title={t('general.sortByCustomerValue')}>
                        <EuroIcon className={props.icon} />
                    </Tooltip>
                </ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    );
};

const SelectedCustomerListView = (props) => {
    const { t } = useTranslation();

    if (props.orderBy === 'abc')
        return (
            <AlphabeticalCustomerList
                customerDescriptions={props.customers}
                filter={props.filter}
                history={props.history}
            />
        );
    else if (props.orderBy === 'money') {
        const viewConfig = [
            { title: `${t('general.over')} 50 000€`, upperLimit: undefined, lowerLimit: 50000 },
            { title: '10 000€ - 50 000€', upperLimit: 50000, lowerLimit: 10000 },
            { title: '1 000€ - 10 000€', upperLimit: 10000, lowerLimit: 1000 },
            { title: `${t('general.under')} 1 000€`, upperLimit: 1000, lowerLimit: undefined },
        ];

        if (props.customerValuations == null) return null;

        const filteredCustomers = _.filter(props.customerValuations, (c) => {
            return _.deburr(c.name).toLowerCase().indexOf(_.deburr(props.filter).toLowerCase()) !== -1;
        });
        let viewGroups = [];
        for (const g of viewConfig) {
            viewGroups.push({
                title: g.title,
                customers: _.orderBy(
                    _.filter(filteredCustomers, function (i) {
                        if (g.upperLimit && g.lowerLimit)
                            return i.valuation >= g.lowerLimit && i.valuation < g.upperLimit;
                        else if (g.upperLimit) return i.valuation < g.upperLimit;
                        else if (g.lowerLimit) return i.valuation >= g.lowerLimit;
                    }).map((c) => {
                        return { name: c.name, id: c.id, value: c.valuation };
                    }),
                    ['value'],
                    ['desc']
                ),
            });
        }

        return (
            <Grid item xs={12}>
                {viewGroups.map((g) => (
                    <ValueSortedCustomerGroup key={g.title} group={g} history={props.history} />
                ))}
            </Grid>
        );
    }

    return null;
};

const SearchStatus = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            {(props.getCustomerDescriptionsInProgress || props.customershipValuationsFetching) && (
                <Spinner padding="20px" title={t('general.loading')} />
            )}
            {(!props.getCustomerDescriptionsInProgress && props.getCustomerDescriptionsFailed) ||
                (!props.customershipValuationsFetching && props.customershipValuationsFailed && (
                    <div style={{ padding: '20px' }}>
                        <Alert severity="error">{t('customer.failedToRetrieveCustomerDataRetry')}</Alert>
                    </div>
                ))}
        </div>
    );
};

const OtherCustomersView = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const custCount = !!props.customers ? props.customers.length : 0;
    if (custCount === 0) return null;
    const filteredCustomers = getAlphabeticallySorted(props.customers, props.filter);

    const customerCountStr = () => {
        let str = '';
        if (props.filter.length > 0) {
            str += filteredCustomers.length + '/';
        }
        str += custCount;
        return str;
    };
    return (
        <Accordion className={classes.accordionPanel}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <strong>
                    {t('customer.otherCustomers')} {customerCountStr()} {t('pcs')}
                </strong>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    {filteredCustomers.map((item) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={item.key}>
                            <h2>{item.key}</h2>
                            {item.customers.map((customer) => (
                                <CustomerItem key={customer.id} customerDesc={customer} history={history} />
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const PotentialCustomers = (props) => {
    const history = useHistory();

    return (
        <Grid container>
            <CustomerSearch
                customerFilter={props.customerFilter}
                onFilterChange={props.onFilterChange}
                class={props.classes.searchField}
            />
            <GroupBySelector
                togglebuttongroup={props.classes.togglebuttongroup}
                orderBy={props.orderBy}
                onOrderByChange={props.onOrderByChange}
                icon={props.classes.icon}
            />
            <SelectedCustomerListView
                customers={props.customersOwn}
                customerValuations={props.valuations}
                filter={props.customerFilter}
                history={history}
                orderBy={props.orderBy}
            />

            <OtherCustomersView customers={props.customersOther} filter={props.customerFilter} />

            <SearchStatus
                getCustomerDescriptionsInProgress={props.getCustomerDescriptionsInProgress}
                customershipValuationsFetching={props.customershipValuationsFetching}
                getCustomerDescriptionsFailed={props.getCustomerDescriptionsFailed}
                customershipValuationsFailed={props.customershipValuationsFailed}
            />
        </Grid>
    );
};

const ActiveCustomers = (props) => {
    const history = useHistory();

    return (
        <Grid container>
            <CustomerSearch
                customerFilter={props.customerFilter}
                onFilterChange={props.onFilterChange}
                class={props.classes.searchField}
            />
            <GroupBySelector
                togglebuttongroup={props.classes.togglebuttongroup}
                orderBy={props.orderBy}
                onOrderByChange={props.onOrderByChange}
                icon={props.classes.icon}
            />

            <SelectedCustomerListView
                customers={props.customersOwn}
                customerValuations={props.valuations}
                filter={props.customerFilter}
                history={history}
                orderBy={props.orderBy}
            />

            <OtherCustomersView customers={props.customersOther} filter={props.customerFilter} />

            <SearchStatus
                getCustomerDescriptionsInProgress={props.getCustomerDescriptionsInProgress}
                customershipValuationsFetching={props.customershipValuationsFetching}
                getCustomerDescriptionsFailed={props.getCustomerDescriptionsFailed}
                customershipValuationsFailed={props.customershipValuationsFailed}
            />
        </Grid>
    );
};

export default function Customers(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = React.useState(1);
    const [orderBy, setOrderBy] = React.useState('abc');
    const customerFilter = useSelector((state) => state.customerdata.customerFilter);
    const customerDescriptions = useSelector((state) => state.customerdata.customerDescriptions);
    const customerDescriptionsOwn = useSelector((state) => state.customerdata.customerDescriptionsOwn);
    const customershipValuations = useSelector((state) => state.customerdata.customershipValuations);
    const getCustomerDescriptionsInProgress = useSelector(
        (state) => state.customerdata.getCustomerDescriptionsInProgress
    );
    const customershipValuationsFetching = useSelector((state) => state.customerdata.customershipValuationsFetching);
    const getCustomerDescriptionsFailed = useSelector((state) => state.customerdata.getCustomerDescriptionsFailed);
    const customershipValuationsFailed = useSelector((state) => state.customerdata.customershipValuationsFailed);
    const { t } = useTranslation();
    const customersOthers =
        customerDescriptions && customerDescriptions.length > 0
            ? customerDescriptions.filter((desc) => !customerDescriptionsOwn.find((o) => o.id === desc.id))
            : customerDescriptions;

    const onClickNewCustomer = () => {
        history.push('/customers/edit/new');
    };

    const onFilterChange = (evt) => {
        dispatch({ type: CUSTOMER_VIEW_FILTER_UPDATE, filter: evt.target.value });
    };

    useEffect(() => {
        dispatch(getCustomerDescriptiveInfo());
        switch (history.location.hash) {
            case '#active-abc':
                setSelectedTab(1);
                setOrderBy('abc');
                break;
            case '#potential-abc':
                setSelectedTab(0);
                setOrderBy('abc');
                break;
            case '#active-money':
                setSelectedTab(1);
                setOrderBy('money');
                break;
            case '#potential-money':
                setSelectedTab(0);
                setOrderBy('money');
                break;
            default:
                history.replace('/customers');
                break;
        }
    }, [dispatch, history]);

    const getActiveCustomers = (customers) => {
        if (customers !== null) return _.filter(customers, { status: CustomershipStatus.Active() });

        return null;
    };

    const getPotentialCustomers = (customers) => {
        if (customers !== null) return _.filter(customers, { status: CustomershipStatus.Potential() });

        return null;
    };

    const setUrlHash = (tab, order) => {
        setTimeout(() => {
            let hash = '/customers#';
            switch (tab) {
                case 0:
                    hash += 'potential';
                    break;
                case 1:
                    hash += 'active';
                    break;
                default:
                    break;
            }
            hash += `-${order}`;
            history.replace(hash);
        }, 1);
    };

    const changeTab = (event, value) => {
        setSelectedTab(value);
        if (value === 1) history.replace(`/customers#active-${orderBy}`);
        else {
            history.replace(`/customers#potential-${orderBy}`);
        }
    };

    const onOrderByChange = (evt, newOrder) => {
        if (newOrder && newOrder !== orderBy) {
            setOrderBy(newOrder);
            setUrlHash(selectedTab, newOrder);
        }
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Box display="flex" justifyContent="center" width="100%">
                    <Tabs
                        value={selectedTab}
                        onChange={changeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto">
                        <Tab value={0} label={t('general.potentials')} />
                        <Tab value={1} label={t('general.actives')} />
                    </Tabs>
                </Box>
            </AppBar>
            <TabPanel value={selectedTab} index={0}>
                <PotentialCustomers
                    customerFilter={customerFilter}
                    classes={classes}
                    orderBy={orderBy}
                    onFilterChange={onFilterChange}
                    onOrderByChange={onOrderByChange}
                    customersOther={getPotentialCustomers(customersOthers)}
                    customersOwn={getPotentialCustomers(customerDescriptionsOwn)}
                    valuations={getPotentialCustomers(customershipValuations)}
                    getCustomerDescriptionsInProgress={getCustomerDescriptionsInProgress}
                    customershipValuationsFetching={customershipValuationsFetching}
                    getCustomerDescriptionsFailed={getCustomerDescriptionsFailed}
                    customershipValuationsFailed={customershipValuationsFailed}
                />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <ActiveCustomers
                    customerFilter={customerFilter}
                    classes={classes}
                    orderBy={orderBy}
                    onFilterChange={onFilterChange}
                    onOrderByChange={onOrderByChange}
                    customersOther={getActiveCustomers(customersOthers)}
                    customersOwn={getActiveCustomers(customerDescriptionsOwn)}
                    valuations={getActiveCustomers(customershipValuations)}
                    getCustomerDescriptionsInProgress={getCustomerDescriptionsInProgress}
                    customershipValuationsFetching={customershipValuationsFetching}
                    getCustomerDescriptionsFailed={getCustomerDescriptionsFailed}
                    customershipValuationsFailed={customershipValuationsFailed}
                />
            </TabPanel>

            <Footer>
                <Grid item>
                    <Button name="btn-new-customer" variant="contained" color="primary" onClick={onClickNewCustomer}>
                        {t('buttons.newCustomer')}
                    </Button>
                </Grid>
            </Footer>
            <FooterPadding />
        </div>
    );
}
