import React, { useEffect } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextEditBlock from './texteditblock';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import Invoice from '../domain/invoice';
import { round } from 'mathjs';

const InvoiceEditRow = (props) => {
    const {
        row,
        edit,
        editRow,
        onChangeQuantity,
        onChangePricePerUnit,
        closeRowEdit,
        saving,
        onChangeProductInfo,
        onRemoveRow,
        onChangeProductNumber,
        onChangeProductName,
        onChangeVatRate,
        onChangeSalesAccount,
        onChangeVatHandling,
        salesInvoiceIntegration,
        vatCodes,
    } = props;
    const { t } = useTranslation();
    const [allowedVatRates, setAllowedVatRates] = React.useState('');

    useEffect(() => {
        if (vatCodes && vatCodes.length > 0 && row.vatHandling && row.vatHandling.length > 0) {
            const selectedVatCode = vatCodes.find((c) => c.vatCode === row.vatHandling);
            if (selectedVatCode) {
                setAllowedVatRates(selectedVatCode.allowedVatPercentages.map((p) => p + '%').join(', '));
            }
        }
    }, [vatCodes, row.vatHandling]);

    const getVatCodeId = (vatHandling) => {
        const vatCode = vatCodes.find((c) => c.vatCode === vatHandling);
        if (!vatCode) {
            throw new Error('invalid vatHandling in invoice row');
        }
        return vatCode.vatId;
    };

    return (
        <TableRow key={row.storageId} onClick={() => editRow(row.storageId)}>
            <TableCell>
                {!edit && <span>{row.productNumber}</span>}
                {edit && row.rowType === 0 && (
                    <TextEditBlock
                        xs={12}
                        md={12}
                        maxLength={13}
                        name={'productNumber'}
                        label={t('product.code')}
                        defaultValue={row.productNumber}
                        onBlur={(event) => onChangeProductNumber(row.storageId, event)}
                    />
                )}
            </TableCell>
            <TableCell>
                {row.rowType === 0 && (
                    <div>
                        {!edit && (
                            <span>
                                {row.productName}
                                <br />
                                <em style={{ fontSize: '0.8em' }}>{row.productInfo}</em>
                            </span>
                        )}
                        {edit && (
                            <TextEditBlock
                                xs={12}
                                md={12}
                                maxLength={45}
                                name={'productName'}
                                label={t('product.name')}
                                defaultValue={row.productName}
                                onBlur={(event) => onChangeProductName(row.storageId, event)}
                            />
                        )}
                    </div>
                )}

                {row.rowType === 1 && !edit && <span>{row.productInfo}</span>}
                {edit && (
                    <TextEditBlock
                        xs={12}
                        md={12}
                        maxLength={1024}
                        name={'productInfo'}
                        label={t('general.definition')}
                        defaultValue={row.productInfo}
                        onBlur={(event) => onChangeProductInfo(row.storageId, event)}
                    />
                )}
            </TableCell>
            <TableCell>
                {row.rowType === 0 && edit && (
                    <TextEditBlock
                        xs={12}
                        md={12}
                        name={'quantity'}
                        label={t('pcs')}
                        defaultValue={row.quantity}
                        onBlur={(event) => onChangeQuantity(row.storageId, event)}
                    />
                )}
                {row.rowType === 0 && !edit && <span>{row.quantity}</span>}
            </TableCell>
            <TableCell>
                {row.rowType === 0 && edit && (
                    <TextEditBlock
                        xs={12}
                        md={12}
                        name={'pricePerUnit'}
                        label={t('invoice.pricePerUnit')}
                        defaultValue={row.pricePerUnit}
                        onBlur={(event) => onChangePricePerUnit(row.storageId, event)}
                    />
                )}
                {row.rowType === 0 && !edit && <span>{round(row.pricePerUnit, 2)}</span>}
            </TableCell>
            <TableCell>
                {row.rowType === 0 && edit && (
                    <div>
                        <TextEditBlock
                            xs={12}
                            md={12}
                            name={'vatRate'}
                            label={t('invoice.vatPercentage')}
                            defaultValue={row.vatRate}
                            onBlur={(event) => onChangeVatRate(row.storageId, event)}
                            sidenote={
                                salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationNetvisor()
                                    ? allowedVatRates
                                    : ''
                            }
                        />
                        {salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationNetvisor() && (
                            <FormControl>
                                <InputLabel id="vat-handling-label">{t('invoice.vatHandling')}</InputLabel>
                                <Select
                                    required
                                    labelId="vat-handling-label"
                                    id="vatHandling"
                                    name="vatHandling"
                                    value={row.vatHandling}
                                    onChange={(event) => onChangeVatHandling(row.storageId, event)}>
                                    {vatCodes.map((opt) => (
                                        <MenuItem key={opt.vatCode} value={opt.vatCode}>
                                            {t('invoice.vatHandlingOpt.' + opt.vatId)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationTalenom() && (
                            <FormControl>
                                <InputLabel id="salesaccount-label">{t('invoice.salesAccount')}</InputLabel>
                                <Select
                                    required
                                    labelId="salesaccount-label"
                                    id="salesaccount"
                                    name="salesaccount"
                                    value={row.salesAccountNumber}
                                    onChange={(event) => onChangeSalesAccount(row.storageId, event)}>
                                    {row.salesAccountOptions().map((opt) => (
                                        <MenuItem key={opt.value} value={opt.value}>
                                            {opt.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </div>
                )}
                {row.rowType === 0 && !edit && (
                    <span>
                        {row.vatRate}
                        <br />
                        {salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationTalenom() && (
                            <em style={{ fontSize: '0.8em' }}>{row.salesAccountDescription()}</em>
                        )}
                        {salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationNetvisor() && (
                            <em style={{ fontSize: '0.8em' }}>
                                {t('invoice.vatHandlingOpt.' + getVatCodeId(row.vatHandling))}
                            </em>
                        )}
                    </span>
                )}
            </TableCell>
            <TableCell>
                {row.rowType === 0 && <span>{round(row.priceVatExcluded, 2)}&nbsp;&nbsp;</span>}
                {edit && (
                    <Button
                        variant="contained"
                        color="primary"
                        name="okrowedit"
                        onClick={closeRowEdit}
                        disabled={saving}>
                        {t('buttons.save')}
                    </Button>
                )}
            </TableCell>
            <TableCell>
                <Tooltip title={t('buttons.remove')}>
                    <IconButton
                        size="small"
                        color="secondary"
                        name="btn-remove-note"
                        onClick={(evt) => {
                            onRemoveRow(row.storageId);
                            evt.stopPropagation();
                        }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default InvoiceEditRow;
