import React, { useEffect } from 'react';
import TextEditBlock from '../invoice/texteditblock';
import { useTranslation } from 'react-i18next';
import { verifyEmailAddress } from './common';
import PropTypes from 'prop-types';

export default function EmailInvoiceAddressInput(props) {
    const { t } = useTranslation();
    const [editedEmail, setEditedEmail] = React.useState('');

    const email = props.email;
    const onChange = props.onChange;

    useEffect(() => {
        if (email !== null) setEditedEmail(email);
    }, [email]);

    const handleChange = (evt) => {
        const value = evt.target.value;
        setEditedEmail(value);
        if (verifyEmailAddress(value)) {
            onChange({ target: { value, name: 'emailInvoiceAddress' } });
        }
    };

    const handleBlur = (evt) => {
        const value = evt.target.value;
        if (verifyEmailAddress(value)) {
            onChange({ target: { value, name: 'emailInvoiceAddress' } });
        } else {
            setEditedEmail('');
        }
    };

    return (
        <TextEditBlock
            id="email-invoice-address"
            name="emailInvoiceAddress"
            label={t('invoice.emailInvoiceAddress')}
            value={editedEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            number={false}
            showCounter={true}
            maxLength={100}
        />
    );
}

EmailInvoiceAddressInput.propTypes = {
    email: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
