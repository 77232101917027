import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddNoteDialog from './addnotedialog';
import Note from '../domain/note';
import { makeStyles } from '@material-ui/core/styles';
import { saveNote as saveNoteLoc, editNote as editNoteLoc, removeNote as removeNoteLoc } from '../modules/locationdata';
import { saveNote as saveNoteCus, editNote as editNoteCus, removeNote as removeNoteCus } from '../modules/customerdata';
import ConfirmationDialog from '../common/confirmationdialog';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    em: {
        whiteSpace: 'pre-line',
    },
    accordion: {
        marginTop: '10px',
    },
    noteListItem: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'lightgray',
        },
    },
}));

export const TypeCustomer = 'customer';
export const TypeLocation = 'location';

export default function NotesView(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const locationsById = useSelector((state) => state.locationdata.locationsById);
    const customersById = useSelector((state) => state.customerdata.customersById);
    const userProfile = useSelector((state) => state.authentication.userProfile);
    const { t } = useTranslation();

    let notes = null;
    if (props.type === TypeLocation) {
        const locationFull = locationsById[props.id];
        notes = locationFull != null ? locationFull.notes : null;
    } else {
        const customerFull = customersById[props.id];
        notes = customerFull != null ? customerFull.notes : null;
    }

    const firstNote = (notes != null) & (notes.length > 0) ? notes[0] : null;
    const notesExcludingFirst = notes != null && notes.length > 1 ? _.slice(notes.slice(), 1) : [];

    const [noteDialogOpen, setNoteDialogOpen] = useState(false);
    const [viewExpanded, setViewExpanded] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [noteEditMode, setNoteEditMode] = useState(false);
    const [noteToEdit, setNoteToEdit] = useState(null);
    const [noteToBeRemoved, setNoteToBeRemoved] = useState(null);

    const noteCount = notes != null ? notes.length : 0;

    const closeDialogRoutine = () => {
        setNoteDialogOpen(false);
        setRemoveDialogOpen(false);

        setNoteEditMode(false);
        setNoteToEdit(null);
        setNoteToBeRemoved(null);
    };

    const newNote = () => {
        setNoteDialogOpen(true);
    };

    const save = async (noteText, noteId) => {
        let note = new Note();
        note.storageId = noteId;
        note.text = noteText;
        note.userId = userProfile.id;
        if (props.type === TypeLocation) {
            note.locationId = props.id;
            if (noteId !== null) await editNoteLoc(note)(dispatch);
            else await saveNoteLoc(note)(dispatch);
        }
        if (props.type === TypeCustomer) {
            note.customerId = props.id;
            if (noteId !== null) await editNoteCus(note)(dispatch);
            else await saveNoteCus(note)(dispatch);
        }
        closeDialogRoutine();
    };

    const cancel = () => {
        closeDialogRoutine();
    };

    const accordionStateChange = (event, expanded) => {
        setViewExpanded(expanded);
    };

    const editNote = (event, note) => {
        event.stopPropagation();
        setNoteEditMode(true);
        setNoteToEdit(note);
        setNoteDialogOpen(true);
    };

    const removeNote = (event, note) => {
        event.stopPropagation();
        setNoteToBeRemoved(note);
        setRemoveDialogOpen(true);
    };

    const confirmRemove = async () => {
        if (props.type === TypeLocation) {
            await removeNoteLoc(noteToBeRemoved)(dispatch);
        }
        if (props.type === TypeCustomer) {
            await removeNoteCus(noteToBeRemoved)(dispatch);
        }
        closeDialogRoutine();
    };

    const cancelRemove = () => {
        closeDialogRoutine();
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <hr style={{ marginTop: '0' }} />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <h5>
                            {t('general.notes')} ({noteCount} kpl)
                        </h5>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" size="small" color="primary" name="btn-new-note" onClick={newNote}>
                            <AddIcon />
                        </Button>
                    </Grid>
                </Grid>
                {firstNote && (
                    <Accordion className={classes.accordion} onChange={accordionStateChange}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} name="notes-panel">
                            <Grid container>
                                {firstNote.noteSaved.format('DD.MM.YYYY HH:mm')}: {firstNote.text} ..
                                {viewExpanded && (
                                    <Grid
                                        container
                                        alignItems={'flex-end'}
                                        alignContent={'flex-end'}
                                        justifyContent={'flex-end'}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                name="btn-edit-note"
                                                onClick={(e) => editNote(e, firstNote)}
                                                startIcon={<EditIcon />}>
                                                {t('buttons.edit')}
                                            </Button>
                                            &nbsp;
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                name="btn-remove-note"
                                                onClick={(e) => removeNote(e, firstNote)}
                                                startIcon={<DeleteIcon />}>
                                                {t('buttons.remove')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List style={{ width: '100%' }}>
                                {notesExcludingFirst.map((item, ind) => (
                                    <ListItem className={classes.noteListItem} key={ind}>
                                        <Grid container>
                                            {item.noteSaved.format('DD.MM.YYYY HH:mm')}:{' '}
                                            <em className={classes.em}>{item.text}</em>
                                            {viewExpanded && (
                                                <Grid
                                                    container
                                                    alignItems={'flex-end'}
                                                    alignContent={'flex-end'}
                                                    justifyContent={'flex-end'}>
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            name="btn-edit-note"
                                                            onClick={(e) => editNote(e, item)}
                                                            startIcon={<EditIcon />}>
                                                            {t('buttons.edit')}
                                                        </Button>
                                                        &nbsp;
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="secondary"
                                                            name="btn-remove-note"
                                                            onClick={(e) => removeNote(e, item)}
                                                            startIcon={<DeleteIcon />}>
                                                            {t('buttons.remove')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </ListItem>
                                ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                )}
                {!firstNote && (
                    <Grid item>
                        <em>{t('general.noNotes')}</em>
                    </Grid>
                )}
                <AddNoteDialog
                    open={noteDialogOpen}
                    save={save}
                    cancel={cancel}
                    edit={noteEditMode}
                    note={noteToEdit}
                />
                <ConfirmationDialog
                    open={removeDialogOpen}
                    confirm={confirmRemove}
                    cancel={cancelRemove}
                    confirmText={'Poista'}>
                    <span>{t('general.confirmRemoveNote')}</span>
                </ConfirmationDialog>
            </Grid>
        </Grid>
    );
}
