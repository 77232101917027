import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',
        width: '100%',
        height: '600px',
    },
}));

const LostSales = (props) => {
    const classes = useStyles();
    const linkToReport = useSelector((state) => state.settingsdata.reportSettings.lostSalesReportLink);
    const isActive = props.active;

    if (!isActive) return null;

    return (
        <div className={classes.root}>
            <iframe
                title="Myynnintuki-poistumat"
                width="100%"
                height="100%"
                src={linkToReport}
                frameborder="0"
                allowFullScreen="true"></iframe>
        </div>
    );
};

export default LostSales;
