import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    interactiveItem: {
        cursor: 'pointer',
        marginBottom: '0.25em',
    },
    iconLink: {
        verticalAlign: 'middle',
    },
    highlight: {
        backgroundColor: 'lightgrey',
    },
}));

export const TypeCustomer = 'customer';
export const TypeLocation = 'location';

export default function DuplicateItemsDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const userProfile = useSelector((state) => state.authentication.userProfile);
    const [locations, setLocations] = useState([]);
    const [customers, setCustomers] = useState([]);
    const { t } = useTranslation();

    const convertItem = useCallback(
        (item, type) => {
            return {
                id: item.id,
                name: item.name,
                businessId: item.businessId,
                portfolioName: item.portfolio.name,
                duplicateType: type,
                onActivePortfolio: item.portfolio.id === userProfile.selectedPortfolioId,
            };
        },
        [userProfile.selectedPortfolioId]
    );

    const getFlattenList = useCallback(
        (duplicateData) => {
            let ret = [];
            if (duplicateData.duplicatesByName && duplicateData.duplicatesByName.length > 0) {
                ret = duplicateData.duplicatesByName.map((item) => convertItem(item, 'name'));
            }
            if (duplicateData.duplicatesByBusinessId && duplicateData.duplicatesByBusinessId.length > 0) {
                ret = ret.concat(duplicateData.duplicatesByBusinessId.map((item) => convertItem(item, 'businessid')));
            }
            return ret;
        },
        [convertItem]
    );

    useEffect(() => {
        if (props.items) {
            if (props.items.locationDuplicates) {
                setLocations(getFlattenList(props.items.locationDuplicates));
            }
            if (props.items.customerDuplicates) {
                setCustomers(getFlattenList(props.items.customerDuplicates));
            }
        }
    }, [props.items, getFlattenList]);

    const onCancel = () => {
        props.cancel();
    };

    const onItemClick = (id, type) => {
        props.history.push(type === TypeCustomer ? `/customers/${id}` : `/locations/${id}`);
    };

    return (
        <Dialog open={props.open} fullWidth={true} maxWidth={'md'} fullScreen={fullScreen}>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <h5>
                            {t('general.customers')} ({customers.length})
                        </h5>
                        {customers.map((item) => (
                            <Grid
                                item
                                xs={12}
                                onClick={() => {
                                    if (item.onActivePortfolio) onItemClick(item.id, TypeCustomer);
                                }}
                                key={item.id}
                                className={classes.interactiveItem}
                                name={item.name}>
                                <Chip label={item.portfolioName} />
                                &nbsp;
                                <span className={item.duplicateType === 'name' ? classes.highlight : null}>
                                    {item.name ? item.name : '<ei nimeä>'}
                                </span>
                                <span className={item.duplicateType === 'businessid' ? classes.highlight : null}>
                                    ({item.businessId})&nbsp;
                                </span>
                                {item.onActivePortfolio && <OpenInNewIcon className={classes.iconLink} />}
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                        <h5>
                            {t('general.locations')} ({locations.length})
                        </h5>
                        {locations.map((item) => (
                            <Grid
                                item
                                xs={12}
                                onClick={() => {
                                    if (item.onActivePortfolio) onItemClick(item.id, TypeLocation);
                                }}
                                key={item.id}
                                className={classes.interactiveItem}
                                name={item.name}>
                                <Chip label={item.portfolioName} />
                                &nbsp;
                                <span className={item.duplicateType === 'name' ? classes.highlight : null}>
                                    {item.name ? item.name : '<ei nimeä>'}
                                </span>
                                <span className={item.duplicateType === 'businessid' ? classes.highlight : null}>
                                    ({item.businessId})&nbsp;
                                </span>
                                {item.onActivePortfolio && <OpenInNewIcon className={classes.iconLink} />}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="default" name="duplicate-view-close" onClick={onCancel}>
                    {t('buttons.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
