import Ajax from './ajax';
import {
    GET_USER_DATA_IN_PROGRESS,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_FAILED,
    GET_USER_BY_ID_IN_PROGRESS,
    GET_USER_BY_ID_DONE,
    GET_USER_BY_ID_FAILED,
    SAVING_USER,
    SAVE_USER_DONE,
    SAVE_USER_FAILED,
    RESET_PASSWORD_IN_PROGRESS,
    RESET_PASSWORD_DONE,
    RESET_PASSWORD_FAILED,
    REMOVE_USER_IN_PROGRESS,
    REMOVE_USER_DONE,
    REMOVE_USER_FAILED,
} from './userdata';
import { toast } from 'react-toastify';
import i18next from 'i18next';

export const saveNewUser = (user, usertype, tempPassword) => {
    return async (dispatch) => {
        try {
            const reqJson = { user: user, usertype: usertype, tempPassword: tempPassword };
            await Ajax.post('api/user/', reqJson);
            return true;
        } catch (err) {
            return false;
        }
    };
};

export const checkUsernameAvailability = (username, usertype) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.post('api/user/usernameavailability/', {
                username: username,
                customerUser: usertype === 1 ? true : false,
            });
            return resp.data.result;
        } catch (err) {
            return false;
        }
    };
};

export const getUserData = () => {
    return (dispatch) => {
        dispatch({
            type: GET_USER_DATA_IN_PROGRESS,
        });
        var orgUsers = Ajax.get('api/user/org');
        var ccUsers = Ajax.get('api/user/contractcustomer');
        return Promise.all([orgUsers, ccUsers])
            .then((values) => {
                dispatch({
                    type: GET_USER_DATA_SUCCESS,
                    orgUsers: values[0].data,
                    ccUsers: values[1].data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_USER_DATA_FAILED,
                });
            });
    };
};

export const getUser = (id) => {
    return (dispatch) => {
        dispatch({
            type: GET_USER_BY_ID_IN_PROGRESS,
        });
        Ajax.get('api/user/' + id)
            .then((resp) => {
                dispatch({
                    type: GET_USER_BY_ID_DONE,
                    user: resp.data,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_USER_BY_ID_FAILED,
                });
            });
    };
};

export const saveUser = (user) => {
    return (dispatch) => {
        dispatch({
            type: SAVING_USER,
        });
        toastInfo('toast.savingUserInfo');
        var promise = new Promise((resolve, reject) => {
            Ajax.put('api/user/' + user.id, user)
                .then((resp) => {
                    dispatch({
                        type: SAVE_USER_DONE,
                        user: resp.data,
                    });
                    toastInfo('toast.userInfoSaved');
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: SAVE_USER_FAILED,
                    });
                    toastError('toast.savingUserInfoFailed');
                    reject();
                });
        });
        return promise;
    };
};

export const resetPassword = (user, newPassword) => {
    return (dispatch) => {
        dispatch({ type: RESET_PASSWORD_IN_PROGRESS });
        toastInfo('toast.savingUserInfo');
        var promise = new Promise((resolve, reject) => {
            Ajax.put('api/user/password/' + user.id, { user: user, newPassword: newPassword })
                .then((resp) => {
                    dispatch({
                        type: RESET_PASSWORD_DONE,
                        userId: user.id,
                    });
                    toastInfo('toast.userInfoSaved');
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: RESET_PASSWORD_FAILED,
                    });
                    toastError('toast.savingUserInfoFailed');
                    reject();
                });
        });
        return promise;
    };
};

export const removeUser = (user) => {
    return (dispatch) => {
        dispatch({ type: REMOVE_USER_IN_PROGRESS });
        toastInfo('toast.removing');
        var promise = new Promise((resolve, reject) => {
            Ajax.delete('api/user/' + user.id)
                .then((resp) => {
                    dispatch({
                        type: REMOVE_USER_DONE,
                        userId: user.id,
                    });
                    toastInfo('toast.userInfoRemoved');
                    resolve();
                })
                .catch((err) => {
                    console.log(err);
                    dispatch({
                        type: REMOVE_USER_FAILED,
                    });
                    toastError('toast.removingUserFailed');
                    reject();
                });
        });
        return promise;
    };
};

const toastInfo = (message) => {
    toast.info(i18next.t(message), { timeout: 1000, hideProgressBar: true });
};

const toastError = (message) => {
    toast.error(i18next.t(message), { timeout: 5000 });
};
