import { LOGGED_OFF } from './authentication';
import CONFIG from '../config';
import { cloneDeep } from 'lodash';

export const UPDATE_FOOTER_HEIGHT = 'appstate/UPDATE_FOOTER_HEIGHT';
export const ADD_LIFECYCLE_EVENT = 'appstate/ADD_LIFECYCLE_EVENT';
export const ADD_WS_EVENT = 'appstate/ADD_WS_EVENT';
export const SET_SHOWCASE_MODE = 'appstate/setshowcasemode';

const initialState = {
    footerHeightInPx: 0,
    debugLifecycleEvents: [],
    debugWsEvents: [],
    showcaseMode: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_WS_EVENT: {
            const newEvents = cloneDeep(state.debugWsEvents);
            newEvents.push(action.event);
            return {
                ...state,
                debugWsEvents: newEvents,
            };
        }
        case ADD_LIFECYCLE_EVENT: {
            const newEvents = cloneDeep(state.debugLifecycleEvents);
            newEvents.push(action.event);
            return {
                ...state,
                debugLifecycleEvents: newEvents,
            };
        }
        case UPDATE_FOOTER_HEIGHT: {
            return {
                ...state,
                footerHeightInPx: action.footerHeightInPx,
            };
        }
        case SET_SHOWCASE_MODE: {
            return { ...state, showcaseMode: action.mode };
        }
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const updateFooterHeight = (footerHeightInPx) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_FOOTER_HEIGHT,
            footerHeightInPx: footerHeightInPx,
        });
    };
};

export const addBrowserLifecycleEvent = (event) => {
    return (dispatch) => {
        if (CONFIG.features.debugUi) {
            dispatch({
                type: ADD_LIFECYCLE_EVENT,
                event,
            });
        }
    };
};

export const addWsEvent = (event) => {
    return (dispatch) => {
        if (CONFIG.features.debugUi) {
            dispatch({
                type: ADD_WS_EVENT,
                event,
            });
        }
    };
};

export const setShowcaseMode = (mode) => {
    return (dispatch) => {
        dispatch({
            type: SET_SHOWCASE_MODE,
            mode: mode,
        });
    };
};
