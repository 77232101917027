import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { ActionAllowed } from '../rbac/rbacutil';
import { rbacactions } from '../rbac/rbacrules';
import EditIcon from '@material-ui/icons/Edit';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    table: {
        width: '100%',
    },
    emptytable: {
        width: '100%',
        padding: '10px',
        fontSize: '0.75em',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    withPadding: {
        padding: '1em',
    },
}));

export default function ProductRequests(props) {
    const userProfile = useSelector((state) => state.authentication.userProfile);
    const classes = useStyles();
    const history = useHistory();
    const [sortBy, setSortBy] = React.useState('added');
    const [sortOrder, setSortOrder] = React.useState('desc');
    const productRequests = props.productRequests;
    const { t } = useTranslation();

    const editProduct = (productId) => {
        history.push(`/product/edit/${productId}`);
    };

    const changeSort = (sortByClicked) => {
        if (sortByClicked === sortBy) {
            if (sortOrder === 'asc') {
                setSortOrder('desc');
            } else {
                setSortOrder('asc');
            }
        } else {
            setSortBy(sortByClicked);
            setSortOrder('desc');
        }
    };

    const sortList = (requests) => {
        return orderBy(requests, [sortBy], [sortOrder]);
    };

    const sortMobile = (requests) => {
        return orderBy(requests, ['added'], ['desc']);
    };

    return (
        <Grid item container xs={12}>
            <Paper className={classes.table}>
                <Grid item className={classes.withPadding}>
                    <h5>{t('product.productRequests')}</h5>
                    {(!productRequests || productRequests.length === 0) && (
                        <span>{t('product.noProductRequests')}</span>
                    )}
                </Grid>
                {productRequests && productRequests.length > 0 && (
                    <div>
                        <div className={classes.sectionDesktop}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortBy === 'productCode'}
                                                direction={sortOrder}
                                                onClick={() => changeSort('productCode')}>
                                                {' '}
                                                {t('product.code')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortBy === 'productName'}
                                                direction={sortOrder}
                                                onClick={() => changeSort('productName')}>
                                                {' '}
                                                {t('product.name')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortBy === 'added'}
                                                direction={sortOrder}
                                                onClick={() => changeSort('added')}>
                                                {' '}
                                                {t('product.addedDate')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortBy === 'addedByUser'}
                                                direction={sortOrder}
                                                onClick={() => changeSort('addedByUser')}>
                                                {' '}
                                                {t('product.addedBy')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortList(productRequests).map((f) => (
                                        <TableRow key={f.id}>
                                            <TableCell>{f.productCode}</TableCell>
                                            <TableCell>{f.productName}</TableCell>
                                            <TableCell>{f.added.format('DD.MM.YYYY')}</TableCell>
                                            <TableCell>
                                                {f.addedByUser !== 'Automatic updater' ? f.addedByUser : '-'}
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    disabled={!ActionAllowed(rbacactions.ModifyProduct, userProfile)}
                                                    variant="contained"
                                                    onClick={() => editProduct(f.id)}
                                                    name="edit-product"
                                                    startIcon={<EditIcon />}>
                                                    {t('buttons.edit')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        <div className={classes.sectionMobile}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('general.product')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortMobile(productRequests).map((f) => (
                                        <TableRow key={f.id}>
                                            <TableCell>
                                                {f.productCode}
                                                <br />
                                                {f.productName}
                                                <br />
                                                {f.added.format('DD.MM.YYYY')}
                                                <br />
                                                {f.addedByUser !== 'Automatic updater' ? f.addedByUser : '-'}
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    disabled={!ActionAllowed(rbacactions.ModifyProduct, userProfile)}
                                                    variant="contained"
                                                    onClick={() => editProduct(f.id)}
                                                    name="edit-product"
                                                    startIcon={<EditIcon />}>
                                                    {t('buttons.edit')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                )}
            </Paper>
        </Grid>
    );
}
