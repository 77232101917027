import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../common/spinner';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import ProductionOrderView from '../order/productionorderview';
import User from '../domain/user';
import { getOrdersByOrderNumber } from '../modules/orderdata';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment';
import { includes } from 'lodash';

export default function SearchResultsOrderDetails(props) {
    const customerUi = process.env.REACT_APP_TARGET === 'customerui';
    const dispatch = useDispatch();
    const ordersByOrderNumber = useSelector((state) => state.orderdata.ordersByOrderNumber);
    const userprofile = useSelector((state) => state.authentication.userProfile);
    const portfolios = useSelector((state) => state.portfoliodata.portfolioDescriptions);
    const ordersFull = ordersByOrderNumber[props.order.orderNumber];
    const [expanded, setExpanded] = useState(false);
    const [portfolio, setPortfolio] = useState({});
    const order = props.order;
    const allowPricing = customerUi ? includes(userprofile.roles, User.RoleAllowPricing()) : true;

    useEffect(() => {
        if (ordersFull && portfolios && portfolios.length > 0) {
            setPortfolio(portfolios.find((p) => p.id === ordersFull[0].portfolioId));
        }
    }, [ordersFull, portfolios]);

    const onExpand = () => {
        setExpanded(!expanded);
        if (!ordersFull) dispatch(getOrdersByOrderNumber(order.orderNumber));
    };

    const openOrder = (event) => {
        props.history.push('/ordernumber/' + order.orderNumber);
        event.stopPropagation();
    };

    return (
        <React.Fragment>
            <Accordion expanded={expanded} onChange={onExpand}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                            <OpenInNewIcon onClick={(event) => openOrder(event)} />
                        </Grid>
                        <Grid item>
                            {moment(order.orderSent).format('DD.MM.YYYY HH:mm')}&nbsp;&nbsp;&nbsp;
                            {order.orderNumber}&nbsp;
                            {order.location.name}
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item>
                        {ordersFull && (
                            <ProductionOrderView
                                orders={ordersFull}
                                allowPricing={allowPricing}
                                portfolio={portfolio}
                                customerUi={customerUi}
                            />
                        )}
                        {!ordersFull && <Spinner />}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
}
