import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    markOrderAsDelivered,
    getProdOrdersOpen,
    getProdOrdersDelivered,
    getProdOrdersInvoiced,
} from '../modules/orderdata';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TabPanel from '../common/tabpanel';
import ProductionOrderListView from './productionorderlistview';
import ProductionPlan from './productionplan';
import { Switch, FormControlLabel, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    switch: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function ProductionContainer(props) {
    const classes = useStyles();
    const openOrders = useSelector((state) => state.orderdata.openProdOrders);
    const invoicedOrders = useSelector((state) => state.orderdata.invoicedOrders);
    const deliveredOrders = useSelector((state) => state.orderdata.deliveredOrders);

    const fetchingOpen = useSelector((state) => state.orderdata.isFetchingProdOrdersOpen);
    const fetchingDelivered = useSelector((state) => state.orderdata.isFetchingProdOrdersDelivered);
    const fetchingInvoiced = useSelector((state) => state.orderdata.isFetchingProdOrdersInvoiced);

    const userProfile = useSelector((state) => state.authentication.userProfile);
    const capacityReservations = useSelector((state) => state.prodplanningdata.capacityReservations);
    const dispatch = useDispatch();
    const [tab, setTab] = useState(0);
    const [viewUnInvoicedOnly, setViewUnInvoicedOnly] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        if (!openOrders && !fetchingOpen) {
            dispatch(getProdOrdersOpen());
        }
        if (!deliveredOrders && !fetchingDelivered) {
            dispatch(getProdOrdersDelivered());
        }
        if (tab === 1 && !viewUnInvoicedOnly && !invoicedOrders && !fetchingInvoiced) {
            dispatch(getProdOrdersInvoiced());
        } else if (tab === 2) {
            if (!openOrders && !fetchingOpen) {
                dispatch(getProdOrdersOpen());
            }
            if (!deliveredOrders && !fetchingDelivered) {
                dispatch(getProdOrdersDelivered());
            }
            if (!invoicedOrders && !fetchingInvoiced) {
                dispatch(getProdOrdersInvoiced());
            }
        }
    }, [
        tab,
        viewUnInvoicedOnly,
        deliveredOrders,
        invoicedOrders,
        dispatch,
        openOrders,
        fetchingOpen,
        fetchingDelivered,
        fetchingInvoiced,
    ]);

    const handleTabChange = (event, value) => {
        setTab(value);
    };

    const markOrderAsDeliveredImpl = async (orderNumber, deliveryDetails) => {
        await markOrderAsDelivered(orderNumber, deliveryDetails)(dispatch);
    };

    const getSentTabCount = () => {
        if (!deliveredOrders) return '-';
        if (deliveredOrders && viewUnInvoicedOnly) return deliveredOrders.length;
        if (deliveredOrders && invoicedOrders && !viewUnInvoicedOnly)
            return deliveredOrders.length + invoicedOrders.length;
        return '-';
    };

    const deliveredAndInvoicedOrders = deliveredOrders && invoicedOrders ? invoicedOrders.concat(deliveredOrders) : [];
    const allOrders =
        deliveredOrders && invoicedOrders && openOrders
            ? openOrders.concat(deliveredOrders).concat(invoicedOrders)
            : [];

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <AppBar position="static" color="default">
                        <Box display="flex" justifyContent="center" width="100%">
                            <Tabs
                                value={tab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto">
                                <Tab
                                    value={0}
                                    label={t('production.open') + ' (' + (openOrders ? openOrders.length : '-') + ')'}
                                />
                                <Tab value={1} label={t('production.sent') + ' (' + getSentTabCount() + ')'} />
                                <Tab value={2} label={t('production.schedule')} />
                            </Tabs>
                        </Box>
                    </AppBar>
                    <TabPanel value={tab} index={0}>
                        <ProductionOrderListView
                            productionOrders={openOrders}
                            markOrderAsDelivered={markOrderAsDeliveredImpl}
                            userProfile={userProfile}
                            history={props.history}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <FormControlLabel
                            className={classes.switch}
                            control={
                                <Switch
                                    checked={viewUnInvoicedOnly}
                                    onChange={() => setViewUnInvoicedOnly(!viewUnInvoicedOnly)}
                                    name="non-invoice-orders-only"
                                    color="primary"
                                />
                            }
                            label={t('production.showOnlyUnInvoiced')}
                        />
                        <ProductionOrderListView
                            productionOrders={viewUnInvoicedOnly ? deliveredOrders : deliveredAndInvoicedOrders}
                            markOrderAsDelivered={() => {}}
                            userProfile={userProfile}
                            history={props.history}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <ProductionPlan
                            active={tab === 2}
                            productionOrders={allOrders}
                            capacityReservations={capacityReservations}
                        />
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    );
}
