import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    details: {
        fontSize: '0.75em',
    },
    highlightProdName: {
        fontWeight: 'bold',
    },
}));

export function OrderRowNameAndDetails(props) {
    const classes = useStyles();
    const orderrow = props.orderrow;
    const mobile = props.mobile;

    return (
        <React.Fragment>
            <span className={classes.highlightProdName}>
                {orderrow.product ? orderrow.product.productName : orderrow.productBundle.name}
            </span>
            {!mobile &&
                orderrow.productBundle &&
                orderrow.productBundle.products.map((prod) => (
                    <span className={classes.details} key={prod.product.id}>
                        <br />
                        {prod.productCount} x {prod.product.productName}
                    </span>
                ))}
            {!mobile && orderrow.filterAdditionalInfo && (
                <span className={classes.details}>
                    <br />
                    <em>{orderrow.filterAdditionalInfo}</em>
                </span>
            )}
        </React.Fragment>
    );
}
