class ViewHelper {
    constructor(views) {
        this.views = views;
    }
    getViewName(id) {
        for (let viewName in this.views) {
            if (this.views[viewName] === id) {
                return viewName;
            }
        }
        // fallback to first
        return Object.keys(this.views)[0];
    }

    getViewId(hash) {
        for (let key in this.views) {
            if (hash.startsWith(key)) {
                return this.views[key];
            }
        }
        // fallback to first
        return this.views[Object.keys(this.views)[0]];
    }
}

export default ViewHelper;
