import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCustomerLocationDescriptiveInfo } from '../../modules/customerlocationdata';
import AlphabeticalLocationList from './../../location/alphabeticallocationlist';
import Spinner from '../../common/spinner';
import { Alert } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import SearchInput from '../../common/searchinput';
import { useTranslation } from 'react-i18next';

export default function CustomerLocations() {
    const [filter, setFilter] = React.useState('');
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const locations = useSelector((state) => state.customerlocationdata.locationDescriptions);
    const getLocationDescriptionsInProgress = useSelector(
        (state) => state.customerlocationdata.getLocationDescriptionsInProgress
    );
    const getLocationDescriptionsFailed = useSelector(
        (state) => state.customerlocationdata.getLocationDescriptionsFailed
    );

    useEffect(() => {
        dispatch(getCustomerLocationDescriptiveInfo());
    }, [dispatch]);

    const onFilterChange = (evt) => {
        setFilter(evt.target.value);
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <SearchInput searchInput={filter} onChange={onFilterChange} />
                </Grid>
            </Grid>
            <Grid container>
                {getLocationDescriptionsInProgress && <Spinner padding="20px" title={t('general.loading')} />}
                {!getLocationDescriptionsInProgress && !getLocationDescriptionsFailed && (
                    <AlphabeticalLocationList
                        locationDescriptions={locations}
                        filter={filter}
                        history={history}
                        emptyListText={t('customer.noLocations')}
                    />
                )}
                {!getLocationDescriptionsInProgress && getLocationDescriptionsFailed && (
                    <div style={{ padding: '20px' }}>
                        <Alert severity="error">{t('general.fetchFailedRetry')}</Alert>
                    </div>
                )}
            </Grid>
        </div>
    );
}
