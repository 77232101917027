import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { sendFeedback } from '../modules/feedbackdata';
import FeedbackItem from '../domain/feedbackitem';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function FeedbackPage() {
    const [feedback, setFeedback] = React.useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        setFeedback(value);
    };

    const onClickSend = () => {
        let fbItem = new FeedbackItem();
        fbItem.message = 'Palaute järjestelmän toiminnasta: ' + feedback;
        dispatch(sendFeedback(fbItem));
        setFeedback('');
        history.goBack();
    };

    const onClickCancel = () => {
        setFeedback('');
        history.goBack();
    };

    return (
        <div>
            <Container maxWidth="sm">
                <Grid
                    container
                    spacing={3}
                    alignItems={'flex-end'}
                    alignContent={'flex-end'}
                    justifyContent={'flex-end'}>
                    <Grid item xs={12}>
                        <h1>{t('general.feedback')}</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <em>{t('general.feedbackOnSystem')}</em>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="standard-multiline-flexible"
                            label={t('general.feedback')}
                            multiline
                            maxRows="6"
                            className={classes.root}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" className={classes.button} onClick={onClickSend}>
                            {t('buttons.send')}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={onClickCancel}>
                            {t('buttons.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
