import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextEditBlock from '../invoice/texteditblock';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    select: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

export default function AddressInput(props) {
    const classes = useStyles();
    const countryCodes = useSelector((state) => state.codedata.countryCodes);
    const postalCodes = useSelector((state) => state.postaldata.postalCodes);
    const address = props.address;
    const idBase = props.idBase ? props.idBase : '';
    const hideName = props.hideName;
    const fieldConfig = props.fieldConfig ? props.fieldConfig : {};
    const { t } = useTranslation();

    const onSelect = (_tmp, elem) => {
        if (!elem) return;
        let newAddress = _.clone(address);
        newAddress.zipCode = elem.postal_code;
        newAddress.city = elem.city_name;
        props.addressChanged(newAddress);
    };

    const onBlurZipCode = (event) => {
        let newAddress = _.clone(address);
        newAddress.zipCode = event.target.value;
        props.addressChanged(newAddress);
    };

    const onBlurCity = (event) => {
        let newAddress = _.clone(address);
        newAddress.city = event.target.value;
        props.addressChanged(newAddress);
    };

    const fixFieldConfigIfNeeded = (config) => {
        if (!config) {
            return {
                showCounter: false,
                maxLength: undefined,
            };
        }
        return config;
    };

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let newAddress = _.clone(address);

        switch (name) {
            case 'name':
                newAddress.name = value;
                break;
            case 'streetAddress':
                newAddress.streetAddress = value;
                break;
            case 'city':
                newAddress.city = value;
                break;
            case 'country':
                newAddress.country = {
                    countryId: value,
                };
                break;
            default:
                break;
        }
        props.addressChanged(newAddress);
    };

    fieldConfig['name'] = fixFieldConfigIfNeeded(fieldConfig['name']);
    fieldConfig['streetAddress'] = fixFieldConfigIfNeeded(fieldConfig['streetAddress']);
    fieldConfig['zipCode'] = fixFieldConfigIfNeeded(fieldConfig['zipCode']);
    fieldConfig['city'] = fixFieldConfigIfNeeded(fieldConfig['city']);

    return (
        <Grid container>
            <input style={{ display: 'none' }} type="text" name="fakestreetAddress" />
            <input style={{ display: 'none' }} type="text" name="fakezipCode" />
            <input style={{ display: 'none' }} type="text" name="fakecity" />
            {!hideName && (
                <TextEditBlock
                    id={idBase + 'name'}
                    name="name"
                    label={t('general.name')}
                    value={address.name ? address.name : ''}
                    onChange={handleChange}
                    number={false}
                    required={false}
                    showCounter={fieldConfig['name'].showCounter}
                    maxLength={fieldConfig['name'].maxLength}
                />
            )}

            <TextEditBlock
                id={idBase + 'streetAddress'}
                name="streetAddress"
                label={t('general.streetAddressOrPoPox')}
                value={address.streetAddress ? address.streetAddress : ''}
                onChange={handleChange}
                required={true}
                number={false}
                showCounter={fieldConfig['streetAddress'].showCounter}
                maxLength={fieldConfig['streetAddress'].maxLength}
            />

            <Grid item xs={12} md={6}>
                <Autocomplete
                    options={postalCodes ? postalCodes : []}
                    getOptionLabel={(option) => (option ? `${option.postal_code}` : '')}
                    renderOption={(option) => (
                        <span>
                            {option.postal_code} {option.city_name}
                        </span>
                    )}
                    filterOptions={createFilterOptions({
                        stringify: (option) => option.postal_code + option.city_name,
                    })}
                    id={idBase + 'zipCode'}
                    name="zipCode"
                    loading={!postalCodes}
                    freeSolo
                    loadingText={t('general.loading')}
                    onChange={onSelect}
                    value={address.zipCode ? { postal_code: address.zipCode } : { postal_code: '' }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label={t('general.zipCode')}
                            margin={'normal'}
                            name="zipCode"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                            error={!address.zipCode || address.zipCode.length !== 5}
                            className={classes.textField}
                            onBlur={(e) => onBlurZipCode(e)}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Autocomplete
                    options={postalCodes ? postalCodes : []}
                    getOptionLabel={(option) => (option ? `${option.city_name}` : '')}
                    renderOption={(option) => (
                        <span>
                            {option.postal_code} {option.city_name}
                        </span>
                    )}
                    filterOptions={createFilterOptions({
                        stringify: (option) => option.postal_code + option.city_name,
                    })}
                    id={idBase + 'city'}
                    name="city"
                    loading={!postalCodes}
                    freeSolo
                    loadingText={t('general.loading')}
                    onChange={onSelect}
                    value={address.city ? { city_name: address.city } : { city_name: '' }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label={t('general.city')}
                            margin={'normal'}
                            name="city"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                            error={!address.city || address.city.length === 0}
                            className={classes.textField}
                            onBlur={(e) => onBlurCity(e)}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <FormControl className={classes.select}>
                    <InputLabel id="country-label">{t('general.country')}</InputLabel>
                    <Select
                        required
                        labelId="country-label"
                        id={idBase + 'country'}
                        name="country"
                        value={address.country.countryId}
                        onChange={handleChange}>
                        {countryCodes.map((code) => (
                            <MenuItem key={code.countryId} value={code.countryId}>
                                {code.texts[0].text} ({code.countryShortStr2})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            {!address.isValid() && (
                <Grid item xs={12}>
                    <Alert severity="error">{t('general.addressInvalid')}</Alert>
                </Grid>
            )}
        </Grid>
    );
}

AddressInput.propTypes = {
    address: PropTypes.object,
    idBase: PropTypes.string,
    hideName: PropTypes.bool,
    fieldConfig: PropTypes.object,
    required: PropTypes.bool,
};
