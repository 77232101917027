import FilterProduct from './filterproduct';
import { round } from 'mathjs';
import { ProductBundle } from './productbundle';

class ContractPrice {
    constructor(json) {
        if (json != null) {
            this.storageId = json.storageId;
            this.contractId = json.contractId;
            this.product = json.product ? new FilterProduct(json.product) : null;
            this.productBundle = json.productBundle ? new ProductBundle(json.productBundle) : null;
            this.price = json.price;
            this.discount = json.discount;
            this.inUse = json.inUse;
        }
    }

    calculateDiscountPrice() {
        const price = this.product ? this.product.priceWithoutVat : this.productBundle.priceWithoutVat();
        return round(price * ((100 - this.discount) / 100), 3);
    }

    calculateDiscountPercent() {
        const price = this.product ? this.product.priceWithoutVat : this.productBundle.priceWithoutVat();
        return round(100 - (this.price / price) * 100, 3);
    }

    marginEur() {
        if (
            this.product &&
            this.product.productionPrice &&
            this.product.productionPrice > 0 &&
            (this.price || this.discount)
        ) {
            return !!this.price
                ? this.price - this.product.productionPrice
                : this.calculateDiscountPrice() - this.product.productionPrice;
        } else if (
            this.productBundle &&
            this.productBundle.productionPrice() &&
            this.productBundle.productionPrice() > 0 &&
            (this.price || this.discount)
        ) {
            return !!this.price
                ? this.price - this.productBundle.productionPrice()
                : this.calculateDiscountPrice() - this.productBundle.productionPrice();
        } else {
            return -1;
        }
    }

    marginPercentage() {
        if (
            this.product &&
            this.product.productionPrice &&
            this.product.productionPrice > 0 &&
            (this.price || this.discount)
        ) {
            return !!this.price
                ? ((1 - this.product.productionPrice / this.price) * 100).toFixed(2)
                : ((1 - this.product.productionPrice / this.calculateDiscountPrice()) * 100).toFixed(2);
        } else if (
            this.productBundle &&
            this.productBundle.productionPrice() &&
            this.productBundle.productionPrice() > 0 &&
            (this.price || this.discount)
        ) {
            return !!this.price
                ? ((1 - this.productBundle.productionPrice() / this.price) * 100).toFixed(2)
                : ((1 - this.productBundle.productionPrice() / this.calculateDiscountPrice()) * 100).toFixed(2);
        } else {
            return -1;
        }
    }
}

export default ContractPrice;
