import React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import { bypassReminder } from '../modules/actiondata';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    itemTitle: {
        fontSize: '1.3em',
        fontWeight: '500',
        verticalAlign: 'middle',
    },
    iconLink: {
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
}));

export default function ActionRow(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [confirmSkip, setConfirmSkip] = React.useState(false);

    const disableButtons =
        props.disableButtons != null && props.disableButtons !== undefined ? props.disableButtons : false;

    const onAddToBasket = () => {
        props.newOrderFromLocations([props.action.location.id]);
    };

    const openLocation = () => {
        props.history.push('/locations/' + props.action.location.id);
    };

    const skip = () => {
        setConfirmSkip(true);
    };

    const cancelSkip = () => {
        setConfirmSkip(false);
    };

    const doSkip = () => {
        const due = moment(props.action.due);
        setConfirmSkip(false);
        // month() + 1 --> moment().month() values 0...11.
        dispatch(bypassReminder(due.month() + 1, due.year(), props.action.location.id))
            .then(() => {})
            .catch((err) => {});
    };

    const ConfirmationDialog = () => {
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
        if (confirmSkip)
            return (
                <Dialog
                    onClose={cancelSkip}
                    aria-labelledby="simple-dialog-title"
                    open={confirmSkip}
                    fullWidth={true}
                    maxWidth={'md'}
                    fullScreen={fullScreen}>
                    <DialogContent dividers>{t('frontpage.confirmBypassReminder')}</DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            name="btn-modal-poista"
                            id="modal-poista"
                            onClick={doSkip}>
                            {t('buttons.bypass')}
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            variant="contained"
                            color="default"
                            name="btn-modal-peruuta"
                            id="modal-peruuta"
                            onClick={cancelSkip}>
                            {t('buttons.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        return null;
    };

    let action = props.action;

    return (
        <Grid container spacing={1} justifyContent={'space-between'} className="action-item-row" alignItems="center">
            <Grid item>
                {action.customer != null && props.showCustomer && (
                    <span className={classes.itemTitle}>
                        {action.customer.name}
                        <br />
                    </span>
                )}
                {action.location != null && (
                    <React.Fragment>
                        <OpenInNewIcon name={'openlocation'} className={classes.iconLink} onClick={openLocation} />
                        <span className={classes.itemTitle}>{action.location.name}</span>
                    </React.Fragment>
                )}
            </Grid>
            {action.completed != null && <Grid item>{moment(action.completed).format('DD.MM.YYYY HH:mm')}</Grid>}
            {action.completed == null && (
                <Grid item>
                    {disableButtons && (
                        <Grid item>
                            <Button variant="contained" name="btn-skip" color="primary" disabled>
                                <SkipNextIcon />
                                &nbsp;{t('buttons.bypass')}
                            </Button>
                            <Button variant="contained" name="btn-order" color="primary" disabled>
                                <AddShoppingCartIcon />
                                &nbsp;{t('buttons.addToCart')}
                            </Button>
                        </Grid>
                    )}

                    {!disableButtons && (
                        <Grid item container spacing={1}>
                            <Grid item>
                                <Button variant="contained" name="btn-skip" color="primary" onClick={skip}>
                                    <SkipNextIcon />
                                    &nbsp;{t('buttons.bypass')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" name="btn-order" color="primary" onClick={onAddToBasket}>
                                    <AddShoppingCartIcon />
                                    &nbsp;{t('buttons.addToCart')}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
            <ConfirmationDialog />
        </Grid>
    );
}
