import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';
import CapacityReservation from '../domain/capacityreservation';
import CapacityAllocation from '../domain/capacityallocation';
import moment from 'moment';
import { groupBy, cloneDeep } from 'lodash';
import {
    formatWeekIdentifierInt,
    formatWeekIdentifierMoment,
    generateContinuousWeekList,
} from '../order/capacityplanner';

export const PROD_CAPACITY_DATA_LOADED = 'prodplanning/capacitydataloaded';
export const PROD_CAPACITY_DATA_LOADING = 'prodplanning/capacitydataloading';
export const CAPACITY_RESERVATIONS_LOADED = 'prodplanning/capacityreservationsloaded';
export const CAPACITY_RESERVATIONS_LOADING = 'prodplanning/capacityreservationsloading';
export const CAPACITY_RESERVATIONS_SAVING = 'prodplanning/capacityreservationssaving';
export const CAPACITY_RESERVATIONS_SAVED = 'prodplanning/capacityreservationssaved';
export const CAPACITY_RESERVATIONS_SAVE_FAIL = 'prodplanning/capacityreservationssavefail';

export const CAPACITY_ALLOCATIONS_SAVING = 'prodplanning/capacityallocationssaving';
export const CAPACITY_ALLOCATIONS_SAVED = 'prodplanning/capacityallocationssaved';
export const CAPACITY_ALLOCATIONS_SAVE_FAIL = 'prodplanning/capacityallocationssavefail';

const initialState = {
    capacityAllocationsByWeek: {},
    loadingCapacityData: false,
    capacityReservations: null,
    loadingCapacityReservations: false,
    savingCapacityReservations: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PROD_CAPACITY_DATA_LOADING:
            return {
                ...state,
                loadingCapacityData: true,
            };
        case PROD_CAPACITY_DATA_LOADED: {
            const allocations = action.data.map((a) => {
                return new CapacityAllocation(a);
            });

            // Täytä aukot tyhjällä taulukolla
            // jos datassa ei ole jokaiselle viikolla jotain dataa, täytetään aukot tyhjällä taulukolla
            // indikoi että data haettu mutta sitä ei ole.
            const storeData = cloneDeep(state.capacityAllocationsByWeek);
            const expectedWeeks = generateContinuousWeekList(action.startWeek, action.endWeek);
            const grouped = groupBy(allocations, (item) => `${formatWeekIdentifierInt(item.year, item.week)}`);
            for (const w of expectedWeeks) {
                storeData[w] = grouped[w] ? grouped[w] : [];
            }

            return {
                ...state,
                loadingCapacityData: false,
                capacityAllocationsByWeek: storeData,
            };
        }
        case CAPACITY_RESERVATIONS_LOADING:
            return {
                ...state,
                loadingCapacityReservations: true,
            };
        case CAPACITY_RESERVATIONS_LOADED: {
            const reservations = action.reservations.map((r) => {
                return new CapacityReservation(r);
            });
            return {
                ...state,
                loadingCapacityReservations: false,
                capacityReservations: reservations,
            };
        }
        case CAPACITY_RESERVATIONS_SAVING:
            return {
                ...state,
                savingCapacityReservations: true,
            };
        case CAPACITY_RESERVATIONS_SAVED: {
            return {
                ...state,
                savingCapacityReservations: false,
            };
        }
        case CAPACITY_RESERVATIONS_SAVE_FAIL:
            return {
                ...state,
                savingCapacityReservations: false,
            };
        case CAPACITY_ALLOCATIONS_SAVING:
        case CAPACITY_ALLOCATIONS_SAVE_FAIL:
            return state;
        case CAPACITY_ALLOCATIONS_SAVED: {
            const storeData = cloneDeep(state.capacityAllocationsByWeek);
            const allocations = action.data.map((a) => {
                return new CapacityAllocation(a);
            });
            for (const a of allocations) {
                const week = storeData[formatWeekIdentifierInt(a.year, a.week)];
                const existingInd = week.findIndex((alloc) => alloc.portfolioId === a.portfolioId);
                if (existingInd !== -1) {
                    week.splice(existingInd, 1);
                }
                week.push(a);
            }
            return {
                ...state,
                loadingCapacityData: false,
                capacityAllocationsByWeek: storeData,
            };
        }
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const loadCapacityReservations = () => {
    return async (dispatch) => {
        dispatch({
            type: CAPACITY_RESERVATIONS_LOADING,
        });
        try {
            const start = moment().add(-2, 'month');
            const end = moment().add(12, 'month');
            const resp = await Ajax.get(`api/prodcapacity/reservations/${start.format()}/${end.format()}`);
            dispatch({
                type: CAPACITY_RESERVATIONS_LOADED,
                reservations: resp.data,
            });
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

export const loadCapacityAllocations = (start, end) => {
    return async (dispatch) => {
        dispatch({
            type: PROD_CAPACITY_DATA_LOADING,
        });
        try {
            let resp;
            const _start = moment().add(-2, 'month');
            const _end = moment().add(12, 'month');
            if (start && end) {
                resp = await Ajax.get(`api/prodcapacity/allocations/${start.format()}/${end.format()}`);
            } else {
                resp = await Ajax.get(`api/prodcapacity/allocations/${_start.format()}/${_end.format()}`);
            }

            dispatch({
                type: PROD_CAPACITY_DATA_LOADED,
                data: resp.data,
                startWeek: start ? formatWeekIdentifierMoment(start) : formatWeekIdentifierMoment(_start),
                endWeek: end ? formatWeekIdentifierMoment(end) : formatWeekIdentifierMoment(_end),
            });
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

export const saveCapacityReservations = (reservations) => {
    return async (dispatch) => {
        dispatch({
            type: CAPACITY_RESERVATIONS_SAVING,
        });
        try {
            await Ajax.post('api/prodcapacity/reservations/', reservations);
            loadCapacityReservations()(dispatch);
            dispatch({
                type: CAPACITY_RESERVATIONS_SAVED,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: CAPACITY_RESERVATIONS_SAVE_FAIL,
            });
            return false;
        }
    };
};

export const saveCapacityAllocations = (allocations) => {
    return async (dispatch) => {
        dispatch({
            type: CAPACITY_ALLOCATIONS_SAVING,
        });
        try {
            await Ajax.post('api/prodcapacity/allocations/', allocations);
            dispatch({
                type: CAPACITY_ALLOCATIONS_SAVED,
                data: allocations,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: CAPACITY_ALLOCATIONS_SAVE_FAIL,
            });
            return false;
        }
    };
};

export const removeCapacityReservationsForOrder = (orderId) => {
    return async (dispatch) => {
        dispatch({
            type: CAPACITY_RESERVATIONS_SAVING,
        });
        try {
            await Ajax.delete(`api/prodcapacity/reservations/${orderId}`);
            loadCapacityReservations()(dispatch);
            dispatch({
                type: CAPACITY_RESERVATIONS_SAVED,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: CAPACITY_RESERVATIONS_SAVE_FAIL,
            });
            return false;
        }
    };
};
