import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listPortfolios } from '../modules/portfoliodata';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { CircularProgress } from '@material-ui/core';
import Footer from '../main/footer';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        width: '100%',
        marginBottom: '20px',
    },
    tablerow: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'lightgray',
            textDecoration: 'underline',
        },
    },
    paper: {
        padding: '5px',
    },
}));

export default function PortfolioManagementView(props) {
    const classes = useStyles();
    const portfolios = useSelector((state) => state.portfoliodata.portfolios);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.active && (!portfolios || portfolios.length === 0)) {
            dispatch(listPortfolios());
        }
    }, [props.active, dispatch, portfolios]);

    const openPortfolio = (portfolioId) => {
        props.history.push('/admin/portfolio/' + portfolioId);
    };

    const onCreate = () => {
        props.history.push('/admin/portfolio/edit/new');
    };

    if (!portfolios || portfolios.length === 0) {
        return (
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    {t('general.retrievingInformation')}
                    <CircularProgress color={'secondary'} size={'1rem'} />
                </Paper>
            </Grid>
        );
    }

    return (
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <h4>Salkut</h4>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('general.portfolio')}</TableCell>
                            <TableCell>{t('admin.users')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {portfolios.length > 0 && (
                        <TableBody>
                            {portfolios.map((portfolio) => (
                                <TableRow
                                    className={classes.tablerow}
                                    key={portfolio.id}
                                    onClick={() => openPortfolio(portfolio.id)}>
                                    <TableCell>{portfolio.name}</TableCell>
                                    <TableCell>{portfolio.users.map((puser) => puser.user.name).join(', ')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </Paper>
            <Footer>
                <Grid item>
                    <Button variant="contained" color="primary" name="newPortfolio" onClick={onCreate}>
                        <i className="fas fa-file"></i>
                        &nbsp;{t('admin.newPortfolio')}
                    </Button>
                </Grid>
            </Footer>
        </Grid>
    );
}
