import React, { useEffect } from 'react';
import moment from 'moment';
import ActionsmonthView from './actionsmonth';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { monthNumberToText } from '../common/common';
import { groupBy } from 'lodash';

const Home = (props) => {
    const { t } = useTranslation();
    const { getOverdueActionData, getActionDataByMonthYear } = props;

    useEffect(() => {
        let start = moment();
        if (props.authenticated && props.userProfile.isSalesUser) {
            getActionDataByMonthYear(start.format('M'), start.format('YYYY'), 1);
            getOverdueActionData();
        }
    }, [props.authenticated, props.userProfile.isSalesUser, getOverdueActionData, getActionDataByMonthYear]);

    const getCurrentMonthData = (actions) => {
        let result = [];
        for (let act of actions) {
            let dueDate = moment(act.due);
            let minDate = moment().date(1).hour(0).minute(0);
            let maxDate = moment().date(1).hour(0).minute(0).add(1, 'M');
            if (dueDate.isSameOrAfter(minDate) && dueDate.isBefore(maxDate)) {
                result.push(act);
            }
        }
        return result;
    };

    const getNextMonthData = (actions) => {
        let result = [];
        for (let act of actions) {
            let dueDate = moment(act.due);
            let minDate = moment().date(1).hour(0).minute(0).add(1, 'M');
            let maxDate = moment().date(1).hour(0).minute(0).add(2, 'M');
            if (dueDate.isSameOrAfter(minDate) && dueDate.isBefore(maxDate)) {
                result.push(act);
            }
        }
        return result;
    };

    const getSales = (actions) => {
        let total = 0;
        let deals = 0;
        for (let action of actions) {
            total += parseFloat(action.locationOrderValue);
            if (action.completed != null) {
                deals += parseFloat(action.locationOrderValue);
            }
        }
        return { potential: total, actual: deals };
    };

    const getByCustomerList = (actions) => {
        let customers = [];
        let orphans = [];
        for (let act of actions) {
            if (act.completed != null) continue;
            if (act.customer != null) {
                let cus = customers.find((c) => c.id === act.customer.id);
                if (cus != null) {
                    cus.locationCount++;
                    cus.actionList.push(act);
                } else {
                    //create new customer to list
                    cus = act.customer;
                    cus.locationCount = 1;
                    cus.actionList = [];
                    cus.actionList.push(act);
                    customers.push(cus);
                }
            } else {
                orphans.push(act);
            }
        }
        customers.sort((a, b) => parseInt(a.locationCount, 10) - parseInt(b.locationCount, 10)).reverse();
        return { withcustomer: customers, nocustomer: orphans };
    };

    const getMonthName = () => {
        return monthNumberToText(moment().month() + 1);
    };

    const getNextMonthName = () => {
        return monthNumberToText(moment().add(1, 'month').month() + 1);
    };

    // key : 2022-01 --> '2023 Tammikuu
    const getOverdueMonthNameFromKey = (key) => {
        const year = key.substring(0, 4);
        const month = monthNumberToText(parseInt(key.substring(5)));
        return year + ' ' + month;
    };

    if (props.fetching === true) return null;
    if (!props.userProfile.isSalesUser) {
        if (props.userProfile.isProductionUser) {
            return (
                <div style={{ padding: '20px' }}>
                    <Alert severity="info">Tuotanto</Alert>
                </div>
            );
        } else if (props.userProfile.isInvoicingUser) {
            return (
                <div style={{ padding: '20px' }}>
                    <Alert severity="info">Laskutus</Alert>
                </div>
            );
        }
    }
    if (
        (props.actions == null || props.actions.length === 0) &&
        (props.overdueActions == null || props.overdueActions.length === 0)
    ) {
        return (
            <div style={{ padding: '20px' }}>
                <Alert severity="info">{t('frontpage.noReminders')}</Alert>
            </div>
        );
    } else {
        const current = getCurrentMonthData(props.actions);
        const sales = getSales(current);
        const cusByLocCount = getByCustomerList(current);

        const next = getNextMonthData(props.actions);
        const nextSales = getSales(next);
        const nextCusByLocCount = getByCustomerList(next);

        const overdueGrouped =
            props.overdueActions && props.overdueActions.length > 0
                ? groupBy(props.overdueActions, (a) => moment(a.due).format('YYYY-MM'))
                : {};

        return (
            <div>
                <div>
                    {Object.keys(overdueGrouped).length > 0 &&
                        Object.keys(overdueGrouped).map((om) => (
                            <ActionsmonthView
                                monthname={t('frontpage.expiredReminders') + ', ' + getOverdueMonthNameFromKey(om)}
                                actions={overdueGrouped[om]}
                                sales={getSales(overdueGrouped[om])}
                                cusByLocCount={getByCustomerList(overdueGrouped[om])}
                                expanded={false}
                                newOrderFromLocations={props.newOrderFromLocations}
                                warning={true}
                                showProgress={false}
                                history={props.history}
                            />
                        ))}
                    <ActionsmonthView
                        monthname={getMonthName()}
                        actions={current}
                        sales={sales}
                        cusByLocCount={cusByLocCount}
                        pendingOrders={props.pendingOrders}
                        newOrderFromLocations={props.newOrderFromLocations}
                        expanded={true}
                        showProgress={true}
                        history={props.history}
                    />

                    <ActionsmonthView
                        monthname={getNextMonthName()}
                        actions={next}
                        sales={nextSales}
                        cusByLocCount={nextCusByLocCount}
                        pendingOrders={props.pendingOrders}
                        newOrderFromLocations={props.newOrderFromLocations}
                        expanded={false}
                        showProgress={true}
                        history={props.history}
                    />
                </div>
            </div>
        );
    }
};

export default Home;
