import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import User from '../domain/user';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import {
    verifyEmailAddress,
    verifyPasswordStrength,
    pseudoRandomPasswordGenerate,
    verifyPhonenumber,
} from '../common/common';
import { saveNewUser, checkUsernameAvailability } from '../modules/userdataactions';
import { toast } from 'react-toastify';
import EmailSender from '../modules/emailsender';
import CONFIG from '../config';
import RepresentationsList from './representationslist';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    formControl: {
        margin: theme.spacing(1),
        width: '95%',
    },
    stepContent: {
        margin: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Käyttäjätyyppi', 'Perustiedot', 'Salasana', 'Tallennus', 'Valmis'];
}

export default function NewUserView(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = React.useState(0);
    const [newUser, setNewUser] = React.useState(new User());
    const [userType, setUserType] = React.useState(0);
    const [tempPassword, setTempPassword] = React.useState('');
    const [saving, setSaving] = React.useState(false);
    const [checkingUsernameAvailability, setCheckingUsernameAvailability] = React.useState(false);
    const [usernameAvailable, setUsernameAvailable] = React.useState(true);
    const steps = getSteps();
    const rolesForOrgUsers = User.RolesForOrgUsers();
    const rolesForCustomers = User.RolesForCcUsers();
    const { t } = useTranslation();
    let availableRoles = null;

    const handleNext = async () => {
        if (activeStep < 3) {
            if (activeStep === 1) {
                setCheckingUsernameAvailability(true);
                setUsernameAvailable(true);
                const resp = await checkUsernameAvailability(newUser.username, userType)(dispatch);

                setCheckingUsernameAvailability(false);
                if (resp) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setUsernameAvailable(true);
                } else {
                    setUsernameAvailable(false);
                }
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep === 3) {
            toast.info(t('toast.savingUserInfo'), { autoClose: 1500, hideProgressBar: true });
            setSaving(true);
            const resp = await saveNewUser(newUser, userType, tempPassword)(dispatch);
            if (resp) {
                toast.info(t('toast.userInfoSaved'), { autoClose: 1500, hideProgressBar: true });
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSaving(false);
            } else {
                toast.error(t('toast.savingUserInfoFailed'), { autoClose: 5000, hideProgressBar: false });
                setSaving(false);
            }
        } else if (activeStep === 4) {
            setSaving(true);
            await EmailSender.send(
                'newuser',
                {
                    USERNAME: newUser.username,
                    PASSWORD: tempPassword,
                    URL: userType === 0 ? CONFIG.frontendUrl : CONFIG.frontendUrlCustomer,
                },
                newUser.username,
                'Uusi käyttäjätunnus'
            );
            props.history.goBack();
        } else {
            throw new Error('');
        }
    };
    const close = () => {
        props.history.goBack();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (evt) => {
        const value = evt.target.value;
        const name = evt.target.name;
        let user = new User(newUser);
        switch (name) {
            case 'rolesselect':
                user.roles = [];
                for (let r of value) {
                    user.roles.push(r);
                }
                break;
            case 'username':
                user.username = value;
                break;
            case 'name':
                user.name = value;
                break;
            case 'phonenumber':
                user.phoneNumber = value.trim();
                break;
            case 'password':
                setTempPassword(value);
                return;
            default:
                break;
        }
        setNewUser(user);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return userTypeInput();
            case 1:
                return userInfo();
            case 2:
                return temporaryPassword();
            case 3:
                return review();
            case 4:
                return ready();
            default:
                return 'Unknown step';
        }
    };

    const changeUserType = (event) => {
        setUserType(parseInt(event.target.value));
    };

    const ShowUserType = () => {
        const typeStr = userType === 0 ? t('admin.vadoUser') : t('admin.contractCustomer');
        return (
            <Grid item xs={12}>
                {t('admin.userType')}: {typeStr}
            </Grid>
        );
    };

    const ShowUserInfo = () => {
        return (
            <Grid item container>
                <Grid item xs={12}>
                    {t('login.username')}: {newUser.username}
                </Grid>
                <Grid item xs={12}>
                    {t('general.name')}: {newUser.name}
                </Grid>
                <Grid item xs={12}>
                    {t('general.phone')}: {newUser.phoneNumber}
                </Grid>
                <Grid item xs={12}>
                    {t('admin.roles')}:{' '}
                    {newUser.roles.map((r) => (
                        <span key={r}>&nbsp;{r}</span>
                    ))}
                </Grid>
                {userType === 1 && (
                    <Grid container>
                        <Grid item xs={6}>
                            {t('admin.represents')}
                        </Grid>
                        <Grid item xs={6}>
                            <RepresentationsList allowEdit={false} representations={newUser.representations} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    };

    const ShowTempPassword = () => {
        return (
            <Grid item xs={12}>
                {t('admin.temporaryPassword')}: {tempPassword}
            </Grid>
        );
    };

    const ready = () => {
        return (
            <Grid item container>
                <Grid item xs={12}>
                    {t('admin.userSaved')} <em>{t('admin.sendEmail')}</em>
                </Grid>
                <ShowUserType />
                <ShowUserInfo />
                <ShowTempPassword />
            </Grid>
        );
    };

    const review = () => {
        return (
            <Grid item container>
                <ShowUserType />
                <ShowUserInfo />
                <ShowTempPassword />
            </Grid>
        );
    };

    const generatePassword = () => {
        setTempPassword(pseudoRandomPasswordGenerate(1, 7, 1));
    };

    const temporaryPassword = () => {
        return (
            <Grid item container>
                <ShowUserType />
                <ShowUserInfo />
                <Grid item xs={12}>
                    <TextField
                        required
                        error={!verifyPasswordStrength(tempPassword)}
                        id="password"
                        name="password"
                        label={t('login.password')}
                        value={tempPassword}
                        className={classes.textField}
                        margin="normal"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        name="generate-password"
                        onClick={generatePassword}
                        className={classes.button}>
                        {t('admin.generate')}
                    </Button>
                </Grid>
            </Grid>
        );
    };

    const userTypeInput = () => {
        return (
            <Grid item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">{t('admin.userType')}</FormLabel>
                    <RadioGroup aria-label="user-type" name="user-type" value={userType} onChange={changeUserType}>
                        <FormControlLabel value={0} control={<Radio />} label={t('admin.vadoUser')} />
                        <FormControlLabel value={1} control={<Radio />} label={t('admin.contractCustomer')} />
                    </RadioGroup>
                </FormControl>
            </Grid>
        );
    };

    const representationListUpdated = (newRep) => {
        const user = new User(newUser);
        user.representations = newRep;
        setNewUser(user);
    };

    const userInfo = () => {
        return (
            <Grid container item>
                <ShowUserType />

                {userType === 1 && (
                    <Grid item container>
                        <Grid item xs={6}>
                            <strong>{t('admin.represents')}</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <RepresentationsList
                                allowEdit={true}
                                representations={newUser.representations}
                                representationsChanged={representationListUpdated}
                            />
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        error={!verifyEmailAddress(newUser.username)}
                        id="username"
                        name="username"
                        label={t('login.username')}
                        value={newUser.username}
                        className={classes.textField}
                        margin="normal"
                        onChange={handleChange}
                    />
                    {!usernameAvailable && <Alert severity="warning">Käyttäjänimi on jo käytössä</Alert>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        error={false}
                        id="name"
                        name="name"
                        label={t('general.name')}
                        value={newUser.name}
                        className={classes.textField}
                        margin="normal"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        error={newUser.phoneNumber == null || !verifyPhonenumber(newUser.phoneNumber)}
                        id="phonenumber"
                        name="phonenumber"
                        label={t('general.phone')}
                        value={newUser.phoneNumber}
                        className={classes.textField}
                        margin="normal"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="rolesLabel">{t('admin.roles')}</InputLabel>
                        <Select
                            data-testid="roleselect"
                            labelId="rolesLabel"
                            id="rolesselect"
                            name="rolesselect"
                            multiple
                            value={newUser.roles.map((role) => role)}
                            onChange={handleChange}
                            input={<Input id="selectRoles" />}
                            renderValue={() => (
                                <div className={classes.chips}>
                                    {newUser.roles.map((role) => (
                                        <Chip key={role} label={role} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}>
                            {availableRoles.map((role) => (
                                <MenuItem key={role} value={role}>
                                    {role}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    };

    const okToNextStep = () => {
        switch (activeStep) {
            case 0:
                return true;
            case 1:
                return (
                    verifyEmailAddress(newUser.username) &&
                    newUser.name.length > 3 &&
                    verifyPhonenumber(newUser.phoneNumber) &&
                    newUser.roles.length > 0 &&
                    (userType === 0 || (userType === 1 && newUser.representations.length > 0))
                );
            case 2:
                return verifyPasswordStrength(tempPassword);
            case 3:
                return true;
            case 4:
                return true;
            default:
                throw new Error();
        }
    };

    if (userType === 0) availableRoles = rolesForOrgUsers;
    else availableRoles = rolesForCustomers;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <div className={classes.stepContent}>{getStepContent(activeStep)}</div>
                    <div>
                        <Button
                            disabled={activeStep === 0 || activeStep === 4 || saving || checkingUsernameAvailability}
                            onClick={handleBack}
                            className={classes.button}>
                            {t('buttons.back')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={!okToNextStep() || checkingUsernameAvailability || saving}
                            className={classes.button}
                            data-testid="btn-next"
                            name="btn-next">
                            {activeStep === 4 && (
                                <span>
                                    {saving && <i className="fas fa-spinner fa-spin"></i>}&nbsp;{t('admin.sendEmail')}
                                </span>
                            )}
                            {activeStep === 3 && (
                                <span>
                                    {saving && <i className="fas fa-spinner fa-spin"></i>}&nbsp;{t('buttons.save')}
                                </span>
                            )}
                            {activeStep < 3 && (
                                <span>
                                    {checkingUsernameAvailability && <i className="fas fa-spinner fa-spin"></i>}&nbsp;Ok
                                </span>
                            )}
                        </Button>
                        &nbsp;
                        {activeStep === 4 && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={close}
                                disabled={saving}
                                className={classes.button}
                                name="btn-close">
                                {t('buttons.close')}
                            </Button>
                        )}
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
