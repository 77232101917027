import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { updateFooterHeight } from '../modules/appstate';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        padding: 10,
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: theme.palette.primary.light,
        webkitBoxShadow: '0px -5px 5px -1px rgba(0,0,0,0.2)',
        mozBoxShadow: '0px -5px 5px -1px rgba(0,0,0,0.2)',
        boxShadow: '0px -5px 5px -1px rgba(0,0,0,0.2)',
    },
}));

const Footer = (props) => {
    const classes = useStyles();
    const ref = useRef(null);

    useEffect(() => {
        props.updateFooterHeight(ref.current.clientHeight);

        return function cleanup() {
            props.updateFooterHeight(0);
        };
    });

    return (
        <div ref={ref} className={classes.root}>
            <Grid container justifyContent="center" spacing={1}>
                {props.children}
            </Grid>
        </div>
    );
};

const mapDispatchToProps = { updateFooterHeight };
export default connect(null, mapDispatchToProps)(Footer);
