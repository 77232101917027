import React from 'react';
import { Grid, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { withStyles } from '@material-ui/core/styles';
import { TREE_FOLDER_OPENED, TREE_FOLDER_CLOSED, TREE_SCROLL_Y_POS_UPDATE } from '../modules/customerdata';
import WarningIcon from '@material-ui/icons/Warning';
import Chip from '@material-ui/core/Chip';
import i18next from 'i18next';
import { formatCurrency } from '../common/common';

function t(key) {
    return i18next.t(key) ? i18next.t(key) : key;
}

const styles = (theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    warning: {
        color: 'orange',
        verticalAlign: 'middle',
        display: 'inline-flex',
        fontSize: '0.9em',
    },
});

const getPaddingLeft = (level, type) => {
    let paddingLeft = level * 20;
    if (type === 'file') paddingLeft += 20;
    return paddingLeft;
};

class TreeItem extends React.Component {
    constructor(props) {
        super(props);
        this.onNewFolder = this.onNewFolder.bind(this);
        this.onEditFolder = this.onEditFolder.bind(this);
        this.onRemoveFolder = this.onRemoveFolder.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDrop = this.onDrop.bind(this);

        this.state = {
            dragOver: false,
        };
        this.leaveTimer = null;
    }

    onDragEnter = (ev) => {
        this.setState({ ...this.state, dragOver: true });
        ev.preventDefault();
    };

    onDragOver = (ev) => {
        if (!this.state.dragOver) this.setState({ ...this.state, dragOver: true });
        clearTimeout(this.leaveTimer);
        ev.preventDefault();
    };

    onDragLeave = (ev) => {
        let _this = this;
        this.leaveTimer = setTimeout(() => {
            _this.setState({ ...this.state, dragOver: false });
        }, 100);
        ev.preventDefault();
    };

    onDrop(ev) {
        this.setState({ ...this.state, dragOver: false });
        if (this.props.type === 'file') return;
        let idCombo = ev.dataTransfer.getData('id');
        //split the id combo, locId_custId
        let ids = idCombo.split('_');
        if (parseInt(ids[1], 10) === this.props.customerId) {
            this.props.onMoveItem(parseInt(ids[0]), this.props.id);
        } else {
            console.log('not allowed to move');
        }
    }

    onDragStart(ev, id, custId) {
        let idCombo = id + '_' + custId;
        ev.dataTransfer.setData('id', idCombo);
    }

    async onNewFolder() {
        await this.loadCustomerDataIfNeeded();
        this.props.onNewFolder(this.props.treedata.id);
    }

    async onEditFolder() {
        await this.loadCustomerDataIfNeeded();
        this.props.onRenameFolder(this.props.treedata.id);
    }

    async onRemoveFolder() {
        await this.loadCustomerDataIfNeeded();
        this.props.onRemoveFolder(this.props.treedata.id);
    }

    getClass = () => {
        if (this.state.dragOver && this.props.type !== 'file') return 'treenode-on-drag-over-drop-allowed';
        else if (this.state.dragOver && this.props.type === 'file') return 'treenode-on-drag-over-drop-blocked';

        return '';
    };

    getIconClass = () => {
        if (this.props.type === 'file') {
            return 'fas fa-th-large';
        }
        if (!!this.props.treedata && this.props.treedata.open) {
            return 'fas fa-folder-open';
        } else {
            return 'fas fa-folder';
        }
    };

    getOrderValue = () => {
        if (this.props.orderValue >= 0) {
            return formatCurrency(this.props.orderValue);
        }
    };

    isAllowedToOpenLocation = (location) => {
        return this.props.userPortfolios && this.props.userPortfolios.find((p) => p.id === location.portfolio.id);
    };

    onClick = async (e) => {
        e.stopPropagation();

        await this.loadCustomerDataIfNeeded();

        if (this.props.type === 'file') {
            if (!this.isAllowedToOpenLocation(this.props.location)) {
                // alert('not allowed');
            } else {
                this.props.store.dispatch({ type: TREE_SCROLL_Y_POS_UPDATE, scrollY: window.scrollY });
                this.props.history.push('/locations/' + this.props.id);
            }
        } else {
            const action = this.props.treedata.open ? TREE_FOLDER_CLOSED : TREE_FOLDER_OPENED;
            this.props.store.dispatch({ type: action, folderId: this.props.id, customerId: this.props.customerId });
        }
    };

    onAddToCart = async (e, id) => {
        e.stopPropagation();

        await this.loadCustomerDataIfNeeded();

        const userPortfolioIds = this.props.userPortfolios.map((p) => p.id);
        let locIds = [];
        if (this.props.type === 'file') {
            if (this.isAllowedToOpenLocation(this.props.location)) {
                locIds = [id];
            }
        } else {
            const locations = this.getAllLocationsFromFromFolder(id);
            locIds = locations
                .filter((loc) => loc.portfolio && userPortfolioIds.includes(loc.portfolio.id))
                .map((loc) => loc.id);
        }
        if (locIds.length > 0) this.props.addToCart(locIds);
    };

    getAllLocationsFromFromFolder = (folderId) => {
        //scoop up all locations in this folder and sub folders
        let targetFol = this.findFolderFromTree(this.props.treedata, folderId);
        let locs = [];
        locs = this.collectLocs(targetFol, locs);
        return locs;
    };

    findFolderFromTree = (node, folId) => {
        let targetFol = null;
        if (node.id === folId) targetFol = node;
        if (targetFol == null && node.childFolders.length > 0) {
            for (let i = 0; i < node.childFolders.length; i++) {
                let retFol = this.findFolderFromTree(node.childFolders[i], folId);
                if (retFol != null) {
                    targetFol = retFol;
                    break;
                }
            }
        }
        return targetFol;
    };

    collectLocs = (node, collection) => {
        if (node.locations != null && node.locations.length > 0) {
            for (let loc of node.locations) {
                collection.push(loc);
            }
        }
        if (node.childFolders != null && node.childFolders.length > 0) {
            for (let cf of node.childFolders) {
                this.collectLocs(cf, collection);
            }
        }
        return collection;
    };

    onNewLocation = async () => {
        await this.loadCustomerDataIfNeeded();
        this.props.onNewLocation(this.props.treedata.id);
    };

    loadCustomerDataIfNeeded = async () => {
        if (!this.props.treedata) {
            await this.props.getCustomerFull(this.props.customerId);
        }
    };

    render() {
        let draggable = false;
        let hasLocations = false;
        let hasChildFolders = false;
        const open = this.props.treedata != null && this.props.treedata.open;
        if (this.props.editMode) {
            draggable = this.props.type === 'file' ? 'true' : 'false';
        }
        if (
            this.props.treedata != null &&
            this.props.treedata.locations != null &&
            this.props.treedata.locations.length > 0
        ) {
            hasLocations = true;
        }
        if (
            this.props.treedata != null &&
            this.props.treedata.childFolders != null &&
            this.props.treedata.childFolders.length > 0
        ) {
            hasChildFolders = true;
        }
        const locationAllowedForUser = this.props.location ? this.isAllowedToOpenLocation(this.props.location) : true;

        return (
            <React.Fragment>
                <Grid container className="treenode-row">
                    <Grid item xs={6}>
                        <div
                            className="treenode"
                            style={{
                                paddingLeft: getPaddingLeft(this.props.level, this.props.type),
                            }}
                            draggable={draggable}
                            onDragStart={(e) => this.onDragStart(e, this.props.id, this.props.customerId)}
                            onDragEnter={(e) => this.onDragEnter(e)}
                            onDragLeave={(e) => this.onDragLeave(e)}
                            onDragOver={(e) => this.onDragOver(e)}
                            onDrop={(e) => this.onDrop(e)}
                            onClick={this.onClick}
                            key={this.props.id}
                            name={'treenode' + this.props.id}>
                            {this.state.dragOver && this.props.type === 'file' && (
                                <div className="drag-blocked-icon">
                                    <i style={{ color: 'red' }} className="fas fa-ban"></i>
                                </div>
                            )}
                            {this.props.type !== 'file' && (
                                <div style={{ cursor: 'pointer' }}>
                                    {open && <i className="fas fa-chevron-up"></i>}
                                    {!open && <i className="fas fa-chevron-down"></i>}
                                </div>
                            )}
                            &nbsp;
                            <div key={this.getIconClass()}>
                                <i className={this.getIconClass()} style={{ color: '#b1cdbb' }}></i>
                                &nbsp;
                            </div>
                            <div className={this.getClass()}>
                                {this.props.name}&nbsp;
                                {!!this.props.showWarning && (
                                    <WarningIcon className={this.props.classes.warning} name="warningIcon" />
                                )}
                                {this.props.nameSubTxt && <i>({this.props.nameSubTxt})&nbsp;</i>}
                                {this.getOrderValue()}
                            </div>
                        </div>
                    </Grid>

                    {this.props.editMode === false && (
                        <Grid item xs={6} onClick={this.onClick}>
                            <Grid
                                container
                                alignItems={'flex-end'}
                                alignContent={'flex-end'}
                                justifyContent={'flex-end'}>
                                {this.props.location && (
                                    <Grid item style={{ paddingBottom: '8px' }}>
                                        <Chip variant="outlined" label={this.props.location.portfolio.name} />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button
                                        size={'small'}
                                        name={this.props.type + this.props.id}
                                        variant="contained"
                                        color="primary"
                                        disabled={!locationAllowedForUser}
                                        onClick={(e) => this.onAddToCart(e, this.props.id)}
                                        className={this.props.classes.button}>
                                        <AddShoppingCartIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {this.props.type === 'folder' && this.props.editMode === true && (
                        <Grid item xs={6}>
                            <Grid
                                container
                                alignItems={'flex-end'}
                                alignContent={'flex-end'}
                                justifyContent={'flex-end'}>
                                <Grid item>
                                    <Button
                                        name={'editfolder' + this.props.id}
                                        size={'small'}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.onEditFolder}
                                        startIcon={<EditIcon />}
                                        className={this.props.classes.button}>
                                        {t('buttons.edit')}
                                    </Button>

                                    <Button
                                        name={'addfolder' + this.props.id}
                                        size={'small'}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.onNewFolder}
                                        startIcon={<AddIcon />}
                                        className={this.props.classes.button}>
                                        {t('general.folder')}
                                    </Button>

                                    <Button
                                        name={'addlocation' + this.props.id}
                                        size={'small'}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.onNewLocation}
                                        startIcon={<AddIcon />}
                                        className={this.props.classes.button}>
                                        {t('general.location')}
                                    </Button>

                                    {hasLocations === false && hasChildFolders === false && (
                                        <Button name={'removefolder' + this.props.id} onClick={this.onRemoveFolder}>
                                            &nbsp;
                                            <i style={{ color: 'red' }} className="fas fa-trash fas-link-trash"></i>
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {this.props.type === 'root' && this.props.editMode === true && (
                        <Grid item xs={6}>
                            <Grid
                                container
                                alignItems={'flex-end'}
                                alignContent={'flex-end'}
                                justifyContent={'flex-end'}>
                                <Grid item>
                                    <Button
                                        name={'addfolder' + this.props.id}
                                        size={'small'}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.onNewFolder}
                                        startIcon={<AddIcon />}
                                        className={this.props.classes.button}>
                                        {t('general.folder')}
                                    </Button>
                                    <Button
                                        name={'addlocation' + this.props.id}
                                        size={'small'}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.onNewLocation}
                                        startIcon={<AddIcon />}
                                        className={this.props.classes.button}>
                                        {t('general.location')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {open && (
                    <div>
                        {/*folders*/}
                        {this.props.treedata !== null &&
                            open &&
                            this.props.treedata.childFolders.map((cf) => (
                                <TreeItem
                                    {...this.props}
                                    key={cf.id}
                                    id={cf.id}
                                    treedata={cf}
                                    name={cf.name}
                                    type={'folder'}
                                    level={this.props.level + 1}
                                    orderValue={cf.orderValue}
                                />
                            ))}
                        {/*items*/}
                        {this.props.treedata !== null &&
                            open &&
                            this.props.treedata.locations.map((loc) => (
                                <TreeItem
                                    {...this.props}
                                    key={loc.id}
                                    id={loc.id}
                                    treedata={null}
                                    name={loc.name}
                                    location={loc}
                                    nameSubTxt={
                                        loc.reminderMonthsArr && loc.reminderMonthsArr.length > 0
                                            ? loc.reminderMonthsArr.join(', ')
                                            : null
                                    }
                                    type={'file'}
                                    level={this.props.level}
                                    parentId={this.props.id}
                                    customerId={this.props.customerId}
                                    showWarning={loc.usesRemovedProducts}
                                    orderValue={loc.orderValue}
                                />
                            ))}
                        {open && !hasChildFolders && !hasLocations && (
                            <span
                                style={{
                                    paddingLeft: getPaddingLeft(this.props.level, 'file'),
                                    fontStyle: 'italic',
                                }}>
                                {t('customer.noLocations')}
                            </span>
                        )}
                    </div>
                )}
                {/*</CollapsibleContent>*/}
            </React.Fragment>
        );
    }
}

TreeItem.defaultProps = {
    level: 0,
};

export default withStyles(styles)(TreeItem);
