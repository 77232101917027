import React from 'react';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { MapDataToCustomerYearlyChart } from './mapDataToChartData';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

const CustomerYearlyReport = (props) => {
    const [customerId, setCustomerId] = React.useState(null);
    const [selectedReports, setSelectedReports] = React.useState(null);
    const [selectableCustomers, setSelectableCustomers] = React.useState(null);
    const { t } = useTranslation();

    const getYearlyTotal = (year, yearlyTotalValue) => {
        let index = _.findIndex(yearlyTotalValue, ['year', year]);
        if (index >= 0) {
            return yearlyTotalValue[index];
        }
        return '';
    };

    const chartLabelTooltip = (tooltipItem, data) => {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) {
            label += ': ';
        }
        label += tooltipItem.yLabel.toLocaleString();
        label += ' €';
        return label;
    };

    const chartFooterTooltip = (tooltipItem, data) => {
        if (tooltipItem.length > 0) {
            let total = t('reports.ordersTotal') + ': ';
            total += getYearlyTotal(tooltipItem[0].xLabel, data.totalvalue).totalvalue.toLocaleString();
            total += ' €';
            return total;
        }
        return;
    };

    const handleChange = (evt) => {
        setCustomerId(evt.target.value);
    };

    useEffect(() => {
        if (customerId && props.reports.customerReports) {
            const grouped = _.groupBy(props.reports.customerReports, 'customerId');
            setSelectedReports(grouped[customerId]);
        }
    }, [customerId, props.reports]);

    useEffect(() => {
        if (props.reports && props.reports.customerReports) {
            const grouped = _.groupBy(props.reports.customerReports, 'customerId');
            const selections = Object.keys(grouped).map((id) => {
                return { id, name: grouped[id][0].customerName };
            });
            setSelectableCustomers(selections);
            setCustomerId(selections[0].id);
            setSelectedReports(grouped[selections[0].id]);
        }
    }, [props.reports]);

    if (!props.reports || !selectedReports || !selectableCustomers) {
        return <div>{t('reports.noReports')}</div>;
    }

    const plotWidth = window.innerWidth - 100;
    const yAxisTitle = t('reports.orderKEur');
    const xAxisTitle = t('reports.year');
    const chartData = MapDataToCustomerYearlyChart(selectedReports);
    if (chartData === null) {
        return <div>{t('reports.noReports')}</div>;
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                {t('reports.customership')}:
                <Select value={customerId} onChange={handleChange}>
                    {selectableCustomers.map((p) => (
                        <MenuItem key={p.id} value={p.id}>
                            {p.name}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item>
                <Bar
                    data={chartData}
                    width={plotWidth}
                    height={500}
                    options={{
                        maintainAspectRatio: false,
                        legend: {
                            display: true,
                        },
                        scales: {
                            xAxes: [
                                {
                                    stacked: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: xAxisTitle,
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    stacked: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: yAxisTitle,
                                    },
                                    ticks: {
                                        callback: (value, index, values) => {
                                            return value.toLocaleString();
                                        },
                                    },
                                },
                            ],
                        },
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    return chartLabelTooltip(tooltipItem, data);
                                },
                                footer: (tooltipItem, data) => {
                                    return chartFooterTooltip(tooltipItem, data);
                                },
                            },
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default CustomerYearlyReport;
