import React from 'react';
import Customer from '../domain/customer';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

export default function LocationBillingInfoView(props) {
    let titleText = '';
    let descriptionText = '';
    const { t } = useTranslation();

    if (props.location.useCustomerBillingAddress) {
        titleText = t('location.usesCustomerInvoicingInfo');
        if (props.location.customer.billingNameNotationSetting === Customer.BillingNameNotationUseCustomerName()) {
            descriptionText =
                t('location.invoicingInfoIsBasedOnCustomerInfo') +
                ' ' +
                t('location.invoicingNameFromCustomerInfo') +
                ' ' +
                t('location.businessIdCustNoContractNoFromCustomerInfo');
        } else if (
            props.location.customer.billingNameNotationSetting ===
            Customer.BillingNameNotationUseCustomerAndLocationName()
        ) {
            descriptionText =
                t('location.invoicingInfoIsBasedOnCustomerInfo') +
                ' ' +
                t('location.invoicingNameCombinedFromCustomerAndLocationName') +
                ' ' +
                t('location.businessIdCustNoContractNoFromCustomerInfo');
        } else {
            descriptionText =
                t('location.invoicingInfoIsBasedOnCustomerInfo') +
                ' ' +
                t('location.invoicingNameByCOrule') +
                ' ' +
                t('location.businessIdCustNoContractNoFromLocationInfo');
        }
    } else if (props.location.useBillingCustomerBillingAddress) {
        titleText = 'Kohteessa käytetään laskutusasiakkaan laskutustietoja';
        if (
            props.location.billingCustomer.billingNameNotationSetting === Customer.BillingNameNotationUseCustomerName()
        ) {
            descriptionText =
                t('location.invoicingInfoIsBasedOnInvoicingCustomerInfo') +
                ' ' +
                t('location.invoicingNameFromInvoicingCustomerInfo') +
                ' ' +
                t('location.businessIdCustNoContractNoFromInvoicingCustInfo');
        } else if (
            props.location.billingCustomer.billingNameNotationSetting ===
            Customer.BillingNameNotationUseCustomerAndLocationName()
        ) {
            descriptionText =
                t('location.invoicingInfoIsBasedOnInvoicingCustomerInfo') +
                ' ' +
                t('location.invoicingNameCombinedFromInvCustomerAndLocationName') +
                ' ' +
                t('location.businessIdCustNoContractNoFromInvoicingCustInfo');
        } else {
            descriptionText =
                t('location.invoicingInfoIsBasedOnInvoicingCustomerInfo') +
                ' ' +
                t('location.invoicingNameByCOrule') +
                ' ' +
                t('location.businessIdCustNoContractNoFromLocationInfo');
        }
    }

    if (titleText) {
        return (
            <Alert severity="info">
                <AlertTitle>{titleText}</AlertTitle>
                {descriptionText}
            </Alert>
        );
    }
    return '';
}
