import React, { Component } from 'react';
import ReactGA from 'react-ga4';

if (process && process.env && process.env.REACT_APP_GA_ID) ReactGA.initialize(process.env.REACT_APP_GA_ID);

const withTracker = (WrappedComponent, options = {}) => {
    // eslint-disable-next-line
    const HOC = class extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
};

export default withTracker;
